import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AccessTokenPayload = {
  __typename?: 'AccessTokenPayload';
  id?: Maybe<Scalars['String']>;
  access_token: Scalars['String'];
};

export type AccessTokenResult = {
  __typename?: 'AccessTokenResult';
  access_token: Scalars['String'];
  expired_on: Scalars['Float'];
};

export type AccessTokenResult2 = {
  __typename?: 'AccessTokenResult2';
  access_token: Scalars['String'];
  expired_on: Scalars['String'];
  expires_in: Scalars['Float'];
};

export type Account = {
  __typename?: 'Account';
  id: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  picture_url?: Maybe<Scalars['String']>;
  bot_count?: Maybe<Scalars['Float']>;
};

export type AccountInsightInput = {
  date_range?: Maybe<DateRange>;
  date_format?: Maybe<Scalars['String']>;
  source?: Maybe<AccountSource>;
};

export enum AccountSource {
  Ais = 'AIS',
  Aiya = 'AIYA',
  Line = 'LINE',
  All = 'ALL'
}

export type Action = {
  __typename?: 'Action';
  id: Scalars['String'];
  keyword_id: Scalars['String'];
  facebook_messages?: Maybe<Scalars['JSON']>;
  line_messages?: Maybe<Scalars['JSON']>;
  before_exec?: Maybe<Scalars['JSON']>;
  form_id?: Maybe<Scalars['String']>;
};

export type ActionInput = {
  keyword_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  facebook_messages?: Maybe<Scalars['JSON']>;
  line_messages?: Maybe<Scalars['JSON']>;
  before_exec?: Maybe<Scalars['JSON']>;
  payload?: Maybe<Scalars['JSON']>;
  form_id?: Maybe<Scalars['String']>;
  broadcast_id?: Maybe<Scalars['String']>;
};

export type ActiveUserReportInput = {
  date_range?: Maybe<DateRange>;
  date_format?: Maybe<Scalars['String']>;
  compare_last?: Maybe<Scalars['Float']>;
  keyword_id?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
};

export type Activity = {
  __typename?: 'Activity';
  id: Scalars['String'];
  service: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['JSON']>;
  creation: Scalars['Float'];
  created_by?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  friend?: Maybe<Friend>;
};

export type ActivitySearchFilter = {
  service?: Maybe<ActivityServiceType>;
  services?: Maybe<Array<ActivityServiceType>>;
  creation?: Maybe<DateRange>;
  user_id?: Maybe<Scalars['String']>;
};

export type ActivitySearchInput = {
  page?: Maybe<ActivitySearchPagination>;
  filters?: Maybe<ActivitySearchFilter>;
};

export type ActivitySearchInternalFilter = {
  service?: Maybe<ActivityServiceType>;
  services?: Maybe<Array<ActivityServiceType>>;
  user_id?: Maybe<Scalars['String']>;
  bot_id?: Maybe<Scalars['String']>;
};

export type ActivitySearchInternalInput = {
  page?: Maybe<ActivitySearchInternalPagination>;
  filters?: Maybe<ActivitySearchInternalFilter>;
};

export type ActivitySearchInternalPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type ActivitySearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type ActivitySearchResult = {
  __typename?: 'ActivitySearchResult';
  total: Scalars['Float'];
  next?: Maybe<Scalars['String']>;
  data: Array<Activity>;
};

export enum ActivityServiceType {
  Comment = 'Comment',
  Keyword = 'Keyword',
  Booking = 'Booking',
  Typeform = 'Typeform',
  UpgradeBot = 'UpgradeBot',
  All = 'All'
}

export type ActivitySubscriptionFilter = {
  user_id?: Maybe<Scalars['String']>;
  service?: Maybe<ActivityServiceType>;
};

export type ActivitySubscriptionResult = {
  __typename?: 'ActivitySubscriptionResult';
  data: Activity;
  operation: Operation;
  old?: Maybe<Activity>;
  bot_id?: Maybe<Scalars['String']>;
};

export type AdminInvoice = {
  __typename?: 'AdminInvoice';
  id: Scalars['String'];
  bot_id: Scalars['String'];
  billing_time: Scalars['Float'];
  items: Array<InvoiceItem>;
  total: Scalars['Float'];
  discount: Scalars['Float'];
  total_after_discount: Scalars['Float'];
  vat_amount: Scalars['Float'];
  grand_total: Scalars['Float'];
  status: Scalars['String'];
  creation: Scalars['Float'];
};

export type AdminInvoiceSingleItemInput = {
  bot_id: Scalars['String'];
  billing_time: Scalars['String'];
  sku: Scalars['String'];
};

export type Aggregation = {
  __typename?: 'Aggregation';
  name: Scalars['String'];
  total: Scalars['Float'];
};

export type AggregationResult = {
  __typename?: 'AggregationResult';
  data?: Maybe<Array<Aggregation>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type AisBilling = {
  __typename?: 'AisBilling';
  id: Scalars['ID'];
};

export type AisBillingSearchInput = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type AisBillingSearchResult = {
  __typename?: 'AisBillingSearchResult';
  data?: Maybe<Array<AisBilling>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type AisRequest = {
  __typename?: 'AisRequest';
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  bot_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  business_name?: Maybe<Scalars['String']>;
  status: AisRequestStatus;
};

export type AisRequestInput = {
  title?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  business_name?: Maybe<Scalars['String']>;
};

export type AisRequestSearchFilter = {
  q?: Maybe<Scalars['String']>;
};

export type AisRequestSearchInput = {
  page?: Maybe<AisRequestSearchPagination>;
  filters?: Maybe<AisRequestSearchFilter>;
};

export type AisRequestSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type AisRequestSearchResult = {
  __typename?: 'AisRequestSearchResult';
  data?: Maybe<Array<AisRequest>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export enum AisRequestStatus {
  Pending = 'PENDING',
  Granted = 'GRANTED'
}

export type AisRequestSubscriptionResult = {
  __typename?: 'AisRequestSubscriptionResult';
  data: AisRequest;
  operation: Operation;
  old?: Maybe<AisRequest>;
  bot_id?: Maybe<Scalars['String']>;
};

export type AnalyticReportInput = {
  date_range?: Maybe<DateRange>;
  date_format?: Maybe<Scalars['String']>;
  compare_last?: Maybe<Scalars['Float']>;
};

export type Answer = {
  __typename?: 'Answer';
  id: Scalars['String'];
  lang?: Maybe<Scalars['String']>;
  type?: Maybe<AnswerType>;
  text?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['JSON']>;
  intent_id?: Maybe<Scalars['String']>;
  intent_title?: Maybe<Scalars['String']>;
};

export type AnswerInput = {
  id?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  type?: Maybe<AnswerType>;
  text?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['JSON']>;
  intent_id?: Maybe<Scalars['String']>;
  intent_title?: Maybe<Scalars['String']>;
};

export type AnswerSearchFilter = {
  intent_id?: Maybe<Scalars['String']>;
  type?: Maybe<AnswerType>;
};

export type AnswerSearchInput = {
  page?: Maybe<AnswerSearchPagination>;
  filters?: Maybe<AnswerSearchFilter>;
};

export type AnswerSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type AnswerSearchResult = {
  __typename?: 'AnswerSearchResult';
  data?: Maybe<Array<Answer>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type AnswerSubscriptionResult = {
  __typename?: 'AnswerSubscriptionResult';
  data: Answer;
  operation: Operation;
  old?: Maybe<Answer>;
  bot_id?: Maybe<Scalars['String']>;
};

export enum AnswerType {
  LineBot = 'LineBot',
  FacebookBot = 'FacebookBot',
  FacebookComment = 'FacebookComment',
  FacebookInbox = 'FacebookInbox'
}

export type Apay = {
  __typename?: 'Apay';
  id: Scalars['String'];
  parent_doctype?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  method?: Maybe<PayMethod>;
  status?: Maybe<PayStatus>;
  amount: Scalars['Float'];
  slip_url?: Maybe<Scalars['String']>;
};

export type ApayInput = {
  parent_doctype: Scalars['String'];
  parent_id: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  method?: Maybe<PayMethod>;
  status?: Maybe<PayStatus>;
  amount?: Maybe<Scalars['Float']>;
  slip_url?: Maybe<Scalars['String']>;
};

export type ApaySearchFilter = {
  method?: Maybe<PayMethod>;
  status?: Maybe<PayStatus>;
};

export type ApaySearchInput = {
  page?: Maybe<ApaySearchPagination>;
  filters?: Maybe<ApaySearchFilter>;
};

export type ApaySearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type ApaySearchResult = {
  __typename?: 'ApaySearchResult';
  data?: Maybe<Array<Apay>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type ApaySubscriptionResult = {
  __typename?: 'ApaySubscriptionResult';
  data: Apay;
  operation: Operation;
  old?: Maybe<Apay>;
  bot_id?: Maybe<Scalars['String']>;
};

export enum Bank {
  Promptpay = 'PROMPTPAY',
  Scb = 'SCB',
  Kbank = 'KBANK',
  Bbl = 'BBL',
  Bay = 'BAY',
  Ktb = 'KTB',
  Tmb = 'TMB',
  Gsb = 'GSB',
  Uob = 'UOB'
}

export type BankAccount = {
  __typename?: 'BankAccount';
  id: Scalars['String'];
  account_id?: Maybe<Scalars['String']>;
  account_type?: Maybe<Scalars['String']>;
  account_name?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
};

export type BankAccountInput = {
  id?: Maybe<Scalars['String']>;
  account_id?: Maybe<Scalars['String']>;
  account_type?: Maybe<Scalars['String']>;
  account_name?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
};

export type BankAccountSearchFilter = {
  account_id?: Maybe<Scalars['String']>;
};

export type BankAccountSearchInput = {
  page?: Maybe<BankAccountSearchPagination>;
  filters?: Maybe<BankAccountSearchFilter>;
};

export type BankAccountSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type BankAccountSearchResult = {
  __typename?: 'BankAccountSearchResult';
  data?: Maybe<Array<BankAccount>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type BankAccountSubscriptionResult = {
  __typename?: 'BankAccountSubscriptionResult';
  data: BankAccount;
  operation: Operation;
  old?: Maybe<BankAccount>;
  bot_id?: Maybe<Scalars['String']>;
};

export type BasicResult = {
  __typename?: 'BasicResult';
  success?: Maybe<Scalars['Boolean']>;
  error_message?: Maybe<Scalars['String']>;
};

export enum BillingType {
  Trial = 'TRIAL',
  Premium = 'PREMIUM',
  Enterprise = 'ENTERPRISE'
}

export type Booking = {
  __typename?: 'Booking';
  id: Scalars['ID'];
  code?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['JSON']>;
  status?: Maybe<BookingStatus>;
  sku?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  slot?: Maybe<Slot>;
  user_id?: Maybe<Scalars['String']>;
  confirmed_by?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
};

export type BookingInput = {
  title?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  contact?: Maybe<Scalars['JSON']>;
  schedule: Scalars['String'];
  slot_id: Scalars['String'];
};

export type BookingSearchResult = {
  __typename?: 'BookingSearchResult';
  total: Scalars['Float'];
  next?: Maybe<Scalars['String']>;
  data: Array<Booking>;
};

export enum BookingStatus {
  Pending = 'PENDING',
  UserRequest = 'USER_REQUEST',
  AdminConfirm = 'ADMIN_CONFIRM',
  Canceled = 'CANCELED'
}

export type BookingSubscriptionResult = {
  __typename?: 'BookingSubscriptionResult';
  data: Booking;
  operation: Operation;
  old?: Maybe<Booking>;
  bot_id?: Maybe<Scalars['String']>;
};

export type BookingSummary = {
  __typename?: 'BookingSummary';
  schedule: Scalars['String'];
  timeslots?: Maybe<Array<BookingTimeslot>>;
};

export type BookingSummaryInput = {
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  sku?: Maybe<Scalars['String']>;
  interval?: Maybe<Scalars['String']>;
};

export type BookingTimeslot = {
  __typename?: 'BookingTimeslot';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  time_start: Scalars['String'];
  time_end: Scalars['String'];
  capacity: Scalars['Float'];
  total_booking: Scalars['Float'];
};

export type BookSearchInput = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  status?: Maybe<BookingStatus>;
  sku?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Bot = {
  __typename?: 'Bot';
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Float']>;
  gender?: Maybe<Scalars['String']>;
  business_type?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  picture_url?: Maybe<Scalars['String']>;
  profile?: Maybe<BusinessProfile>;
  created_by?: Maybe<Scalars['String']>;
  creation?: Maybe<Scalars['Float']>;
  modified?: Maybe<Scalars['Float']>;
  modules?: Maybe<Array<Scalars['String']>>;
  chitchat_enabled?: Maybe<Scalars['Float']>;
  fb_app_id?: Maybe<Scalars['String']>;
  friend_limit?: Maybe<Scalars['Float']>;
  sku?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  shop?: Maybe<Shop>;
  billing_type?: Maybe<BillingType>;
  linenotify_token?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  invoice_address?: Maybe<Scalars['String']>;
  contact_number?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  subscription?: Maybe<SubscriptionPackage>;
  subscription_cost?: Maybe<Scalars['Float']>;
  friend_count?: Maybe<Scalars['Float']>;
  owner?: Maybe<Account>;
};

export type BotGroup = {
  __typename?: 'BotGroup';
  id: Scalars['ID'];
};

export type BotInput = {
  title?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  picture_url?: Maybe<Scalars['String']>;
  linenotify_token?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  invoice_address?: Maybe<Scalars['String']>;
  contact_number?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type BotInsightInput = {
  date_range?: Maybe<DateRange>;
  date_format?: Maybe<Scalars['String']>;
  source?: Maybe<AccountSource>;
};

export enum BotPackageCategory {
  Free = 'Free',
  Premium = 'Premium'
}

export type BotPackageSearchFilter = {
  category?: Maybe<BotPackageCategory>;
};

export type BotPackageSearchInput = {
  filters?: Maybe<BotPackageSearchFilter>;
};

export type BotPublic = {
  __typename?: 'BotPublic';
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  picture_url?: Maybe<Scalars['String']>;
};

export enum BotRole {
  Agent = 'agent',
  Admin = 'admin'
}

export type BotSearchResult = {
  __typename?: 'BotSearchResult';
  data?: Maybe<Array<Bot>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type BotSetting = {
  __typename?: 'BotSetting';
  disabled_expires_on?: Maybe<Scalars['Float']>;
  disabled?: Maybe<Scalars['Float']>;
};

export type BotSettingInput = {
  disabled_expires_on?: Maybe<Scalars['Float']>;
  disabled?: Maybe<Scalars['Float']>;
};

export type BotSubscriptionResult = {
  __typename?: 'BotSubscriptionResult';
  data: Bot;
  operation: Operation;
  old?: Maybe<Bot>;
  bot_id?: Maybe<Scalars['String']>;
};

export type BotUpgradeInput = {
  bot_id: Scalars['String'];
  sku: Scalars['String'];
};

export type BotUser = {
  __typename?: 'BotUser';
  user_id: Scalars['String'];
  account?: Maybe<Account>;
};

export type BotUserSearchResult = {
  __typename?: 'BotUserSearchResult';
  data?: Maybe<Array<BotUser>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type Broadcast = {
  __typename?: 'Broadcast';
  id: Scalars['String'];
  bot_id: Scalars['String'];
  title: Scalars['String'];
  schedule?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['String']>;
  channel_id?: Maybe<Scalars['String']>;
  platform: Platform;
  status: BroadcastStatus;
  send_type?: Maybe<BroadcastSendType>;
  audience_type?: Maybe<BroadcastAudienceType>;
  target?: Maybe<Scalars['String']>;
  target_url?: Maybe<Scalars['String']>;
  target_tags?: Maybe<Array<Scalars['String']>>;
  is_some?: Maybe<Scalars['Boolean']>;
  target_group?: Maybe<Scalars['String']>;
  target_limit?: Maybe<Scalars['Float']>;
  stats?: Maybe<BroadcastStats>;
  action?: Maybe<Action>;
};

export type BroadcastAudience = {
  __typename?: 'BroadcastAudience';
  id: Scalars['String'];
  broadcast_id: Scalars['String'];
  user_id: Scalars['String'];
  data?: Maybe<Scalars['JSON']>;
  status?: Maybe<BroadcastAudienceStatus>;
  error_message?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['JSON']>;
  creation?: Maybe<Scalars['Float']>;
  modified?: Maybe<Scalars['Float']>;
};

export type BroadcastAudienceInput = {
  user_id: Scalars['String'];
  data?: Maybe<Scalars['JSON']>;
};

export type BroadcastAudienceSearchFilter = {
  status?: Maybe<BroadcastAudienceStatus>;
};

export type BroadcastAudienceSearchInput = {
  page?: Maybe<BroadcastAudienceSearchPagination>;
  filters?: Maybe<BroadcastAudienceSearchFilter>;
};

export type BroadcastAudienceSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type BroadcastAudienceSearchResult = {
  __typename?: 'BroadcastAudienceSearchResult';
  data?: Maybe<Array<BroadcastAudience>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export enum BroadcastAudienceStatus {
  Pending = 'PENDING',
  Ready = 'READY',
  Sending = 'SENDING',
  Sent = 'SENT',
  BadRequest = 'BAD_REQUEST',
  Overlimit = 'OVERLIMIT'
}

export enum BroadcastAudienceType {
  AllOfLineoa = 'ALL_OF_LINEOA',
  AllOfAcrm = 'ALL_OF_ACRM',
  Group = 'GROUP',
  Tag = 'TAG',
  File = 'FILE'
}

export type BroadcastInput = {
  title: Scalars['String'];
  schedule?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  platform: Scalars['String'];
  channel_id: Scalars['String'];
  send_type?: Maybe<BroadcastSendType>;
  status?: Maybe<BroadcastStatus>;
  audience_type?: Maybe<BroadcastAudienceType>;
  target?: Maybe<Scalars['String']>;
  target_url?: Maybe<Scalars['String']>;
  target_tags?: Maybe<Array<Scalars['String']>>;
  is_some?: Maybe<Scalars['Boolean']>;
  target_group?: Maybe<Scalars['String']>;
  target_limit?: Maybe<Scalars['Float']>;
};

export type BroadcastRetryInput = {
  status: Scalars['Float'];
};

export type BroadcastSearchFilter = {
  status?: Maybe<BroadcastStatus>;
};

export type BroadcastSearchInput = {
  page?: Maybe<BroadcastSearchPagination>;
  filters?: Maybe<BroadcastSearchFilter>;
};

export type BroadcastSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type BroadcastSearchResult = {
  __typename?: 'BroadcastSearchResult';
  data?: Maybe<Array<Broadcast>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type BroadcastSendInput = {
  size?: Maybe<Scalars['Float']>;
  concurrency?: Maybe<Scalars['Float']>;
};

export enum BroadcastSendType {
  Now = 'NOW',
  Schedule = 'SCHEDULE'
}

export type BroadcastStats = {
  __typename?: 'BroadcastStats';
  total: Scalars['Float'];
  sent: Scalars['Float'];
  sending: Scalars['Float'];
  error: Scalars['Float'];
  ready: Scalars['Float'];
};

export enum BroadcastStatus {
  Pending = 'PENDING',
  Scheduled = 'SCHEDULED',
  Ready = 'READY',
  Sending = 'SENDING',
  Sent = 'SENT',
  Canceled = 'CANCELED'
}

export type BroadcastSubscriptionResult = {
  __typename?: 'BroadcastSubscriptionResult';
  data: BroadcastSubscrition;
  operation: Operation;
  old?: Maybe<BroadcastSubscrition>;
  bot_id?: Maybe<Scalars['String']>;
};

export type BroadcastSubscrition = {
  __typename?: 'BroadcastSubscrition';
  id: Scalars['String'];
};

export type BroadcastUploadAudienceInput = {
  broadcast_id: Scalars['String'];
  audiences: Array<BroadcastAudienceInput>;
};

export type BulkUpdateResult = {
  __typename?: 'BulkUpdateResult';
  total: Scalars['Float'];
  success?: Maybe<Scalars['Boolean']>;
  error_message?: Maybe<Scalars['String']>;
};

export type BusinessProfile = {
  __typename?: 'BusinessProfile';
  title?: Maybe<Scalars['String']>;
  open_hours?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
};

export type Card = {
  __typename?: 'Card';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['String']>;
  agent_id?: Maybe<Scalars['String']>;
  friend_id?: Maybe<Scalars['String']>;
  friend_name?: Maybe<Scalars['String']>;
  is_accept?: Maybe<Scalars['Boolean']>;
  created_by: Scalars['String'];
  creation: Scalars['Float'];
};

export type CardInput = {
  title?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['String']>;
  agent_id?: Maybe<Scalars['String']>;
  friend_id?: Maybe<Scalars['String']>;
  friend_name?: Maybe<Scalars['String']>;
};

export type CardSearchFilter = {
  q?: Maybe<Scalars['String']>;
};

export type CardSearchInput = {
  page?: Maybe<CardSearchPagination>;
  filters?: Maybe<CardSearchFilter>;
};

export type CardSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type CardSearchResult = {
  __typename?: 'CardSearchResult';
  data?: Maybe<Array<Card>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type CardSubscriptionResult = {
  __typename?: 'CardSubscriptionResult';
  data: Card;
  operation: Operation;
  old?: Maybe<Card>;
  bot_id?: Maybe<Scalars['String']>;
};

export type ChangeItemInput = {
  product_id: Scalars['String'];
  qty: Scalars['Float'];
};

export type ChangeItemQtyInput = {
  items: Array<ChangeItemInput>;
};

export type Channel = {
  __typename?: 'Channel';
  id: Scalars['String'];
  bot_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  enabled?: Maybe<Scalars['Float']>;
  channel_secret?: Maybe<Scalars['String']>;
  access_token?: Maybe<Scalars['String']>;
  picture_url?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  basic_id?: Maybe<Scalars['String']>;
  line_basic_id?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['JSON']>;
  detached?: Maybe<Scalars['Float']>;
};

export type ChannelInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Float']>;
  channel_secret?: Maybe<Scalars['String']>;
  access_token?: Maybe<Scalars['String']>;
  picture_url?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  basic_id?: Maybe<Scalars['String']>;
  detached?: Maybe<Scalars['Float']>;
};

export type ChannelSearchFilter = {
  platform?: Maybe<Platform>;
  id?: Maybe<Scalars['String']>;
};

export type ChannelSearchInput = {
  page?: Maybe<ChannelSearchPagination>;
  filters?: Maybe<ChannelSearchFilter>;
};

export type ChannelSearchInput2 = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  filters?: Maybe<ChannelSearchFilter>;
};

export type ChannelSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type ChannelSearchResult = {
  __typename?: 'ChannelSearchResult';
  data?: Maybe<Array<Channel>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type ChannelSubscriptionResult = {
  __typename?: 'ChannelSubscriptionResult';
  data: Channel;
  operation: Operation;
  old?: Maybe<Channel>;
  bot_id?: Maybe<Scalars['String']>;
};

export type Chat = {
  __typename?: 'Chat';
  id: Scalars['String'];
  bot_id: Scalars['String'];
  channel_id: Scalars['String'];
  chat_id: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Float']>;
  source?: Maybe<ChatSource>;
  message?: Maybe<Scalars['JSON']>;
  intent?: Maybe<ChatIntent>;
  postback?: Maybe<MessagePostback>;
  full_name?: Maybe<Scalars['String']>;
};

export type ChatExportInput = {
  query_string?: Maybe<Scalars['String']>;
  source_type?: Maybe<Scalars['String']>;
  intent_id?: Maybe<Scalars['String']>;
  channel_id?: Maybe<Scalars['String']>;
  date_range?: Maybe<DateRange>;
};

export type ChatInput = {
  id: Scalars['String'];
  bot_id?: Maybe<Scalars['String']>;
  channel_id: Scalars['String'];
  chat_id: Scalars['String'];
  type: Scalars['String'];
  platform: Scalars['String'];
  timestamp: Scalars['Float'];
  source: ChatSourceInput;
  message: Scalars['JSON'];
  intent?: Maybe<ChatIntentInput>;
  postback?: Maybe<MessagePostbackInput>;
};

export type ChatInsightInput = {
  date_range?: Maybe<DateRange>;
  date_format?: Maybe<Scalars['String']>;
  channel_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ChatIntent = {
  __typename?: 'ChatIntent';
  title?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  confidence?: Maybe<Scalars['Float']>;
};

export type ChatIntentHits = {
  __typename?: 'ChatIntentHits';
  title: Scalars['String'];
  count: Scalars['Float'];
};

export type ChatIntentHitsResult = {
  __typename?: 'ChatIntentHitsResult';
  data?: Maybe<Array<ChatIntentHits>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type ChatIntentInput = {
  title?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  confidence?: Maybe<Scalars['Float']>;
};

export type ChatMessageInput = {
  type?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['JSON']>;
  attachment?: Maybe<Scalars['JSON']>;
  originalContentUrl?: Maybe<Scalars['String']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  stickerId?: Maybe<Scalars['String']>;
  packageId?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSON']>;
  template?: Maybe<Scalars['String']>;
};

export type ChatSearchFilter = {
  type?: Maybe<Scalars['String']>;
  channel_id?: Maybe<Scalars['String']>;
  intent_id?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
};

export type ChatSearchInput = {
  inbox_id?: Maybe<Scalars['String']>;
  page?: Maybe<ChatSearchPagination>;
  filters?: Maybe<ChatSearchFilter>;
};

export type ChatSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type ChatSearchResult = {
  __typename?: 'ChatSearchResult';
  data?: Maybe<Array<Chat>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type ChatSource = {
  __typename?: 'ChatSource';
  type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChatSourceInput = {
  type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChatStat = {
  __typename?: 'ChatStat';
  total_chats: Scalars['Float'];
  total_intent_chats: Scalars['Float'];
  total_unknown_chats: Scalars['Float'];
};

export type ChatStatInput = {
  intent_id?: Maybe<Scalars['String']>;
  channel_id?: Maybe<Scalars['String']>;
};

export enum ChatStatus {
  Default = 'DEFAULT',
  FollowedUp = 'FOLLOWED_UP',
  Done = 'DONE',
  Spam = 'SPAM',
  All = 'ALL'
}

export type ChatSubscriptionResult = {
  __typename?: 'ChatSubscriptionResult';
  data: Chat;
  operation: Operation;
  old?: Maybe<Chat>;
  bot_id?: Maybe<Scalars['String']>;
};

export type Chitchat = {
  __typename?: 'Chitchat';
  question: Scalars['String'];
  answer: Scalars['String'];
};

export type ChitchatSearchFilter = {
  q?: Maybe<Scalars['String']>;
};

export type ChitchatSearchInput = {
  page?: Maybe<ChitchatSearchPagination>;
  filters?: Maybe<ChitchatSearchFilter>;
};

export type ChitchatSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type ChitchatSearchResult = {
  __typename?: 'ChitchatSearchResult';
  data?: Maybe<Array<Chitchat>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type CommentActivityInput = {
  message: Scalars['String'];
  user_id: Scalars['String'];
};

export type CommentActivityUpdate = {
  message: Scalars['String'];
};

export type Contact = {
  __typename?: 'Contact';
  id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  tax_id?: Maybe<Scalars['Float']>;
  branch?: Maybe<Scalars['String']>;
  person?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['Float']>;
};

export type ContactFa = {
  __typename?: 'ContactFA';
  id: Scalars['Float'];
  contactName: Scalars['String'];
};

export type ContactInput = {
  user_id: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  tax_id?: Maybe<Scalars['Float']>;
  branc?: Maybe<Scalars['String']>;
  person?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['Float']>;
};

export type ContactSearchFilterFa = {
  customerName?: Maybe<Scalars['String']>;
};

export type ContactSearchInputFa = {
  page?: Maybe<ContactSearchPaginationFa>;
  filters?: Maybe<ContactSearchFilterFa>;
};

export type ContactSearchPaginationFa = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type ContactSearchResultFa = {
  __typename?: 'ContactSearchResultFA';
  data?: Maybe<Array<ContactFa>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type Content = {
  __typename?: 'Content';
  id: Scalars['String'];
  content_type: Scalars['String'];
  bot_id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  fields?: Maybe<Array<ContentField>>;
  creation?: Maybe<Scalars['Float']>;
  modified?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  body?: Maybe<Scalars['JSON']>;
};

export type ContentField = {
  __typename?: 'ContentField';
  field_name: Scalars['String'];
  field_value: Scalars['String'];
};

export type ContentFieldInput = {
  field_name: Scalars['String'];
  field_value: Scalars['String'];
};

export type ContentInput = {
  title: Scalars['String'];
  content_type?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['JSON']>;
  weight?: Maybe<Scalars['Float']>;
};

export type ContentSearchFilter = {
  title?: Maybe<Scalars['String']>;
  content_type?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<ContentFieldInput>>;
};

export type ContentSearchInput = {
  page?: Maybe<ContentSearchPagination>;
  filters?: Maybe<ContentSearchFilter>;
  sort_by?: Maybe<Array<SortBy>>;
};

export type ContentSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type ContentSearchResult = {
  __typename?: 'ContentSearchResult';
  data?: Maybe<Array<Content>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type ContentType = {
  __typename?: 'ContentType';
  id: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
  bot_id?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<ContentTypeField>>;
  creation?: Maybe<Scalars['Float']>;
  modified?: Maybe<Scalars['Float']>;
};

export type ContentTypeField = {
  __typename?: 'ContentTypeField';
  title: Scalars['String'];
  name: Scalars['String'];
  type: FieldType;
  required?: Maybe<Scalars['Boolean']>;
  default_value?: Maybe<Scalars['String']>;
};

export type ContentTypeFieldInput = {
  title: Scalars['String'];
  name: Scalars['String'];
  type: FieldType;
  required?: Maybe<Scalars['Boolean']>;
  default_value?: Maybe<Scalars['String']>;
};

export type ContentTypeInput = {
  title: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<ContentTypeFieldInput>>;
};

export type ContentTypeSearchFilter = {
  q?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ContentTypeSearchInput = {
  page?: Maybe<ContentTypeSearchPagination>;
  filters?: Maybe<ContentTypeSearchFilter>;
};

export type ContentTypeSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type ContentTypeSearchResult = {
  __typename?: 'ContentTypeSearchResult';
  data?: Maybe<Array<ContentType>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type ContentTypeSubscriptionResult = {
  __typename?: 'ContentTypeSubscriptionResult';
  data: ContentTypeSubscrition;
  operation: Operation;
  old?: Maybe<ContentTypeSubscrition>;
  bot_id?: Maybe<Scalars['String']>;
};

export type ContentTypeSubscrition = {
  __typename?: 'ContentTypeSubscrition';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<ContentTypeField>>;
};

export type CreateBotDto = {
  title: Scalars['String'];
  template_id: Scalars['String'];
  template_name: Scalars['String'];
  business_type: Scalars['String'];
  sku: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  friend_limit?: Maybe<Scalars['Float']>;
  linenotify_token?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  invoice_address?: Maybe<Scalars['String']>;
  contact_number?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  msisdn?: Maybe<Scalars['String']>;
};

export type DateRange = {
  start_date: Scalars['String'];
  end_date: Scalars['String'];
};


export type DeliveryNote = {
  __typename?: 'DeliveryNote';
  id: Scalars['String'];
  parent_doctype?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['String']>;
  receiver?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  shipping_id?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
  shipping?: Maybe<Shipping>;
};

export type DeliveryNoteInput = {
  id?: Maybe<Scalars['String']>;
  parent_doctype?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['String']>;
  receiver?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  shipping_id?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
};

export type DeliveryNoteSearchFilter = {
  shipping_id?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['String']>;
};

export type DeliveryNoteSearchInput = {
  page?: Maybe<DeliveryNoteSearchPagination>;
  filters?: Maybe<DeliveryNoteSearchFilter>;
};

export type DeliveryNoteSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type DeliveryNoteSearchResult = {
  __typename?: 'DeliveryNoteSearchResult';
  data?: Maybe<Array<DeliveryNote>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type DeliveryNoteSubscriptionResult = {
  __typename?: 'DeliveryNoteSubscriptionResult';
  data: DeliveryNote;
  operation: Operation;
  old?: Maybe<DeliveryNote>;
  bot_id?: Maybe<Scalars['String']>;
};

export type FacebookOverview = {
  __typename?: 'FacebookOverview';
  like: Scalars['Float'];
  total_comments: Scalars['Float'];
  total_post_likes: Scalars['Float'];
  total_shares: Scalars['Float'];
};

export type FacebookPost = {
  __typename?: 'FacebookPost';
  id: Scalars['String'];
  picture?: Maybe<Scalars['String']>;
  full_picture?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  scheduled_publish_time?: Maybe<Scalars['Float']>;
};

export type FbComment = {
  __typename?: 'FbComment';
  id: Scalars['String'];
  page_id?: Maybe<Scalars['String']>;
  post_id?: Maybe<Scalars['String']>;
  comment_id?: Maybe<Scalars['String']>;
  sender_id?: Maybe<Scalars['String']>;
  sender_name?: Maybe<Scalars['String']>;
  picture_url?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Float']>;
  medium_id?: Maybe<Scalars['String']>;
};

export type FbCommentInput = {
  id?: Maybe<Scalars['String']>;
  page_id?: Maybe<Scalars['String']>;
  post_id?: Maybe<Scalars['String']>;
  comment_id?: Maybe<Scalars['String']>;
  sender_id?: Maybe<Scalars['String']>;
  sender_name?: Maybe<Scalars['String']>;
  picture_url?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Float']>;
  medium_id?: Maybe<Scalars['String']>;
};

export type FbCommentSearchFilter = {
  page_id?: Maybe<Scalars['String']>;
  post_id?: Maybe<Scalars['String']>;
  sender_id?: Maybe<Scalars['String']>;
  medium_id?: Maybe<Scalars['String']>;
};

export type FbCommentSearchInput = {
  page?: Maybe<FbCommentSearchPagination>;
  filters?: Maybe<FbCommentSearchFilter>;
};

export type FbCommentSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type FbCommentSearchResult = {
  __typename?: 'FbCommentSearchResult';
  data?: Maybe<Array<FbComment>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type FbCommentSubscriptionFilter = {
  post_id?: Maybe<Scalars['String']>;
  medium_id?: Maybe<Scalars['String']>;
};

export type FbCommentSubscriptionResult = {
  __typename?: 'FbCommentSubscriptionResult';
  data: FbComment;
  operation: Operation;
  old?: Maybe<FbComment>;
  bot_id?: Maybe<Scalars['String']>;
};

export type FbCommentUser = {
  __typename?: 'FbCommentUser';
  id: Scalars['String'];
  page_id?: Maybe<Scalars['String']>;
  post_id?: Maybe<Scalars['String']>;
  comment_id?: Maybe<Scalars['String']>;
  sender_id?: Maybe<Scalars['String']>;
  sender_name?: Maybe<Scalars['String']>;
  picture_url?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Float']>;
};

export type FbPost = {
  __typename?: 'FBPost';
  id: Scalars['String'];
  page_id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  full_picture?: Maybe<Scalars['String']>;
};

export type FbPostInput = {
  id?: Maybe<Scalars['String']>;
  page_id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  full_picture?: Maybe<Scalars['String']>;
};

export type FbPostSearchFilter = {
  title?: Maybe<Scalars['String']>;
};

export type FbPostSearchInput = {
  page?: Maybe<FbPostSearchPagination>;
  filters?: Maybe<FbPostSearchFilter>;
};

export type FbPostSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type FbPostSearchResult = {
  __typename?: 'FBPostSearchResult';
  data?: Maybe<Array<FbPost>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type FbPostSubscriptionResult = {
  __typename?: 'FBPostSubscriptionResult';
  data: FbPost;
  operation: Operation;
  old?: Maybe<FbPost>;
  bot_id?: Maybe<Scalars['String']>;
};

export enum FieldType {
  Text = 'Text',
  RichText = 'RichText',
  Number = 'Number',
  Date = 'Date',
  Boolean = 'Boolean',
  Email = 'Email',
  Password = 'Password',
  Enumeration = 'Enumeration',
  Media = 'Media',
  Json = 'JSON',
  Uid = 'UID',
  Relation = 'Relation'
}

export type File = {
  __typename?: 'File';
  id: Scalars['String'];
  filename: Scalars['String'];
  url: Scalars['String'];
  mimetype: Scalars['String'];
  encoding: Scalars['String'];
};

export type Form = {
  __typename?: 'Form';
  id: Scalars['String'];
  bot_id?: Maybe<Scalars['String']>;
  form_type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  creation?: Maybe<Scalars['Float']>;
  modified?: Maybe<Scalars['Float']>;
  greeting_keyword?: Maybe<Scalars['String']>;
  completed_keyword?: Maybe<Scalars['String']>;
  questions?: Maybe<Array<Question>>;
  greeting_enabled?: Maybe<Scalars['Float']>;
  hidden_values?: Maybe<Scalars['JSON']>;
};

export type FormInput = {
  title: Scalars['String'];
  form_type: Scalars['String'];
};

export type FormSearchFilter = {
  q?: Maybe<Scalars['String']>;
};

export type FormSearchInput = {
  page?: Maybe<FormSearchPagination>;
  filters?: Maybe<FormSearchFilter>;
};

export type FormSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type FormSearchResult = {
  __typename?: 'FormSearchResult';
  data?: Maybe<Array<Form>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type FormSubscriptionResult = {
  __typename?: 'FormSubscriptionResult';
  data: Form;
  operation: Operation;
  old?: Maybe<Form>;
  bot_id?: Maybe<Scalars['String']>;
};

export type Friend = {
  __typename?: 'Friend';
  id: Scalars['String'];
  bot_id?: Maybe<Scalars['String']>;
  social_id?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  picture_url?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  channel_id?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<ChatStatus>;
  connected?: Maybe<Scalars['Float']>;
  connected_on?: Maybe<Scalars['Float']>;
  blocked?: Maybe<Scalars['Float']>;
  creation?: Maybe<Scalars['Float']>;
  modified?: Maybe<Scalars['Float']>;
  member?: Maybe<Scalars['JSON']>;
  contact?: Maybe<Scalars['JSON']>;
  slot?: Maybe<Scalars['JSON']>;
  rating?: Maybe<Scalars['Float']>;
  about?: Maybe<Scalars['String']>;
  behavior_tags?: Maybe<Array<Scalars['String']>>;
  area_tags?: Maybe<Array<Scalars['String']>>;
  interested_tags?: Maybe<Array<Scalars['String']>>;
  lead_tags?: Maybe<Array<Scalars['String']>>;
  purchased_tags?: Maybe<Array<Scalars['String']>>;
  reached_tags?: Maybe<Array<Scalars['String']>>;
  roles?: Maybe<Array<Scalars['String']>>;
  aiya_data?: Maybe<FriendAiyaData>;
  chat_mode?: Maybe<Scalars['JSON']>;
  inbox?: Maybe<Inbox>;
};

export type FriendAiyaData = {
  __typename?: 'FriendAIYAData';
  user_id: Scalars['String'];
  bots: Array<Bot>;
};

export type FriendGroup = {
  __typename?: 'FriendGroup';
  id: Scalars['ID'];
  title: Scalars['String'];
  favorite?: Maybe<Scalars['Boolean']>;
  is_some?: Maybe<Scalars['Boolean']>;
  includes?: Maybe<Array<Scalars['String']>>;
  excludes?: Maybe<Array<Scalars['String']>>;
  module?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  count?: Maybe<Scalars['Float']>;
};

export type FriendGroupInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  favorite?: Maybe<Scalars['Boolean']>;
  is_some?: Maybe<Scalars['Boolean']>;
  includes?: Maybe<Array<Scalars['String']>>;
  excludes?: Maybe<Array<Scalars['String']>>;
  user_id?: Maybe<Scalars['String']>;
  module?: Maybe<Scalars['String']>;
};

export type FriendGroupSearchFilter = {
  title?: Maybe<Scalars['String']>;
  favorite?: Maybe<Scalars['Boolean']>;
};

export type FriendGroupSearchInput = {
  page?: Maybe<FriendGroupSearchPagination>;
  filters?: Maybe<FriendGroupSearchFilter>;
};

export type FriendGroupSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type FriendGroupSearchResult = {
  __typename?: 'FriendGroupSearchResult';
  data?: Maybe<Array<FriendGroup>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type FriendInput = {
  id?: Maybe<Scalars['String']>;
  bot_id?: Maybe<Scalars['String']>;
  social_id?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  picture_url?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  channel_id?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<ChatStatus>;
  connected?: Maybe<Scalars['Float']>;
  connected_on?: Maybe<Scalars['Float']>;
  blocked?: Maybe<Scalars['Float']>;
  creation?: Maybe<Scalars['Float']>;
  modified?: Maybe<Scalars['Float']>;
  member?: Maybe<Scalars['JSON']>;
  contact?: Maybe<Scalars['JSON']>;
  slot?: Maybe<Scalars['JSON']>;
  rating?: Maybe<Scalars['Float']>;
  about?: Maybe<Scalars['String']>;
  behavior_tags?: Maybe<Array<Scalars['String']>>;
  area_tags?: Maybe<Array<Scalars['String']>>;
  interested_tags?: Maybe<Array<Scalars['String']>>;
  lead_tags?: Maybe<Array<Scalars['String']>>;
  purchased_tags?: Maybe<Array<Scalars['String']>>;
  reached_tags?: Maybe<Array<Scalars['String']>>;
  roles?: Maybe<Array<Scalars['String']>>;
};

export type FriendInsightInput = {
  date_range?: Maybe<DateRange>;
  date_format?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
};

export type FriendSearchFilter = {
  tags?: Maybe<Scalars['String']>;
  q?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  exists?: Maybe<Array<Scalars['String']>>;
  tags_exist_some?: Maybe<Array<Scalars['String']>>;
  tags_exist_all?: Maybe<Array<Scalars['String']>>;
  tags_exclude?: Maybe<Array<Scalars['String']>>;
  last_active_in?: Maybe<Scalars['Float']>;
};

export type FriendSearchInput = {
  page?: Maybe<FriendSearchPagination>;
  filters?: Maybe<FriendSearchFilter>;
};

export type FriendSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type FriendSearchResult = {
  __typename?: 'FriendSearchResult';
  data?: Maybe<Array<Friend>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type FriendStat = {
  __typename?: 'FriendStat';
  blocked?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  targetReach?: Maybe<Scalars['Float']>;
  facebook?: Maybe<Scalars['Float']>;
  line?: Maybe<Scalars['Float']>;
};

export type FriendSubscriptionResult = {
  __typename?: 'FriendSubscriptionResult';
  data: Friend;
  operation: Operation;
  old?: Maybe<Friend>;
  bot_id?: Maybe<Scalars['String']>;
};

export type ImportLiveProductInput = {
  products: Array<ProductInput>;
};

export type ImportResult = {
  __typename?: 'ImportResult';
  total: Scalars['Float'];
};

export type Inbox = {
  __typename?: 'Inbox';
  id: Scalars['String'];
  chat_id: Scalars['String'];
  bot_id: Scalars['String'];
  title: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  picture_url?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  creation: Scalars['Float'];
  timestamp?: Maybe<Scalars['Float']>;
  platform?: Maybe<Platform>;
  channel_id?: Maybe<Scalars['String']>;
  status?: Maybe<ChatStatus>;
  event?: Maybe<Scalars['JSON']>;
  read?: Maybe<Scalars['Boolean']>;
  bot?: Maybe<BotSetting>;
  bot_disabled: Scalars['Boolean'];
};

export type InboxInput = {
  id?: Maybe<Scalars['String']>;
  chat_id?: Maybe<Scalars['String']>;
  bot_id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  picture_url?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  timestamp?: Maybe<Scalars['Float']>;
  platform?: Maybe<Platform>;
  channel_id?: Maybe<Scalars['String']>;
  status?: Maybe<ChatStatus>;
  event?: Maybe<Scalars['JSON']>;
  read?: Maybe<Scalars['Boolean']>;
  bot?: Maybe<BotSettingInput>;
};

export type InboxSearchFilter = {
  q?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  platform?: Maybe<Platform>;
  channel_id?: Maybe<Scalars['String']>;
  status?: Maybe<ChatStatus>;
  creation?: Maybe<DateRange>;
};

export type InboxSearchInput = {
  page?: Maybe<InboxSearchPagination>;
  filters?: Maybe<InboxSearchFilter>;
};

export type InboxSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type InboxSearchResult = {
  __typename?: 'InboxSearchResult';
  data?: Maybe<Array<Inbox>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type InboxSubscriptionFilter = {
  inbox_id?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  platform?: Maybe<Platform>;
  channel_id?: Maybe<Scalars['String']>;
  status?: Maybe<ChatStatus>;
};

export type InboxSubscriptionResult = {
  __typename?: 'InboxSubscriptionResult';
  data: Inbox;
  operation: Operation;
  old?: Maybe<Inbox>;
  bot_id?: Maybe<Scalars['String']>;
};

export type InsightResult = {
  __typename?: 'InsightResult';
  series: Array<Series>;
  categories: Array<Scalars['String']>;
};

export type Intent = {
  __typename?: 'Intent';
  id: Scalars['String'];
  bot_id?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  type?: Maybe<IntentType>;
  title?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  deleted?: Maybe<Scalars['Boolean']>;
  is_hidden_comment?: Maybe<Scalars['Boolean']>;
  is_delete_comment?: Maybe<Scalars['Boolean']>;
  domain?: Maybe<Scalars['String']>;
  context?: Maybe<Scalars['String']>;
  payload_type?: Maybe<PayloadType>;
  payload?: Maybe<Scalars['JSON']>;
  weight?: Maybe<Scalars['Float']>;
  sentences?: Maybe<Array<Sentence>>;
  answers?: Maybe<Array<Answer>>;
  answer_fbbot?: Maybe<Answer>;
};

export type IntentInput = {
  id?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  type?: Maybe<IntentType>;
  title?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  deleted?: Maybe<Scalars['Boolean']>;
  is_hidden_comment?: Maybe<Scalars['Boolean']>;
  is_delete_comment?: Maybe<Scalars['Boolean']>;
  domain?: Maybe<Scalars['String']>;
  context?: Maybe<Scalars['String']>;
  payload_type?: Maybe<PayloadType>;
  payload?: Maybe<Scalars['JSON']>;
  weight?: Maybe<Scalars['Float']>;
};

export type IntentReport = {
  __typename?: 'IntentReport';
  title: Scalars['String'];
  count: Scalars['Float'];
};

export type IntentReportResult = {
  __typename?: 'IntentReportResult';
  data?: Maybe<Array<IntentReport>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type IntentSearchFilter = {
  type?: Maybe<IntentType>;
  context?: Maybe<Scalars['String']>;
};

export type IntentSearchInput = {
  page?: Maybe<IntentSearchPagination>;
  filters?: Maybe<IntentSearchFilter>;
};

export type IntentSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type IntentSearchResult = {
  __typename?: 'IntentSearchResult';
  data?: Maybe<Array<Intent>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type IntentSubscriptionResult = {
  __typename?: 'IntentSubscriptionResult';
  data: Intent;
  operation: Operation;
  old?: Maybe<Intent>;
  bot_id?: Maybe<Scalars['String']>;
};

export enum IntentType {
  Keyword = 'Keyword',
  Comment = 'Comment',
  Live = 'Live'
}

export type Invitation = {
  __typename?: 'Invitation';
  id: Scalars['String'];
  module: Scalars['String'];
  module_id: Scalars['String'];
  permission_type: Scalars['String'];
  permission_role: Scalars['String'];
  created_by: Scalars['String'];
  creation: Scalars['Float'];
  expired_on: Scalars['Float'];
  activated: Scalars['Float'];
};

export type InvitationActivationInput = {
  user_id: Scalars['String'];
  id: Scalars['String'];
  module: Scalars['String'];
  module_id: Scalars['String'];
  permission_type: Scalars['String'];
  permission_role: Scalars['String'];
  created_by: Scalars['String'];
};

export type InvitationInput = {
  module: Scalars['String'];
  module_id: Scalars['String'];
  permission_type: Scalars['String'];
  permission_role: Scalars['String'];
  created_by: Scalars['String'];
};

export type InvitationSearchFilter = {
  module?: Maybe<Scalars['String']>;
};

export type InvitationSearchInput = {
  page?: Maybe<InvitationSearchPagination>;
  filters?: Maybe<InvitationSearchFilter>;
};

export type InvitationSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type InvitationSearchResult = {
  __typename?: 'InvitationSearchResult';
  total: Scalars['Float'];
  next?: Maybe<Scalars['String']>;
  data: Array<Invitation>;
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  qty: Scalars['Float'];
  price: Scalars['Float'];
  amount: Scalars['Float'];
};

export type InvoiceSearchResult = {
  __typename?: 'InvoiceSearchResult';
  data?: Maybe<Array<AdminInvoice>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type InvoiceSubscriptionResult = {
  __typename?: 'InvoiceSubscriptionResult';
  data: AdminInvoice;
  operation: Operation;
  old?: Maybe<AdminInvoice>;
  bot_id?: Maybe<Scalars['String']>;
};

export type IssueTicketInput = {
  sku?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  issued_by?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['String']>;
};

export type Item = {
  __typename?: 'Item';
  id?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  picture_url?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['Float']>;
  unit_name?: Maybe<Scalars['String']>;
  price_per_unit?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  discount_amount?: Maybe<Scalars['Float']>;
  vat_rate?: Maybe<Scalars['Float']>;
  medium_id?: Maybe<Scalars['String']>;
  sender_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  profile_pic?: Maybe<Scalars['String']>;
  salesOrder?: Maybe<SalesOrder>;
};

export type ItemInput = {
  parent_id?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  picture_url?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['Float']>;
  unit_name?: Maybe<Scalars['String']>;
  price_per_unit?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  discount_amount?: Maybe<Scalars['Float']>;
  vat_rate?: Maybe<Scalars['Float']>;
  medium_id?: Maybe<Scalars['String']>;
  sender_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  profile_pic?: Maybe<Scalars['String']>;
};

export type ItemSearchFilter = {
  medium_id?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['String']>;
  sender_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

export type ItemSearchInput = {
  page?: Maybe<ItemSearchPagination>;
  filters?: Maybe<ItemSearchFilter>;
};

export type ItemSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type ItemSearchResult = {
  __typename?: 'ItemSearchResult';
  data?: Maybe<Array<Item>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type ItemSubscriptionFilter = {
  medium_id: Scalars['String'];
};

export type ItemSubscriptionResult = {
  __typename?: 'ItemSubscriptionResult';
  data: Item;
  operation: Operation;
  old?: Maybe<Item>;
  bot_id?: Maybe<Scalars['String']>;
};


export type Keyword = {
  __typename?: 'Keyword';
  bot_id?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type?: Maybe<KeywordType>;
  published?: Maybe<Scalars['Float']>;
  sentences?: Maybe<Array<KeywordSentence>>;
  plugins?: Maybe<Array<Plugin>>;
  is_linenotify?: Maybe<Scalars['Boolean']>;
  linenotify_payload?: Maybe<Scalars['JSON']>;
  is_autotag?: Maybe<Scalars['Boolean']>;
  autotags?: Maybe<Array<Scalars['String']>>;
  is_api?: Maybe<Scalars['Boolean']>;
  api_payload?: Maybe<Scalars['JSON']>;
  keywords?: Maybe<Array<Scalars['String']>>;
  action?: Maybe<Action>;
};

export type KeywordInput = {
  title?: Maybe<Scalars['String']>;
  type?: Maybe<KeywordType>;
};

export type KeywordLink = {
  __typename?: 'KeywordLink';
  id: Scalars['String'];
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  creation?: Maybe<Scalars['Float']>;
};

export type KeywordLinkSearchFilter = {
  group?: Maybe<Scalars['String']>;
};

export type KeywordLinkSearchInput = {
  page?: Maybe<KeywordLinkSearchPagination>;
  filters?: Maybe<KeywordLinkSearchFilter>;
};

export type KeywordLinkSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type KeywordLinkSearchResult = {
  __typename?: 'KeywordLinkSearchResult';
  data?: Maybe<Array<KeywordLink>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type KeywordLinkSubscriptionResult = {
  __typename?: 'KeywordLinkSubscriptionResult';
  data: KeywordLink;
  operation: Operation;
  old?: Maybe<KeywordLink>;
  bot_id?: Maybe<Scalars['String']>;
};

export type KeywordSearchFilter = {
  q?: Maybe<Scalars['String']>;
  source?: Maybe<KeywordSource>;
};

export type KeywordSearchInput = {
  page?: Maybe<KeywordSearchPagination>;
  filters?: Maybe<KeywordSearchFilter>;
};

export type KeywordSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type KeywordSearchResult = {
  __typename?: 'KeywordSearchResult';
  data?: Maybe<Array<Keyword>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type KeywordSentence = {
  __typename?: 'KeywordSentence';
  type?: Maybe<KeywordSentenceType>;
  text: Scalars['String'];
};

export type KeywordSentenceInput = {
  type: KeywordSentenceType;
  text: Scalars['String'];
};

export enum KeywordSentenceType {
  User = 'USER',
  System = 'SYSTEM'
}

export enum KeywordSource {
  Map = 'MAP',
  Faq = 'FAQ'
}

export type KeywordSubscriptionResult = {
  __typename?: 'KeywordSubscriptionResult';
  data: Keyword;
  operation: Operation;
  old?: Maybe<Keyword>;
  bot_id?: Maybe<Scalars['String']>;
};

export enum KeywordType {
  Default = 'DEFAULT',
  Chatform = 'CHATFORM',
  Booking = 'BOOKING',
  Typeform = 'TYPEFORM',
  System = 'SYSTEM',
  Auto = 'AUTO'
}

export type KeywordUpdate = {
  title?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Float']>;
  is_linenotify?: Maybe<Scalars['Boolean']>;
  linenotify_payload?: Maybe<Scalars['JSON']>;
  is_autotag?: Maybe<Scalars['Boolean']>;
  autotags?: Maybe<Array<Scalars['String']>>;
  is_api?: Maybe<Scalars['Boolean']>;
  api_payload?: Maybe<Scalars['JSON']>;
  sentences?: Maybe<Array<KeywordSentenceInput>>;
  keywords?: Maybe<Array<Scalars['String']>>;
};

export type LearnedKeywordReport = {
  __typename?: 'LearnedKeywordReport';
  total_keywords: Scalars['Float'];
  total_sentences: Scalars['Float'];
  total_today_keywords: Scalars['Float'];
  total_today_learns: Scalars['Float'];
};

export type Liff = {
  __typename?: 'Liff';
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  module?: Maybe<Scalars['String']>;
  liff_id: Scalars['String'];
  config?: Maybe<Scalars['String']>;
  bot_id: Scalars['String'];
  channel_id: Scalars['String'];
  created_by?: Maybe<Scalars['String']>;
};

export type LiffAccessToken = {
  __typename?: 'LiffAccessToken';
  access_token: Scalars['String'];
};

export type LiffInput = {
  id: Scalars['String'];
  user_id: Scalars['String'];
};

export type LineAuthorizedPayload = {
  __typename?: 'LineAuthorizedPayload';
  userId?: Maybe<Scalars['String']>;
  basicId?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  premiumId?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  chatMode?: Maybe<Scalars['String']>;
  markAsReadMode?: Maybe<Scalars['String']>;
};

export type Live = {
  __typename?: 'Live';
  id: Scalars['ID'];
  bot_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  picture_url?: Maybe<Scalars['String']>;
  post_id?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Product>>;
};

export type LiveInput = {
  title?: Maybe<Scalars['String']>;
  picture_url?: Maybe<Scalars['String']>;
  post_id?: Maybe<Scalars['String']>;
};

export type LiveSalesOrderInput = {
  live_id: Scalars['String'];
  sender_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

export type LiveSearchFilter = {
  q?: Maybe<Scalars['String']>;
};

export type LiveSearchInput = {
  page?: Maybe<LiveSearchPagination>;
  filters?: Maybe<LiveSearchFilter>;
};

export type LiveSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type LiveSearchResult = {
  __typename?: 'LiveSearchResult';
  data?: Maybe<Array<Live>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type LiveStats = {
  __typename?: 'LiveStats';
  total_reserved_qty?: Maybe<Scalars['Float']>;
  total_reserved_value?: Maybe<Scalars['Float']>;
};

export type LiveSubscriptionResult = {
  __typename?: 'LiveSubscriptionResult';
  data: Live;
  operation: Operation;
  old?: Maybe<Live>;
  bot_id?: Maybe<Scalars['String']>;
};

export type LiveUpdate = {
  title?: Maybe<Scalars['String']>;
  picture_url?: Maybe<Scalars['String']>;
  post_id?: Maybe<Scalars['String']>;
};

export type LoginInput = {
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<ProviderType>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  tid?: Maybe<Scalars['String']>;
};

export type Media = {
  __typename?: 'Media';
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  bot_id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  filename: Scalars['String'];
  url: Scalars['String'];
  mimetype: Scalars['String'];
  encoding: Scalars['String'];
  tags?: Maybe<Array<Scalars['String']>>;
  duration?: Maybe<Scalars['Float']>;
};

export type MediaInput = {
  title?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  filename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  encoding?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  duration?: Maybe<Scalars['Float']>;
};

export type MediaSearchFilter = {
  q?: Maybe<Scalars['String']>;
};

export type MediaSearchInput = {
  page?: Maybe<MediaSearchPagination>;
  filters?: Maybe<MediaSearchFilter>;
};

export type MediaSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type MediaSearchResult = {
  __typename?: 'MediaSearchResult';
  data?: Maybe<Array<Media>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type MediaSubscriptionResult = {
  __typename?: 'MediaSubscriptionResult';
  data: Media;
  operation: Operation;
  old?: Maybe<Media>;
  bot_id?: Maybe<Scalars['String']>;
};

export enum Medium {
  Messenger = 'Messenger',
  Line = 'Line',
  FacebookLive = 'FacebookLive',
  Website = 'Website'
}

export type MessageByChannelInput = {
  date_format?: Maybe<Scalars['String']>;
  keyword_id?: Maybe<Scalars['String']>;
  date_range?: Maybe<DateRange>;
};

export type MessageOverview = {
  __typename?: 'MessageOverview';
  current_message: Scalars['Float'];
  previous_message: Scalars['Float'];
  current_bot_message: Scalars['Float'];
  previous_bot_message: Scalars['Float'];
};

export type MessagePostback = {
  __typename?: 'MessagePostback';
  data?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type MessagePostbackInput = {
  data?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type MessageReportInput = {
  date_range?: Maybe<DateRange>;
  date_format?: Maybe<Scalars['String']>;
  compare_last?: Maybe<Scalars['Float']>;
  channel_id?: Maybe<Scalars['String']>;
  keyword_id?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createPermission: Permission;
  removePermission: RemoveResult;
  createInbox: Inbox;
  markAsRead: Scalars['JSON'];
  markAsUnread: Scalars['JSON'];
  createFbComment: FbComment;
  updateFbComment: FbComment;
  removeFbComment: RemoveResult;
  userLogin: AccessTokenPayload;
  createInvoiceSingleItem: AdminInvoice;
  topupFriendAis: Scalars['Float'];
  login: AccessTokenPayload;
  register: AccessTokenPayload;
  login2: AccessTokenPayload;
  registerAccount: AccessTokenPayload;
  subscribe: SubscriptionPackage;
  createSnap: Snap;
  resetPassword: Scalars['String'];
  updateBot: Bot;
  upgradeBot: Bot;
  createBot: Bot;
  createBot2: Bot;
  cloneBot: Scalars['String'];
  authorizeLineBot: LineAuthorizedPayload;
  createFBPost: FbPost;
  updateFBPost: FbPost;
  removeFBPost: RemoveResult;
  createKeyword: KeywordLink;
  createKeywordWithAction: Keyword;
  updateKeyword: Keyword;
  removeKeyword: RemoveResult;
  createKeywordLink: KeywordLink;
  removeKeywordLink: RemoveResult;
  updateAction: Action;
  createSentence: Sentence;
  updateSentence: Sentence;
  removeSentence: RemoveResult;
  createIntent: Intent;
  updateIntent: Intent;
  removeIntent: RemoveResult;
  createAnswer: Answer;
  updateAnswer: Answer;
  removeAnswer: RemoveResult;
  updateShopInfo: Shop;
  createBankAccount: BankAccount;
  updateBankAccount: BankAccount;
  removeBankAccount: RemoveResult;
  createShipping: Shipping;
  updateShipping: Shipping;
  removeShipping: RemoveResult;
  createSalesOrder: SalesOrder;
  createLiveSalesOrder: SalesOrder;
  updateSalesOrder: SalesOrder;
  changeItemQty: SalesOrder;
  removeSalesOrder: RemoveResult;
  createContentTypeFromTemplate: ContentType;
  createContentType: ContentType;
  updateContentType: ContentType;
  removeContentType: ContentType;
  createContent: Content;
  updateContent: Content;
  removeContent: Content;
  createItem: Item;
  createStock: Stock;
  updateStock: Stock;
  removeStock: RemoveResult;
  createDeliveryNote: DeliveryNote;
  updateDeliveryNote: DeliveryNote;
  removeDeliveryNote: RemoveResult;
  createChannel: Channel;
  updateChannel: Channel;
  removeChannel: RemoveResult;
  testChannel: Channel;
  fillAnswer: Scalars['JSON'];
  pauseForm: Scalars['JSON'];
  resetForm: Scalars['JSON'];
  createForm: Form;
  updateForm: Form;
  removeForm: RemoveResult;
  createChat: Chat;
  pushMessage: Scalars['JSON'];
  createFriend: Friend;
  updateFriend: Scalars['JSON'];
  turnOnBot: Scalars['JSON'];
  turnOffBot: Scalars['JSON'];
  setChatMode: Scalars['JSON'];
  createBroadcast: Broadcast;
  updateBroadcast: Broadcast;
  removeBroadcast: RemoveResult;
  uploadAudience: UpdateResult;
  resetAudience: BasicResult;
  sendBroadcast: BasicResult;
  resendBroadcast: BulkUpdateResult;
  loginLiff: LiffAccessToken;
  createProduct: Product;
  updateProduct: Product;
  addStockToProduct: Product;
  importProduct: ImportResult;
  removeProduct: RemoveResult;
  issueTicket: ProductTicket;
  createPage: Page;
  updatePage: Page;
  createProductCategory: ProductCategory;
  updateProductCategory: ProductCategory;
  removeProductCategory: ProductCategory;
  importProductCategory: ImportResult;
  createBooking: Booking;
  requestBooking: Booking;
  confirmBooking: Booking;
  removeBooking: RemoveResult;
  createSimpleBooking: SimpleBooking;
  updateSimpleBooking: SimpleBooking;
  removeSimpleBooking: RemoveResult;
  getFbLongLivedUserAccessToken: AccessTokenResult;
  updateOrder: Order;
  createOrder: Order;
  updateOrderPayment: Order;
  updateOrderPaymentPublic: Order;
  removeOrder: RemoveResult;
  confirmOrderPayment: Order;
  cancelOrderPayment: Order;
  issueOrderPayment: Order;
  updateOrderDelivery: Order;
  createPlugin: Plugin;
  updatePlugin: UpdateResult;
  removePlugin: RemoveResult;
  createCommentActivity: Activity;
  updateCommentActivity: Activity;
  deleteCommentActivity: Activity;
  updateTag: Scalars['JSON'];
  createFriendGroup: FriendGroup;
  updateFriendGroup: UpdateResult;
  removeFriendGroup: RemoveResult;
  confirmLinepayPayment: Payment;
  confirmPromptpayPayment: Payment;
  updatePayment: Payment;
  createPayment: Payment;
  uploadTransactionImage: File;
  uploadOrderPaymentImage: File;
  uploadImage: File;
  uploadFile: File;
  createCard: Card;
  acceptCard: Card;
  createMedia: Media;
  updateMedia: Media;
  removeMedia: RemoveResult;
  readUserInbox: UserInbox;
  createInviteLink: Invitation;
  activateInviteLink: UpdateResult;
  createLive: Live;
  recreateLiveIntent: Scalars['JSON'];
  updateLive: Live;
  removeLive: Live;
  importLiveProduct: Scalars['JSON'];
  removeLiveProduct: RemoveResult;
  createAisRequest: AisRequest;
  grantAisRequest: AisRequest;
  updateAisRequest: AisRequest;
  removeAisRequest: RemoveResult;
  createApay: Apay;
  updateApay: Apay;
  removeApay: RemoveResult;
};


export type MutationCreatePermissionArgs = {
  input: PermissionInput;
};


export type MutationRemovePermissionArgs = {
  user_id: Scalars['String'];
};


export type MutationCreateInboxArgs = {
  input: InboxInput;
};


export type MutationMarkAsReadArgs = {
  inbox_id: Scalars['String'];
};


export type MutationMarkAsUnreadArgs = {
  inbox_id: Scalars['String'];
};


export type MutationCreateFbCommentArgs = {
  input: FbCommentInput;
};


export type MutationUpdateFbCommentArgs = {
  input: FbCommentInput;
  id: Scalars['String'];
};


export type MutationRemoveFbCommentArgs = {
  id: Scalars['String'];
};


export type MutationUserLoginArgs = {
  input: LoginInput;
};


export type MutationCreateInvoiceSingleItemArgs = {
  input: AdminInvoiceSingleItemInput;
};


export type MutationTopupFriendAisArgs = {
  input: TopupFriendAisInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationRegisterArgs = {
  input: RegisterAccountDto;
};


export type MutationLogin2Args = {
  input: LoginInput;
};


export type MutationRegisterAccountArgs = {
  input: RegisterAccountDto;
};


export type MutationSubscribeArgs = {
  invoice_id: Scalars['String'];
};


export type MutationCreateSnapArgs = {
  input: SnapInput;
};


export type MutationResetPasswordArgs = {
  new_password: Scalars['String'];
  account_id: Scalars['String'];
};


export type MutationUpdateBotArgs = {
  input: BotInput;
  id: Scalars['String'];
};


export type MutationUpgradeBotArgs = {
  input: BotUpgradeInput;
};


export type MutationCreateBotArgs = {
  input: CreateBotDto;
};


export type MutationCreateBot2Args = {
  input: CreateBotDto;
};


export type MutationCloneBotArgs = {
  to_bot_id: Scalars['String'];
  from_bot_id: Scalars['String'];
};


export type MutationAuthorizeLineBotArgs = {
  redirectUri: Scalars['String'];
  code: Scalars['String'];
};


export type MutationCreateFbPostArgs = {
  input: FbPostInput;
};


export type MutationUpdateFbPostArgs = {
  input: FbPostInput;
  id: Scalars['String'];
};


export type MutationRemoveFbPostArgs = {
  id: Scalars['String'];
};


export type MutationCreateKeywordArgs = {
  input: KeywordInput;
};


export type MutationCreateKeywordWithActionArgs = {
  action_input: ActionInput;
  keyword_input: KeywordInput;
  parent_id?: Maybe<Scalars['String']>;
};


export type MutationUpdateKeywordArgs = {
  input: KeywordUpdate;
  keyword_id: Scalars['String'];
};


export type MutationRemoveKeywordArgs = {
  id: Scalars['String'];
};


export type MutationCreateKeywordLinkArgs = {
  to_id: Scalars['String'];
  from_id: Scalars['String'];
};


export type MutationRemoveKeywordLinkArgs = {
  id: Scalars['String'];
};


export type MutationUpdateActionArgs = {
  input: ActionInput;
  id: Scalars['String'];
};


export type MutationCreateSentenceArgs = {
  input: SentenceInput;
};


export type MutationUpdateSentenceArgs = {
  input: SentenceInput;
  id: Scalars['String'];
};


export type MutationRemoveSentenceArgs = {
  input?: Maybe<SentenceInput>;
  id: Scalars['String'];
};


export type MutationCreateIntentArgs = {
  input: IntentInput;
};


export type MutationUpdateIntentArgs = {
  old: IntentInput;
  input: IntentInput;
  id: Scalars['String'];
};


export type MutationRemoveIntentArgs = {
  id: Scalars['String'];
};


export type MutationCreateAnswerArgs = {
  input: AnswerInput;
};


export type MutationUpdateAnswerArgs = {
  input: AnswerInput;
  id: Scalars['String'];
};


export type MutationRemoveAnswerArgs = {
  id: Scalars['String'];
};


export type MutationUpdateShopInfoArgs = {
  input: ShopInput;
};


export type MutationCreateBankAccountArgs = {
  input: BankAccountInput;
};


export type MutationUpdateBankAccountArgs = {
  input: BankAccountInput;
  id: Scalars['String'];
};


export type MutationRemoveBankAccountArgs = {
  id: Scalars['String'];
};


export type MutationCreateShippingArgs = {
  input: ShippingInput;
};


export type MutationUpdateShippingArgs = {
  input: ShippingInput;
  id: Scalars['String'];
};


export type MutationRemoveShippingArgs = {
  id: Scalars['String'];
};


export type MutationCreateSalesOrderArgs = {
  input: SalesOrderInput;
};


export type MutationCreateLiveSalesOrderArgs = {
  input: LiveSalesOrderInput;
};


export type MutationUpdateSalesOrderArgs = {
  input: SalesOrderInput;
  id: Scalars['String'];
};


export type MutationChangeItemQtyArgs = {
  input: ChangeItemQtyInput;
  id: Scalars['String'];
};


export type MutationRemoveSalesOrderArgs = {
  id: Scalars['String'];
};


export type MutationCreateContentTypeFromTemplateArgs = {
  content_type_id: Scalars['String'];
};


export type MutationCreateContentTypeArgs = {
  input: ContentTypeInput;
};


export type MutationUpdateContentTypeArgs = {
  input: ContentTypeInput;
  id: Scalars['String'];
};


export type MutationRemoveContentTypeArgs = {
  id: Scalars['String'];
};


export type MutationCreateContentArgs = {
  input: ContentInput;
};


export type MutationUpdateContentArgs = {
  input: ContentInput;
  id: Scalars['String'];
};


export type MutationRemoveContentArgs = {
  id: Scalars['String'];
};


export type MutationCreateItemArgs = {
  input: ItemInput;
};


export type MutationCreateStockArgs = {
  input: StockInput;
};


export type MutationUpdateStockArgs = {
  input: StockInput;
  id: Scalars['String'];
};


export type MutationRemoveStockArgs = {
  id: Scalars['String'];
};


export type MutationCreateDeliveryNoteArgs = {
  input: DeliveryNoteInput;
};


export type MutationUpdateDeliveryNoteArgs = {
  input: DeliveryNoteInput;
  id: Scalars['String'];
};


export type MutationRemoveDeliveryNoteArgs = {
  id: Scalars['String'];
};


export type MutationCreateChannelArgs = {
  input: ChannelInput;
};


export type MutationUpdateChannelArgs = {
  input: ChannelInput;
  id: Scalars['String'];
};


export type MutationRemoveChannelArgs = {
  id: Scalars['String'];
};


export type MutationTestChannelArgs = {
  input: ChannelInput;
};


export type MutationFillAnswerArgs = {
  body: Scalars['JSON'];
  friend_id: Scalars['String'];
  form_id: Scalars['String'];
};


export type MutationPauseFormArgs = {
  friend_id: Scalars['String'];
  form_id: Scalars['String'];
};


export type MutationResetFormArgs = {
  friend_id: Scalars['String'];
  form_id: Scalars['String'];
};


export type MutationCreateFormArgs = {
  input: FormInput;
};


export type MutationUpdateFormArgs = {
  input: FormInput;
  id: Scalars['String'];
};


export type MutationRemoveFormArgs = {
  id: Scalars['String'];
};


export type MutationCreateChatArgs = {
  input: ChatInput;
};


export type MutationPushMessageArgs = {
  params?: Maybe<Scalars['JSON']>;
  train_sentence?: Maybe<Scalars['String']>;
  keyword_id?: Maybe<Scalars['String']>;
  messages?: Maybe<Array<ChatMessageInput>>;
  platform: Platform;
  chat_id: Scalars['String'];
};


export type MutationCreateFriendArgs = {
  input: FriendInput;
};


export type MutationUpdateFriendArgs = {
  input: FriendInput;
  friend_id: Scalars['String'];
};


export type MutationTurnOnBotArgs = {
  friend_id: Scalars['String'];
};


export type MutationTurnOffBotArgs = {
  friend_id: Scalars['String'];
};


export type MutationSetChatModeArgs = {
  mode: Scalars['String'];
  friend_id: Scalars['String'];
};


export type MutationCreateBroadcastArgs = {
  input: BroadcastInput;
};


export type MutationUpdateBroadcastArgs = {
  input: BroadcastInput;
  id: Scalars['String'];
};


export type MutationRemoveBroadcastArgs = {
  id: Scalars['String'];
};


export type MutationUploadAudienceArgs = {
  input: BroadcastUploadAudienceInput;
};


export type MutationResetAudienceArgs = {
  id: Scalars['String'];
};


export type MutationSendBroadcastArgs = {
  input?: Maybe<BroadcastSendInput>;
  is_test?: Maybe<Scalars['Boolean']>;
  broadcast_id: Scalars['String'];
};


export type MutationResendBroadcastArgs = {
  input: BroadcastRetryInput;
  broadcast_id: Scalars['String'];
};


export type MutationLoginLiffArgs = {
  liffInput: LiffInput;
};


export type MutationCreateProductArgs = {
  input: ProductInput;
};


export type MutationUpdateProductArgs = {
  input: ProductInput;
  id: Scalars['String'];
};


export type MutationAddStockToProductArgs = {
  qty: Scalars['Float'];
  sku: Scalars['String'];
};


export type MutationImportProductArgs = {
  sheet_number: Scalars['Float'];
  google_sheet_id: Scalars['String'];
};


export type MutationRemoveProductArgs = {
  id: Scalars['String'];
};


export type MutationIssueTicketArgs = {
  input: IssueTicketInput;
};


export type MutationCreatePageArgs = {
  pageInput: PageInput;
};


export type MutationUpdatePageArgs = {
  pageInput: PageInput;
  page_id: Scalars['String'];
};


export type MutationCreateProductCategoryArgs = {
  input: ProductCategoryInput;
};


export type MutationUpdateProductCategoryArgs = {
  input: ProductCategoryUpdate;
  id: Scalars['String'];
};


export type MutationRemoveProductCategoryArgs = {
  id: Scalars['String'];
};


export type MutationImportProductCategoryArgs = {
  sheet_number: Scalars['Float'];
  google_sheet_id: Scalars['String'];
};


export type MutationCreateBookingArgs = {
  bookingInput: BookingInput;
};


export type MutationRequestBookingArgs = {
  booking_id: Scalars['String'];
};


export type MutationConfirmBookingArgs = {
  new_schedule?: Maybe<Scalars['String']>;
  booking_id: Scalars['String'];
};


export type MutationRemoveBookingArgs = {
  booking_id: Scalars['String'];
};


export type MutationCreateSimpleBookingArgs = {
  input: SimpleBookingInput;
};


export type MutationUpdateSimpleBookingArgs = {
  input: SimpleBookingInput;
  id: Scalars['String'];
};


export type MutationRemoveSimpleBookingArgs = {
  id: Scalars['String'];
};


export type MutationGetFbLongLivedUserAccessTokenArgs = {
  token: Scalars['String'];
  app_id: Scalars['String'];
};


export type MutationUpdateOrderArgs = {
  order_id: Scalars['String'];
  input: OrderUpdate;
};


export type MutationCreateOrderArgs = {
  input: OrderInput;
};


export type MutationUpdateOrderPaymentArgs = {
  input: OrderPaymentInput;
  order_id: Scalars['String'];
};


export type MutationUpdateOrderPaymentPublicArgs = {
  input: OrderPaymentInput;
  order_id: Scalars['String'];
};


export type MutationRemoveOrderArgs = {
  id: Scalars['String'];
};


export type MutationConfirmOrderPaymentArgs = {
  order_id: Scalars['String'];
};


export type MutationCancelOrderPaymentArgs = {
  cancel_reason: Scalars['String'];
  order_id: Scalars['String'];
};


export type MutationIssueOrderPaymentArgs = {
  input: OrderIssuePaymentInput;
  order_id: Scalars['String'];
};


export type MutationUpdateOrderDeliveryArgs = {
  input: OrderDeliveryInfoInput;
  order_id: Scalars['String'];
};


export type MutationCreatePluginArgs = {
  input: PluginInput;
};


export type MutationUpdatePluginArgs = {
  input: PluginInput;
  id: Scalars['String'];
};


export type MutationRemovePluginArgs = {
  id: Scalars['String'];
};


export type MutationCreateCommentActivityArgs = {
  input: CommentActivityInput;
};


export type MutationUpdateCommentActivityArgs = {
  input: CommentActivityUpdate;
  activity_id: Scalars['String'];
};


export type MutationDeleteCommentActivityArgs = {
  activity_id: Scalars['String'];
};


export type MutationUpdateTagArgs = {
  input: UpdateTag;
  tag_id: Scalars['String'];
};


export type MutationCreateFriendGroupArgs = {
  input: FriendGroupInput;
};


export type MutationUpdateFriendGroupArgs = {
  input: FriendGroupInput;
  id: Scalars['String'];
};


export type MutationRemoveFriendGroupArgs = {
  id: Scalars['String'];
};


export type MutationConfirmLinepayPaymentArgs = {
  order_id: Scalars['String'];
  transaction_id: Scalars['String'];
};


export type MutationConfirmPromptpayPaymentArgs = {
  id: Scalars['String'];
};


export type MutationUpdatePaymentArgs = {
  input: PaymentUpdateInput;
  id: Scalars['String'];
};


export type MutationCreatePaymentArgs = {
  input: PaymentInput;
};


export type MutationUploadTransactionImageArgs = {
  file: Scalars['Upload'];
  payment_id: Scalars['String'];
};


export type MutationUploadOrderPaymentImageArgs = {
  file: Scalars['Upload'];
  order_id: Scalars['String'];
};


export type MutationUploadImageArgs = {
  file: Scalars['Upload'];
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
};


export type MutationCreateCardArgs = {
  input: CardInput;
};


export type MutationAcceptCardArgs = {
  card_id: Scalars['String'];
};


export type MutationCreateMediaArgs = {
  input: MediaInput;
};


export type MutationUpdateMediaArgs = {
  input: MediaInput;
  id: Scalars['String'];
};


export type MutationRemoveMediaArgs = {
  id: Scalars['String'];
};


export type MutationReadUserInboxArgs = {
  id: Scalars['String'];
};


export type MutationCreateInviteLinkArgs = {
  input: InvitationInput;
};


export type MutationActivateInviteLinkArgs = {
  input: InvitationActivationInput;
};


export type MutationCreateLiveArgs = {
  input: LiveInput;
};


export type MutationRecreateLiveIntentArgs = {
  live_id: Scalars['String'];
};


export type MutationUpdateLiveArgs = {
  input: LiveUpdate;
  id: Scalars['String'];
};


export type MutationRemoveLiveArgs = {
  id: Scalars['String'];
};


export type MutationImportLiveProductArgs = {
  input: ImportLiveProductInput;
  id: Scalars['String'];
};


export type MutationRemoveLiveProductArgs = {
  product_id: Scalars['String'];
};


export type MutationCreateAisRequestArgs = {
  input: AisRequestInput;
};


export type MutationGrantAisRequestArgs = {
  phone: Scalars['String'];
};


export type MutationUpdateAisRequestArgs = {
  input: AisRequestInput;
  id: Scalars['String'];
};


export type MutationRemoveAisRequestArgs = {
  id: Scalars['String'];
};


export type MutationCreateApayArgs = {
  input: ApayInput;
};


export type MutationUpdateApayArgs = {
  input: ApayInput;
  id: Scalars['String'];
};


export type MutationRemoveApayArgs = {
  id: Scalars['String'];
};

export enum Operation {
  Create = 'CREATE',
  Update = 'UPDATE',
  Delete = 'DELETE'
}

export type Order = {
  __typename?: 'Order';
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  chat_id?: Maybe<Scalars['String']>;
  status: OrderStatus;
  full_name?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  items: Array<OrderItem>;
  total: Scalars['Float'];
  vat_amount: Scalars['Float'];
  grand_total: Scalars['Float'];
  customer_info?: Maybe<OrderCustomerInfo>;
  payment_info?: Maybe<OrderPaymentInfo>;
  delivery_info?: Maybe<OrderDeliveryInfo>;
  cancel_reason?: Maybe<Scalars['String']>;
  creation: Scalars['Float'];
  expiration_date?: Maybe<Scalars['Float']>;
  total_item?: Maybe<Scalars['Float']>;
  friend?: Maybe<Friend>;
};

export type OrderCustomerInfo = {
  __typename?: 'OrderCustomerInfo';
  full_name?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
};

export type OrderCustomerInfoInput = {
  full_name: Scalars['String'];
  tel: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  address: Scalars['String'];
};

export type OrderDeliveryInfo = {
  __typename?: 'OrderDeliveryInfo';
  title?: Maybe<Scalars['String']>;
  delivery_company?: Maybe<Scalars['String']>;
  tracking_no?: Maybe<Scalars['String']>;
  delivery_time?: Maybe<Scalars['Float']>;
  cost: Scalars['Float'];
};

export type OrderDeliveryInfoInput = {
  delivery_company: Scalars['String'];
  tracking_no: Scalars['String'];
  delivery_time?: Maybe<Scalars['Float']>;
};

export type OrderInput = {
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  chat_id?: Maybe<Scalars['String']>;
  bot_id: Scalars['String'];
  items: Array<OrderItemInput>;
  delivery?: Maybe<Scalars['String']>;
  customer_info: OrderCustomerInfoInput;
};

export type OrderIssuePaymentInput = {
  reason: Scalars['String'];
};

export type OrderItem = {
  __typename?: 'OrderItem';
  title?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  amount: Scalars['Float'];
  qty: Scalars['Float'];
};

export type OrderItemInput = {
  sku: Scalars['String'];
  qty: Scalars['Float'];
};

export type OrderPaymentInfo = {
  __typename?: 'OrderPaymentInfo';
  status: OrderPaymentStatus;
  reason?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  account_no?: Maybe<Scalars['String']>;
  account_name?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  transfer_date?: Maybe<Scalars['String']>;
  transfer_time?: Maybe<Scalars['String']>;
  transaction_image_url?: Maybe<Scalars['String']>;
  bank?: Maybe<Bank>;
};

export type OrderPaymentInput = {
  method?: Maybe<Scalars['String']>;
  account_no?: Maybe<Scalars['String']>;
  account_name?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  transfer_date?: Maybe<Scalars['String']>;
  transfer_time?: Maybe<Scalars['String']>;
  transaction_image_url?: Maybe<Scalars['String']>;
  bank?: Maybe<Bank>;
};

export enum OrderPaymentStatus {
  Unpaid = 'UNPAID',
  Paid = 'PAID',
  Failed = 'FAILED'
}

export type OrderSearchFilter = {
  sku?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  status?: Maybe<OrderStatus>;
};

export type OrderSearchInput = {
  page?: Maybe<OrderSearchPagination>;
  filters?: Maybe<OrderSearchFilter>;
  query?: Maybe<Scalars['String']>;
};

export type OrderSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type OrderSearchResult = {
  __typename?: 'OrderSearchResult';
  total: Scalars['Float'];
  next?: Maybe<Scalars['String']>;
  data: Array<Order>;
};

export enum OrderStatus {
  WaitPayment = 'WAIT_PAYMENT',
  ReviewPayment = 'REVIEW_PAYMENT',
  WaitDeliver = 'WAIT_DELIVER',
  Complete = 'COMPLETE',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED'
}

export type OrderSubscriptionResult = {
  __typename?: 'OrderSubscriptionResult';
  data: Order;
  operation: Operation;
  old?: Maybe<Order>;
  bot_id?: Maybe<Scalars['String']>;
};

export type OrderUpdate = {
  payment_info?: Maybe<OrderPaymentInput>;
  status?: Maybe<OrderStatus>;
  comment?: Maybe<Scalars['String']>;
};

export type Page = {
  __typename?: 'Page';
  id: Scalars['ID'];
  logo_url?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  sku?: Maybe<Scalars['String']>;
  public_url?: Maybe<Scalars['String']>;
  primary_color?: Maybe<Scalars['String']>;
  secondary_color?: Maybe<Scalars['String']>;
  booking_liff_url?: Maybe<Scalars['String']>;
  order_liff_url?: Maybe<Scalars['String']>;
  delivery_liff_url?: Maybe<Scalars['String']>;
  contents?: Maybe<Scalars['JSON']>;
  product?: Maybe<Product>;
};

export type PageInput = {
  title: Scalars['String'];
  logo_url?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  primary_color?: Maybe<Scalars['String']>;
  secondary_color?: Maybe<Scalars['String']>;
  contents?: Maybe<Scalars['JSON']>;
};

export type PageSubscriptionResult = {
  __typename?: 'PageSubscriptionResult';
  data: Page;
  operation: Operation;
  old?: Maybe<Page>;
  bot_id?: Maybe<Scalars['String']>;
};

export type PaginationInput = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export enum PayloadType {
  FacebookPost = 'FacebookPost'
}

export type Payment = {
  __typename?: 'Payment';
  id: Scalars['ID'];
  invoice_id: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  method: PaymentMethod;
  status: PaymentStatus;
  amount: Scalars['Float'];
  creation: Scalars['Float'];
  bot_id: Scalars['String'];
  transaction_image_url?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['JSON']>;
};

export type PaymentInput = {
  invoice_id: Scalars['String'];
  method: PaymentMethod;
  confirm_url?: Maybe<Scalars['String']>;
  cancel_url?: Maybe<Scalars['String']>;
};

export enum PaymentMethod {
  LinePay = 'LinePay',
  PromptPay = 'PromptPay',
  BillPayment = 'BillPayment',
  GbQrCode = 'GBQrCode',
  GbQrCredit = 'GBQrCredit',
  BankTranser = 'BankTranser'
}

export type PaymentSearchFilter = {
  bot_id?: Maybe<Scalars['String']>;
};

export type PaymentSearchInput = {
  page?: Maybe<PaymentSearchPagination>;
  filters?: Maybe<PaymentSearchFilter>;
};

export type PaymentSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type PaymentSearchResult = {
  __typename?: 'PaymentSearchResult';
  data?: Maybe<Array<Payment>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export enum PaymentStatus {
  Pending = 'PENDING',
  Paid = 'PAID',
  Review = 'REVIEW',
  Billing = 'BILLING',
  Success = 'SUCCESS',
  Failed = 'FAILED'
}

export type PaymentSubscriptionResult = {
  __typename?: 'PaymentSubscriptionResult';
  data: Payment;
  operation: Operation;
  old?: Maybe<Payment>;
  bot_id?: Maybe<Scalars['String']>;
};

export type PaymentUpdateInput = {
  transaction_image_url?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['JSON']>;
};

export enum PayMethod {
  LinePay = 'LinePay',
  PromptPay = 'PromptPay',
  BillPayment = 'BillPayment',
  GbQrCode = 'GBQrCode',
  GbQrCredit = 'GBQrCredit',
  BankTranser = 'BankTranser'
}

export enum PayStatus {
  Pending = 'PENDING',
  Paid = 'PAID',
  Review = 'REVIEW',
  Billing = 'BILLING',
  Success = 'SUCCESS',
  Failed = 'FAILED'
}

export type Permission = {
  __typename?: 'Permission';
  bot_id: Scalars['String'];
  user_id: Scalars['String'];
  role: Scalars['String'];
  account?: Maybe<Account>;
};

export type PermissionInput = {
  bot_id: Scalars['String'];
  user_id: Scalars['String'];
  role: Scalars['String'];
  admin_id: Scalars['String'];
};

export type PermissionSearchFilter = {
  role?: Maybe<Scalars['String']>;
};

export type PermissionSearchInput = {
  page?: Maybe<PermissionSearchPagination>;
  filters?: Maybe<PermissionSearchFilter>;
};

export type PermissionSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type PermissionSearchResult = {
  __typename?: 'PermissionSearchResult';
  data?: Maybe<Array<Permission>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type PermissionSubscriptionResult = {
  __typename?: 'PermissionSubscriptionResult';
  data: Permission;
  operation: Operation;
  old?: Maybe<Permission>;
  bot_id?: Maybe<Scalars['String']>;
};

export type Place = {
  __typename?: 'Place';
  id: Scalars['ID'];
};

export enum Platform {
  FacebookBot = 'FacebookBot',
  LineBot = 'LineBot',
  LineModular = 'LineModular',
  All = 'All'
}

export type Plugin = {
  __typename?: 'Plugin';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  keyword_id?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['JSON']>;
  _isLocal?: Maybe<Scalars['Float']>;
};

export type PluginInput = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  keyword_id?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['JSON']>;
  _isLocal?: Maybe<Scalars['Float']>;
};

export type PluginSearchFilter = {
  platform?: Maybe<Platform>;
};

export type PluginSearchInput = {
  page?: Maybe<PluginSearchPagination>;
  filters?: Maybe<PluginSearchFilter>;
};

export type PluginSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type PluginSearchResult = {
  __typename?: 'PluginSearchResult';
  total: Scalars['Float'];
  next?: Maybe<Scalars['String']>;
  data: Array<Plugin>;
};

export type Product = {
  __typename?: 'Product';
  id?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  live_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  sell_description?: Maybe<Scalars['String']>;
  full_price?: Maybe<Scalars['Float']>;
  sell_price?: Maybe<Scalars['Float']>;
  price_per_month?: Maybe<Scalars['Float']>;
  price_per_year?: Maybe<Scalars['Float']>;
  group?: Maybe<Scalars['String']>;
  booking_title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  keywords?: Maybe<Array<Scalars['String']>>;
  is_bookable?: Maybe<Scalars['Boolean']>;
  is_purchasable?: Maybe<Scalars['Boolean']>;
  is_deliverable?: Maybe<Scalars['Boolean']>;
  selling_start_date?: Maybe<Scalars['String']>;
  selling_end_date?: Maybe<Scalars['String']>;
  timeslot_id?: Maybe<Scalars['String']>;
  picture_url?: Maybe<Scalars['String']>;
  picture_urls?: Maybe<Array<Scalars['String']>>;
  friend_limit?: Maybe<Scalars['Float']>;
  live_status?: Maybe<ProductLiveStatus>;
  timeslot?: Maybe<Timeslot>;
  bot?: Maybe<Bot>;
  page?: Maybe<Page>;
  live?: Maybe<Live>;
  stock_qty?: Maybe<Scalars['Float']>;
  stock_total_in?: Maybe<Scalars['Float']>;
  stock_total_out?: Maybe<Scalars['Float']>;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  id: Scalars['String'];
  picture_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type ProductCategoryInput = {
  title: Scalars['String'];
  picture_url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type ProductCategorySearchFilter = {
  group?: Maybe<Scalars['String']>;
};

export type ProductCategorySearchInput = {
  page?: Maybe<ProductCategorySearchPagination>;
  filters?: Maybe<ProductCategorySearchFilter>;
};

export type ProductCategorySearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type ProductCategorySearchResult = {
  __typename?: 'ProductCategorySearchResult';
  data?: Maybe<Array<ProductCategory>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type ProductCategorySubscriptionResult = {
  __typename?: 'ProductCategorySubscriptionResult';
  data: ProductCategory;
  operation: Operation;
  old?: Maybe<ProductCategory>;
  bot_id?: Maybe<Scalars['String']>;
};

export type ProductCategoryUpdate = {
  title?: Maybe<Scalars['String']>;
  picture_url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type ProductInput = {
  sku?: Maybe<Scalars['String']>;
  live_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  full_price?: Maybe<Scalars['Float']>;
  sell_price?: Maybe<Scalars['Float']>;
  sell_description?: Maybe<Scalars['String']>;
  stock_qty?: Maybe<Scalars['Float']>;
  category?: Maybe<Scalars['String']>;
  picture_url?: Maybe<Scalars['String']>;
  picture_urls?: Maybe<Array<Scalars['String']>>;
  keywords?: Maybe<Array<Scalars['String']>>;
  live_status?: Maybe<ProductLiveStatus>;
};

export enum ProductLiveStatus {
  Ready = 'Ready',
  Done = 'Done'
}

export type ProductSearchFilter = {
  category?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  is_bookable?: Maybe<Scalars['Boolean']>;
  live_id?: Maybe<Scalars['String']>;
};

export type ProductSearchInput = {
  page?: Maybe<ProductSearchPagination>;
  filters?: Maybe<ProductSearchFilter>;
  sort_by?: Maybe<Array<SortBy>>;
  query?: Maybe<Scalars['String']>;
};

export type ProductSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type ProductSearchResult = {
  __typename?: 'ProductSearchResult';
  data?: Maybe<Array<Product>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type ProductSubscriptionFilter = {
  live_id?: Maybe<Scalars['String']>;
};

export type ProductSubscriptionResult = {
  __typename?: 'ProductSubscriptionResult';
  data: Product;
  operation: Operation;
  old?: Maybe<Product>;
  bot_id?: Maybe<Scalars['String']>;
};

export type ProductTicket = {
  __typename?: 'ProductTicket';
  id: Scalars['String'];
  bot_id: Scalars['String'];
  sku: Scalars['String'];
  code: Scalars['String'];
  status: Scalars['String'];
  creation: Scalars['Float'];
  modified: Scalars['Float'];
};

export enum ProviderType {
  Line = 'line',
  Google = 'google',
  Email = 'email'
}

export type QuatationFa = {
  __typename?: 'QuatationFA';
  recordId: Scalars['Float'];
  documentSerial?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  publishedOn?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  grandTotal?: Maybe<Scalars['String']>;
};

export type QuatationSearchFilterFa = {
  customerName?: Maybe<Scalars['String']>;
};

export type QuatationSearchInputFa = {
  page?: Maybe<QuatationSearchPaginationFa>;
  filters?: Maybe<QuatationSearchFilterFa>;
};

export type QuatationSearchPaginationFa = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type QuatationSearchResultFa = {
  __typename?: 'QuatationSearchResultFA';
  data?: Maybe<Array<QuatationFa>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  searchPermission: PermissionSearchResult;
  searchInbox: InboxSearchResult;
  getInbox: Inbox;
  searchFbComment: FbCommentSearchResult;
  getFbComment: FbComment;
  getUserCloudData: UserCloudData;
  searchInvoice: InvoiceSearchResult;
  getInvoice: AdminInvoice;
  aisCountMonthlySubscriber: Scalars['JSON'];
  aisCountMonthlyTrial: Scalars['JSON'];
  aisCountMonthlyBilling: Scalars['JSON'];
  getAccount: Account;
  searchSubscription: SubscriptionSearchResult;
  searchSnap: SnapSearchResult;
  getSnapChart: InsightResult;
  getSnapBarChart: InsightResult;
  getAccountLineChart: InsightResult;
  getBot: Bot;
  getBotRole?: Maybe<BotRole>;
  getBotAccessToken: AccessTokenPayload;
  searchBot: BotSearchResult;
  searchBotPackage: ProductSearchResult;
  searchBotTemplate: BotSearchResult;
  allBots: BotSearchResult;
  allShops: BotSearchResult;
  getAppAccessToken: AccessTokenPayload;
  searchBotUser: BotUserSearchResult;
  getBotLineChart: InsightResult;
  getBotInternal: Bot;
  getPublicBot: BotPublic;
  searchFBPost: FbPostSearchResult;
  getFBPost: FbPost;
  searchKeyword: KeywordSearchResult;
  getKeyword: Keyword;
  getKeywordSuggestion: KeywordSearchResult;
  searchKeywordLink: KeywordLinkSearchResult;
  getAction: Action;
  searchSentence: SentenceSearchResult;
  getSentence: Sentence;
  searchIntent: IntentSearchResult;
  getIntent: Intent;
  searchAnswer: AnswerSearchResult;
  getAnswer: Answer;
  getShopInfo: Shop;
  getShopInfoPublic: ShopPublic;
  searchBankAccount: BankAccountSearchResult;
  getBankAccount: BankAccount;
  searchShipping: ShippingSearchResult;
  getShipping: Shipping;
  searchSalesOrder: SalesOrderSearchResult;
  getSalesOrder: SalesOrder;
  searchContentType: ContentTypeSearchResult;
  searchContentTypeTemplate: ContentTypeSearchResult;
  getContentType: ContentType;
  getContentTypeByType: ContentType;
  searchContent: ContentSearchResult;
  getContent: Content;
  searchItem: ItemSearchResult;
  getStock: Stock;
  searchDeliveryNote: DeliveryNoteSearchResult;
  getDeliveryNote: DeliveryNote;
  searchChannel: ChannelSearchResult;
  allChannels: ChannelSearchResult;
  getChannel: Channel;
  countChannel: Scalars['Float'];
  findChannel: ChannelSearchResult;
  loadForm: Scalars['JSON'];
  startForm: Scalars['JSON'];
  getCurrentQuestion: Scalars['JSON'];
  getNextQuestion: Scalars['JSON'];
  getAnswers: Scalars['JSON'];
  searchForm: FormSearchResult;
  getForm: Form;
  searchChitchat: ChitchatSearchResult;
  searchChat: ChatSearchResult;
  getChatStat: ChatStat;
  getChatIntentHits: ChatIntentHitsResult;
  getChatAgentCount: Scalars['JSON'];
  exportChat: ChatSearchResult;
  searchFriend: FriendSearchResult;
  countFriend: Scalars['Float'];
  getFriend: Friend;
  getFriendStat: FriendStat;
  getUserHeatmap: InsightResult;
  getCountFriendTags: AggregationResult;
  searchBroadcast: BroadcastSearchResult;
  getBroadcast: Broadcast;
  searchBroadcastAudience: BroadcastAudienceSearchResult;
  getLiffId: Liff;
  getLiffUser: Friend;
  searchProduct: ProductSearchResult;
  getProduct: Product;
  allProducts: ProductSearchResult;
  getProductBySku: Product;
  getPage: Page;
  allPages: ResultSearch;
  allPages2: ResultSearch;
  getPage2: Page;
  searchProductPublic: ProductSearchResult;
  getProductPublic: Product;
  searchProductCategoryPublic: ProductCategorySearchResult;
  searchProductCategory: ProductCategorySearchResult;
  getProductCategory: ProductCategory;
  searchBookings: BookingSearchResult;
  searchBookingTimeslots: TimeslotSearchResult;
  getBookingSummary: Array<BookingSummary>;
  getBookingSimpleSummary: Scalars['JSON'];
  searchSimpleBookings: BookingSearchResult;
  searchSimpleBooking: SimpleBookingSearchResult;
  getSimpleBooking: SimpleBooking;
  getMessageByChannel: InsightResult;
  getLearnedKeywordReport: LearnedKeywordReport;
  getTrainingReport: TrainingReportResult;
  getFacebookOverview: FacebookOverview;
  getIntentReport: IntentReportResult;
  searchFeed: Array<FacebookPost>;
  searchScheduledPost: Array<FacebookPost>;
  getModularAccessToken: AccessTokenResult2;
  getMessageReport: InsightResult;
  getActiveUserReport: InsightResult;
  getMessageOverview: MessageOverview;
  searchOrder: OrderSearchResult;
  getOrderPublic: Order;
  getOrder: Order;
  searchPlugin: PluginSearchResult;
  getPlugin: Plugin;
  searchActivity: ActivitySearchResult;
  searchActivityInternal: ActivitySearchResult;
  searchTag: TagSearchResult;
  searchTeam: TeamSearchResult;
  searchFriendGroup: FriendGroupSearchResult;
  getFriendGroup: FriendGroup;
  searchPayment: PaymentSearchResult;
  getPayment: Payment;
  searchAisUser: AisBillingSearchResult;
  searchCard: CardSearchResult;
  getCard: Card;
  searchMedia: MediaSearchResult;
  getMedia: Media;
  searchUserInbox: UserInboxSearchResult;
  getUserInbox: UserInbox;
  countUserInbox: Scalars['Float'];
  searchInvitation: InvitationSearchResult;
  getInvitation: Invitation;
  searchLive: LiveSearchResult;
  getLive: Live;
  getLiveByPost: Live;
  getLiveStats: LiveStats;
  searchContact: ContactSearchResultFa;
  searchQuatation: QuatationSearchResultFa;
  searchAisRequest: AisRequestSearchResult;
  getAisRequest: AisRequest;
  searchApay: ApaySearchResult;
  getApay: Apay;
};


export type QuerySearchPermissionArgs = {
  input?: Maybe<PermissionSearchInput>;
};


export type QuerySearchInboxArgs = {
  input?: Maybe<InboxSearchInput>;
};


export type QueryGetInboxArgs = {
  inbox_id: Scalars['String'];
};


export type QuerySearchFbCommentArgs = {
  input?: Maybe<FbCommentSearchInput>;
};


export type QueryGetFbCommentArgs = {
  id: Scalars['String'];
};


export type QuerySearchInvoiceArgs = {
  bot_id: Scalars['String'];
};


export type QueryGetInvoiceArgs = {
  invoice_id: Scalars['String'];
};


export type QueryAisCountMonthlyTrialArgs = {
  billing_start?: Maybe<Scalars['Float']>;
};


export type QueryAisCountMonthlyBillingArgs = {
  billing_start?: Maybe<Scalars['Float']>;
};


export type QuerySearchSubscriptionArgs = {
  input?: Maybe<SubscriptionSearchInput>;
};


export type QuerySearchSnapArgs = {
  input?: Maybe<SnapSearchInput>;
};


export type QueryGetSnapChartArgs = {
  input?: Maybe<SnapInsightInput>;
};


export type QueryGetSnapBarChartArgs = {
  input?: Maybe<SnapInsightInput>;
};


export type QueryGetAccountLineChartArgs = {
  input?: Maybe<AccountInsightInput>;
};


export type QueryGetBotArgs = {
  id: Scalars['String'];
};


export type QueryGetBotRoleArgs = {
  id: Scalars['String'];
};


export type QueryGetBotAccessTokenArgs = {
  bot_id: Scalars['String'];
};


export type QuerySearchBotPackageArgs = {
  input: BotPackageSearchInput;
};


export type QueryAllBotsArgs = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};


export type QueryAllShopsArgs = {
  input?: Maybe<BotInsightInput>;
};


export type QueryGetAppAccessTokenArgs = {
  scope: Scalars['String'];
};


export type QueryGetBotLineChartArgs = {
  input?: Maybe<BotInsightInput>;
};


export type QueryGetBotInternalArgs = {
  id: Scalars['String'];
};


export type QueryGetPublicBotArgs = {
  id: Scalars['String'];
};


export type QuerySearchFbPostArgs = {
  input?: Maybe<FbPostSearchInput>;
};


export type QueryGetFbPostArgs = {
  id: Scalars['String'];
};


export type QuerySearchKeywordArgs = {
  input?: Maybe<KeywordSearchInput>;
};


export type QueryGetKeywordArgs = {
  keyword_id: Scalars['String'];
};


export type QueryGetKeywordSuggestionArgs = {
  size?: Maybe<Scalars['Float']>;
  user_id: Scalars['String'];
  query: Scalars['String'];
};


export type QuerySearchKeywordLinkArgs = {
  input?: Maybe<KeywordLinkSearchInput>;
};


export type QueryGetActionArgs = {
  keyword_id: Scalars['String'];
};


export type QuerySearchSentenceArgs = {
  input?: Maybe<SentenceSearchInput>;
};


export type QueryGetSentenceArgs = {
  id: Scalars['String'];
};


export type QuerySearchIntentArgs = {
  input?: Maybe<IntentSearchInput>;
};


export type QueryGetIntentArgs = {
  id: Scalars['String'];
};


export type QuerySearchAnswerArgs = {
  input?: Maybe<AnswerSearchInput>;
};


export type QueryGetAnswerArgs = {
  id: Scalars['String'];
};


export type QueryGetShopInfoPublicArgs = {
  bot_id: Scalars['String'];
};


export type QuerySearchBankAccountArgs = {
  input?: Maybe<BankAccountSearchInput>;
};


export type QueryGetBankAccountArgs = {
  id: Scalars['String'];
};


export type QuerySearchShippingArgs = {
  input?: Maybe<ShippingSearchInput>;
};


export type QueryGetShippingArgs = {
  id: Scalars['String'];
};


export type QuerySearchSalesOrderArgs = {
  input?: Maybe<SalesOrderSearchInput>;
};


export type QueryGetSalesOrderArgs = {
  id: Scalars['String'];
};


export type QuerySearchContentTypeArgs = {
  input?: Maybe<ContentTypeSearchInput>;
};


export type QuerySearchContentTypeTemplateArgs = {
  input?: Maybe<ContentTypeSearchInput>;
};


export type QueryGetContentTypeArgs = {
  id: Scalars['String'];
};


export type QueryGetContentTypeByTypeArgs = {
  type: Scalars['String'];
};


export type QuerySearchContentArgs = {
  input?: Maybe<ContentSearchInput>;
};


export type QueryGetContentArgs = {
  id: Scalars['String'];
};


export type QuerySearchItemArgs = {
  input?: Maybe<ItemSearchInput>;
};


export type QueryGetStockArgs = {
  id: Scalars['String'];
};


export type QuerySearchDeliveryNoteArgs = {
  input?: Maybe<DeliveryNoteSearchInput>;
};


export type QueryGetDeliveryNoteArgs = {
  id: Scalars['String'];
};


export type QuerySearchChannelArgs = {
  input?: Maybe<ChannelSearchInput>;
};


export type QueryAllChannelsArgs = {
  input?: Maybe<ChannelSearchInput2>;
};


export type QueryGetChannelArgs = {
  id: Scalars['String'];
};


export type QueryCountChannelArgs = {
  input?: Maybe<ChannelSearchFilter>;
};


export type QueryFindChannelArgs = {
  input: ChannelSearchFilter;
};


export type QueryLoadFormArgs = {
  form_id: Scalars['String'];
};


export type QueryStartFormArgs = {
  body?: Maybe<Scalars['JSON']>;
  friend_id: Scalars['String'];
  form_id: Scalars['String'];
};


export type QueryGetCurrentQuestionArgs = {
  friend_id: Scalars['String'];
  form_id: Scalars['String'];
};


export type QueryGetNextQuestionArgs = {
  friend_id: Scalars['String'];
  form_id: Scalars['String'];
};


export type QueryGetAnswersArgs = {
  friend_id: Scalars['String'];
  form_id: Scalars['String'];
};


export type QuerySearchFormArgs = {
  input?: Maybe<FormSearchInput>;
};


export type QueryGetFormArgs = {
  id: Scalars['String'];
};


export type QuerySearchChitchatArgs = {
  input?: Maybe<ChitchatSearchInput>;
};


export type QuerySearchChatArgs = {
  input?: Maybe<ChatSearchInput>;
};


export type QueryGetChatStatArgs = {
  input?: Maybe<ChatStatInput>;
};


export type QueryGetChatIntentHitsArgs = {
  input?: Maybe<ChatInsightInput>;
};


export type QueryGetChatAgentCountArgs = {
  input?: Maybe<ChatInsightInput>;
};


export type QueryExportChatArgs = {
  input: ChatExportInput;
};


export type QuerySearchFriendArgs = {
  input?: Maybe<FriendSearchInput>;
};


export type QueryCountFriendArgs = {
  input?: Maybe<FriendSearchFilter>;
};


export type QueryGetFriendArgs = {
  friend_id: Scalars['String'];
};


export type QueryGetUserHeatmapArgs = {
  input?: Maybe<FriendInsightInput>;
};


export type QuerySearchBroadcastArgs = {
  input?: Maybe<BroadcastSearchInput>;
};


export type QueryGetBroadcastArgs = {
  id: Scalars['String'];
};


export type QuerySearchBroadcastAudienceArgs = {
  input?: Maybe<BroadcastAudienceSearchInput>;
  broadcast_id: Scalars['String'];
};


export type QueryGetLiffIdArgs = {
  ref: Scalars['String'];
};


export type QuerySearchProductArgs = {
  input?: Maybe<ProductSearchInput>;
};


export type QueryGetProductArgs = {
  id: Scalars['String'];
};


export type QueryAllProductsArgs = {
  input?: Maybe<ProductSearchInput>;
};


export type QueryGetProductBySkuArgs = {
  sku?: Maybe<Scalars['String']>;
};


export type QueryGetPageArgs = {
  id: Scalars['String'];
};


export type QueryAllPagesArgs = {
  paginationInput?: Maybe<PaginationInput>;
};


export type QueryAllPages2Args = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  botId?: Maybe<Scalars['String']>;
};


export type QueryGetPage2Args = {
  id: Scalars['String'];
};


export type QuerySearchProductPublicArgs = {
  input?: Maybe<ProductSearchInput>;
  bot_id: Scalars['String'];
};


export type QueryGetProductPublicArgs = {
  sku: Scalars['String'];
  bot_id: Scalars['String'];
};


export type QuerySearchProductCategoryPublicArgs = {
  bot_id: Scalars['String'];
};


export type QuerySearchProductCategoryArgs = {
  searchInput?: Maybe<ProductCategorySearchInput>;
};


export type QueryGetProductCategoryArgs = {
  id: Scalars['String'];
};


export type QuerySearchBookingsArgs = {
  searchInput?: Maybe<BookSearchInput>;
};


export type QuerySearchBookingTimeslotsArgs = {
  searchInput?: Maybe<TimeslotSearchInput>;
};


export type QueryGetBookingSummaryArgs = {
  input: BookingSummaryInput;
};


export type QueryGetBookingSimpleSummaryArgs = {
  input: BookingSummaryInput;
};


export type QuerySearchSimpleBookingsArgs = {
  input?: Maybe<BookSearchInput>;
};


export type QuerySearchSimpleBookingArgs = {
  input?: Maybe<SimpleBookingSearchInput>;
};


export type QueryGetSimpleBookingArgs = {
  id: Scalars['String'];
};


export type QueryGetMessageByChannelArgs = {
  input?: Maybe<MessageByChannelInput>;
};


export type QueryGetIntentReportArgs = {
  input?: Maybe<ChatInsightInput>;
};


export type QuerySearchFeedArgs = {
  page_id: Scalars['String'];
};


export type QuerySearchScheduledPostArgs = {
  page_id: Scalars['String'];
};


export type QueryGetMessageReportArgs = {
  input?: Maybe<MessageReportInput>;
};


export type QueryGetActiveUserReportArgs = {
  input?: Maybe<ActiveUserReportInput>;
};


export type QueryGetMessageOverviewArgs = {
  input?: Maybe<AnalyticReportInput>;
};


export type QuerySearchOrderArgs = {
  input?: Maybe<OrderSearchInput>;
};


export type QueryGetOrderPublicArgs = {
  order_id: Scalars['String'];
};


export type QueryGetOrderArgs = {
  order_id: Scalars['String'];
};


export type QuerySearchPluginArgs = {
  input?: Maybe<PluginSearchInput>;
};


export type QueryGetPluginArgs = {
  id: Scalars['String'];
};


export type QuerySearchActivityArgs = {
  input: ActivitySearchInput;
};


export type QuerySearchActivityInternalArgs = {
  input?: Maybe<ActivitySearchInternalInput>;
};


export type QuerySearchTagArgs = {
  input?: Maybe<TagSearchInput>;
};


export type QuerySearchFriendGroupArgs = {
  input?: Maybe<FriendGroupSearchInput>;
};


export type QueryGetFriendGroupArgs = {
  platform?: Maybe<Platform>;
  id: Scalars['String'];
};


export type QuerySearchPaymentArgs = {
  input?: Maybe<PaymentSearchInput>;
};


export type QueryGetPaymentArgs = {
  payment_id: Scalars['String'];
};


export type QuerySearchAisUserArgs = {
  input?: Maybe<AisBillingSearchInput>;
};


export type QuerySearchCardArgs = {
  input?: Maybe<CardSearchInput>;
};


export type QueryGetCardArgs = {
  card_id: Scalars['String'];
};


export type QuerySearchMediaArgs = {
  input?: Maybe<MediaSearchInput>;
};


export type QueryGetMediaArgs = {
  id: Scalars['String'];
};


export type QuerySearchUserInboxArgs = {
  input?: Maybe<UserInboxSearchInput>;
};


export type QueryGetUserInboxArgs = {
  id: Scalars['String'];
};


export type QueryCountUserInboxArgs = {
  read: Scalars['Boolean'];
};


export type QuerySearchInvitationArgs = {
  input?: Maybe<InvitationSearchInput>;
};


export type QueryGetInvitationArgs = {
  id: Scalars['String'];
};


export type QuerySearchLiveArgs = {
  input?: Maybe<LiveSearchInput>;
};


export type QueryGetLiveArgs = {
  id: Scalars['String'];
};


export type QueryGetLiveByPostArgs = {
  post_id: Scalars['String'];
};


export type QueryGetLiveStatsArgs = {
  id: Scalars['String'];
};


export type QuerySearchContactArgs = {
  input?: Maybe<ContactSearchInputFa>;
};


export type QuerySearchQuatationArgs = {
  input?: Maybe<QuatationSearchInputFa>;
};


export type QuerySearchAisRequestArgs = {
  input?: Maybe<AisRequestSearchInput>;
};


export type QueryGetAisRequestArgs = {
  id: Scalars['String'];
};


export type QuerySearchApayArgs = {
  input?: Maybe<ApaySearchInput>;
};


export type QueryGetApayArgs = {
  id: Scalars['String'];
};

export type Question = {
  __typename?: 'Question';
  seq: Scalars['Float'];
  id: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
  key: Scalars['String'];
  group?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['JSON']>;
  form_id?: Maybe<Scalars['String']>;
};

export type RegisterAccountDto = {
  password?: Maybe<Scalars['String']>;
  provider?: Maybe<ProviderType>;
  email?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  social_id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};

export type RemoveResult = {
  __typename?: 'RemoveResult';
  id: Scalars['String'];
  success?: Maybe<Scalars['Boolean']>;
  error_message?: Maybe<Scalars['String']>;
};

export type ResultSearch = {
  __typename?: 'ResultSearch';
  total: Scalars['Float'];
  data: Array<Page>;
  next?: Maybe<Scalars['String']>;
};

export type SalesOrder = {
  __typename?: 'SalesOrder';
  id: Scalars['String'];
  doc_no?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  sender_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  profile_pic?: Maybe<Scalars['String']>;
  credit_type?: Maybe<Scalars['Float']>;
  credit_days?: Maybe<Scalars['Float']>;
  due_date?: Maybe<Scalars['String']>;
  sales_name?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  is_vat_inclusive?: Maybe<Scalars['Boolean']>;
  use_receipt_deduction?: Maybe<Scalars['Boolean']>;
  sub_total?: Maybe<Scalars['Float']>;
  discount_percentage?: Maybe<Scalars['Float']>;
  discount_amount?: Maybe<Scalars['Float']>;
  total_after_discount?: Maybe<Scalars['Float']>;
  is_vat?: Maybe<Scalars['Boolean']>;
  vat_amount?: Maybe<Scalars['Float']>;
  grand_total?: Maybe<Scalars['Float']>;
  total_qty?: Maybe<Scalars['Float']>;
  creation?: Maybe<Scalars['Float']>;
  modified?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
  medium?: Maybe<Medium>;
  medium_id?: Maybe<Scalars['String']>;
  status: SalesOrderStatus;
  tags?: Maybe<Array<Scalars['String']>>;
  items?: Maybe<Array<Item>>;
  contact?: Maybe<Contact>;
  payment?: Maybe<Apay>;
  delivery_note?: Maybe<DeliveryNote>;
};

export type SalesOrderInput = {
  sender_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  profile_pic?: Maybe<Scalars['String']>;
  doc_no?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  contact?: Maybe<ContactInput>;
  items?: Maybe<Array<ItemInput>>;
  credit_type?: Maybe<Scalars['Float']>;
  credit_days?: Maybe<Scalars['Float']>;
  due_date?: Maybe<Scalars['String']>;
  sales_name?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  is_vat_inclusive?: Maybe<Scalars['Boolean']>;
  use_receipt_deduction?: Maybe<Scalars['Boolean']>;
  sub_total?: Maybe<Scalars['Float']>;
  discount_percentage?: Maybe<Scalars['Float']>;
  discount_amount?: Maybe<Scalars['Float']>;
  total_after_discount?: Maybe<Scalars['Float']>;
  is_vat?: Maybe<Scalars['Boolean']>;
  vat_amount?: Maybe<Scalars['Float']>;
  grand_total?: Maybe<Scalars['Float']>;
  total_qty?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
  medium?: Maybe<Medium>;
  medium_id?: Maybe<Scalars['String']>;
  status?: Maybe<SalesOrderStatus>;
  tags?: Maybe<Array<Scalars['String']>>;
};

export type SalesOrderSearchFilter = {
  medium?: Maybe<Medium>;
  medium_id?: Maybe<Scalars['String']>;
  status?: Maybe<SalesOrderStatus>;
  doc_no?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  sender_id?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  q?: Maybe<Scalars['String']>;
};

export type SalesOrderSearchInput = {
  page?: Maybe<SalesOrderSearchPagination>;
  filters?: Maybe<SalesOrderSearchFilter>;
};

export type SalesOrderSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type SalesOrderSearchResult = {
  __typename?: 'SalesOrderSearchResult';
  data?: Maybe<Array<SalesOrder>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export enum SalesOrderStatus {
  Draft = 'DRAFT',
  Ready = 'READY',
  RequestReview = 'REQUEST_REVIEW',
  Reviewing = 'REVIEWING',
  Comfirmed = 'COMFIRMED',
  PrepareDelivery = 'PREPARE_DELIVERY',
  WaitDelivery = 'WAIT_DELIVERY',
  Delivering = 'DELIVERING',
  Unpaid = 'UNPAID',
  Paid = 'PAID',
  Failed = 'FAILED',
  Cancelled = 'CANCELLED'
}

export type SalesOrderSubscriptionFilter = {
  medium_id: Scalars['String'];
};

export type SalesOrderSubscriptionResult = {
  __typename?: 'SalesOrderSubscriptionResult';
  data: SalesOrder;
  operation: Operation;
  old?: Maybe<SalesOrder>;
  bot_id?: Maybe<Scalars['String']>;
};

export type Sentence = {
  __typename?: 'Sentence';
  id: Scalars['String'];
  lang?: Maybe<Scalars['String']>;
  type?: Maybe<SentenceType>;
  text?: Maybe<Scalars['String']>;
  intent_id?: Maybe<Scalars['String']>;
  intent_title?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  context?: Maybe<Scalars['String']>;
};

export type SentenceInput = {
  id?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  type?: Maybe<SentenceType>;
  text?: Maybe<Scalars['String']>;
  intent_id?: Maybe<Scalars['String']>;
  intent_title?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  context?: Maybe<Scalars['String']>;
  migration?: Maybe<Scalars['Boolean']>;
};

export type SentenceSearchFilter = {
  intent_id?: Maybe<Scalars['String']>;
  type?: Maybe<SentenceType>;
  context?: Maybe<Scalars['String']>;
};

export type SentenceSearchInput = {
  page?: Maybe<SentenceSearchPagination>;
  filters?: Maybe<SentenceSearchFilter>;
};

export type SentenceSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type SentenceSearchResult = {
  __typename?: 'SentenceSearchResult';
  data?: Maybe<Array<Sentence>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type SentenceSubscriptionResult = {
  __typename?: 'SentenceSubscriptionResult';
  data: Sentence;
  operation: Operation;
  old?: Maybe<Sentence>;
  bot_id?: Maybe<Scalars['String']>;
};

export enum SentenceType {
  Keyword = 'Keyword',
  Comment = 'Comment',
  Live = 'Live'
}

export type Series = {
  __typename?: 'Series';
  name: Scalars['String'];
  data: Array<Scalars['Float']>;
};

export type Shipping = {
  __typename?: 'Shipping';
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  vendor?: Maybe<Vendor>;
  rate_type?: Maybe<ShippingRateType>;
  rate?: Maybe<Scalars['Float']>;
  min_weight?: Maybe<Scalars['Float']>;
  max_weight?: Maybe<Scalars['Float']>;
};

export type ShippingInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  vendor?: Maybe<Vendor>;
  rate_type?: Maybe<ShippingRateType>;
  rate?: Maybe<Scalars['Float']>;
  min_weight?: Maybe<Scalars['Float']>;
  max_weight?: Maybe<Scalars['Float']>;
};

export enum ShippingRateType {
  Fix = 'Fix',
  Variant = 'Variant'
}

export type ShippingSearchFilter = {
  vendor?: Maybe<Vendor>;
};

export type ShippingSearchInput = {
  page?: Maybe<ShippingSearchPagination>;
  filters?: Maybe<ShippingSearchFilter>;
};

export type ShippingSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type ShippingSearchResult = {
  __typename?: 'ShippingSearchResult';
  data?: Maybe<Array<Shipping>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type ShippingSubscriptionResult = {
  __typename?: 'ShippingSubscriptionResult';
  data: Shipping;
  operation: Operation;
  old?: Maybe<Shipping>;
  bot_id?: Maybe<Scalars['String']>;
};

export type Shop = {
  __typename?: 'Shop';
  title?: Maybe<Scalars['String']>;
  working_day?: Maybe<Scalars['String']>;
  open_close_time?: Maybe<Scalars['String']>;
  picture_url?: Maybe<Scalars['String']>;
  contact?: Maybe<ShopContact>;
  bank_accounts: Array<ShopBankAccount>;
  shippings: Array<ShopShipping>;
  line_notify_token?: Maybe<Scalars['String']>;
  product_google_sheet_url?: Maybe<Scalars['String']>;
  product_category_google_sheet_url?: Maybe<Scalars['String']>;
};

export type ShopBankAccount = {
  __typename?: 'ShopBankAccount';
  enable?: Maybe<Scalars['Boolean']>;
  account_no?: Maybe<Scalars['String']>;
  account_name?: Maybe<Scalars['String']>;
  bank?: Maybe<Bank>;
};

export type ShopBankAccountInput = {
  enable?: Maybe<Scalars['Boolean']>;
  account_no: Scalars['String'];
  account_name?: Maybe<Scalars['String']>;
  bank: Bank;
};

export type ShopContact = {
  __typename?: 'ShopContact';
  cover_image_url?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
};

export type ShopContactInput = {
  cover_image_url?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
};

export type ShopInput = {
  title?: Maybe<Scalars['String']>;
  working_day?: Maybe<Scalars['String']>;
  open_close_time?: Maybe<Scalars['String']>;
  picture_url?: Maybe<Scalars['String']>;
  contact?: Maybe<ShopContactInput>;
  bank_accounts?: Maybe<Array<ShopBankAccountInput>>;
  shippings?: Maybe<Array<ShopShippingInput>>;
  line_notify_token?: Maybe<Scalars['String']>;
  product_google_sheet_url?: Maybe<Scalars['String']>;
  product_category_google_sheet_url?: Maybe<Scalars['String']>;
};

export type ShopPublic = {
  __typename?: 'ShopPublic';
  title?: Maybe<Scalars['String']>;
  open_close_time?: Maybe<Scalars['String']>;
  picture_url?: Maybe<Scalars['String']>;
  bank_accounts: Array<ShopBankAccount>;
  shippings: Array<ShopShipping>;
};

export type ShopShipping = {
  __typename?: 'ShopShipping';
  title?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
};

export type ShopShippingInput = {
  title: Scalars['String'];
  company: Scalars['String'];
  cost: Scalars['Float'];
};

export type SimpleBooking = {
  __typename?: 'SimpleBooking';
  bot_id?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  chat_id?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  contact_phone?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  status?: Maybe<BookingStatus>;
  friend?: Maybe<Friend>;
};

export type SimpleBookingInput = {
  id?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  chat_id?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  contact_phone?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['DateTime']>;
  end_time?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  status?: Maybe<BookingStatus>;
};

export type SimpleBookingSearchFilter = {
  chat_id?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type SimpleBookingSearchInput = {
  page?: Maybe<SimpleBookingSearchPagination>;
  filters?: Maybe<SimpleBookingSearchFilter>;
};

export type SimpleBookingSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type SimpleBookingSearchResult = {
  __typename?: 'SimpleBookingSearchResult';
  data?: Maybe<Array<SimpleBooking>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type SimpleBookingSubscriptionResult = {
  __typename?: 'SimpleBookingSubscriptionResult';
  data: SimpleBooking;
  operation: Operation;
  old?: Maybe<SimpleBooking>;
  bot_id?: Maybe<Scalars['String']>;
};

export type Slot = {
  __typename?: 'Slot';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  time_start: Scalars['String'];
  time_end: Scalars['String'];
  capacity?: Maybe<Scalars['Float']>;
};

export type Snap = {
  __typename?: 'Snap';
  doctype?: Maybe<Scalars['String']>;
  bot_id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  creation: Scalars['Float'];
};

export type SnapInput = {
  bot_id?: Maybe<Scalars['String']>;
  doctype?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type SnapInsightInput = {
  date_range?: Maybe<DateRange>;
  date_format?: Maybe<Scalars['String']>;
};

export type SnapSearchFilter = {
  bot_id?: Maybe<Scalars['String']>;
  doctype?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type SnapSearchInput = {
  page?: Maybe<SnapSearchPagination>;
  filters?: Maybe<SnapSearchFilter>;
};

export type SnapSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type SnapSearchResult = {
  __typename?: 'SnapSearchResult';
  data?: Maybe<Array<Snap>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type SortBy = {
  field?: Maybe<Scalars['String']>;
  order?: Maybe<SortOrder>;
};

export enum SortOrder {
  Desc = 'DESC',
  Asc = 'ASC'
}

export type Stock = {
  __typename?: 'Stock';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<StockType>;
  product_id?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['Float']>;
  status?: Maybe<StockStatus>;
  medium_id?: Maybe<Scalars['String']>;
};

export type StockInput = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<StockType>;
  product_id?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['Float']>;
  status?: Maybe<StockStatus>;
  medium_id?: Maybe<Scalars['String']>;
};

export enum StockStatus {
  Ready = 'Ready',
  Reserved = 'Reserved',
  Canceled = 'Canceled'
}

export type StockSubscriptionFilter = {
  medium_id?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['String']>;
};

export type StockSubscriptionResult = {
  __typename?: 'StockSubscriptionResult';
  data: Stock;
  operation: Operation;
  old?: Maybe<Stock>;
  bot_id?: Maybe<Scalars['String']>;
};

export enum StockType {
  In = 'IN',
  Out = 'OUT'
}

export type Subscription = {
  __typename?: 'Subscription';
  onPermissionChanged: PermissionSubscriptionResult;
  onInboxChanged: InboxSubscriptionResult;
  onFbCommentChanged: FbCommentSubscriptionResult;
  onInvoiceChanged: InvoiceSubscriptionResult;
  onBotChanged: BotSubscriptionResult;
  onFBPostChanged: FbPostSubscriptionResult;
  onKeywordChanged: KeywordSubscriptionResult;
  onKeywordLinkChanged: KeywordLinkSubscriptionResult;
  onSentenceChanged: SentenceSubscriptionResult;
  onIntentChanged: IntentSubscriptionResult;
  onAnswerChanged: AnswerSubscriptionResult;
  onBankAccountChanged: BankAccountSubscriptionResult;
  onShippingChanged: ShippingSubscriptionResult;
  onSalesOrderChanged: SalesOrderSubscriptionResult;
  onContentTypeChanged: ContentTypeSubscriptionResult;
  onItemChanged: ItemSubscriptionResult;
  onStockChanged: StockSubscriptionResult;
  onDeliveryNoteChanged: DeliveryNoteSubscriptionResult;
  onChannelChanged: ChannelSubscriptionResult;
  onFormChanged: FormSubscriptionResult;
  onChatChanged: ChatSubscriptionResult;
  onFriendChanged: FriendSubscriptionResult;
  onBroadcastChanged: BroadcastSubscriptionResult;
  onProductChanged: ProductSubscriptionResult;
  onPageChanged: PageSubscriptionResult;
  onProductCategoryChanged: ProductCategorySubscriptionResult;
  onBookingChanged: BookingSubscriptionResult;
  onSimpleBookingChanged: SimpleBookingSubscriptionResult;
  onOrderChanged: OrderSubscriptionResult;
  onActivityChanged: ActivitySubscriptionResult;
  onTagChanged: TagSubscriptionResult;
  onPaymentChanged: PaymentSubscriptionResult;
  onCardChanged: CardSubscriptionResult;
  onMediaChanged: MediaSubscriptionResult;
  onUserInboxChanged: UserInboxSubscriptionResult;
  onLiveChanged: LiveSubscriptionResult;
  onAisRequestChanged: AisRequestSubscriptionResult;
  onApayChanged: ApaySubscriptionResult;
};


export type SubscriptionOnInboxChangedArgs = {
  filters?: Maybe<InboxSubscriptionFilter>;
};


export type SubscriptionOnFbCommentChangedArgs = {
  filters?: Maybe<FbCommentSubscriptionFilter>;
};


export type SubscriptionOnInvoiceChangedArgs = {
  invoice_id?: Maybe<Scalars['String']>;
  bot_id: Scalars['String'];
};


export type SubscriptionOnSalesOrderChangedArgs = {
  filters?: Maybe<SalesOrderSubscriptionFilter>;
};


export type SubscriptionOnItemChangedArgs = {
  filters?: Maybe<ItemSubscriptionFilter>;
};


export type SubscriptionOnStockChangedArgs = {
  filters?: Maybe<StockSubscriptionFilter>;
};


export type SubscriptionOnChatChangedArgs = {
  chat_id?: Maybe<Scalars['String']>;
};


export type SubscriptionOnProductChangedArgs = {
  filters?: Maybe<ProductSubscriptionFilter>;
};


export type SubscriptionOnPageChangedArgs = {
  page_id?: Maybe<Scalars['String']>;
};


export type SubscriptionOnOrderChangedArgs = {
  order_id?: Maybe<Scalars['String']>;
};


export type SubscriptionOnActivityChangedArgs = {
  filters?: Maybe<ActivitySubscriptionFilter>;
};


export type SubscriptionOnUserInboxChangedArgs = {
  filters?: Maybe<UserInboxSubscriptionFilter>;
};

export type SubscriptionPackage = {
  __typename?: 'SubscriptionPackage';
  id: Scalars['String'];
  bot_id: Scalars['String'];
  sku: Scalars['String'];
  title: Scalars['String'];
  price: Scalars['Float'];
  status?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  purchased_datetime?: Maybe<Scalars['Float']>;
  billing_start?: Maybe<Scalars['Float']>;
  billing_period: Scalars['String'];
  subscribed_by: Scalars['String'];
  expired_in: Scalars['Float'];
};

export type SubscriptionSearchFilter = {
  expired_in_lte?: Maybe<Scalars['Float']>;
  sku?: Maybe<Scalars['String']>;
};

export type SubscriptionSearchInput = {
  page?: Maybe<SubscriptionSearchPagination>;
  filters?: Maybe<SubscriptionSearchFilter>;
};

export type SubscriptionSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type SubscriptionSearchResult = {
  __typename?: 'SubscriptionSearchResult';
  data?: Maybe<Array<SubscriptionPackage>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  title: Scalars['String'];
  type: Scalars['String'];
  actions?: Maybe<Array<TagAction>>;
};

export type TagAction = {
  __typename?: 'TagAction';
  type: TagActionType;
  data?: Maybe<Scalars['String']>;
};

export type TagActionInput = {
  type: TagActionType;
  data?: Maybe<Scalars['String']>;
};

export enum TagActionType {
  UpdateFriendType = 'UpdateFriendType'
}

export type TagSearchFilter = {
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type TagSearchInput = {
  page?: Maybe<TagSearchPagination>;
  filters?: Maybe<TagSearchFilter>;
};

export type TagSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type TagSearchResult = {
  __typename?: 'TagSearchResult';
  data?: Maybe<Array<Tag>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type TagSubcription = {
  __typename?: 'TagSubcription';
  id: Scalars['ID'];
};

export type TagSubscriptionResult = {
  __typename?: 'TagSubscriptionResult';
  data: TagSubcription;
  operation: Operation;
  old?: Maybe<TagSubcription>;
  bot_id?: Maybe<Scalars['String']>;
};

export type Team = {
  __typename?: 'Team';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type TeamChannel = {
  __typename?: 'TeamChannel';
  id: Scalars['ID'];
};

export type TeamSearchResult = {
  __typename?: 'TeamSearchResult';
  total: Scalars['Float'];
  next?: Maybe<Scalars['String']>;
  data: Array<Team>;
};

export type Timeslot = {
  __typename?: 'Timeslot';
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  slots: Array<Slot>;
};

export type TimeslotSearchInput = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type TimeslotSearchResult = {
  __typename?: 'TimeslotSearchResult';
  total: Scalars['Float'];
  next?: Maybe<Scalars['String']>;
  data: Array<Timeslot>;
};

export type TopupFriendAisInput = {
  msisdn: Scalars['String'];
  topup_sku: Scalars['String'];
  shop_sku: Scalars['String'];
  topup_value: Scalars['Float'];
};

export type TrainingReport = {
  __typename?: 'TrainingReport';
  keyword_id: Scalars['String'];
  title: Scalars['String'];
  sentence: KeywordSentence;
  creation: Scalars['Float'];
};

export type TrainingReportResult = {
  __typename?: 'TrainingReportResult';
  data?: Maybe<Array<TrainingReport>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type UpdateResult = {
  __typename?: 'UpdateResult';
  id: Scalars['String'];
  success?: Maybe<Scalars['Boolean']>;
  error_message?: Maybe<Scalars['String']>;
};

export type UpdateTag = {
  actions?: Maybe<Array<TagActionInput>>;
};


export type UserCloudData = {
  __typename?: 'UserCloudData';
  bot_id: Scalars['String'];
};

export type UserInbox = {
  __typename?: 'UserInbox';
  id: Scalars['ID'];
  read: Scalars['Boolean'];
  creation: Scalars['Float'];
  message: Scalars['String'];
  type_id?: Maybe<Scalars['String']>;
  type: UserInboxType;
};

export type UserInboxSearchFilter = {
  type?: Maybe<UserInboxType>;
};

export type UserInboxSearchInput = {
  page?: Maybe<UserInboxSearchPagination>;
  filters?: Maybe<UserInboxSearchFilter>;
};

export type UserInboxSearchPagination = {
  start?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type UserInboxSearchResult = {
  __typename?: 'UserInboxSearchResult';
  data?: Maybe<Array<UserInbox>>;
  total: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
};

export type UserInboxSubscriptionFilter = {
  type?: Maybe<UserInboxType>;
  user_inbox_id?: Maybe<Scalars['String']>;
};

export type UserInboxSubscriptionResult = {
  __typename?: 'UserInboxSubscriptionResult';
  data: UserInbox;
  operation: Operation;
  old?: Maybe<UserInbox>;
  bot_id?: Maybe<Scalars['String']>;
};

export enum UserInboxType {
  Chat = 'Chat',
  Card = 'Card',
  Order = 'Order',
  Permission = 'Permission',
  Channel = 'Channel'
}

export enum Vendor {
  ThaiPostEms = 'ThaiPostEms',
  ThaiPostReg = 'ThaiPostReg',
  Kerry = 'Kerry',
  Dhl = 'DHL',
  BestExpress = 'BestExpress',
  AlphaFast = 'AlphaFast',
  NinjaVan = 'NinjaVan',
  Jt = 'JT',
  Scg = 'SCG',
  Flash = 'Flash',
  Nim = 'Nim',
  Other = 'Other'
}

export type CreateAisRequestMutationVariables = Exact<{
  input: AisRequestInput;
}>;


export type CreateAisRequestMutation = (
  { __typename?: 'Mutation' }
  & { createAisRequest: (
    { __typename?: 'AisRequest' }
    & Pick<AisRequest, 'id' | 'title'>
  ) }
);

export type AisCountMonthlySubscriberQueryVariables = Exact<{ [key: string]: never; }>;


export type AisCountMonthlySubscriberQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'aisCountMonthlySubscriber'>
);

export type AisCountMonthlyTrialQueryVariables = Exact<{
  billingStartDate?: Maybe<Scalars['Float']>;
}>;


export type AisCountMonthlyTrialQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'aisCountMonthlyTrial'>
);

export type AisCountMonthlyBillingQueryVariables = Exact<{ [key: string]: never; }>;


export type AisCountMonthlyBillingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'aisCountMonthlyBilling'>
);

export type SearchAnswerQueryVariables = Exact<{
  input?: Maybe<AnswerSearchInput>;
}>;


export type SearchAnswerQuery = (
  { __typename?: 'Query' }
  & { searchAnswer: (
    { __typename?: 'AnswerSearchResult' }
    & Pick<AnswerSearchResult, 'total' | 'next'>
    & { data?: Maybe<Array<(
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'lang' | 'type' | 'text' | 'intent_id' | 'intent_title'>
    )>> }
  ) }
);

export type CreateAnswerMutationVariables = Exact<{
  input: AnswerInput;
}>;


export type CreateAnswerMutation = (
  { __typename?: 'Mutation' }
  & { createAnswer: (
    { __typename?: 'Answer' }
    & Pick<Answer, 'id' | 'lang' | 'type' | 'text' | 'payload' | 'intent_id' | 'intent_title'>
  ) }
);

export type GetAnswerQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetAnswerQuery = (
  { __typename?: 'Query' }
  & { getAnswer: (
    { __typename?: 'Answer' }
    & Pick<Answer, 'id' | 'lang' | 'type' | 'text' | 'payload' | 'intent_id' | 'intent_title'>
  ) }
);

export type UpdateAnswerMutationVariables = Exact<{
  id: Scalars['String'];
  input: AnswerInput;
}>;


export type UpdateAnswerMutation = (
  { __typename?: 'Mutation' }
  & { updateAnswer: (
    { __typename?: 'Answer' }
    & Pick<Answer, 'id'>
  ) }
);

export type RemoveAnswerMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveAnswerMutation = (
  { __typename?: 'Mutation' }
  & { removeAnswer: (
    { __typename?: 'RemoveResult' }
    & Pick<RemoveResult, 'id'>
  ) }
);

export type OnAnswerChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnAnswerChangedSubscription = (
  { __typename?: 'Subscription' }
  & { onAnswerChanged: (
    { __typename?: 'AnswerSubscriptionResult' }
    & Pick<AnswerSubscriptionResult, 'operation'>
    & { data: (
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'lang' | 'type' | 'text' | 'payload' | 'intent_id' | 'intent_title'>
    ) }
  ) }
);

export type SearchApayQueryVariables = Exact<{
  input?: Maybe<ApaySearchInput>;
}>;


export type SearchApayQuery = (
  { __typename?: 'Query' }
  & { searchApay: (
    { __typename?: 'ApaySearchResult' }
    & Pick<ApaySearchResult, 'total' | 'next'>
    & { data?: Maybe<Array<(
      { __typename?: 'Apay' }
      & Pick<Apay, 'id' | 'method' | 'status' | 'amount' | 'slip_url'>
    )>> }
  ) }
);

export type CreateApayMutationVariables = Exact<{
  input: ApayInput;
}>;


export type CreateApayMutation = (
  { __typename?: 'Mutation' }
  & { createApay: (
    { __typename?: 'Apay' }
    & Pick<Apay, 'id'>
  ) }
);

export type GetApayQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetApayQuery = (
  { __typename?: 'Query' }
  & { getApay: (
    { __typename?: 'Apay' }
    & Pick<Apay, 'id' | 'method' | 'status' | 'amount' | 'slip_url'>
  ) }
);

export type UpdateApayMutationVariables = Exact<{
  id: Scalars['String'];
  input: ApayInput;
}>;


export type UpdateApayMutation = (
  { __typename?: 'Mutation' }
  & { updateApay: (
    { __typename?: 'Apay' }
    & Pick<Apay, 'id'>
  ) }
);

export type RemoveApayMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveApayMutation = (
  { __typename?: 'Mutation' }
  & { removeApay: (
    { __typename?: 'RemoveResult' }
    & Pick<RemoveResult, 'id'>
  ) }
);

export type OnApayChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnApayChangedSubscription = (
  { __typename?: 'Subscription' }
  & { onApayChanged: (
    { __typename?: 'ApaySubscriptionResult' }
    & Pick<ApaySubscriptionResult, 'operation'>
    & { data: (
      { __typename?: 'Apay' }
      & Pick<Apay, 'id' | 'method' | 'status' | 'amount' | 'slip_url'>
    ) }
  ) }
);

export type SearchBankAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchBankAccountQuery = (
  { __typename?: 'Query' }
  & { searchBankAccount: (
    { __typename?: 'BankAccountSearchResult' }
    & Pick<BankAccountSearchResult, 'total' | 'next'>
    & { data?: Maybe<Array<(
      { __typename?: 'BankAccount' }
      & Pick<BankAccount, 'id' | 'account_id' | 'account_type' | 'account_name' | 'bank'>
    )>> }
  ) }
);

export type CreateBankAccountMutationVariables = Exact<{
  input: BankAccountInput;
}>;


export type CreateBankAccountMutation = (
  { __typename?: 'Mutation' }
  & { createBankAccount: (
    { __typename?: 'BankAccount' }
    & Pick<BankAccount, 'id' | 'account_id' | 'account_type' | 'account_name' | 'bank'>
  ) }
);

export type GetBankAccountQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetBankAccountQuery = (
  { __typename?: 'Query' }
  & { getBankAccount: (
    { __typename?: 'BankAccount' }
    & Pick<BankAccount, 'id' | 'account_id' | 'account_type' | 'account_name' | 'bank'>
  ) }
);

export type UpdateBankAccountMutationVariables = Exact<{
  id: Scalars['String'];
  input: BankAccountInput;
}>;


export type UpdateBankAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateBankAccount: (
    { __typename?: 'BankAccount' }
    & Pick<BankAccount, 'id'>
  ) }
);

export type RemoveBankAccountMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveBankAccountMutation = (
  { __typename?: 'Mutation' }
  & { removeBankAccount: (
    { __typename?: 'RemoveResult' }
    & Pick<RemoveResult, 'id'>
  ) }
);

export type OnBankAccountChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnBankAccountChangedSubscription = (
  { __typename?: 'Subscription' }
  & { onBankAccountChanged: (
    { __typename?: 'BankAccountSubscriptionResult' }
    & Pick<BankAccountSubscriptionResult, 'operation'>
    & { data: (
      { __typename?: 'BankAccount' }
      & Pick<BankAccount, 'id' | 'account_id' | 'account_type' | 'account_name' | 'bank'>
    ) }
  ) }
);

export type SearchBotTemplateQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchBotTemplateQuery = (
  { __typename?: 'Query' }
  & { searchBotTemplate: (
    { __typename?: 'BotSearchResult' }
    & { data?: Maybe<Array<(
      { __typename?: 'Bot' }
      & Pick<Bot, 'id' | 'title' | 'picture_url' | 'business_type'>
      & { profile?: Maybe<(
        { __typename?: 'BusinessProfile' }
        & Pick<BusinessProfile, 'title' | 'open_hours' | 'contact' | 'address'>
      )> }
    )>> }
  ) }
);

export type CreateBot2MutationVariables = Exact<{
  input: CreateBotDto;
}>;


export type CreateBot2Mutation = (
  { __typename?: 'Mutation' }
  & { createBot2: (
    { __typename?: 'Bot' }
    & Pick<Bot, 'id'>
  ) }
);

export type GetBotQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetBotQuery = (
  { __typename?: 'Query' }
  & { getBot: (
    { __typename?: 'Bot' }
    & Pick<Bot, 'id' | 'title' | 'picture_url' | 'business_type' | 'modules' | 'friend_count' | 'friend_limit' | 'sku' | 'subscription_cost' | 'gender' | 'address' | 'invoice_address' | 'contact_number' | 'email' | 'created_by' | 'linenotify_token' | 'source' | 'version'>
  ) }
);

export type UpdateBotMutationVariables = Exact<{
  id: Scalars['String'];
  input: BotInput;
}>;


export type UpdateBotMutation = (
  { __typename?: 'Mutation' }
  & { updateBot: (
    { __typename?: 'Bot' }
    & Pick<Bot, 'id' | 'title' | 'gender' | 'picture_url'>
  ) }
);

export type SearchChannelQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchChannelQuery = (
  { __typename?: 'Query' }
  & { searchChannel: (
    { __typename?: 'ChannelSearchResult' }
    & { data?: Maybe<Array<(
      { __typename?: 'Channel' }
      & Pick<Channel, 'id' | 'title' | 'platform' | 'access_token' | 'channel_secret' | 'picture_url' | 'basic_id' | 'enabled'>
    )>> }
  ) }
);

export type CreateChannelMutationVariables = Exact<{
  input: ChannelInput;
}>;


export type CreateChannelMutation = (
  { __typename?: 'Mutation' }
  & { createChannel: (
    { __typename?: 'Channel' }
    & Pick<Channel, 'id' | 'title' | 'platform' | 'access_token' | 'picture_url' | 'basic_id'>
  ) }
);

export type GetChannelQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetChannelQuery = (
  { __typename?: 'Query' }
  & { getChannel: (
    { __typename?: 'Channel' }
    & Pick<Channel, 'id' | 'title' | 'platform' | 'access_token' | 'channel_secret' | 'picture_url' | 'basic_id' | 'enabled'>
  ) }
);

export type UpdateChannelMutationVariables = Exact<{
  id: Scalars['String'];
  input: ChannelInput;
}>;


export type UpdateChannelMutation = (
  { __typename?: 'Mutation' }
  & { updateChannel: (
    { __typename?: 'Channel' }
    & Pick<Channel, 'id' | 'title' | 'platform' | 'access_token' | 'channel_secret' | 'enabled'>
  ) }
);

export type RemoveChannelMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveChannelMutation = (
  { __typename?: 'Mutation' }
  & { removeChannel: (
    { __typename?: 'RemoveResult' }
    & Pick<RemoveResult, 'id' | 'success' | 'error_message'>
  ) }
);

export type OnChannelChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnChannelChangedSubscription = (
  { __typename?: 'Subscription' }
  & { onChannelChanged: (
    { __typename?: 'ChannelSubscriptionResult' }
    & Pick<ChannelSubscriptionResult, 'operation'>
    & { data: (
      { __typename?: 'Channel' }
      & Pick<Channel, 'id' | 'title' | 'platform' | 'access_token' | 'channel_secret' | 'picture_url' | 'enabled'>
    ) }
  ) }
);

export type TestChannelMutationVariables = Exact<{
  input: ChannelInput;
}>;


export type TestChannelMutation = (
  { __typename?: 'Mutation' }
  & { testChannel: (
    { __typename?: 'Channel' }
    & Pick<Channel, 'id'>
  ) }
);

export type CountChannelQueryVariables = Exact<{
  input?: Maybe<ChannelSearchFilter>;
}>;


export type CountChannelQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'countChannel'>
);

export type PushMessageMutationVariables = Exact<{
  chatId: Scalars['String'];
  messages?: Maybe<Array<ChatMessageInput>>;
  platform: Platform;
  keywordId?: Maybe<Scalars['String']>;
  trainSentence?: Maybe<Scalars['String']>;
  params?: Maybe<Scalars['JSON']>;
}>;


export type PushMessageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'pushMessage'>
);

export type SearchContentTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchContentTypeQuery = (
  { __typename?: 'Query' }
  & { searchContentType: (
    { __typename?: 'ContentTypeSearchResult' }
    & Pick<ContentTypeSearchResult, 'total' | 'next'>
    & { data?: Maybe<Array<(
      { __typename?: 'ContentType' }
      & Pick<ContentType, 'id' | 'title' | 'type'>
    )>> }
  ) }
);

export type CreateContentTypeMutationVariables = Exact<{
  input: ContentTypeInput;
}>;


export type CreateContentTypeMutation = (
  { __typename?: 'Mutation' }
  & { createContentType: (
    { __typename?: 'ContentType' }
    & Pick<ContentType, 'id' | 'title'>
  ) }
);

export type GetContentTypeQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetContentTypeQuery = (
  { __typename?: 'Query' }
  & { getContentType: (
    { __typename?: 'ContentType' }
    & Pick<ContentType, 'id' | 'title' | 'type'>
    & { fields?: Maybe<Array<(
      { __typename?: 'ContentTypeField' }
      & Pick<ContentTypeField, 'type' | 'name' | 'title'>
    )>> }
  ) }
);

export type GetContentTypeByTypeQueryVariables = Exact<{
  type: Scalars['String'];
}>;


export type GetContentTypeByTypeQuery = (
  { __typename?: 'Query' }
  & { getContentTypeByType: (
    { __typename?: 'ContentType' }
    & Pick<ContentType, 'id' | 'title' | 'type'>
    & { fields?: Maybe<Array<(
      { __typename?: 'ContentTypeField' }
      & Pick<ContentTypeField, 'type' | 'name' | 'title'>
    )>> }
  ) }
);

export type UpdateContentTypeMutationVariables = Exact<{
  id: Scalars['String'];
  input: ContentTypeInput;
}>;


export type UpdateContentTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateContentType: (
    { __typename?: 'ContentType' }
    & Pick<ContentType, 'id' | 'title'>
  ) }
);

export type RemoveContentTypeMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveContentTypeMutation = (
  { __typename?: 'Mutation' }
  & { removeContentType: (
    { __typename?: 'ContentType' }
    & Pick<ContentType, 'id'>
  ) }
);

export type OnContentTypeChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnContentTypeChangedSubscription = (
  { __typename?: 'Subscription' }
  & { onContentTypeChanged: (
    { __typename?: 'ContentTypeSubscriptionResult' }
    & Pick<ContentTypeSubscriptionResult, 'operation'>
    & { data: (
      { __typename?: 'ContentTypeSubscrition' }
      & Pick<ContentTypeSubscrition, 'id' | 'title' | 'type'>
      & { fields?: Maybe<Array<(
        { __typename?: 'ContentTypeField' }
        & Pick<ContentTypeField, 'title' | 'name' | 'type'>
      )>> }
    ) }
  ) }
);

export type SearchContentQueryVariables = Exact<{
  input?: Maybe<ContentSearchInput>;
}>;


export type SearchContentQuery = (
  { __typename?: 'Query' }
  & { searchContent: (
    { __typename?: 'ContentSearchResult' }
    & Pick<ContentSearchResult, 'total' | 'next'>
    & { data?: Maybe<Array<(
      { __typename?: 'Content' }
      & Pick<Content, 'id' | 'title' | 'content_type' | 'weight' | 'body' | 'creation'>
    )>> }
  ) }
);

export type CreateContentMutationVariables = Exact<{
  input: ContentInput;
}>;


export type CreateContentMutation = (
  { __typename?: 'Mutation' }
  & { createContent: (
    { __typename?: 'Content' }
    & Pick<Content, 'id' | 'title'>
  ) }
);

export type GetContentQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetContentQuery = (
  { __typename?: 'Query' }
  & { getContent: (
    { __typename?: 'Content' }
    & Pick<Content, 'id' | 'title' | 'content_type' | 'weight' | 'body'>
  ) }
);

export type UpdateContentMutationVariables = Exact<{
  id: Scalars['String'];
  input: ContentInput;
}>;


export type UpdateContentMutation = (
  { __typename?: 'Mutation' }
  & { updateContent: (
    { __typename?: 'Content' }
    & Pick<Content, 'id' | 'title'>
  ) }
);

export type RemoveContentMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveContentMutation = (
  { __typename?: 'Mutation' }
  & { removeContent: (
    { __typename?: 'Content' }
    & Pick<Content, 'id'>
  ) }
);

export type GetContextQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetContextQuery = (
  { __typename?: 'Query' }
  & { getBot: (
    { __typename?: 'Bot' }
    & Pick<Bot, 'id' | 'title' | 'picture_url' | 'business_type' | 'modules' | 'friend_count' | 'friend_limit' | 'sku' | 'subscription_cost' | 'gender' | 'address' | 'invoice_address' | 'contact_number' | 'email' | 'created_by' | 'linenotify_token' | 'source' | 'version'>
  ), getCountFriendTags: (
    { __typename?: 'AggregationResult' }
    & { data?: Maybe<Array<(
      { __typename?: 'Aggregation' }
      & Pick<Aggregation, 'name' | 'total'>
    )>> }
  ), searchFriendGroup: (
    { __typename?: 'FriendGroupSearchResult' }
    & { data?: Maybe<Array<(
      { __typename?: 'FriendGroup' }
      & Pick<FriendGroup, 'id' | 'title' | 'favorite' | 'includes' | 'excludes' | 'is_some'>
    )>> }
  ), searchChannel: (
    { __typename?: 'ChannelSearchResult' }
    & { data?: Maybe<Array<(
      { __typename?: 'Channel' }
      & Pick<Channel, 'id' | 'title' | 'platform' | 'access_token' | 'channel_secret' | 'picture_url' | 'basic_id' | 'enabled'>
    )>> }
  ), searchTag: (
    { __typename?: 'TagSearchResult' }
    & { data?: Maybe<Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'title' | 'type'>
    )>> }
  ), searchContentType: (
    { __typename?: 'ContentTypeSearchResult' }
    & Pick<ContentTypeSearchResult, 'total' | 'next'>
    & { data?: Maybe<Array<(
      { __typename?: 'ContentType' }
      & Pick<ContentType, 'id' | 'title' | 'type'>
    )>> }
  ), searchProductCategory: (
    { __typename?: 'ProductCategorySearchResult' }
    & { data?: Maybe<Array<(
      { __typename?: 'ProductCategory' }
      & Pick<ProductCategory, 'title' | 'picture_url' | 'id' | 'description'>
    )>> }
  ) }
);

export type SearchFeedQueryVariables = Exact<{
  pageId: Scalars['String'];
}>;


export type SearchFeedQuery = (
  { __typename?: 'Query' }
  & { searchFeed: Array<(
    { __typename?: 'FacebookPost' }
    & Pick<FacebookPost, 'id' | 'message' | 'picture' | 'full_picture'>
  )> }
);

export type FbCommentFieldsFragment = (
  { __typename?: 'FbComment' }
  & Pick<FbComment, 'id' | 'sender_id' | 'sender_name' | 'timestamp' | 'text' | 'picture_url'>
);

export type SearchFbCommentQueryVariables = Exact<{
  input?: Maybe<FbCommentSearchInput>;
}>;


export type SearchFbCommentQuery = (
  { __typename?: 'Query' }
  & { searchFbComment: (
    { __typename?: 'FbCommentSearchResult' }
    & Pick<FbCommentSearchResult, 'total' | 'next'>
    & { data?: Maybe<Array<(
      { __typename?: 'FbComment' }
      & FbCommentFieldsFragment
    )>> }
  ) }
);

export type CreateFbCommentMutationVariables = Exact<{
  input: FbCommentInput;
}>;


export type CreateFbCommentMutation = (
  { __typename?: 'Mutation' }
  & { createFbComment: (
    { __typename?: 'FbComment' }
    & Pick<FbComment, 'id'>
  ) }
);

export type GetFbCommentQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetFbCommentQuery = (
  { __typename?: 'Query' }
  & { getFbComment: (
    { __typename?: 'FbComment' }
    & FbCommentFieldsFragment
  ) }
);

export type RemoveFbCommentMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveFbCommentMutation = (
  { __typename?: 'Mutation' }
  & { removeFbComment: (
    { __typename?: 'RemoveResult' }
    & Pick<RemoveResult, 'id'>
  ) }
);

export type OnFbCommentChangedSubscriptionVariables = Exact<{
  filters?: Maybe<FbCommentSubscriptionFilter>;
}>;


export type OnFbCommentChangedSubscription = (
  { __typename?: 'Subscription' }
  & { onFbCommentChanged: (
    { __typename?: 'FbCommentSubscriptionResult' }
    & Pick<FbCommentSubscriptionResult, 'operation'>
    & { data: (
      { __typename?: 'FbComment' }
      & FbCommentFieldsFragment
    ) }
  ) }
);

export type SearchFbPostQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchFbPostQuery = (
  { __typename?: 'Query' }
  & { searchFBPost: (
    { __typename?: 'FBPostSearchResult' }
    & Pick<FbPostSearchResult, 'total' | 'next'>
    & { data?: Maybe<Array<(
      { __typename?: 'FBPost' }
      & Pick<FbPost, 'id' | 'message' | 'full_picture'>
    )>> }
  ) }
);

export type CreateFbPostMutationVariables = Exact<{
  input: FbPostInput;
}>;


export type CreateFbPostMutation = (
  { __typename?: 'Mutation' }
  & { createFBPost: (
    { __typename?: 'FBPost' }
    & Pick<FbPost, 'id' | 'message' | 'full_picture'>
  ) }
);

export type GetFbPostQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetFbPostQuery = (
  { __typename?: 'Query' }
  & { getFBPost: (
    { __typename?: 'FBPost' }
    & Pick<FbPost, 'id' | 'message' | 'full_picture'>
  ) }
);

export type UpdateFbPostMutationVariables = Exact<{
  id: Scalars['String'];
  input: FbPostInput;
}>;


export type UpdateFbPostMutation = (
  { __typename?: 'Mutation' }
  & { updateFBPost: (
    { __typename?: 'FBPost' }
    & Pick<FbPost, 'id'>
  ) }
);

export type RemoveFbPostMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveFbPostMutation = (
  { __typename?: 'Mutation' }
  & { removeFBPost: (
    { __typename?: 'RemoveResult' }
    & Pick<RemoveResult, 'id'>
  ) }
);

export type OnFbPostChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnFbPostChangedSubscription = (
  { __typename?: 'Subscription' }
  & { onFBPostChanged: (
    { __typename?: 'FBPostSubscriptionResult' }
    & Pick<FbPostSubscriptionResult, 'operation'>
    & { data: (
      { __typename?: 'FBPost' }
      & Pick<FbPost, 'id' | 'message' | 'full_picture'>
    ) }
  ) }
);

export type SearchFriendQueryVariables = Exact<{
  input: FriendSearchInput;
}>;


export type SearchFriendQuery = (
  { __typename?: 'Query' }
  & { searchFriend: (
    { __typename?: 'FriendSearchResult' }
    & Pick<FriendSearchResult, 'total' | 'next'>
    & { data?: Maybe<Array<(
      { __typename?: 'Friend' }
      & Pick<Friend, 'id' | 'channel_id' | 'social_id' | 'full_name' | 'picture_url' | 'platform' | 'tags'>
    )>> }
  ) }
);

export type GetFriendQueryVariables = Exact<{
  friend_id: Scalars['String'];
}>;


export type GetFriendQuery = (
  { __typename?: 'Query' }
  & { getFriend: (
    { __typename?: 'Friend' }
    & Pick<Friend, 'id' | 'bot_id' | 'full_name' | 'status' | 'contact' | 'tags' | 'picture_url' | 'platform' | 'channel_id' | 'social_id' | 'rating' | 'about' | 'purchased_tags' | 'reached_tags'>
  ) }
);

export type UpdateFriendMutationVariables = Exact<{
  friend_id: Scalars['String'];
  input: FriendInput;
}>;


export type UpdateFriendMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateFriend'>
);

export type GetCountFriendTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountFriendTagsQuery = (
  { __typename?: 'Query' }
  & { getCountFriendTags: (
    { __typename?: 'AggregationResult' }
    & { data?: Maybe<Array<(
      { __typename?: 'Aggregation' }
      & Pick<Aggregation, 'name' | 'total'>
    )>> }
  ) }
);

export type CountFriendQueryVariables = Exact<{
  input?: Maybe<FriendSearchFilter>;
}>;


export type CountFriendQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'countFriend'>
);

export type OnFriendChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnFriendChangedSubscription = (
  { __typename?: 'Subscription' }
  & { onFriendChanged: (
    { __typename?: 'FriendSubscriptionResult' }
    & { data: (
      { __typename?: 'Friend' }
      & Pick<Friend, 'id' | 'full_name' | 'status' | 'contact' | 'tags' | 'picture_url' | 'platform' | 'channel_id' | 'social_id' | 'rating' | 'about'>
    ) }
  ) }
);

export type SearchIntentQueryVariables = Exact<{
  input?: Maybe<IntentSearchInput>;
}>;


export type SearchIntentQuery = (
  { __typename?: 'Query' }
  & { searchIntent: (
    { __typename?: 'IntentSearchResult' }
    & Pick<IntentSearchResult, 'total' | 'next'>
    & { data?: Maybe<Array<(
      { __typename?: 'Intent' }
      & Pick<Intent, 'id' | 'lang' | 'type' | 'title' | 'published' | 'payload_type' | 'payload'>
    )>> }
  ) }
);

export type CreateIntentMutationVariables = Exact<{
  input: IntentInput;
}>;


export type CreateIntentMutation = (
  { __typename?: 'Mutation' }
  & { createIntent: (
    { __typename?: 'Intent' }
    & Pick<Intent, 'id' | 'title' | 'context'>
  ) }
);

export type GetIntentQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetIntentQuery = (
  { __typename?: 'Query' }
  & { getIntent: (
    { __typename?: 'Intent' }
    & Pick<Intent, 'id' | 'lang' | 'type' | 'title' | 'published' | 'is_hidden_comment' | 'is_delete_comment' | 'payload_type' | 'payload' | 'context'>
    & { sentences?: Maybe<Array<(
      { __typename?: 'Sentence' }
      & Pick<Sentence, 'id' | 'text'>
    )>>, answers?: Maybe<Array<(
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'type' | 'text' | 'payload'>
    )>>, answer_fbbot?: Maybe<(
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'intent_id' | 'payload'>
    )> }
  ) }
);

export type UpdateIntentMutationVariables = Exact<{
  id: Scalars['String'];
  input: IntentInput;
  old: IntentInput;
}>;


export type UpdateIntentMutation = (
  { __typename?: 'Mutation' }
  & { updateIntent: (
    { __typename?: 'Intent' }
    & Pick<Intent, 'id'>
  ) }
);

export type RemoveIntentMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveIntentMutation = (
  { __typename?: 'Mutation' }
  & { removeIntent: (
    { __typename?: 'RemoveResult' }
    & Pick<RemoveResult, 'id'>
  ) }
);

export type OnIntentChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnIntentChangedSubscription = (
  { __typename?: 'Subscription' }
  & { onIntentChanged: (
    { __typename?: 'IntentSubscriptionResult' }
    & Pick<IntentSubscriptionResult, 'operation'>
    & { data: (
      { __typename?: 'Intent' }
      & Pick<Intent, 'id' | 'lang' | 'type' | 'title' | 'published'>
    ), old?: Maybe<(
      { __typename?: 'Intent' }
      & Pick<Intent, 'id' | 'lang' | 'type' | 'title' | 'published'>
    )> }
  ) }
);

export type ItemFieldsFragment = (
  { __typename?: 'Item' }
  & Pick<Item, 'id' | 'sku' | 'type' | 'title' | 'description' | 'qty' | 'unit_name' | 'price_per_unit' | 'total' | 'discount_amount' | 'vat_rate' | 'picture_url' | 'user_id' | 'profile_pic' | 'display_name'>
);

export type SalesOrderFieldsFragment = (
  { __typename?: 'SalesOrder' }
  & Pick<SalesOrder, 'id' | 'code' | 'status' | 'user_id' | 'profile_pic' | 'display_name'>
);

export type SearchItemQueryVariables = Exact<{
  input: ItemSearchInput;
}>;


export type SearchItemQuery = (
  { __typename?: 'Query' }
  & { searchItem: (
    { __typename?: 'ItemSearchResult' }
    & Pick<ItemSearchResult, 'total' | 'next'>
    & { data?: Maybe<Array<(
      { __typename?: 'Item' }
      & ItemFieldsFragment
    )>> }
  ) }
);

export type OnItemChangedSubscriptionVariables = Exact<{
  filters?: Maybe<ItemSubscriptionFilter>;
}>;


export type OnItemChangedSubscription = (
  { __typename?: 'Subscription' }
  & { onItemChanged: (
    { __typename?: 'ItemSubscriptionResult' }
    & Pick<ItemSubscriptionResult, 'operation'>
    & { data: (
      { __typename?: 'Item' }
      & ItemFieldsFragment
    ) }
  ) }
);

export type FetchDigramQueryVariables = Exact<{
  input?: Maybe<KeywordSearchInput>;
}>;


export type FetchDigramQuery = (
  { __typename?: 'Query' }
  & { searchKeyword: (
    { __typename?: 'KeywordSearchResult' }
    & { data?: Maybe<Array<(
      { __typename?: 'Keyword' }
      & Pick<Keyword, 'id' | 'title' | 'type'>
    )>> }
  ), searchKeywordLink: (
    { __typename?: 'KeywordLinkSearchResult' }
    & { data?: Maybe<Array<(
      { __typename?: 'KeywordLink' }
      & Pick<KeywordLink, 'id' | 'from' | 'to'>
    )>> }
  ) }
);

export type GetKeywordQueryVariables = Exact<{
  keywordId: Scalars['String'];
}>;


export type GetKeywordQuery = (
  { __typename?: 'Query' }
  & { getKeyword: (
    { __typename?: 'Keyword' }
    & Pick<Keyword, 'id' | 'title' | 'type' | 'published' | 'is_linenotify' | 'linenotify_payload' | 'is_autotag' | 'autotags' | 'is_api' | 'api_payload' | 'keywords'>
    & { sentences?: Maybe<Array<(
      { __typename?: 'KeywordSentence' }
      & Pick<KeywordSentence, 'type' | 'text'>
    )>>, action?: Maybe<(
      { __typename?: 'Action' }
      & Pick<Action, 'id' | 'facebook_messages' | 'line_messages'>
    )>, plugins?: Maybe<Array<(
      { __typename?: 'Plugin' }
      & Pick<Plugin, 'id' | 'type' | 'body' | 'enabled'>
    )>> }
  ) }
);

export type CreateKeywordMutationVariables = Exact<{
  input: KeywordInput;
}>;


export type CreateKeywordMutation = (
  { __typename?: 'Mutation' }
  & { createKeyword: (
    { __typename?: 'KeywordLink' }
    & Pick<KeywordLink, 'id'>
  ) }
);

export type UpdateKeywordMutationVariables = Exact<{
  keywordId: Scalars['String'];
  input: KeywordUpdate;
}>;


export type UpdateKeywordMutation = (
  { __typename?: 'Mutation' }
  & { updateKeyword: (
    { __typename?: 'Keyword' }
    & Pick<Keyword, 'id'>
  ) }
);

export type RemoveKeywordMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveKeywordMutation = (
  { __typename?: 'Mutation' }
  & { removeKeyword: (
    { __typename?: 'RemoveResult' }
    & Pick<RemoveResult, 'id' | 'success' | 'error_message'>
  ) }
);

export type OnKeywordChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnKeywordChangedSubscription = (
  { __typename?: 'Subscription' }
  & { onKeywordChanged: (
    { __typename?: 'KeywordSubscriptionResult' }
    & Pick<KeywordSubscriptionResult, 'operation'>
    & { data: (
      { __typename?: 'Keyword' }
      & Pick<Keyword, 'id' | 'title' | 'type'>
    ) }
  ) }
);

export type CreateKeywordLinkMutationVariables = Exact<{
  from_id: Scalars['String'];
  to_id: Scalars['String'];
}>;


export type CreateKeywordLinkMutation = (
  { __typename?: 'Mutation' }
  & { createKeywordLink: (
    { __typename?: 'KeywordLink' }
    & Pick<KeywordLink, 'id' | 'from' | 'to'>
  ) }
);

export type RemoveKeywordLinkMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveKeywordLinkMutation = (
  { __typename?: 'Mutation' }
  & { removeKeywordLink: (
    { __typename?: 'RemoveResult' }
    & Pick<RemoveResult, 'id' | 'success' | 'error_message'>
  ) }
);

export type OnKeywordLinkChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnKeywordLinkChangedSubscription = (
  { __typename?: 'Subscription' }
  & { onKeywordLinkChanged: (
    { __typename?: 'KeywordLinkSubscriptionResult' }
    & Pick<KeywordLinkSubscriptionResult, 'operation'>
    & { data: (
      { __typename?: 'KeywordLink' }
      & Pick<KeywordLink, 'id' | 'from' | 'to'>
    ) }
  ) }
);

export type SearchLiveQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchLiveQuery = (
  { __typename?: 'Query' }
  & { searchLive: (
    { __typename?: 'LiveSearchResult' }
    & Pick<LiveSearchResult, 'total'>
    & { data?: Maybe<Array<(
      { __typename?: 'Live' }
      & Pick<Live, 'id' | 'post_id' | 'title' | 'picture_url'>
    )>> }
  ) }
);

export type CreateLiveMutationVariables = Exact<{
  input: LiveInput;
}>;


export type CreateLiveMutation = (
  { __typename?: 'Mutation' }
  & { createLive: (
    { __typename?: 'Live' }
    & Pick<Live, 'id' | 'post_id' | 'title' | 'picture_url'>
  ) }
);

export type GetLiveQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetLiveQuery = (
  { __typename?: 'Query' }
  & { getLive: (
    { __typename?: 'Live' }
    & Pick<Live, 'id' | 'post_id' | 'title' | 'picture_url'>
  ) }
);

export type UpdateLiveMutationVariables = Exact<{
  id: Scalars['String'];
  input: LiveUpdate;
}>;


export type UpdateLiveMutation = (
  { __typename?: 'Mutation' }
  & { updateLive: (
    { __typename?: 'Live' }
    & Pick<Live, 'id'>
  ) }
);

export type RemoveLiveMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveLiveMutation = (
  { __typename?: 'Mutation' }
  & { removeLive: (
    { __typename?: 'Live' }
    & Pick<Live, 'id'>
  ) }
);

export type OnLiveChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnLiveChangedSubscription = (
  { __typename?: 'Subscription' }
  & { onLiveChanged: (
    { __typename?: 'LiveSubscriptionResult' }
    & Pick<LiveSubscriptionResult, 'operation'>
    & { data: (
      { __typename?: 'Live' }
      & Pick<Live, 'id' | 'post_id' | 'title'>
    ) }
  ) }
);

export type ImportLiveProductMutationVariables = Exact<{
  id: Scalars['String'];
  input: ImportLiveProductInput;
}>;


export type ImportLiveProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'importLiveProduct'>
);

export type RemoveLiveProductMutationVariables = Exact<{
  productId: Scalars['String'];
}>;


export type RemoveLiveProductMutation = (
  { __typename?: 'Mutation' }
  & { removeLiveProduct: (
    { __typename?: 'RemoveResult' }
    & Pick<RemoveResult, 'id'>
  ) }
);

export type GetLiveStatsQueryVariables = Exact<{
  liveId: Scalars['String'];
}>;


export type GetLiveStatsQuery = (
  { __typename?: 'Query' }
  & { getLiveStats: (
    { __typename?: 'LiveStats' }
    & Pick<LiveStats, 'total_reserved_qty' | 'total_reserved_value'>
  ) }
);

export type GetLiveContextQueryVariables = Exact<{
  id: Scalars['String'];
  liveId: Scalars['String'];
}>;


export type GetLiveContextQuery = (
  { __typename?: 'Query' }
  & { getLive: (
    { __typename?: 'Live' }
    & Pick<Live, 'id' | 'post_id' | 'title' | 'picture_url'>
  ), getBot: (
    { __typename?: 'Bot' }
    & Pick<Bot, 'id' | 'title' | 'picture_url' | 'business_type' | 'modules' | 'friend_count' | 'friend_limit' | 'sku' | 'subscription_cost' | 'gender' | 'address' | 'invoice_address' | 'contact_number' | 'email' | 'created_by' | 'linenotify_token' | 'source' | 'version'>
  ), searchChannel: (
    { __typename?: 'ChannelSearchResult' }
    & { data?: Maybe<Array<(
      { __typename?: 'Channel' }
      & Pick<Channel, 'id' | 'title' | 'platform' | 'access_token' | 'channel_secret' | 'picture_url' | 'basic_id' | 'enabled'>
    )>> }
  ), searchProduct: (
    { __typename?: 'ProductSearchResult' }
    & Pick<ProductSearchResult, 'next'>
    & { data?: Maybe<Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'sku' | 'title' | 'category' | 'full_price' | 'sell_description' | 'sell_price' | 'price_per_year' | 'price_per_month' | 'type' | 'picture_url' | 'picture_urls' | 'stock_qty' | 'keywords' | 'live_status'>
    )>> }
  ), searchBankAccount: (
    { __typename?: 'BankAccountSearchResult' }
    & Pick<BankAccountSearchResult, 'total' | 'next'>
    & { data?: Maybe<Array<(
      { __typename?: 'BankAccount' }
      & Pick<BankAccount, 'id' | 'account_id' | 'account_type' | 'account_name' | 'bank'>
    )>> }
  ), searchShipping: (
    { __typename?: 'ShippingSearchResult' }
    & Pick<ShippingSearchResult, 'total' | 'next'>
    & { data?: Maybe<Array<(
      { __typename?: 'Shipping' }
      & Pick<Shipping, 'id' | 'title' | 'vendor' | 'description' | 'rate' | 'rate_type'>
    )>> }
  ), searchItem: (
    { __typename?: 'ItemSearchResult' }
    & Pick<ItemSearchResult, 'total' | 'next'>
    & { data?: Maybe<Array<(
      { __typename?: 'Item' }
      & ItemFieldsFragment
    )>> }
  ), searchFbComment: (
    { __typename?: 'FbCommentSearchResult' }
    & Pick<FbCommentSearchResult, 'total' | 'next'>
    & { data?: Maybe<Array<(
      { __typename?: 'FbComment' }
      & FbCommentFieldsFragment
    )>> }
  ) }
);

export type RecreateLiveIntentMutationVariables = Exact<{
  liveId: Scalars['String'];
}>;


export type RecreateLiveIntentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'recreateLiveIntent'>
);

export type SearchAppointmentQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchAppointmentQuery = (
  { __typename?: 'Query' }
  & { searchSimpleBookings: (
    { __typename?: 'BookingSearchResult' }
    & { data: Array<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'status' | 'title' | 'schedule' | 'user_id' | 'contact' | 'location'>
    )> }
  ) }
);

export type RemoveBookingMutationVariables = Exact<{
  bookingId: Scalars['String'];
}>;


export type RemoveBookingMutation = (
  { __typename?: 'Mutation' }
  & { removeBooking: (
    { __typename?: 'RemoveResult' }
    & Pick<RemoveResult, 'id'>
  ) }
);

export type ConfirmBookingMutationVariables = Exact<{
  bookingId: Scalars['String'];
  newSchedule?: Maybe<Scalars['String']>;
}>;


export type ConfirmBookingMutation = (
  { __typename?: 'Mutation' }
  & { confirmBooking: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'title' | 'status' | 'schedule' | 'user_id' | 'contact' | 'location'>
  ) }
);

export type OnBookingChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnBookingChangedSubscription = (
  { __typename?: 'Subscription' }
  & { onBookingChanged: (
    { __typename?: 'BookingSubscriptionResult' }
    & Pick<BookingSubscriptionResult, 'operation'>
    & { data: (
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'title' | 'status' | 'schedule' | 'user_id' | 'contact' | 'location'>
    ) }
  ) }
);

export type AuthorizeLineBotMutationVariables = Exact<{
  code: Scalars['String'];
  redirectUri: Scalars['String'];
}>;


export type AuthorizeLineBotMutation = (
  { __typename?: 'Mutation' }
  & { authorizeLineBot: (
    { __typename?: 'LineAuthorizedPayload' }
    & Pick<LineAuthorizedPayload, 'userId' | 'basicId' | 'displayName' | 'pictureUrl' | 'premiumId'>
  ) }
);

export type GetPublicBotQueryVariables = Exact<{
  botId: Scalars['String'];
}>;


export type GetPublicBotQuery = (
  { __typename?: 'Query' }
  & { getPublicBot: (
    { __typename?: 'BotPublic' }
    & Pick<BotPublic, 'id' | 'title' | 'picture_url'>
  ) }
);

export type GetShopInfoPublicQueryVariables = Exact<{
  botId: Scalars['String'];
}>;


export type GetShopInfoPublicQuery = (
  { __typename?: 'Query' }
  & { getShopInfoPublic: (
    { __typename?: 'ShopPublic' }
    & Pick<ShopPublic, 'title' | 'picture_url'>
    & { bank_accounts: Array<(
      { __typename?: 'ShopBankAccount' }
      & Pick<ShopBankAccount, 'account_no' | 'account_name' | 'enable' | 'bank'>
    )>, shippings: Array<(
      { __typename?: 'ShopShipping' }
      & Pick<ShopShipping, 'title' | 'company' | 'cost'>
    )> }
  ) }
);

export type SearchBotQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchBotQuery = (
  { __typename?: 'Query' }
  & { searchBot: (
    { __typename?: 'BotSearchResult' }
    & Pick<BotSearchResult, 'next' | 'total'>
    & { data?: Maybe<Array<(
      { __typename?: 'Bot' }
      & Pick<Bot, 'id' | 'title' | 'picture_url' | 'business_type' | 'sku' | 'friend_count' | 'friend_limit' | 'modules' | 'role'>
      & { profile?: Maybe<(
        { __typename?: 'BusinessProfile' }
        & Pick<BusinessProfile, 'title' | 'open_hours' | 'contact' | 'address'>
      )>, subscription?: Maybe<(
        { __typename?: 'SubscriptionPackage' }
        & Pick<SubscriptionPackage, 'id' | 'bot_id' | 'sku' | 'title' | 'price' | 'status' | 'billing_period' | 'expired_in' | 'subscribed_by'>
      )> }
    )>> }
  ) }
);

export type AccountLoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type AccountLoginMutation = (
  { __typename?: 'Mutation' }
  & { login2: (
    { __typename?: 'AccessTokenPayload' }
    & Pick<AccessTokenPayload, 'id' | 'access_token'>
  ) }
);

export type GetBotAccessTokenQueryVariables = Exact<{
  bot_id: Scalars['String'];
}>;


export type GetBotAccessTokenQuery = (
  { __typename?: 'Query' }
  & { getBotAccessToken: (
    { __typename?: 'AccessTokenPayload' }
    & Pick<AccessTokenPayload, 'id' | 'access_token'>
  ) }
);

export type RegisterAccountMutationVariables = Exact<{
  input: RegisterAccountDto;
}>;


export type RegisterAccountMutation = (
  { __typename?: 'Mutation' }
  & { registerAccount: (
    { __typename?: 'AccessTokenPayload' }
    & Pick<AccessTokenPayload, 'id' | 'access_token'>
  ) }
);

export type AllBotPackagesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllBotPackagesQuery = (
  { __typename?: 'Query' }
  & { searchBotPackage: (
    { __typename?: 'ProductSearchResult' }
    & { data?: Maybe<Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'sku' | 'sell_description' | 'price_per_month' | 'price_per_year' | 'title'>
    )>> }
  ) }
);

export type UpgradeBotMutationVariables = Exact<{
  input: BotUpgradeInput;
}>;


export type UpgradeBotMutation = (
  { __typename?: 'Mutation' }
  & { upgradeBot: (
    { __typename?: 'Bot' }
    & Pick<Bot, 'id'>
  ) }
);

export type OnBotChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnBotChangedSubscription = (
  { __typename?: 'Subscription' }
  & { onBotChanged: (
    { __typename?: 'BotSubscriptionResult' }
    & Pick<BotSubscriptionResult, 'operation'>
    & { data: (
      { __typename?: 'Bot' }
      & Pick<Bot, 'id' | 'business_type' | 'title' | 'category' | 'picture_url'>
    ) }
  ) }
);

export type CreatePaymentMutationVariables = Exact<{
  input: PaymentInput;
}>;


export type CreatePaymentMutation = (
  { __typename?: 'Mutation' }
  & { createPayment: (
    { __typename?: 'Payment' }
    & Pick<Payment, 'id' | 'payload'>
  ) }
);

export type UpdatePaymentMutationVariables = Exact<{
  id: Scalars['String'];
  input: PaymentUpdateInput;
}>;


export type UpdatePaymentMutation = (
  { __typename?: 'Mutation' }
  & { updatePayment: (
    { __typename?: 'Payment' }
    & Pick<Payment, 'id' | 'invoice_id'>
  ) }
);

export type GetInvoiceQueryVariables = Exact<{
  invoice_id: Scalars['String'];
}>;


export type GetInvoiceQuery = (
  { __typename?: 'Query' }
  & { getInvoice: (
    { __typename?: 'AdminInvoice' }
    & Pick<AdminInvoice, 'id' | 'bot_id' | 'total'>
    & { items: Array<(
      { __typename?: 'InvoiceItem' }
      & Pick<InvoiceItem, 'sku' | 'title' | 'amount'>
    )> }
  ) }
);

export type OnInvoiceChangedSubscriptionVariables = Exact<{
  bot_id: Scalars['String'];
  invoice_id: Scalars['String'];
}>;


export type OnInvoiceChangedSubscription = (
  { __typename?: 'Subscription' }
  & { onInvoiceChanged: (
    { __typename?: 'InvoiceSubscriptionResult' }
    & Pick<InvoiceSubscriptionResult, 'operation'>
    & { data: (
      { __typename?: 'AdminInvoice' }
      & Pick<AdminInvoice, 'id' | 'status'>
    ) }
  ) }
);

export type SearchPaymentQueryVariables = Exact<{
  input?: Maybe<PaymentSearchInput>;
}>;


export type SearchPaymentQuery = (
  { __typename?: 'Query' }
  & { searchPayment: (
    { __typename?: 'PaymentSearchResult' }
    & Pick<PaymentSearchResult, 'next' | 'total'>
    & { data?: Maybe<Array<(
      { __typename?: 'Payment' }
      & Pick<Payment, 'id' | 'first_name' | 'last_name' | 'method' | 'status' | 'amount' | 'creation' | 'bot_id'>
    )>> }
  ) }
);

export type SearchInvoiceQueryVariables = Exact<{
  bot_id: Scalars['String'];
}>;


export type SearchInvoiceQuery = (
  { __typename?: 'Query' }
  & { searchInvoice: (
    { __typename?: 'InvoiceSearchResult' }
    & { data?: Maybe<Array<(
      { __typename?: 'AdminInvoice' }
      & Pick<AdminInvoice, 'id' | 'total' | 'status' | 'bot_id' | 'billing_time' | 'creation'>
      & { items: Array<(
        { __typename?: 'InvoiceItem' }
        & Pick<InvoiceItem, 'title' | 'description' | 'sku' | 'qty' | 'price' | 'amount'>
      )> }
    )>> }
  ) }
);

export type GetShopInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetShopInfoQuery = (
  { __typename?: 'Query' }
  & { getShopInfo: (
    { __typename?: 'Shop' }
    & Pick<Shop, 'title' | 'picture_url' | 'line_notify_token' | 'product_google_sheet_url' | 'product_category_google_sheet_url'>
    & { contact?: Maybe<(
      { __typename?: 'ShopContact' }
      & Pick<ShopContact, 'facebook' | 'cover_image_url' | 'instagram' | 'phone_number'>
    )>, bank_accounts: Array<(
      { __typename?: 'ShopBankAccount' }
      & Pick<ShopBankAccount, 'enable' | 'account_no' | 'bank' | 'account_name'>
    )>, shippings: Array<(
      { __typename?: 'ShopShipping' }
      & Pick<ShopShipping, 'title' | 'company' | 'cost'>
    )> }
  ) }
);

export type UpdateShopInfoMutationVariables = Exact<{
  input: ShopInput;
}>;


export type UpdateShopInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateShopInfo: (
    { __typename?: 'Shop' }
    & Pick<Shop, 'product_google_sheet_url'>
  ) }
);

export type ImportProductMutationVariables = Exact<{
  google_sheet_id: Scalars['String'];
  sheet_number: Scalars['Float'];
}>;


export type ImportProductMutation = (
  { __typename?: 'Mutation' }
  & { importProduct: (
    { __typename?: 'ImportResult' }
    & Pick<ImportResult, 'total'>
  ) }
);

export type SearchPermissionQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchPermissionQuery = (
  { __typename?: 'Query' }
  & { searchPermission: (
    { __typename?: 'PermissionSearchResult' }
    & { data?: Maybe<Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'bot_id' | 'user_id' | 'role'>
      & { account?: Maybe<(
        { __typename?: 'Account' }
        & Pick<Account, 'id' | 'first_name' | 'last_name' | 'picture_url' | 'phone' | 'email'>
      )> }
    )>> }
  ) }
);

export type RemovePermissionMutationVariables = Exact<{
  user_id: Scalars['String'];
}>;


export type RemovePermissionMutation = (
  { __typename?: 'Mutation' }
  & { removePermission: (
    { __typename?: 'RemoveResult' }
    & Pick<RemoveResult, 'id' | 'success' | 'error_message'>
  ) }
);

export type OnPermissionChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnPermissionChangedSubscription = (
  { __typename?: 'Subscription' }
  & { onPermissionChanged: (
    { __typename?: 'PermissionSubscriptionResult' }
    & Pick<PermissionSubscriptionResult, 'operation'>
    & { data: (
      { __typename?: 'Permission' }
      & Pick<Permission, 'bot_id' | 'user_id' | 'role'>
      & { account?: Maybe<(
        { __typename?: 'Account' }
        & Pick<Account, 'id' | 'first_name' | 'last_name' | 'picture_url' | 'phone' | 'email'>
      )> }
    ) }
  ) }
);

export type SearchInvitationQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchInvitationQuery = (
  { __typename?: 'Query' }
  & { searchInvitation: (
    { __typename?: 'InvitationSearchResult' }
    & Pick<InvitationSearchResult, 'total'>
    & { data: Array<(
      { __typename?: 'Invitation' }
      & Pick<Invitation, 'id' | 'module' | 'module_id' | 'permission_type' | 'permission_role' | 'creation' | 'expired_on' | 'activated'>
    )> }
  ) }
);

export type CreateInviteLinkMutationVariables = Exact<{
  input: InvitationInput;
}>;


export type CreateInviteLinkMutation = (
  { __typename?: 'Mutation' }
  & { createInviteLink: (
    { __typename?: 'Invitation' }
    & Pick<Invitation, 'id' | 'creation' | 'expired_on'>
  ) }
);

export type GetInvitationQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetInvitationQuery = (
  { __typename?: 'Query' }
  & { getInvitation: (
    { __typename?: 'Invitation' }
    & Pick<Invitation, 'id' | 'module' | 'module_id' | 'permission_type' | 'permission_role' | 'creation' | 'expired_on' | 'activated' | 'created_by'>
  ) }
);

export type ActivateInviteLinkMutationVariables = Exact<{
  input: InvitationActivationInput;
}>;


export type ActivateInviteLinkMutation = (
  { __typename?: 'Mutation' }
  & { activateInviteLink: (
    { __typename?: 'UpdateResult' }
    & Pick<UpdateResult, 'id' | 'success' | 'error_message'>
  ) }
);

export type GetChatStatQueryVariables = Exact<{
  input?: Maybe<ChatStatInput>;
}>;


export type GetChatStatQuery = (
  { __typename?: 'Query' }
  & { getChatStat: (
    { __typename?: 'ChatStat' }
    & Pick<ChatStat, 'total_chats' | 'total_intent_chats' | 'total_unknown_chats'>
  ) }
);

export type GetActiveUserReportQueryVariables = Exact<{
  input?: Maybe<ActiveUserReportInput>;
}>;


export type GetActiveUserReportQuery = (
  { __typename?: 'Query' }
  & { getActiveUserReport: (
    { __typename?: 'InsightResult' }
    & Pick<InsightResult, 'categories'>
    & { series: Array<(
      { __typename?: 'Series' }
      & Pick<Series, 'name' | 'data'>
    )> }
  ) }
);

export type GetUserHeatmapQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserHeatmapQuery = (
  { __typename?: 'Query' }
  & { getUserHeatmap: (
    { __typename?: 'InsightResult' }
    & Pick<InsightResult, 'categories'>
    & { series: Array<(
      { __typename?: 'Series' }
      & Pick<Series, 'name' | 'data'>
    )> }
  ) }
);

export type GetMessageByChannelQueryVariables = Exact<{
  input?: Maybe<MessageByChannelInput>;
}>;


export type GetMessageByChannelQuery = (
  { __typename?: 'Query' }
  & { getMessageByChannel: (
    { __typename?: 'InsightResult' }
    & Pick<InsightResult, 'categories'>
    & { series: Array<(
      { __typename?: 'Series' }
      & Pick<Series, 'name' | 'data'>
    )> }
  ) }
);

export type SearchBroadcastQueryVariables = Exact<{
  input?: Maybe<BroadcastSearchInput>;
}>;


export type SearchBroadcastQuery = (
  { __typename?: 'Query' }
  & { searchBroadcast: (
    { __typename?: 'BroadcastSearchResult' }
    & Pick<BroadcastSearchResult, 'next' | 'total'>
    & { data?: Maybe<Array<(
      { __typename?: 'Broadcast' }
      & Pick<Broadcast, 'id' | 'bot_id' | 'title' | 'schedule' | 'channel_id' | 'platform' | 'status' | 'send_type'>
    )>> }
  ) }
);

export type SearchBroadcastWithStatsQueryVariables = Exact<{
  input?: Maybe<BroadcastSearchInput>;
}>;


export type SearchBroadcastWithStatsQuery = (
  { __typename?: 'Query' }
  & { searchBroadcast: (
    { __typename?: 'BroadcastSearchResult' }
    & Pick<BroadcastSearchResult, 'next' | 'total'>
    & { data?: Maybe<Array<(
      { __typename?: 'Broadcast' }
      & Pick<Broadcast, 'id' | 'bot_id' | 'title' | 'schedule' | 'channel_id' | 'platform' | 'status' | 'send_type'>
      & { stats?: Maybe<(
        { __typename?: 'BroadcastStats' }
        & Pick<BroadcastStats, 'total' | 'sent' | 'sending' | 'error' | 'ready'>
      )> }
    )>> }
  ) }
);

export type CreateBroadcastMutationVariables = Exact<{
  input: BroadcastInput;
}>;


export type CreateBroadcastMutation = (
  { __typename?: 'Mutation' }
  & { createBroadcast: (
    { __typename?: 'Broadcast' }
    & Pick<Broadcast, 'id' | 'title'>
  ) }
);

export type GetBroadcastQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetBroadcastQuery = (
  { __typename?: 'Query' }
  & { getBroadcast: (
    { __typename?: 'Broadcast' }
    & Pick<Broadcast, 'id' | 'bot_id' | 'title' | 'schedule' | 'channel_id' | 'platform' | 'status' | 'user_id' | 'send_type' | 'audience_type' | 'target' | 'target_url' | 'target_limit' | 'target_tags' | 'target_group' | 'is_some'>
    & { stats?: Maybe<(
      { __typename?: 'BroadcastStats' }
      & Pick<BroadcastStats, 'total' | 'sent' | 'sending' | 'error' | 'ready'>
    )>, action?: Maybe<(
      { __typename?: 'Action' }
      & Pick<Action, 'id' | 'facebook_messages' | 'line_messages'>
    )> }
  ), getFriendStat: (
    { __typename?: 'FriendStat' }
    & Pick<FriendStat, 'total' | 'targetReach' | 'blocked' | 'facebook' | 'line'>
  ) }
);

export type UpdateBroadcastMutationVariables = Exact<{
  id: Scalars['String'];
  input: BroadcastInput;
}>;


export type UpdateBroadcastMutation = (
  { __typename?: 'Mutation' }
  & { updateBroadcast: (
    { __typename?: 'Broadcast' }
    & Pick<Broadcast, 'id' | 'title'>
  ) }
);

export type RemoveBroadcastMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveBroadcastMutation = (
  { __typename?: 'Mutation' }
  & { removeBroadcast: (
    { __typename?: 'RemoveResult' }
    & Pick<RemoveResult, 'id' | 'success'>
  ) }
);

export type OnBroadcastChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnBroadcastChangedSubscription = (
  { __typename?: 'Subscription' }
  & { onBroadcastChanged: (
    { __typename?: 'BroadcastSubscriptionResult' }
    & Pick<BroadcastSubscriptionResult, 'operation'>
    & { data: (
      { __typename?: 'BroadcastSubscrition' }
      & Pick<BroadcastSubscrition, 'id'>
    ) }
  ) }
);

export type UploadAudienceMutationVariables = Exact<{
  input: BroadcastUploadAudienceInput;
}>;


export type UploadAudienceMutation = (
  { __typename?: 'Mutation' }
  & { uploadAudience: (
    { __typename?: 'UpdateResult' }
    & Pick<UpdateResult, 'id'>
  ) }
);

export type ResetAudienceMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ResetAudienceMutation = (
  { __typename?: 'Mutation' }
  & { resetAudience: (
    { __typename?: 'BasicResult' }
    & Pick<BasicResult, 'success'>
  ) }
);

export type SearchBroadcastAudienceQueryVariables = Exact<{
  broadcastId: Scalars['String'];
  input?: Maybe<BroadcastAudienceSearchInput>;
}>;


export type SearchBroadcastAudienceQuery = (
  { __typename?: 'Query' }
  & { searchBroadcastAudience: (
    { __typename?: 'BroadcastAudienceSearchResult' }
    & Pick<BroadcastAudienceSearchResult, 'total' | 'next'>
    & { data?: Maybe<Array<(
      { __typename?: 'BroadcastAudience' }
      & Pick<BroadcastAudience, 'id' | 'broadcast_id' | 'user_id' | 'data' | 'payload' | 'creation' | 'status' | 'error_message'>
    )>> }
  ) }
);

export type SendBroadcastMutationVariables = Exact<{
  broadcastId: Scalars['String'];
  isTest?: Maybe<Scalars['Boolean']>;
}>;


export type SendBroadcastMutation = (
  { __typename?: 'Mutation' }
  & { sendBroadcast: (
    { __typename?: 'BasicResult' }
    & Pick<BasicResult, 'success' | 'error_message'>
  ) }
);

export type UpdateActionMutationVariables = Exact<{
  id: Scalars['String'];
  input: ActionInput;
}>;


export type UpdateActionMutation = (
  { __typename?: 'Mutation' }
  & { updateAction: (
    { __typename?: 'Action' }
    & Pick<Action, 'id' | 'line_messages' | 'facebook_messages'>
  ) }
);

export type UploadImageMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadImageMutation = (
  { __typename?: 'Mutation' }
  & { uploadImage: (
    { __typename?: 'File' }
    & Pick<File, 'url'>
  ) }
);

export type UploadFileMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadFileMutation = (
  { __typename?: 'Mutation' }
  & { uploadFile: (
    { __typename?: 'File' }
    & Pick<File, 'url'>
  ) }
);

export type SearchFriendGroupQueryVariables = Exact<{
  input?: Maybe<FriendGroupSearchInput>;
}>;


export type SearchFriendGroupQuery = (
  { __typename?: 'Query' }
  & { searchFriendGroup: (
    { __typename?: 'FriendGroupSearchResult' }
    & Pick<FriendGroupSearchResult, 'next'>
    & { data?: Maybe<Array<(
      { __typename?: 'FriendGroup' }
      & Pick<FriendGroup, 'id' | 'title' | 'favorite' | 'includes' | 'excludes' | 'is_some'>
    )>> }
  ) }
);

export type GetFriendGroupQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetFriendGroupQuery = (
  { __typename?: 'Query' }
  & { getFriendGroup: (
    { __typename?: 'FriendGroup' }
    & Pick<FriendGroup, 'id' | 'title' | 'includes' | 'excludes' | 'favorite' | 'is_some'>
  ) }
);

export type GetFriendGroupWithCountQueryVariables = Exact<{
  id: Scalars['String'];
  platform?: Maybe<Platform>;
}>;


export type GetFriendGroupWithCountQuery = (
  { __typename?: 'Query' }
  & { getFriendGroup: (
    { __typename?: 'FriendGroup' }
    & Pick<FriendGroup, 'id' | 'title' | 'includes' | 'excludes' | 'favorite' | 'is_some' | 'count'>
  ) }
);

export type CreateFriendGroupMutationVariables = Exact<{
  input: FriendGroupInput;
}>;


export type CreateFriendGroupMutation = (
  { __typename?: 'Mutation' }
  & { createFriendGroup: (
    { __typename?: 'FriendGroup' }
    & Pick<FriendGroup, 'id' | 'title' | 'favorite'>
  ) }
);

export type UpdateFriendGroupMutationVariables = Exact<{
  id: Scalars['String'];
  input: FriendGroupInput;
}>;


export type UpdateFriendGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateFriendGroup: (
    { __typename?: 'UpdateResult' }
    & Pick<UpdateResult, 'id'>
  ) }
);

export type RemoveFriendGroupMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveFriendGroupMutation = (
  { __typename?: 'Mutation' }
  & { removeFriendGroup: (
    { __typename?: 'RemoveResult' }
    & Pick<RemoveResult, 'id' | 'success' | 'error_message'>
  ) }
);

export type SearchSnapQueryVariables = Exact<{
  input?: Maybe<SnapSearchInput>;
}>;


export type SearchSnapQuery = (
  { __typename?: 'Query' }
  & { searchSnap: (
    { __typename?: 'SnapSearchResult' }
    & { data?: Maybe<Array<(
      { __typename?: 'Snap' }
      & Pick<Snap, 'label' | 'value' | 'category' | 'action' | 'creation'>
    )>> }
  ) }
);

export type GetAccountLineChartQueryVariables = Exact<{
  input?: Maybe<AccountInsightInput>;
}>;


export type GetAccountLineChartQuery = (
  { __typename?: 'Query' }
  & { getAccountLineChart: (
    { __typename?: 'InsightResult' }
    & Pick<InsightResult, 'categories'>
    & { series: Array<(
      { __typename?: 'Series' }
      & Pick<Series, 'data' | 'name'>
    )> }
  ) }
);

export type GetBotLineChartQueryVariables = Exact<{
  input?: Maybe<BotInsightInput>;
}>;


export type GetBotLineChartQuery = (
  { __typename?: 'Query' }
  & { getBotLineChart: (
    { __typename?: 'InsightResult' }
    & Pick<InsightResult, 'categories'>
    & { series: Array<(
      { __typename?: 'Series' }
      & Pick<Series, 'data' | 'name'>
    )> }
  ) }
);

export type AllShopsQueryVariables = Exact<{
  input?: Maybe<BotInsightInput>;
}>;


export type AllShopsQuery = (
  { __typename?: 'Query' }
  & { allShops: (
    { __typename?: 'BotSearchResult' }
    & Pick<BotSearchResult, 'total'>
    & { data?: Maybe<Array<(
      { __typename?: 'Bot' }
      & Pick<Bot, 'id' | 'title' | 'picture_url' | 'business_type' | 'friend_count' | 'friend_limit' | 'created_by' | 'source' | 'creation'>
      & { profile?: Maybe<(
        { __typename?: 'BusinessProfile' }
        & Pick<BusinessProfile, 'title' | 'open_hours' | 'contact' | 'address'>
      )>, subscription?: Maybe<(
        { __typename?: 'SubscriptionPackage' }
        & Pick<SubscriptionPackage, 'id' | 'title' | 'sku' | 'price' | 'end_date' | 'expired_in'>
      )>, owner?: Maybe<(
        { __typename?: 'Account' }
        & Pick<Account, 'id' | 'first_name' | 'last_name' | 'email' | 'phone'>
      )> }
    )>> }
  ) }
);

export type GetOrderPublicQueryVariables = Exact<{
  order_id: Scalars['String'];
}>;


export type GetOrderPublicQuery = (
  { __typename?: 'Query' }
  & { getOrderPublic: (
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'title' | 'status' | 'full_name' | 'comment' | 'total' | 'vat_amount' | 'grand_total' | 'creation' | 'expiration_date' | 'total_item'>
    & { items: Array<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'title' | 'sku' | 'amount' | 'qty'>
    )>, customer_info?: Maybe<(
      { __typename?: 'OrderCustomerInfo' }
      & Pick<OrderCustomerInfo, 'full_name' | 'tel' | 'email' | 'address'>
    )>, payment_info?: Maybe<(
      { __typename?: 'OrderPaymentInfo' }
      & Pick<OrderPaymentInfo, 'status' | 'reason' | 'method' | 'account_no' | 'account_name' | 'transfer_date' | 'transfer_time' | 'transaction_image_url' | 'bank'>
    )>, delivery_info?: Maybe<(
      { __typename?: 'OrderDeliveryInfo' }
      & Pick<OrderDeliveryInfo, 'delivery_company' | 'tracking_no' | 'delivery_time' | 'cost'>
    )> }
  ) }
);

export type UpdateOrderPaymentPublicMutationVariables = Exact<{
  input: OrderPaymentInput;
  order_id: Scalars['String'];
}>;


export type UpdateOrderPaymentPublicMutation = (
  { __typename?: 'Mutation' }
  & { updateOrderPaymentPublic: (
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'title' | 'status' | 'full_name' | 'comment' | 'total' | 'vat_amount' | 'grand_total' | 'creation' | 'expiration_date' | 'total_item'>
    & { items: Array<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'title' | 'sku' | 'amount' | 'qty'>
    )>, customer_info?: Maybe<(
      { __typename?: 'OrderCustomerInfo' }
      & Pick<OrderCustomerInfo, 'full_name' | 'tel' | 'email' | 'address'>
    )>, payment_info?: Maybe<(
      { __typename?: 'OrderPaymentInfo' }
      & Pick<OrderPaymentInfo, 'status' | 'reason' | 'method' | 'account_no' | 'account_name' | 'transfer_date' | 'transfer_time' | 'transaction_image_url' | 'bank'>
    )>, delivery_info?: Maybe<(
      { __typename?: 'OrderDeliveryInfo' }
      & Pick<OrderDeliveryInfo, 'delivery_company' | 'tracking_no' | 'delivery_time'>
    )> }
  ) }
);

export type UploadOrderPaymentImageMutationVariables = Exact<{
  file: Scalars['Upload'];
  order_id: Scalars['String'];
}>;


export type UploadOrderPaymentImageMutation = (
  { __typename?: 'Mutation' }
  & { uploadOrderPaymentImage: (
    { __typename?: 'File' }
    & Pick<File, 'id' | 'filename' | 'url' | 'mimetype' | 'encoding'>
  ) }
);

export type GetFbLongLivedUserAccessTokenMutationVariables = Exact<{
  appId: Scalars['String'];
  token: Scalars['String'];
}>;


export type GetFbLongLivedUserAccessTokenMutation = (
  { __typename?: 'Mutation' }
  & { getFbLongLivedUserAccessToken: (
    { __typename?: 'AccessTokenResult' }
    & Pick<AccessTokenResult, 'access_token' | 'expired_on'>
  ) }
);

export type UpdatePluginMutationVariables = Exact<{
  id: Scalars['String'];
  input: PluginInput;
}>;


export type UpdatePluginMutation = (
  { __typename?: 'Mutation' }
  & { updatePlugin: (
    { __typename?: 'UpdateResult' }
    & Pick<UpdateResult, 'id'>
  ) }
);

export type SearchChitchatQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchChitchatQuery = (
  { __typename?: 'Query' }
  & { searchChitchat: (
    { __typename?: 'ChitchatSearchResult' }
    & Pick<ChitchatSearchResult, 'next'>
    & { data?: Maybe<Array<(
      { __typename?: 'Chitchat' }
      & Pick<Chitchat, 'question' | 'answer'>
    )>> }
  ) }
);

export type GetFriendStatQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFriendStatQuery = (
  { __typename?: 'Query' }
  & { getFriendStat: (
    { __typename?: 'FriendStat' }
    & Pick<FriendStat, 'total' | 'targetReach' | 'blocked' | 'facebook' | 'line'>
  ) }
);

export type GetChatIntentHitsQueryVariables = Exact<{
  input?: Maybe<ChatInsightInput>;
}>;


export type GetChatIntentHitsQuery = (
  { __typename?: 'Query' }
  & { getChatIntentHits: (
    { __typename?: 'ChatIntentHitsResult' }
    & { data?: Maybe<Array<(
      { __typename?: 'ChatIntentHits' }
      & Pick<ChatIntentHits, 'title' | 'count'>
    )>> }
  ) }
);

export type ExportChatQueryVariables = Exact<{
  input: ChatExportInput;
}>;


export type ExportChatQuery = (
  { __typename?: 'Query' }
  & { exportChat: (
    { __typename?: 'ChatSearchResult' }
    & Pick<ChatSearchResult, 'total'>
    & { data?: Maybe<Array<(
      { __typename?: 'Chat' }
      & Pick<Chat, 'message' | 'timestamp' | 'full_name'>
      & { intent?: Maybe<(
        { __typename?: 'ChatIntent' }
        & Pick<ChatIntent, 'title'>
      )> }
    )>> }
  ) }
);

export type GetLearnStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLearnStatsQuery = (
  { __typename?: 'Query' }
  & { getTrainingReport: (
    { __typename?: 'TrainingReportResult' }
    & { data?: Maybe<Array<(
      { __typename?: 'TrainingReport' }
      & Pick<TrainingReport, 'keyword_id' | 'title' | 'creation'>
      & { sentence: (
        { __typename?: 'KeywordSentence' }
        & Pick<KeywordSentence, 'text'>
      ) }
    )>> }
  ), getLearnedKeywordReport: (
    { __typename?: 'LearnedKeywordReport' }
    & Pick<LearnedKeywordReport, 'total_keywords' | 'total_sentences' | 'total_today_keywords' | 'total_today_learns'>
  ) }
);

export type SearchUserInboxQueryVariables = Exact<{
  input?: Maybe<UserInboxSearchInput>;
}>;


export type SearchUserInboxQuery = (
  { __typename?: 'Query' }
  & { searchUserInbox: (
    { __typename?: 'UserInboxSearchResult' }
    & Pick<UserInboxSearchResult, 'next'>
    & { data?: Maybe<Array<(
      { __typename?: 'UserInbox' }
      & Pick<UserInbox, 'id' | 'read' | 'message' | 'type_id' | 'type' | 'creation'>
    )>> }
  ) }
);

export type OnUserInboxChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnUserInboxChangedSubscription = (
  { __typename?: 'Subscription' }
  & { onUserInboxChanged: (
    { __typename?: 'UserInboxSubscriptionResult' }
    & Pick<UserInboxSubscriptionResult, 'operation'>
    & { data: (
      { __typename?: 'UserInbox' }
      & Pick<UserInbox, 'id' | 'read' | 'message' | 'type_id' | 'type'>
    ) }
  ) }
);

export type ReadUserInboxMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ReadUserInboxMutation = (
  { __typename?: 'Mutation' }
  & { readUserInbox: (
    { __typename?: 'UserInbox' }
    & Pick<UserInbox, 'id'>
  ) }
);

export type CountUserInboxQueryVariables = Exact<{
  read: Scalars['Boolean'];
}>;


export type CountUserInboxQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'countUserInbox'>
);

export type SearchInboxQueryVariables = Exact<{
  filters?: Maybe<InboxSearchFilter>;
  page?: Maybe<InboxSearchPagination>;
}>;


export type SearchInboxQuery = (
  { __typename?: 'Query' }
  & { searchInbox: (
    { __typename?: 'InboxSearchResult' }
    & Pick<InboxSearchResult, 'total' | 'next'>
    & { data?: Maybe<Array<(
      { __typename?: 'Inbox' }
      & Pick<Inbox, 'id' | 'chat_id' | 'title' | 'picture_url' | 'read' | 'tags' | 'platform' | 'status' | 'event' | 'bot_id' | 'creation' | 'bot_disabled'>
    )>> }
  ) }
);

export type GetInboxQueryVariables = Exact<{
  inboxId: Scalars['String'];
}>;


export type GetInboxQuery = (
  { __typename?: 'Query' }
  & { getInbox: (
    { __typename?: 'Inbox' }
    & Pick<Inbox, 'chat_id' | 'bot_id' | 'id' | 'title' | 'picture_url' | 'platform' | 'tags' | 'bot_disabled' | 'status' | 'creation'>
  ) }
);

export type SearchChatQueryVariables = Exact<{
  inboxId: Scalars['String'];
  page?: Maybe<ChatSearchPagination>;
  filters?: Maybe<ChatSearchFilter>;
}>;


export type SearchChatQuery = (
  { __typename?: 'Query' }
  & { searchChat: (
    { __typename?: 'ChatSearchResult' }
    & Pick<ChatSearchResult, 'total' | 'next'>
    & { data?: Maybe<Array<(
      { __typename?: 'Chat' }
      & Pick<Chat, 'id' | 'bot_id' | 'channel_id' | 'chat_id' | 'message' | 'timestamp' | 'type'>
      & { postback?: Maybe<(
        { __typename?: 'MessagePostback' }
        & Pick<MessagePostback, 'title' | 'data'>
      )>, source?: Maybe<(
        { __typename?: 'ChatSource' }
        & Pick<ChatSource, 'type' | 'name' | 'userId' | 'id'>
      )> }
    )>> }
  ) }
);

export type IssueTicketMutationVariables = Exact<{
  input: IssueTicketInput;
}>;


export type IssueTicketMutation = (
  { __typename?: 'Mutation' }
  & { issueTicket: (
    { __typename?: 'ProductTicket' }
    & Pick<ProductTicket, 'id' | 'code'>
  ) }
);

export type SearchActivityQueryVariables = Exact<{
  input: ActivitySearchInput;
}>;


export type SearchActivityQuery = (
  { __typename?: 'Query' }
  & { searchActivity: (
    { __typename?: 'ActivitySearchResult' }
    & Pick<ActivitySearchResult, 'total' | 'next'>
    & { data: Array<(
      { __typename?: 'Activity' }
      & Pick<Activity, 'id' | 'service' | 'name' | 'user_id' | 'message' | 'body' | 'created_by' | 'creation'>
      & { friend?: Maybe<(
        { __typename?: 'Friend' }
        & Pick<Friend, 'id' | 'full_name' | 'picture_url'>
      )> }
    )> }
  ) }
);

export type SearchOrderQueryVariables = Exact<{
  input?: Maybe<OrderSearchInput>;
}>;


export type SearchOrderQuery = (
  { __typename?: 'Query' }
  & { searchOrder: (
    { __typename?: 'OrderSearchResult' }
    & Pick<OrderSearchResult, 'next'>
    & { data: Array<(
      { __typename?: 'Order' }
      & Pick<Order, 'status' | 'id' | 'comment' | 'full_name' | 'total' | 'vat_amount' | 'grand_total' | 'creation' | 'user_id' | 'cancel_reason'>
      & { items: Array<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'title' | 'amount' | 'sku' | 'qty'>
      )>, customer_info?: Maybe<(
        { __typename?: 'OrderCustomerInfo' }
        & Pick<OrderCustomerInfo, 'full_name' | 'tel' | 'address'>
      )>, delivery_info?: Maybe<(
        { __typename?: 'OrderDeliveryInfo' }
        & Pick<OrderDeliveryInfo, 'cost' | 'delivery_company' | 'tracking_no' | 'delivery_time'>
      )>, payment_info?: Maybe<(
        { __typename?: 'OrderPaymentInfo' }
        & Pick<OrderPaymentInfo, 'status' | 'transfer_date' | 'transfer_time' | 'transaction_image_url'>
      )> }
    )> }
  ) }
);

export type CreateOrderMutationVariables = Exact<{
  input: OrderInput;
}>;


export type CreateOrderMutation = (
  { __typename?: 'Mutation' }
  & { createOrder: (
    { __typename?: 'Order' }
    & Pick<Order, 'status' | 'id' | 'comment' | 'chat_id' | 'user_id' | 'full_name' | 'total' | 'vat_amount' | 'grand_total' | 'creation'>
    & { items: Array<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'title' | 'amount' | 'sku' | 'qty'>
    )> }
  ) }
);

export type GetOrderQueryVariables = Exact<{
  order_id: Scalars['String'];
}>;


export type GetOrderQuery = (
  { __typename?: 'Query' }
  & { getOrder: (
    { __typename?: 'Order' }
    & Pick<Order, 'status' | 'id' | 'comment' | 'chat_id' | 'user_id' | 'full_name' | 'total' | 'vat_amount' | 'grand_total' | 'creation' | 'cancel_reason'>
    & { items: Array<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'title' | 'amount' | 'sku' | 'qty'>
    )>, customer_info?: Maybe<(
      { __typename?: 'OrderCustomerInfo' }
      & Pick<OrderCustomerInfo, 'full_name' | 'tel' | 'address'>
    )>, delivery_info?: Maybe<(
      { __typename?: 'OrderDeliveryInfo' }
      & Pick<OrderDeliveryInfo, 'cost' | 'delivery_company' | 'tracking_no' | 'delivery_time'>
    )>, payment_info?: Maybe<(
      { __typename?: 'OrderPaymentInfo' }
      & Pick<OrderPaymentInfo, 'status' | 'transfer_date' | 'transfer_time' | 'transaction_image_url'>
    )> }
  ) }
);

export type UpdateOrderMutationVariables = Exact<{
  order_id: Scalars['String'];
  input: OrderUpdate;
}>;


export type UpdateOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateOrder: (
    { __typename?: 'Order' }
    & Pick<Order, 'id'>
  ) }
);

export type RemoveOrderMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveOrderMutation = (
  { __typename?: 'Mutation' }
  & { removeOrder: (
    { __typename?: 'RemoveResult' }
    & Pick<RemoveResult, 'id'>
  ) }
);

export type CancelOrderPaymentMutationVariables = Exact<{
  order_id: Scalars['String'];
  cancel_reason: Scalars['String'];
}>;


export type CancelOrderPaymentMutation = (
  { __typename?: 'Mutation' }
  & { cancelOrderPayment: (
    { __typename?: 'Order' }
    & Pick<Order, 'id'>
  ) }
);

export type ConfirmOrderPaymentMutationVariables = Exact<{
  order_id: Scalars['String'];
}>;


export type ConfirmOrderPaymentMutation = (
  { __typename?: 'Mutation' }
  & { confirmOrderPayment: (
    { __typename?: 'Order' }
    & Pick<Order, 'id'>
  ) }
);

export type IssueOrderPaymentMutationVariables = Exact<{
  input: OrderIssuePaymentInput;
  order_id: Scalars['String'];
}>;


export type IssueOrderPaymentMutation = (
  { __typename?: 'Mutation' }
  & { issueOrderPayment: (
    { __typename?: 'Order' }
    & Pick<Order, 'id'>
  ) }
);

export type UpdateOrderDeliveryMutationVariables = Exact<{
  input: OrderDeliveryInfoInput;
  order_id: Scalars['String'];
}>;


export type UpdateOrderDeliveryMutation = (
  { __typename?: 'Mutation' }
  & { updateOrderDelivery: (
    { __typename?: 'Order' }
    & Pick<Order, 'id'>
  ) }
);

export type ConfirmLinepayPaymentMutationVariables = Exact<{
  order_id: Scalars['String'];
  transaction_id: Scalars['String'];
}>;


export type ConfirmLinepayPaymentMutation = (
  { __typename?: 'Mutation' }
  & { confirmLinepayPayment: (
    { __typename?: 'Payment' }
    & Pick<Payment, 'id'>
  ) }
);

export type OnOrderChangedSubscriptionVariables = Exact<{
  orderId?: Maybe<Scalars['String']>;
}>;


export type OnOrderChangedSubscription = (
  { __typename?: 'Subscription' }
  & { onOrderChanged: (
    { __typename?: 'OrderSubscriptionResult' }
    & Pick<OrderSubscriptionResult, 'operation'>
    & { data: (
      { __typename?: 'Order' }
      & Pick<Order, 'status' | 'id' | 'comment' | 'full_name' | 'total' | 'vat_amount' | 'grand_total' | 'creation' | 'cancel_reason'>
      & { items: Array<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'title' | 'amount' | 'sku' | 'qty'>
      )>, customer_info?: Maybe<(
        { __typename?: 'OrderCustomerInfo' }
        & Pick<OrderCustomerInfo, 'full_name' | 'tel' | 'address'>
      )>, delivery_info?: Maybe<(
        { __typename?: 'OrderDeliveryInfo' }
        & Pick<OrderDeliveryInfo, 'cost' | 'delivery_company' | 'tracking_no' | 'delivery_time'>
      )>, payment_info?: Maybe<(
        { __typename?: 'OrderPaymentInfo' }
        & Pick<OrderPaymentInfo, 'status' | 'transfer_date' | 'transfer_time' | 'transaction_image_url'>
      )> }
    ) }
  ) }
);

export type SearchProductCategoryQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchProductCategoryQuery = (
  { __typename?: 'Query' }
  & { searchProductCategory: (
    { __typename?: 'ProductCategorySearchResult' }
    & { data?: Maybe<Array<(
      { __typename?: 'ProductCategory' }
      & Pick<ProductCategory, 'title' | 'picture_url' | 'id' | 'description'>
    )>> }
  ) }
);

export type SearchProductCategoryPublicQueryVariables = Exact<{
  botId: Scalars['String'];
}>;


export type SearchProductCategoryPublicQuery = (
  { __typename?: 'Query' }
  & { searchProductCategoryPublic: (
    { __typename?: 'ProductCategorySearchResult' }
    & Pick<ProductCategorySearchResult, 'next' | 'total'>
    & { data?: Maybe<Array<(
      { __typename?: 'ProductCategory' }
      & Pick<ProductCategory, 'id' | 'title' | 'picture_url'>
    )>> }
  ) }
);

export type CreateProductCategoryMutationVariables = Exact<{
  input: ProductCategoryInput;
}>;


export type CreateProductCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createProductCategory: (
    { __typename?: 'ProductCategory' }
    & Pick<ProductCategory, 'id' | 'description' | 'picture_url'>
  ) }
);

export type GetProductCategoryQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetProductCategoryQuery = (
  { __typename?: 'Query' }
  & { getProductCategory: (
    { __typename?: 'ProductCategory' }
    & Pick<ProductCategory, 'id' | 'title' | 'picture_url'>
  ) }
);

export type UpdateProductCategoryMutationVariables = Exact<{
  id: Scalars['String'];
  input: ProductCategoryUpdate;
}>;


export type UpdateProductCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateProductCategory: (
    { __typename?: 'ProductCategory' }
    & Pick<ProductCategory, 'id' | 'picture_url'>
  ) }
);

export type RemoveProductCategoryMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveProductCategoryMutation = (
  { __typename?: 'Mutation' }
  & { removeProductCategory: (
    { __typename?: 'ProductCategory' }
    & Pick<ProductCategory, 'id'>
  ) }
);

export type ImportProductCategoryMutationVariables = Exact<{
  google_sheet_id: Scalars['String'];
  sheet_number: Scalars['Float'];
}>;


export type ImportProductCategoryMutation = (
  { __typename?: 'Mutation' }
  & { importProductCategory: (
    { __typename?: 'ImportResult' }
    & Pick<ImportResult, 'total'>
  ) }
);

export type OnProductCategoryChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnProductCategoryChangedSubscription = (
  { __typename?: 'Subscription' }
  & { onProductCategoryChanged: (
    { __typename?: 'ProductCategorySubscriptionResult' }
    & Pick<ProductCategorySubscriptionResult, 'operation'>
    & { data: (
      { __typename?: 'ProductCategory' }
      & Pick<ProductCategory, 'id' | 'title' | 'picture_url' | 'description'>
    ) }
  ) }
);

export type SearchProductQueryVariables = Exact<{
  input?: Maybe<ProductSearchInput>;
}>;


export type SearchProductQuery = (
  { __typename?: 'Query' }
  & { searchProduct: (
    { __typename?: 'ProductSearchResult' }
    & Pick<ProductSearchResult, 'next'>
    & { data?: Maybe<Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'sku' | 'title' | 'category' | 'full_price' | 'sell_description' | 'sell_price' | 'price_per_year' | 'price_per_month' | 'type' | 'picture_url' | 'picture_urls' | 'stock_qty' | 'keywords' | 'live_status'>
    )>> }
  ) }
);

export type GetProductQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetProductQuery = (
  { __typename?: 'Query' }
  & { getProduct: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'sku' | 'title' | 'full_price' | 'description' | 'sell_price' | 'sell_description' | 'price_per_month' | 'price_per_year' | 'category' | 'picture_url' | 'picture_urls' | 'stock_qty' | 'stock_total_in' | 'stock_total_out' | 'keywords' | 'live_id' | 'live_status'>
    & { bot?: Maybe<(
      { __typename?: 'Bot' }
      & Pick<Bot, 'id'>
    )>, live?: Maybe<(
      { __typename?: 'Live' }
      & Pick<Live, 'id' | 'title'>
    )> }
  ) }
);

export type GetProductBySkuQueryVariables = Exact<{
  sku: Scalars['String'];
}>;


export type GetProductBySkuQuery = (
  { __typename?: 'Query' }
  & { getProductBySku: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'sku' | 'title' | 'description' | 'full_price' | 'sell_description' | 'sell_price' | 'price_per_month' | 'price_per_year' | 'category' | 'picture_url' | 'picture_urls' | 'stock_qty'>
    & { bot?: Maybe<(
      { __typename?: 'Bot' }
      & Pick<Bot, 'id'>
    )> }
  ) }
);

export type AllProductsQueryVariables = Exact<{
  input?: Maybe<ProductSearchInput>;
}>;


export type AllProductsQuery = (
  { __typename?: 'Query' }
  & { allProducts: (
    { __typename?: 'ProductSearchResult' }
    & Pick<ProductSearchResult, 'total'>
    & { data?: Maybe<Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'sku' | 'title' | 'sell_description' | 'sell_price' | 'category'>
    )>> }
  ) }
);

export type AddStockToProductMutationVariables = Exact<{
  qty: Scalars['Float'];
  sku: Scalars['String'];
}>;


export type AddStockToProductMutation = (
  { __typename?: 'Mutation' }
  & { addStockToProduct: (
    { __typename?: 'Product' }
    & Pick<Product, 'id'>
  ) }
);

export type UpdateProductMutationVariables = Exact<{
  id: Scalars['String'];
  input: ProductInput;
}>;


export type UpdateProductMutation = (
  { __typename?: 'Mutation' }
  & { updateProduct: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'sku' | 'title' | 'description' | 'sell_price' | 'sell_description' | 'price_per_month' | 'price_per_year' | 'category' | 'picture_url' | 'picture_urls' | 'stock_qty'>
  ) }
);

export type RemoveProductMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveProductMutation = (
  { __typename?: 'Mutation' }
  & { removeProduct: (
    { __typename?: 'RemoveResult' }
    & Pick<RemoveResult, 'id'>
  ) }
);

export type OnProductChangedSubscriptionVariables = Exact<{
  filters?: Maybe<ProductSubscriptionFilter>;
}>;


export type OnProductChangedSubscription = (
  { __typename?: 'Subscription' }
  & { onProductChanged: (
    { __typename?: 'ProductSubscriptionResult' }
    & Pick<ProductSubscriptionResult, 'operation'>
    & { data: (
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'sku' | 'title' | 'category' | 'sell_price' | 'sell_description' | 'picture_url' | 'picture_urls' | 'stock_qty' | 'description' | 'live_id' | 'keywords' | 'live_status'>
    ) }
  ) }
);

export type ContactFieldsFragment = (
  { __typename?: 'Contact' }
  & Pick<Contact, 'id' | 'user_id' | 'code' | 'name' | 'address'>
);

export type SearchSalesOrderQueryVariables = Exact<{
  input: SalesOrderSearchInput;
}>;


export type SearchSalesOrderQuery = (
  { __typename?: 'Query' }
  & { searchSalesOrder: (
    { __typename?: 'SalesOrderSearchResult' }
    & Pick<SalesOrderSearchResult, 'total' | 'next'>
    & { data?: Maybe<Array<(
      { __typename?: 'SalesOrder' }
      & Pick<SalesOrder, 'id' | 'doc_no' | 'code' | 'status' | 'user_id' | 'medium_id' | 'display_name' | 'profile_pic' | 'grand_total' | 'total_qty' | 'creation' | 'tags'>
      & { delivery_note?: Maybe<(
        { __typename?: 'DeliveryNote' }
        & Pick<DeliveryNote, 'id' | 'rate'>
      )> }
    )>> }
  ) }
);

export type CreateSalesOrderMutationVariables = Exact<{
  input: SalesOrderInput;
}>;


export type CreateSalesOrderMutation = (
  { __typename?: 'Mutation' }
  & { createSalesOrder: (
    { __typename?: 'SalesOrder' }
    & Pick<SalesOrder, 'id' | 'doc_no' | 'medium_id'>
    & { contact?: Maybe<(
      { __typename?: 'Contact' }
      & ContactFieldsFragment
    )>, items?: Maybe<Array<(
      { __typename?: 'Item' }
      & ItemFieldsFragment
    )>> }
  ) }
);

export type GetSalesOrderQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetSalesOrderQuery = (
  { __typename?: 'Query' }
  & { getSalesOrder: (
    { __typename?: 'SalesOrder' }
    & Pick<SalesOrder, 'id' | 'doc_no' | 'code' | 'status' | 'user_id' | 'display_name' | 'profile_pic' | 'grand_total' | 'total_qty' | 'tags' | 'medium_id' | 'creation'>
    & { contact?: Maybe<(
      { __typename?: 'Contact' }
      & ContactFieldsFragment
    )>, items?: Maybe<Array<(
      { __typename?: 'Item' }
      & ItemFieldsFragment
    )>>, payment?: Maybe<(
      { __typename?: 'Apay' }
      & Pick<Apay, 'id' | 'slip_url' | 'amount'>
    )>, delivery_note?: Maybe<(
      { __typename?: 'DeliveryNote' }
      & Pick<DeliveryNote, 'id' | 'receiver' | 'address' | 'zipcode' | 'phone' | 'rate'>
      & { shipping?: Maybe<(
        { __typename?: 'Shipping' }
        & Pick<Shipping, 'vendor'>
      )> }
    )> }
  ) }
);

export type UpdateSalesOrderMutationVariables = Exact<{
  id: Scalars['String'];
  input: SalesOrderInput;
}>;


export type UpdateSalesOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateSalesOrder: (
    { __typename?: 'SalesOrder' }
    & Pick<SalesOrder, 'id'>
  ) }
);

export type RemoveSalesOrderMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveSalesOrderMutation = (
  { __typename?: 'Mutation' }
  & { removeSalesOrder: (
    { __typename?: 'RemoveResult' }
    & Pick<RemoveResult, 'id'>
  ) }
);

export type OnSalesOrderChangedSubscriptionVariables = Exact<{
  filters?: Maybe<SalesOrderSubscriptionFilter>;
}>;


export type OnSalesOrderChangedSubscription = (
  { __typename?: 'Subscription' }
  & { onSalesOrderChanged: (
    { __typename?: 'SalesOrderSubscriptionResult' }
    & Pick<SalesOrderSubscriptionResult, 'operation'>
    & { data: (
      { __typename?: 'SalesOrder' }
      & Pick<SalesOrder, 'id' | 'doc_no' | 'code' | 'status' | 'user_id' | 'display_name' | 'profile_pic' | 'grand_total' | 'total_qty' | 'medium_id' | 'tags' | 'creation'>
      & { items?: Maybe<Array<(
        { __typename?: 'Item' }
        & ItemFieldsFragment
      )>>, delivery_note?: Maybe<(
        { __typename?: 'DeliveryNote' }
        & Pick<DeliveryNote, 'id' | 'receiver' | 'address' | 'zipcode' | 'phone' | 'rate'>
        & { shipping?: Maybe<(
          { __typename?: 'Shipping' }
          & Pick<Shipping, 'vendor'>
        )> }
      )> }
    ) }
  ) }
);

export type SearchSentenceQueryVariables = Exact<{
  input?: Maybe<SentenceSearchInput>;
}>;


export type SearchSentenceQuery = (
  { __typename?: 'Query' }
  & { searchSentence: (
    { __typename?: 'SentenceSearchResult' }
    & Pick<SentenceSearchResult, 'total' | 'next'>
    & { data?: Maybe<Array<(
      { __typename?: 'Sentence' }
      & Pick<Sentence, 'id' | 'lang' | 'type' | 'text' | 'intent_id' | 'intent_title'>
    )>> }
  ) }
);

export type CreateSentenceMutationVariables = Exact<{
  input: SentenceInput;
}>;


export type CreateSentenceMutation = (
  { __typename?: 'Mutation' }
  & { createSentence: (
    { __typename?: 'Sentence' }
    & Pick<Sentence, 'id' | 'lang' | 'type' | 'text' | 'intent_id' | 'intent_title'>
  ) }
);

export type GetSentenceQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetSentenceQuery = (
  { __typename?: 'Query' }
  & { getSentence: (
    { __typename?: 'Sentence' }
    & Pick<Sentence, 'id' | 'lang' | 'type' | 'text' | 'intent_id' | 'intent_title'>
  ) }
);

export type UpdateSentenceMutationVariables = Exact<{
  id: Scalars['String'];
  input: SentenceInput;
}>;


export type UpdateSentenceMutation = (
  { __typename?: 'Mutation' }
  & { updateSentence: (
    { __typename?: 'Sentence' }
    & Pick<Sentence, 'id'>
  ) }
);

export type RemoveSentenceMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveSentenceMutation = (
  { __typename?: 'Mutation' }
  & { removeSentence: (
    { __typename?: 'RemoveResult' }
    & Pick<RemoveResult, 'id'>
  ) }
);

export type OnSentenceChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnSentenceChangedSubscription = (
  { __typename?: 'Subscription' }
  & { onSentenceChanged: (
    { __typename?: 'SentenceSubscriptionResult' }
    & Pick<SentenceSubscriptionResult, 'operation'>
    & { data: (
      { __typename?: 'Sentence' }
      & Pick<Sentence, 'id' | 'lang' | 'type' | 'text' | 'intent_id' | 'intent_title'>
    ) }
  ) }
);

export type SearchShippingQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchShippingQuery = (
  { __typename?: 'Query' }
  & { searchShipping: (
    { __typename?: 'ShippingSearchResult' }
    & Pick<ShippingSearchResult, 'total' | 'next'>
    & { data?: Maybe<Array<(
      { __typename?: 'Shipping' }
      & Pick<Shipping, 'id' | 'title' | 'vendor' | 'description' | 'rate' | 'rate_type'>
    )>> }
  ) }
);

export type CreateShippingMutationVariables = Exact<{
  input: ShippingInput;
}>;


export type CreateShippingMutation = (
  { __typename?: 'Mutation' }
  & { createShipping: (
    { __typename?: 'Shipping' }
    & Pick<Shipping, 'id' | 'title' | 'vendor' | 'description' | 'rate' | 'rate_type'>
  ) }
);

export type GetShippingQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetShippingQuery = (
  { __typename?: 'Query' }
  & { getShipping: (
    { __typename?: 'Shipping' }
    & Pick<Shipping, 'id' | 'title' | 'vendor' | 'description' | 'rate' | 'rate_type' | 'min_weight' | 'max_weight'>
  ) }
);

export type UpdateShippingMutationVariables = Exact<{
  id: Scalars['String'];
  input: ShippingInput;
}>;


export type UpdateShippingMutation = (
  { __typename?: 'Mutation' }
  & { updateShipping: (
    { __typename?: 'Shipping' }
    & Pick<Shipping, 'id'>
  ) }
);

export type RemoveShippingMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveShippingMutation = (
  { __typename?: 'Mutation' }
  & { removeShipping: (
    { __typename?: 'RemoveResult' }
    & Pick<RemoveResult, 'id'>
  ) }
);

export type OnShippingChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnShippingChangedSubscription = (
  { __typename?: 'Subscription' }
  & { onShippingChanged: (
    { __typename?: 'ShippingSubscriptionResult' }
    & Pick<ShippingSubscriptionResult, 'operation'>
    & { data: (
      { __typename?: 'Shipping' }
      & Pick<Shipping, 'id' | 'title' | 'vendor' | 'description' | 'rate' | 'rate_type'>
    ) }
  ) }
);

export type GetShopInfoWithCategoryPublicQueryVariables = Exact<{
  botId: Scalars['String'];
}>;


export type GetShopInfoWithCategoryPublicQuery = (
  { __typename?: 'Query' }
  & { getShopInfoPublic: (
    { __typename?: 'ShopPublic' }
    & Pick<ShopPublic, 'title' | 'picture_url'>
    & { shippings: Array<(
      { __typename?: 'ShopShipping' }
      & Pick<ShopShipping, 'title' | 'company' | 'cost'>
    )> }
  ), searchProductCategoryPublic: (
    { __typename?: 'ProductCategorySearchResult' }
    & Pick<ProductCategorySearchResult, 'next' | 'total'>
    & { data?: Maybe<Array<(
      { __typename?: 'ProductCategory' }
      & Pick<ProductCategory, 'id' | 'title' | 'picture_url'>
    )>> }
  ) }
);

export type SearchProductPublicQueryVariables = Exact<{
  botId: Scalars['String'];
  category: Scalars['String'];
}>;


export type SearchProductPublicQuery = (
  { __typename?: 'Query' }
  & { searchProductPublic: (
    { __typename?: 'ProductSearchResult' }
    & { data?: Maybe<Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'sku' | 'title' | 'category' | 'sell_price' | 'sell_description' | 'picture_url' | 'picture_urls' | 'stock_qty'>
    )>> }
  ) }
);

export type GetProductPublicQueryVariables = Exact<{
  botId: Scalars['String'];
  sku: Scalars['String'];
}>;


export type GetProductPublicQuery = (
  { __typename?: 'Query' }
  & { getProductPublic: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'title' | 'sku' | 'category' | 'sell_price' | 'sell_description' | 'picture_url' | 'stock_qty'>
  ) }
);

export type CreateProductMutationVariables = Exact<{
  input: ProductInput;
}>;


export type CreateProductMutation = (
  { __typename?: 'Mutation' }
  & { createProduct: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'sku'>
  ) }
);

export type SearchSimpleBookingQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchSimpleBookingQuery = (
  { __typename?: 'Query' }
  & { searchSimpleBooking: (
    { __typename?: 'SimpleBookingSearchResult' }
    & Pick<SimpleBookingSearchResult, 'total' | 'next'>
    & { data?: Maybe<Array<(
      { __typename?: 'SimpleBooking' }
      & Pick<SimpleBooking, 'id' | 'subject' | 'contact_name' | 'contact_phone' | 'start_time' | 'end_time' | 'branch' | 'sku' | 'status' | 'duration'>
      & { friend?: Maybe<(
        { __typename?: 'Friend' }
        & Pick<Friend, 'id' | 'picture_url'>
      )> }
    )>> }
  ) }
);

export type CreateSimpleBookingMutationVariables = Exact<{
  input: SimpleBookingInput;
}>;


export type CreateSimpleBookingMutation = (
  { __typename?: 'Mutation' }
  & { createSimpleBooking: (
    { __typename?: 'SimpleBooking' }
    & Pick<SimpleBooking, 'id' | 'subject'>
  ) }
);

export type GetSimpleBookingQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetSimpleBookingQuery = (
  { __typename?: 'Query' }
  & { getSimpleBooking: (
    { __typename?: 'SimpleBooking' }
    & Pick<SimpleBooking, 'bot_id' | 'chat_id' | 'id' | 'subject' | 'contact_name' | 'contact_phone' | 'description' | 'start_time' | 'end_time' | 'duration' | 'branch' | 'sku' | 'status'>
  ) }
);

export type UpdateSimpleBookingMutationVariables = Exact<{
  id: Scalars['String'];
  input: SimpleBookingInput;
}>;


export type UpdateSimpleBookingMutation = (
  { __typename?: 'Mutation' }
  & { updateSimpleBooking: (
    { __typename?: 'SimpleBooking' }
    & Pick<SimpleBooking, 'id'>
  ) }
);

export type RemoveSimpleBookingMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveSimpleBookingMutation = (
  { __typename?: 'Mutation' }
  & { removeSimpleBooking: (
    { __typename?: 'RemoveResult' }
    & Pick<RemoveResult, 'id'>
  ) }
);

export type OnSimpleBookingChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnSimpleBookingChangedSubscription = (
  { __typename?: 'Subscription' }
  & { onSimpleBookingChanged: (
    { __typename?: 'SimpleBookingSubscriptionResult' }
    & Pick<SimpleBookingSubscriptionResult, 'operation'>
    & { data: (
      { __typename?: 'SimpleBooking' }
      & Pick<SimpleBooking, 'id' | 'subject' | 'contact_name' | 'contact_phone' | 'description' | 'start_time' | 'branch' | 'sku' | 'status'>
    ) }
  ) }
);

export type CreateStockMutationVariables = Exact<{
  input: StockInput;
}>;


export type CreateStockMutation = (
  { __typename?: 'Mutation' }
  & { createStock: (
    { __typename?: 'Stock' }
    & Pick<Stock, 'id'>
  ) }
);

export type OnStockChangedSubscriptionVariables = Exact<{
  filters?: Maybe<StockSubscriptionFilter>;
}>;


export type OnStockChangedSubscription = (
  { __typename?: 'Subscription' }
  & { onStockChanged: (
    { __typename?: 'StockSubscriptionResult' }
    & Pick<StockSubscriptionResult, 'operation'>
    & { data: (
      { __typename?: 'Stock' }
      & Pick<Stock, 'type' | 'product_id' | 'qty' | 'status'>
    ) }
  ) }
);

export type SearchTagQueryVariables = Exact<{
  input?: Maybe<TagSearchInput>;
}>;


export type SearchTagQuery = (
  { __typename?: 'Query' }
  & { searchTag: (
    { __typename?: 'TagSearchResult' }
    & { data?: Maybe<Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'title' | 'type'>
    )>> }
  ) }
);

export type SearchTagWithCountQueryVariables = Exact<{
  input?: Maybe<TagSearchInput>;
}>;


export type SearchTagWithCountQuery = (
  { __typename?: 'Query' }
  & { searchTag: (
    { __typename?: 'TagSearchResult' }
    & { data?: Maybe<Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'title' | 'type'>
    )>> }
  ) }
);

export type OnTagChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnTagChangedSubscription = (
  { __typename?: 'Subscription' }
  & { onTagChanged: (
    { __typename?: 'TagSubscriptionResult' }
    & Pick<TagSubscriptionResult, 'operation'>
    & { data: (
      { __typename?: 'TagSubcription' }
      & Pick<TagSubcription, 'id'>
    ) }
  ) }
);

export const FbCommentFieldsFragmentDoc = gql`
    fragment fbCommentFields on FbComment {
  id
  sender_id
  sender_name
  timestamp
  text
  picture_url
}
    `;
export const ItemFieldsFragmentDoc = gql`
    fragment itemFields on Item {
  id
  sku
  type
  title
  description
  qty
  unit_name
  price_per_unit
  total
  discount_amount
  vat_rate
  picture_url
  user_id
  profile_pic
  display_name
}
    `;
export const SalesOrderFieldsFragmentDoc = gql`
    fragment salesOrderFields on SalesOrder {
  id
  code
  status
  user_id
  profile_pic
  display_name
}
    `;
export const ContactFieldsFragmentDoc = gql`
    fragment contactFields on Contact {
  id
  user_id
  code
  name
  address
}
    `;
export const CreateAisRequestDocument = gql`
    mutation createAisRequest($input: AisRequestInput!) {
  createAisRequest(input: $input) {
    id
    title
  }
}
    `;
export type CreateAisRequestMutationFn = Apollo.MutationFunction<CreateAisRequestMutation, CreateAisRequestMutationVariables>;

/**
 * __useCreateAisRequestMutation__
 *
 * To run a mutation, you first call `useCreateAisRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAisRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAisRequestMutation, { data, loading, error }] = useCreateAisRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAisRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateAisRequestMutation, CreateAisRequestMutationVariables>) {
        return Apollo.useMutation<CreateAisRequestMutation, CreateAisRequestMutationVariables>(CreateAisRequestDocument, baseOptions);
      }
export type CreateAisRequestMutationHookResult = ReturnType<typeof useCreateAisRequestMutation>;
export type CreateAisRequestMutationResult = Apollo.MutationResult<CreateAisRequestMutation>;
export type CreateAisRequestMutationOptions = Apollo.BaseMutationOptions<CreateAisRequestMutation, CreateAisRequestMutationVariables>;
export const AisCountMonthlySubscriberDocument = gql`
    query aisCountMonthlySubscriber {
  aisCountMonthlySubscriber
}
    `;

/**
 * __useAisCountMonthlySubscriberQuery__
 *
 * To run a query within a React component, call `useAisCountMonthlySubscriberQuery` and pass it any options that fit your needs.
 * When your component renders, `useAisCountMonthlySubscriberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAisCountMonthlySubscriberQuery({
 *   variables: {
 *   },
 * });
 */
export function useAisCountMonthlySubscriberQuery(baseOptions?: Apollo.QueryHookOptions<AisCountMonthlySubscriberQuery, AisCountMonthlySubscriberQueryVariables>) {
        return Apollo.useQuery<AisCountMonthlySubscriberQuery, AisCountMonthlySubscriberQueryVariables>(AisCountMonthlySubscriberDocument, baseOptions);
      }
export function useAisCountMonthlySubscriberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AisCountMonthlySubscriberQuery, AisCountMonthlySubscriberQueryVariables>) {
          return Apollo.useLazyQuery<AisCountMonthlySubscriberQuery, AisCountMonthlySubscriberQueryVariables>(AisCountMonthlySubscriberDocument, baseOptions);
        }
export type AisCountMonthlySubscriberQueryHookResult = ReturnType<typeof useAisCountMonthlySubscriberQuery>;
export type AisCountMonthlySubscriberLazyQueryHookResult = ReturnType<typeof useAisCountMonthlySubscriberLazyQuery>;
export type AisCountMonthlySubscriberQueryResult = Apollo.QueryResult<AisCountMonthlySubscriberQuery, AisCountMonthlySubscriberQueryVariables>;
export const AisCountMonthlyTrialDocument = gql`
    query aisCountMonthlyTrial($billingStartDate: Float) {
  aisCountMonthlyTrial(billing_start: $billingStartDate)
}
    `;

/**
 * __useAisCountMonthlyTrialQuery__
 *
 * To run a query within a React component, call `useAisCountMonthlyTrialQuery` and pass it any options that fit your needs.
 * When your component renders, `useAisCountMonthlyTrialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAisCountMonthlyTrialQuery({
 *   variables: {
 *      billingStartDate: // value for 'billingStartDate'
 *   },
 * });
 */
export function useAisCountMonthlyTrialQuery(baseOptions?: Apollo.QueryHookOptions<AisCountMonthlyTrialQuery, AisCountMonthlyTrialQueryVariables>) {
        return Apollo.useQuery<AisCountMonthlyTrialQuery, AisCountMonthlyTrialQueryVariables>(AisCountMonthlyTrialDocument, baseOptions);
      }
export function useAisCountMonthlyTrialLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AisCountMonthlyTrialQuery, AisCountMonthlyTrialQueryVariables>) {
          return Apollo.useLazyQuery<AisCountMonthlyTrialQuery, AisCountMonthlyTrialQueryVariables>(AisCountMonthlyTrialDocument, baseOptions);
        }
export type AisCountMonthlyTrialQueryHookResult = ReturnType<typeof useAisCountMonthlyTrialQuery>;
export type AisCountMonthlyTrialLazyQueryHookResult = ReturnType<typeof useAisCountMonthlyTrialLazyQuery>;
export type AisCountMonthlyTrialQueryResult = Apollo.QueryResult<AisCountMonthlyTrialQuery, AisCountMonthlyTrialQueryVariables>;
export const AisCountMonthlyBillingDocument = gql`
    query aisCountMonthlyBilling {
  aisCountMonthlyBilling
}
    `;

/**
 * __useAisCountMonthlyBillingQuery__
 *
 * To run a query within a React component, call `useAisCountMonthlyBillingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAisCountMonthlyBillingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAisCountMonthlyBillingQuery({
 *   variables: {
 *   },
 * });
 */
export function useAisCountMonthlyBillingQuery(baseOptions?: Apollo.QueryHookOptions<AisCountMonthlyBillingQuery, AisCountMonthlyBillingQueryVariables>) {
        return Apollo.useQuery<AisCountMonthlyBillingQuery, AisCountMonthlyBillingQueryVariables>(AisCountMonthlyBillingDocument, baseOptions);
      }
export function useAisCountMonthlyBillingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AisCountMonthlyBillingQuery, AisCountMonthlyBillingQueryVariables>) {
          return Apollo.useLazyQuery<AisCountMonthlyBillingQuery, AisCountMonthlyBillingQueryVariables>(AisCountMonthlyBillingDocument, baseOptions);
        }
export type AisCountMonthlyBillingQueryHookResult = ReturnType<typeof useAisCountMonthlyBillingQuery>;
export type AisCountMonthlyBillingLazyQueryHookResult = ReturnType<typeof useAisCountMonthlyBillingLazyQuery>;
export type AisCountMonthlyBillingQueryResult = Apollo.QueryResult<AisCountMonthlyBillingQuery, AisCountMonthlyBillingQueryVariables>;
export const SearchAnswerDocument = gql`
    query searchAnswer($input: AnswerSearchInput) {
  searchAnswer(input: $input) {
    total
    next
    data {
      id
      lang
      type
      text
      intent_id
      intent_title
    }
  }
}
    `;

/**
 * __useSearchAnswerQuery__
 *
 * To run a query within a React component, call `useSearchAnswerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAnswerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAnswerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchAnswerQuery(baseOptions?: Apollo.QueryHookOptions<SearchAnswerQuery, SearchAnswerQueryVariables>) {
        return Apollo.useQuery<SearchAnswerQuery, SearchAnswerQueryVariables>(SearchAnswerDocument, baseOptions);
      }
export function useSearchAnswerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchAnswerQuery, SearchAnswerQueryVariables>) {
          return Apollo.useLazyQuery<SearchAnswerQuery, SearchAnswerQueryVariables>(SearchAnswerDocument, baseOptions);
        }
export type SearchAnswerQueryHookResult = ReturnType<typeof useSearchAnswerQuery>;
export type SearchAnswerLazyQueryHookResult = ReturnType<typeof useSearchAnswerLazyQuery>;
export type SearchAnswerQueryResult = Apollo.QueryResult<SearchAnswerQuery, SearchAnswerQueryVariables>;
export const CreateAnswerDocument = gql`
    mutation createAnswer($input: AnswerInput!) {
  createAnswer(input: $input) {
    id
    lang
    type
    text
    payload
    intent_id
    intent_title
  }
}
    `;
export type CreateAnswerMutationFn = Apollo.MutationFunction<CreateAnswerMutation, CreateAnswerMutationVariables>;

/**
 * __useCreateAnswerMutation__
 *
 * To run a mutation, you first call `useCreateAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnswerMutation, { data, loading, error }] = useCreateAnswerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAnswerMutation(baseOptions?: Apollo.MutationHookOptions<CreateAnswerMutation, CreateAnswerMutationVariables>) {
        return Apollo.useMutation<CreateAnswerMutation, CreateAnswerMutationVariables>(CreateAnswerDocument, baseOptions);
      }
export type CreateAnswerMutationHookResult = ReturnType<typeof useCreateAnswerMutation>;
export type CreateAnswerMutationResult = Apollo.MutationResult<CreateAnswerMutation>;
export type CreateAnswerMutationOptions = Apollo.BaseMutationOptions<CreateAnswerMutation, CreateAnswerMutationVariables>;
export const GetAnswerDocument = gql`
    query getAnswer($id: String!) {
  getAnswer(id: $id) {
    id
    lang
    type
    text
    payload
    intent_id
    intent_title
  }
}
    `;

/**
 * __useGetAnswerQuery__
 *
 * To run a query within a React component, call `useGetAnswerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnswerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnswerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAnswerQuery(baseOptions: Apollo.QueryHookOptions<GetAnswerQuery, GetAnswerQueryVariables>) {
        return Apollo.useQuery<GetAnswerQuery, GetAnswerQueryVariables>(GetAnswerDocument, baseOptions);
      }
export function useGetAnswerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnswerQuery, GetAnswerQueryVariables>) {
          return Apollo.useLazyQuery<GetAnswerQuery, GetAnswerQueryVariables>(GetAnswerDocument, baseOptions);
        }
export type GetAnswerQueryHookResult = ReturnType<typeof useGetAnswerQuery>;
export type GetAnswerLazyQueryHookResult = ReturnType<typeof useGetAnswerLazyQuery>;
export type GetAnswerQueryResult = Apollo.QueryResult<GetAnswerQuery, GetAnswerQueryVariables>;
export const UpdateAnswerDocument = gql`
    mutation updateAnswer($id: String!, $input: AnswerInput!) {
  updateAnswer(id: $id, input: $input) {
    id
  }
}
    `;
export type UpdateAnswerMutationFn = Apollo.MutationFunction<UpdateAnswerMutation, UpdateAnswerMutationVariables>;

/**
 * __useUpdateAnswerMutation__
 *
 * To run a mutation, you first call `useUpdateAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnswerMutation, { data, loading, error }] = useUpdateAnswerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAnswerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAnswerMutation, UpdateAnswerMutationVariables>) {
        return Apollo.useMutation<UpdateAnswerMutation, UpdateAnswerMutationVariables>(UpdateAnswerDocument, baseOptions);
      }
export type UpdateAnswerMutationHookResult = ReturnType<typeof useUpdateAnswerMutation>;
export type UpdateAnswerMutationResult = Apollo.MutationResult<UpdateAnswerMutation>;
export type UpdateAnswerMutationOptions = Apollo.BaseMutationOptions<UpdateAnswerMutation, UpdateAnswerMutationVariables>;
export const RemoveAnswerDocument = gql`
    mutation removeAnswer($id: String!) {
  removeAnswer(id: $id) {
    id
  }
}
    `;
export type RemoveAnswerMutationFn = Apollo.MutationFunction<RemoveAnswerMutation, RemoveAnswerMutationVariables>;

/**
 * __useRemoveAnswerMutation__
 *
 * To run a mutation, you first call `useRemoveAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAnswerMutation, { data, loading, error }] = useRemoveAnswerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveAnswerMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAnswerMutation, RemoveAnswerMutationVariables>) {
        return Apollo.useMutation<RemoveAnswerMutation, RemoveAnswerMutationVariables>(RemoveAnswerDocument, baseOptions);
      }
export type RemoveAnswerMutationHookResult = ReturnType<typeof useRemoveAnswerMutation>;
export type RemoveAnswerMutationResult = Apollo.MutationResult<RemoveAnswerMutation>;
export type RemoveAnswerMutationOptions = Apollo.BaseMutationOptions<RemoveAnswerMutation, RemoveAnswerMutationVariables>;
export const OnAnswerChangedDocument = gql`
    subscription onAnswerChanged {
  onAnswerChanged {
    operation
    data {
      id
      lang
      type
      text
      payload
      intent_id
      intent_title
    }
  }
}
    `;

/**
 * __useOnAnswerChangedSubscription__
 *
 * To run a query within a React component, call `useOnAnswerChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAnswerChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAnswerChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnAnswerChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnAnswerChangedSubscription, OnAnswerChangedSubscriptionVariables>) {
        return Apollo.useSubscription<OnAnswerChangedSubscription, OnAnswerChangedSubscriptionVariables>(OnAnswerChangedDocument, baseOptions);
      }
export type OnAnswerChangedSubscriptionHookResult = ReturnType<typeof useOnAnswerChangedSubscription>;
export type OnAnswerChangedSubscriptionResult = Apollo.SubscriptionResult<OnAnswerChangedSubscription>;
export const SearchApayDocument = gql`
    query searchApay($input: ApaySearchInput) {
  searchApay(input: $input) {
    total
    next
    data {
      id
      method
      status
      amount
      slip_url
    }
  }
}
    `;

/**
 * __useSearchApayQuery__
 *
 * To run a query within a React component, call `useSearchApayQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchApayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchApayQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchApayQuery(baseOptions?: Apollo.QueryHookOptions<SearchApayQuery, SearchApayQueryVariables>) {
        return Apollo.useQuery<SearchApayQuery, SearchApayQueryVariables>(SearchApayDocument, baseOptions);
      }
export function useSearchApayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchApayQuery, SearchApayQueryVariables>) {
          return Apollo.useLazyQuery<SearchApayQuery, SearchApayQueryVariables>(SearchApayDocument, baseOptions);
        }
export type SearchApayQueryHookResult = ReturnType<typeof useSearchApayQuery>;
export type SearchApayLazyQueryHookResult = ReturnType<typeof useSearchApayLazyQuery>;
export type SearchApayQueryResult = Apollo.QueryResult<SearchApayQuery, SearchApayQueryVariables>;
export const CreateApayDocument = gql`
    mutation createApay($input: ApayInput!) {
  createApay(input: $input) {
    id
  }
}
    `;
export type CreateApayMutationFn = Apollo.MutationFunction<CreateApayMutation, CreateApayMutationVariables>;

/**
 * __useCreateApayMutation__
 *
 * To run a mutation, you first call `useCreateApayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApayMutation, { data, loading, error }] = useCreateApayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApayMutation(baseOptions?: Apollo.MutationHookOptions<CreateApayMutation, CreateApayMutationVariables>) {
        return Apollo.useMutation<CreateApayMutation, CreateApayMutationVariables>(CreateApayDocument, baseOptions);
      }
export type CreateApayMutationHookResult = ReturnType<typeof useCreateApayMutation>;
export type CreateApayMutationResult = Apollo.MutationResult<CreateApayMutation>;
export type CreateApayMutationOptions = Apollo.BaseMutationOptions<CreateApayMutation, CreateApayMutationVariables>;
export const GetApayDocument = gql`
    query getApay($id: String!) {
  getApay(id: $id) {
    id
    method
    status
    amount
    slip_url
  }
}
    `;

/**
 * __useGetApayQuery__
 *
 * To run a query within a React component, call `useGetApayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApayQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetApayQuery(baseOptions: Apollo.QueryHookOptions<GetApayQuery, GetApayQueryVariables>) {
        return Apollo.useQuery<GetApayQuery, GetApayQueryVariables>(GetApayDocument, baseOptions);
      }
export function useGetApayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApayQuery, GetApayQueryVariables>) {
          return Apollo.useLazyQuery<GetApayQuery, GetApayQueryVariables>(GetApayDocument, baseOptions);
        }
export type GetApayQueryHookResult = ReturnType<typeof useGetApayQuery>;
export type GetApayLazyQueryHookResult = ReturnType<typeof useGetApayLazyQuery>;
export type GetApayQueryResult = Apollo.QueryResult<GetApayQuery, GetApayQueryVariables>;
export const UpdateApayDocument = gql`
    mutation updateApay($id: String!, $input: ApayInput!) {
  updateApay(id: $id, input: $input) {
    id
  }
}
    `;
export type UpdateApayMutationFn = Apollo.MutationFunction<UpdateApayMutation, UpdateApayMutationVariables>;

/**
 * __useUpdateApayMutation__
 *
 * To run a mutation, you first call `useUpdateApayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApayMutation, { data, loading, error }] = useUpdateApayMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApayMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApayMutation, UpdateApayMutationVariables>) {
        return Apollo.useMutation<UpdateApayMutation, UpdateApayMutationVariables>(UpdateApayDocument, baseOptions);
      }
export type UpdateApayMutationHookResult = ReturnType<typeof useUpdateApayMutation>;
export type UpdateApayMutationResult = Apollo.MutationResult<UpdateApayMutation>;
export type UpdateApayMutationOptions = Apollo.BaseMutationOptions<UpdateApayMutation, UpdateApayMutationVariables>;
export const RemoveApayDocument = gql`
    mutation removeApay($id: String!) {
  removeApay(id: $id) {
    id
  }
}
    `;
export type RemoveApayMutationFn = Apollo.MutationFunction<RemoveApayMutation, RemoveApayMutationVariables>;

/**
 * __useRemoveApayMutation__
 *
 * To run a mutation, you first call `useRemoveApayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveApayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeApayMutation, { data, loading, error }] = useRemoveApayMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveApayMutation(baseOptions?: Apollo.MutationHookOptions<RemoveApayMutation, RemoveApayMutationVariables>) {
        return Apollo.useMutation<RemoveApayMutation, RemoveApayMutationVariables>(RemoveApayDocument, baseOptions);
      }
export type RemoveApayMutationHookResult = ReturnType<typeof useRemoveApayMutation>;
export type RemoveApayMutationResult = Apollo.MutationResult<RemoveApayMutation>;
export type RemoveApayMutationOptions = Apollo.BaseMutationOptions<RemoveApayMutation, RemoveApayMutationVariables>;
export const OnApayChangedDocument = gql`
    subscription onApayChanged {
  onApayChanged {
    operation
    data {
      id
      method
      status
      amount
      slip_url
    }
  }
}
    `;

/**
 * __useOnApayChangedSubscription__
 *
 * To run a query within a React component, call `useOnApayChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnApayChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnApayChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnApayChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnApayChangedSubscription, OnApayChangedSubscriptionVariables>) {
        return Apollo.useSubscription<OnApayChangedSubscription, OnApayChangedSubscriptionVariables>(OnApayChangedDocument, baseOptions);
      }
export type OnApayChangedSubscriptionHookResult = ReturnType<typeof useOnApayChangedSubscription>;
export type OnApayChangedSubscriptionResult = Apollo.SubscriptionResult<OnApayChangedSubscription>;
export const SearchBankAccountDocument = gql`
    query searchBankAccount {
  searchBankAccount {
    total
    next
    data {
      id
      account_id
      account_type
      account_name
      bank
    }
  }
}
    `;

/**
 * __useSearchBankAccountQuery__
 *
 * To run a query within a React component, call `useSearchBankAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBankAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBankAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchBankAccountQuery(baseOptions?: Apollo.QueryHookOptions<SearchBankAccountQuery, SearchBankAccountQueryVariables>) {
        return Apollo.useQuery<SearchBankAccountQuery, SearchBankAccountQueryVariables>(SearchBankAccountDocument, baseOptions);
      }
export function useSearchBankAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchBankAccountQuery, SearchBankAccountQueryVariables>) {
          return Apollo.useLazyQuery<SearchBankAccountQuery, SearchBankAccountQueryVariables>(SearchBankAccountDocument, baseOptions);
        }
export type SearchBankAccountQueryHookResult = ReturnType<typeof useSearchBankAccountQuery>;
export type SearchBankAccountLazyQueryHookResult = ReturnType<typeof useSearchBankAccountLazyQuery>;
export type SearchBankAccountQueryResult = Apollo.QueryResult<SearchBankAccountQuery, SearchBankAccountQueryVariables>;
export const CreateBankAccountDocument = gql`
    mutation createBankAccount($input: BankAccountInput!) {
  createBankAccount(input: $input) {
    id
    account_id
    account_type
    account_name
    bank
  }
}
    `;
export type CreateBankAccountMutationFn = Apollo.MutationFunction<CreateBankAccountMutation, CreateBankAccountMutationVariables>;

/**
 * __useCreateBankAccountMutation__
 *
 * To run a mutation, you first call `useCreateBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBankAccountMutation, { data, loading, error }] = useCreateBankAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBankAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateBankAccountMutation, CreateBankAccountMutationVariables>) {
        return Apollo.useMutation<CreateBankAccountMutation, CreateBankAccountMutationVariables>(CreateBankAccountDocument, baseOptions);
      }
export type CreateBankAccountMutationHookResult = ReturnType<typeof useCreateBankAccountMutation>;
export type CreateBankAccountMutationResult = Apollo.MutationResult<CreateBankAccountMutation>;
export type CreateBankAccountMutationOptions = Apollo.BaseMutationOptions<CreateBankAccountMutation, CreateBankAccountMutationVariables>;
export const GetBankAccountDocument = gql`
    query getBankAccount($id: String!) {
  getBankAccount(id: $id) {
    id
    account_id
    account_type
    account_name
    bank
  }
}
    `;

/**
 * __useGetBankAccountQuery__
 *
 * To run a query within a React component, call `useGetBankAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBankAccountQuery(baseOptions: Apollo.QueryHookOptions<GetBankAccountQuery, GetBankAccountQueryVariables>) {
        return Apollo.useQuery<GetBankAccountQuery, GetBankAccountQueryVariables>(GetBankAccountDocument, baseOptions);
      }
export function useGetBankAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBankAccountQuery, GetBankAccountQueryVariables>) {
          return Apollo.useLazyQuery<GetBankAccountQuery, GetBankAccountQueryVariables>(GetBankAccountDocument, baseOptions);
        }
export type GetBankAccountQueryHookResult = ReturnType<typeof useGetBankAccountQuery>;
export type GetBankAccountLazyQueryHookResult = ReturnType<typeof useGetBankAccountLazyQuery>;
export type GetBankAccountQueryResult = Apollo.QueryResult<GetBankAccountQuery, GetBankAccountQueryVariables>;
export const UpdateBankAccountDocument = gql`
    mutation updateBankAccount($id: String!, $input: BankAccountInput!) {
  updateBankAccount(id: $id, input: $input) {
    id
  }
}
    `;
export type UpdateBankAccountMutationFn = Apollo.MutationFunction<UpdateBankAccountMutation, UpdateBankAccountMutationVariables>;

/**
 * __useUpdateBankAccountMutation__
 *
 * To run a mutation, you first call `useUpdateBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBankAccountMutation, { data, loading, error }] = useUpdateBankAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBankAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBankAccountMutation, UpdateBankAccountMutationVariables>) {
        return Apollo.useMutation<UpdateBankAccountMutation, UpdateBankAccountMutationVariables>(UpdateBankAccountDocument, baseOptions);
      }
export type UpdateBankAccountMutationHookResult = ReturnType<typeof useUpdateBankAccountMutation>;
export type UpdateBankAccountMutationResult = Apollo.MutationResult<UpdateBankAccountMutation>;
export type UpdateBankAccountMutationOptions = Apollo.BaseMutationOptions<UpdateBankAccountMutation, UpdateBankAccountMutationVariables>;
export const RemoveBankAccountDocument = gql`
    mutation removeBankAccount($id: String!) {
  removeBankAccount(id: $id) {
    id
  }
}
    `;
export type RemoveBankAccountMutationFn = Apollo.MutationFunction<RemoveBankAccountMutation, RemoveBankAccountMutationVariables>;

/**
 * __useRemoveBankAccountMutation__
 *
 * To run a mutation, you first call `useRemoveBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBankAccountMutation, { data, loading, error }] = useRemoveBankAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveBankAccountMutation(baseOptions?: Apollo.MutationHookOptions<RemoveBankAccountMutation, RemoveBankAccountMutationVariables>) {
        return Apollo.useMutation<RemoveBankAccountMutation, RemoveBankAccountMutationVariables>(RemoveBankAccountDocument, baseOptions);
      }
export type RemoveBankAccountMutationHookResult = ReturnType<typeof useRemoveBankAccountMutation>;
export type RemoveBankAccountMutationResult = Apollo.MutationResult<RemoveBankAccountMutation>;
export type RemoveBankAccountMutationOptions = Apollo.BaseMutationOptions<RemoveBankAccountMutation, RemoveBankAccountMutationVariables>;
export const OnBankAccountChangedDocument = gql`
    subscription onBankAccountChanged {
  onBankAccountChanged {
    operation
    data {
      id
      account_id
      account_type
      account_name
      bank
    }
  }
}
    `;

/**
 * __useOnBankAccountChangedSubscription__
 *
 * To run a query within a React component, call `useOnBankAccountChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnBankAccountChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnBankAccountChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnBankAccountChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnBankAccountChangedSubscription, OnBankAccountChangedSubscriptionVariables>) {
        return Apollo.useSubscription<OnBankAccountChangedSubscription, OnBankAccountChangedSubscriptionVariables>(OnBankAccountChangedDocument, baseOptions);
      }
export type OnBankAccountChangedSubscriptionHookResult = ReturnType<typeof useOnBankAccountChangedSubscription>;
export type OnBankAccountChangedSubscriptionResult = Apollo.SubscriptionResult<OnBankAccountChangedSubscription>;
export const SearchBotTemplateDocument = gql`
    query searchBotTemplate {
  searchBotTemplate {
    data {
      id
      title
      picture_url
      business_type
      profile {
        title
        open_hours
        contact
        address
      }
    }
  }
}
    `;

/**
 * __useSearchBotTemplateQuery__
 *
 * To run a query within a React component, call `useSearchBotTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBotTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBotTemplateQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchBotTemplateQuery(baseOptions?: Apollo.QueryHookOptions<SearchBotTemplateQuery, SearchBotTemplateQueryVariables>) {
        return Apollo.useQuery<SearchBotTemplateQuery, SearchBotTemplateQueryVariables>(SearchBotTemplateDocument, baseOptions);
      }
export function useSearchBotTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchBotTemplateQuery, SearchBotTemplateQueryVariables>) {
          return Apollo.useLazyQuery<SearchBotTemplateQuery, SearchBotTemplateQueryVariables>(SearchBotTemplateDocument, baseOptions);
        }
export type SearchBotTemplateQueryHookResult = ReturnType<typeof useSearchBotTemplateQuery>;
export type SearchBotTemplateLazyQueryHookResult = ReturnType<typeof useSearchBotTemplateLazyQuery>;
export type SearchBotTemplateQueryResult = Apollo.QueryResult<SearchBotTemplateQuery, SearchBotTemplateQueryVariables>;
export const CreateBot2Document = gql`
    mutation createBot2($input: CreateBotDto!) {
  createBot2(input: $input) {
    id
  }
}
    `;
export type CreateBot2MutationFn = Apollo.MutationFunction<CreateBot2Mutation, CreateBot2MutationVariables>;

/**
 * __useCreateBot2Mutation__
 *
 * To run a mutation, you first call `useCreateBot2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBot2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBot2Mutation, { data, loading, error }] = useCreateBot2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBot2Mutation(baseOptions?: Apollo.MutationHookOptions<CreateBot2Mutation, CreateBot2MutationVariables>) {
        return Apollo.useMutation<CreateBot2Mutation, CreateBot2MutationVariables>(CreateBot2Document, baseOptions);
      }
export type CreateBot2MutationHookResult = ReturnType<typeof useCreateBot2Mutation>;
export type CreateBot2MutationResult = Apollo.MutationResult<CreateBot2Mutation>;
export type CreateBot2MutationOptions = Apollo.BaseMutationOptions<CreateBot2Mutation, CreateBot2MutationVariables>;
export const GetBotDocument = gql`
    query getBot($id: String!) {
  getBot(id: $id) {
    id
    title
    picture_url
    business_type
    modules
    friend_count
    friend_limit
    sku
    subscription_cost
    gender
    address
    invoice_address
    contact_number
    email
    created_by
    linenotify_token
    source
    version
  }
}
    `;

/**
 * __useGetBotQuery__
 *
 * To run a query within a React component, call `useGetBotQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBotQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBotQuery(baseOptions: Apollo.QueryHookOptions<GetBotQuery, GetBotQueryVariables>) {
        return Apollo.useQuery<GetBotQuery, GetBotQueryVariables>(GetBotDocument, baseOptions);
      }
export function useGetBotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBotQuery, GetBotQueryVariables>) {
          return Apollo.useLazyQuery<GetBotQuery, GetBotQueryVariables>(GetBotDocument, baseOptions);
        }
export type GetBotQueryHookResult = ReturnType<typeof useGetBotQuery>;
export type GetBotLazyQueryHookResult = ReturnType<typeof useGetBotLazyQuery>;
export type GetBotQueryResult = Apollo.QueryResult<GetBotQuery, GetBotQueryVariables>;
export const UpdateBotDocument = gql`
    mutation updateBot($id: String!, $input: BotInput!) {
  updateBot(input: $input, id: $id) {
    id
    title
    gender
    picture_url
  }
}
    `;
export type UpdateBotMutationFn = Apollo.MutationFunction<UpdateBotMutation, UpdateBotMutationVariables>;

/**
 * __useUpdateBotMutation__
 *
 * To run a mutation, you first call `useUpdateBotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBotMutation, { data, loading, error }] = useUpdateBotMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBotMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBotMutation, UpdateBotMutationVariables>) {
        return Apollo.useMutation<UpdateBotMutation, UpdateBotMutationVariables>(UpdateBotDocument, baseOptions);
      }
export type UpdateBotMutationHookResult = ReturnType<typeof useUpdateBotMutation>;
export type UpdateBotMutationResult = Apollo.MutationResult<UpdateBotMutation>;
export type UpdateBotMutationOptions = Apollo.BaseMutationOptions<UpdateBotMutation, UpdateBotMutationVariables>;
export const SearchChannelDocument = gql`
    query searchChannel {
  searchChannel {
    data {
      id
      title
      platform
      access_token
      channel_secret
      picture_url
      basic_id
      enabled
    }
  }
}
    `;

/**
 * __useSearchChannelQuery__
 *
 * To run a query within a React component, call `useSearchChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchChannelQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchChannelQuery(baseOptions?: Apollo.QueryHookOptions<SearchChannelQuery, SearchChannelQueryVariables>) {
        return Apollo.useQuery<SearchChannelQuery, SearchChannelQueryVariables>(SearchChannelDocument, baseOptions);
      }
export function useSearchChannelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchChannelQuery, SearchChannelQueryVariables>) {
          return Apollo.useLazyQuery<SearchChannelQuery, SearchChannelQueryVariables>(SearchChannelDocument, baseOptions);
        }
export type SearchChannelQueryHookResult = ReturnType<typeof useSearchChannelQuery>;
export type SearchChannelLazyQueryHookResult = ReturnType<typeof useSearchChannelLazyQuery>;
export type SearchChannelQueryResult = Apollo.QueryResult<SearchChannelQuery, SearchChannelQueryVariables>;
export const CreateChannelDocument = gql`
    mutation createChannel($input: ChannelInput!) {
  createChannel(input: $input) {
    id
    title
    platform
    access_token
    picture_url
    basic_id
  }
}
    `;
export type CreateChannelMutationFn = Apollo.MutationFunction<CreateChannelMutation, CreateChannelMutationVariables>;

/**
 * __useCreateChannelMutation__
 *
 * To run a mutation, you first call `useCreateChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChannelMutation, { data, loading, error }] = useCreateChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChannelMutation(baseOptions?: Apollo.MutationHookOptions<CreateChannelMutation, CreateChannelMutationVariables>) {
        return Apollo.useMutation<CreateChannelMutation, CreateChannelMutationVariables>(CreateChannelDocument, baseOptions);
      }
export type CreateChannelMutationHookResult = ReturnType<typeof useCreateChannelMutation>;
export type CreateChannelMutationResult = Apollo.MutationResult<CreateChannelMutation>;
export type CreateChannelMutationOptions = Apollo.BaseMutationOptions<CreateChannelMutation, CreateChannelMutationVariables>;
export const GetChannelDocument = gql`
    query getChannel($id: String!) {
  getChannel(id: $id) {
    id
    title
    platform
    access_token
    channel_secret
    picture_url
    basic_id
    enabled
  }
}
    `;

/**
 * __useGetChannelQuery__
 *
 * To run a query within a React component, call `useGetChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetChannelQuery(baseOptions: Apollo.QueryHookOptions<GetChannelQuery, GetChannelQueryVariables>) {
        return Apollo.useQuery<GetChannelQuery, GetChannelQueryVariables>(GetChannelDocument, baseOptions);
      }
export function useGetChannelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChannelQuery, GetChannelQueryVariables>) {
          return Apollo.useLazyQuery<GetChannelQuery, GetChannelQueryVariables>(GetChannelDocument, baseOptions);
        }
export type GetChannelQueryHookResult = ReturnType<typeof useGetChannelQuery>;
export type GetChannelLazyQueryHookResult = ReturnType<typeof useGetChannelLazyQuery>;
export type GetChannelQueryResult = Apollo.QueryResult<GetChannelQuery, GetChannelQueryVariables>;
export const UpdateChannelDocument = gql`
    mutation updateChannel($id: String!, $input: ChannelInput!) {
  updateChannel(input: $input, id: $id) {
    id
    title
    platform
    access_token
    channel_secret
    enabled
  }
}
    `;
export type UpdateChannelMutationFn = Apollo.MutationFunction<UpdateChannelMutation, UpdateChannelMutationVariables>;

/**
 * __useUpdateChannelMutation__
 *
 * To run a mutation, you first call `useUpdateChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelMutation, { data, loading, error }] = useUpdateChannelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChannelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChannelMutation, UpdateChannelMutationVariables>) {
        return Apollo.useMutation<UpdateChannelMutation, UpdateChannelMutationVariables>(UpdateChannelDocument, baseOptions);
      }
export type UpdateChannelMutationHookResult = ReturnType<typeof useUpdateChannelMutation>;
export type UpdateChannelMutationResult = Apollo.MutationResult<UpdateChannelMutation>;
export type UpdateChannelMutationOptions = Apollo.BaseMutationOptions<UpdateChannelMutation, UpdateChannelMutationVariables>;
export const RemoveChannelDocument = gql`
    mutation removeChannel($id: String!) {
  removeChannel(id: $id) {
    id
    success
    error_message
  }
}
    `;
export type RemoveChannelMutationFn = Apollo.MutationFunction<RemoveChannelMutation, RemoveChannelMutationVariables>;

/**
 * __useRemoveChannelMutation__
 *
 * To run a mutation, you first call `useRemoveChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeChannelMutation, { data, loading, error }] = useRemoveChannelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveChannelMutation(baseOptions?: Apollo.MutationHookOptions<RemoveChannelMutation, RemoveChannelMutationVariables>) {
        return Apollo.useMutation<RemoveChannelMutation, RemoveChannelMutationVariables>(RemoveChannelDocument, baseOptions);
      }
export type RemoveChannelMutationHookResult = ReturnType<typeof useRemoveChannelMutation>;
export type RemoveChannelMutationResult = Apollo.MutationResult<RemoveChannelMutation>;
export type RemoveChannelMutationOptions = Apollo.BaseMutationOptions<RemoveChannelMutation, RemoveChannelMutationVariables>;
export const OnChannelChangedDocument = gql`
    subscription onChannelChanged {
  onChannelChanged {
    operation
    data {
      id
      title
      platform
      access_token
      channel_secret
      picture_url
      enabled
    }
  }
}
    `;

/**
 * __useOnChannelChangedSubscription__
 *
 * To run a query within a React component, call `useOnChannelChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnChannelChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnChannelChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnChannelChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnChannelChangedSubscription, OnChannelChangedSubscriptionVariables>) {
        return Apollo.useSubscription<OnChannelChangedSubscription, OnChannelChangedSubscriptionVariables>(OnChannelChangedDocument, baseOptions);
      }
export type OnChannelChangedSubscriptionHookResult = ReturnType<typeof useOnChannelChangedSubscription>;
export type OnChannelChangedSubscriptionResult = Apollo.SubscriptionResult<OnChannelChangedSubscription>;
export const TestChannelDocument = gql`
    mutation testChannel($input: ChannelInput!) {
  testChannel(input: $input) {
    id
  }
}
    `;
export type TestChannelMutationFn = Apollo.MutationFunction<TestChannelMutation, TestChannelMutationVariables>;

/**
 * __useTestChannelMutation__
 *
 * To run a mutation, you first call `useTestChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testChannelMutation, { data, loading, error }] = useTestChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTestChannelMutation(baseOptions?: Apollo.MutationHookOptions<TestChannelMutation, TestChannelMutationVariables>) {
        return Apollo.useMutation<TestChannelMutation, TestChannelMutationVariables>(TestChannelDocument, baseOptions);
      }
export type TestChannelMutationHookResult = ReturnType<typeof useTestChannelMutation>;
export type TestChannelMutationResult = Apollo.MutationResult<TestChannelMutation>;
export type TestChannelMutationOptions = Apollo.BaseMutationOptions<TestChannelMutation, TestChannelMutationVariables>;
export const CountChannelDocument = gql`
    query countChannel($input: ChannelSearchFilter) {
  countChannel(input: $input)
}
    `;

/**
 * __useCountChannelQuery__
 *
 * To run a query within a React component, call `useCountChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountChannelQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCountChannelQuery(baseOptions?: Apollo.QueryHookOptions<CountChannelQuery, CountChannelQueryVariables>) {
        return Apollo.useQuery<CountChannelQuery, CountChannelQueryVariables>(CountChannelDocument, baseOptions);
      }
export function useCountChannelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountChannelQuery, CountChannelQueryVariables>) {
          return Apollo.useLazyQuery<CountChannelQuery, CountChannelQueryVariables>(CountChannelDocument, baseOptions);
        }
export type CountChannelQueryHookResult = ReturnType<typeof useCountChannelQuery>;
export type CountChannelLazyQueryHookResult = ReturnType<typeof useCountChannelLazyQuery>;
export type CountChannelQueryResult = Apollo.QueryResult<CountChannelQuery, CountChannelQueryVariables>;
export const PushMessageDocument = gql`
    mutation pushMessage($chatId: String!, $messages: [ChatMessageInput!], $platform: Platform!, $keywordId: String, $trainSentence: String, $params: JSON) {
  pushMessage(chat_id: $chatId, messages: $messages, platform: $platform, keyword_id: $keywordId, train_sentence: $trainSentence, params: $params)
}
    `;
export type PushMessageMutationFn = Apollo.MutationFunction<PushMessageMutation, PushMessageMutationVariables>;

/**
 * __usePushMessageMutation__
 *
 * To run a mutation, you first call `usePushMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePushMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pushMessageMutation, { data, loading, error }] = usePushMessageMutation({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      messages: // value for 'messages'
 *      platform: // value for 'platform'
 *      keywordId: // value for 'keywordId'
 *      trainSentence: // value for 'trainSentence'
 *      params: // value for 'params'
 *   },
 * });
 */
export function usePushMessageMutation(baseOptions?: Apollo.MutationHookOptions<PushMessageMutation, PushMessageMutationVariables>) {
        return Apollo.useMutation<PushMessageMutation, PushMessageMutationVariables>(PushMessageDocument, baseOptions);
      }
export type PushMessageMutationHookResult = ReturnType<typeof usePushMessageMutation>;
export type PushMessageMutationResult = Apollo.MutationResult<PushMessageMutation>;
export type PushMessageMutationOptions = Apollo.BaseMutationOptions<PushMessageMutation, PushMessageMutationVariables>;
export const SearchContentTypeDocument = gql`
    query searchContentType {
  searchContentType {
    total
    next
    data {
      id
      title
      type
    }
  }
}
    `;

/**
 * __useSearchContentTypeQuery__
 *
 * To run a query within a React component, call `useSearchContentTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchContentTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchContentTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchContentTypeQuery(baseOptions?: Apollo.QueryHookOptions<SearchContentTypeQuery, SearchContentTypeQueryVariables>) {
        return Apollo.useQuery<SearchContentTypeQuery, SearchContentTypeQueryVariables>(SearchContentTypeDocument, baseOptions);
      }
export function useSearchContentTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchContentTypeQuery, SearchContentTypeQueryVariables>) {
          return Apollo.useLazyQuery<SearchContentTypeQuery, SearchContentTypeQueryVariables>(SearchContentTypeDocument, baseOptions);
        }
export type SearchContentTypeQueryHookResult = ReturnType<typeof useSearchContentTypeQuery>;
export type SearchContentTypeLazyQueryHookResult = ReturnType<typeof useSearchContentTypeLazyQuery>;
export type SearchContentTypeQueryResult = Apollo.QueryResult<SearchContentTypeQuery, SearchContentTypeQueryVariables>;
export const CreateContentTypeDocument = gql`
    mutation createContentType($input: ContentTypeInput!) {
  createContentType(input: $input) {
    id
    title
  }
}
    `;
export type CreateContentTypeMutationFn = Apollo.MutationFunction<CreateContentTypeMutation, CreateContentTypeMutationVariables>;

/**
 * __useCreateContentTypeMutation__
 *
 * To run a mutation, you first call `useCreateContentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContentTypeMutation, { data, loading, error }] = useCreateContentTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContentTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateContentTypeMutation, CreateContentTypeMutationVariables>) {
        return Apollo.useMutation<CreateContentTypeMutation, CreateContentTypeMutationVariables>(CreateContentTypeDocument, baseOptions);
      }
export type CreateContentTypeMutationHookResult = ReturnType<typeof useCreateContentTypeMutation>;
export type CreateContentTypeMutationResult = Apollo.MutationResult<CreateContentTypeMutation>;
export type CreateContentTypeMutationOptions = Apollo.BaseMutationOptions<CreateContentTypeMutation, CreateContentTypeMutationVariables>;
export const GetContentTypeDocument = gql`
    query getContentType($id: String!) {
  getContentType(id: $id) {
    id
    title
    type
    fields {
      type
      name
      title
    }
  }
}
    `;

/**
 * __useGetContentTypeQuery__
 *
 * To run a query within a React component, call `useGetContentTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContentTypeQuery(baseOptions: Apollo.QueryHookOptions<GetContentTypeQuery, GetContentTypeQueryVariables>) {
        return Apollo.useQuery<GetContentTypeQuery, GetContentTypeQueryVariables>(GetContentTypeDocument, baseOptions);
      }
export function useGetContentTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentTypeQuery, GetContentTypeQueryVariables>) {
          return Apollo.useLazyQuery<GetContentTypeQuery, GetContentTypeQueryVariables>(GetContentTypeDocument, baseOptions);
        }
export type GetContentTypeQueryHookResult = ReturnType<typeof useGetContentTypeQuery>;
export type GetContentTypeLazyQueryHookResult = ReturnType<typeof useGetContentTypeLazyQuery>;
export type GetContentTypeQueryResult = Apollo.QueryResult<GetContentTypeQuery, GetContentTypeQueryVariables>;
export const GetContentTypeByTypeDocument = gql`
    query getContentTypeByType($type: String!) {
  getContentTypeByType(type: $type) {
    id
    title
    type
    fields {
      type
      name
      title
    }
  }
}
    `;

/**
 * __useGetContentTypeByTypeQuery__
 *
 * To run a query within a React component, call `useGetContentTypeByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentTypeByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentTypeByTypeQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetContentTypeByTypeQuery(baseOptions: Apollo.QueryHookOptions<GetContentTypeByTypeQuery, GetContentTypeByTypeQueryVariables>) {
        return Apollo.useQuery<GetContentTypeByTypeQuery, GetContentTypeByTypeQueryVariables>(GetContentTypeByTypeDocument, baseOptions);
      }
export function useGetContentTypeByTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentTypeByTypeQuery, GetContentTypeByTypeQueryVariables>) {
          return Apollo.useLazyQuery<GetContentTypeByTypeQuery, GetContentTypeByTypeQueryVariables>(GetContentTypeByTypeDocument, baseOptions);
        }
export type GetContentTypeByTypeQueryHookResult = ReturnType<typeof useGetContentTypeByTypeQuery>;
export type GetContentTypeByTypeLazyQueryHookResult = ReturnType<typeof useGetContentTypeByTypeLazyQuery>;
export type GetContentTypeByTypeQueryResult = Apollo.QueryResult<GetContentTypeByTypeQuery, GetContentTypeByTypeQueryVariables>;
export const UpdateContentTypeDocument = gql`
    mutation updateContentType($id: String!, $input: ContentTypeInput!) {
  updateContentType(id: $id, input: $input) {
    id
    title
  }
}
    `;
export type UpdateContentTypeMutationFn = Apollo.MutationFunction<UpdateContentTypeMutation, UpdateContentTypeMutationVariables>;

/**
 * __useUpdateContentTypeMutation__
 *
 * To run a mutation, you first call `useUpdateContentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContentTypeMutation, { data, loading, error }] = useUpdateContentTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContentTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContentTypeMutation, UpdateContentTypeMutationVariables>) {
        return Apollo.useMutation<UpdateContentTypeMutation, UpdateContentTypeMutationVariables>(UpdateContentTypeDocument, baseOptions);
      }
export type UpdateContentTypeMutationHookResult = ReturnType<typeof useUpdateContentTypeMutation>;
export type UpdateContentTypeMutationResult = Apollo.MutationResult<UpdateContentTypeMutation>;
export type UpdateContentTypeMutationOptions = Apollo.BaseMutationOptions<UpdateContentTypeMutation, UpdateContentTypeMutationVariables>;
export const RemoveContentTypeDocument = gql`
    mutation removeContentType($id: String!) {
  removeContentType(id: $id) {
    id
  }
}
    `;
export type RemoveContentTypeMutationFn = Apollo.MutationFunction<RemoveContentTypeMutation, RemoveContentTypeMutationVariables>;

/**
 * __useRemoveContentTypeMutation__
 *
 * To run a mutation, you first call `useRemoveContentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContentTypeMutation, { data, loading, error }] = useRemoveContentTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveContentTypeMutation(baseOptions?: Apollo.MutationHookOptions<RemoveContentTypeMutation, RemoveContentTypeMutationVariables>) {
        return Apollo.useMutation<RemoveContentTypeMutation, RemoveContentTypeMutationVariables>(RemoveContentTypeDocument, baseOptions);
      }
export type RemoveContentTypeMutationHookResult = ReturnType<typeof useRemoveContentTypeMutation>;
export type RemoveContentTypeMutationResult = Apollo.MutationResult<RemoveContentTypeMutation>;
export type RemoveContentTypeMutationOptions = Apollo.BaseMutationOptions<RemoveContentTypeMutation, RemoveContentTypeMutationVariables>;
export const OnContentTypeChangedDocument = gql`
    subscription onContentTypeChanged {
  onContentTypeChanged {
    operation
    data {
      id
      title
      type
      fields {
        title
        name
        type
      }
    }
  }
}
    `;

/**
 * __useOnContentTypeChangedSubscription__
 *
 * To run a query within a React component, call `useOnContentTypeChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnContentTypeChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnContentTypeChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnContentTypeChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnContentTypeChangedSubscription, OnContentTypeChangedSubscriptionVariables>) {
        return Apollo.useSubscription<OnContentTypeChangedSubscription, OnContentTypeChangedSubscriptionVariables>(OnContentTypeChangedDocument, baseOptions);
      }
export type OnContentTypeChangedSubscriptionHookResult = ReturnType<typeof useOnContentTypeChangedSubscription>;
export type OnContentTypeChangedSubscriptionResult = Apollo.SubscriptionResult<OnContentTypeChangedSubscription>;
export const SearchContentDocument = gql`
    query searchContent($input: ContentSearchInput) {
  searchContent(input: $input) {
    total
    next
    data {
      id
      title
      content_type
      weight
      body
      creation
    }
  }
}
    `;

/**
 * __useSearchContentQuery__
 *
 * To run a query within a React component, call `useSearchContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchContentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchContentQuery(baseOptions?: Apollo.QueryHookOptions<SearchContentQuery, SearchContentQueryVariables>) {
        return Apollo.useQuery<SearchContentQuery, SearchContentQueryVariables>(SearchContentDocument, baseOptions);
      }
export function useSearchContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchContentQuery, SearchContentQueryVariables>) {
          return Apollo.useLazyQuery<SearchContentQuery, SearchContentQueryVariables>(SearchContentDocument, baseOptions);
        }
export type SearchContentQueryHookResult = ReturnType<typeof useSearchContentQuery>;
export type SearchContentLazyQueryHookResult = ReturnType<typeof useSearchContentLazyQuery>;
export type SearchContentQueryResult = Apollo.QueryResult<SearchContentQuery, SearchContentQueryVariables>;
export const CreateContentDocument = gql`
    mutation createContent($input: ContentInput!) {
  createContent(input: $input) {
    id
    title
  }
}
    `;
export type CreateContentMutationFn = Apollo.MutationFunction<CreateContentMutation, CreateContentMutationVariables>;

/**
 * __useCreateContentMutation__
 *
 * To run a mutation, you first call `useCreateContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContentMutation, { data, loading, error }] = useCreateContentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContentMutation(baseOptions?: Apollo.MutationHookOptions<CreateContentMutation, CreateContentMutationVariables>) {
        return Apollo.useMutation<CreateContentMutation, CreateContentMutationVariables>(CreateContentDocument, baseOptions);
      }
export type CreateContentMutationHookResult = ReturnType<typeof useCreateContentMutation>;
export type CreateContentMutationResult = Apollo.MutationResult<CreateContentMutation>;
export type CreateContentMutationOptions = Apollo.BaseMutationOptions<CreateContentMutation, CreateContentMutationVariables>;
export const GetContentDocument = gql`
    query getContent($id: String!) {
  getContent(id: $id) {
    id
    title
    content_type
    weight
    body
  }
}
    `;

/**
 * __useGetContentQuery__
 *
 * To run a query within a React component, call `useGetContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContentQuery(baseOptions: Apollo.QueryHookOptions<GetContentQuery, GetContentQueryVariables>) {
        return Apollo.useQuery<GetContentQuery, GetContentQueryVariables>(GetContentDocument, baseOptions);
      }
export function useGetContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentQuery, GetContentQueryVariables>) {
          return Apollo.useLazyQuery<GetContentQuery, GetContentQueryVariables>(GetContentDocument, baseOptions);
        }
export type GetContentQueryHookResult = ReturnType<typeof useGetContentQuery>;
export type GetContentLazyQueryHookResult = ReturnType<typeof useGetContentLazyQuery>;
export type GetContentQueryResult = Apollo.QueryResult<GetContentQuery, GetContentQueryVariables>;
export const UpdateContentDocument = gql`
    mutation updateContent($id: String!, $input: ContentInput!) {
  updateContent(id: $id, input: $input) {
    id
    title
  }
}
    `;
export type UpdateContentMutationFn = Apollo.MutationFunction<UpdateContentMutation, UpdateContentMutationVariables>;

/**
 * __useUpdateContentMutation__
 *
 * To run a mutation, you first call `useUpdateContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContentMutation, { data, loading, error }] = useUpdateContentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContentMutation, UpdateContentMutationVariables>) {
        return Apollo.useMutation<UpdateContentMutation, UpdateContentMutationVariables>(UpdateContentDocument, baseOptions);
      }
export type UpdateContentMutationHookResult = ReturnType<typeof useUpdateContentMutation>;
export type UpdateContentMutationResult = Apollo.MutationResult<UpdateContentMutation>;
export type UpdateContentMutationOptions = Apollo.BaseMutationOptions<UpdateContentMutation, UpdateContentMutationVariables>;
export const RemoveContentDocument = gql`
    mutation removeContent($id: String!) {
  removeContent(id: $id) {
    id
  }
}
    `;
export type RemoveContentMutationFn = Apollo.MutationFunction<RemoveContentMutation, RemoveContentMutationVariables>;

/**
 * __useRemoveContentMutation__
 *
 * To run a mutation, you first call `useRemoveContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContentMutation, { data, loading, error }] = useRemoveContentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveContentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveContentMutation, RemoveContentMutationVariables>) {
        return Apollo.useMutation<RemoveContentMutation, RemoveContentMutationVariables>(RemoveContentDocument, baseOptions);
      }
export type RemoveContentMutationHookResult = ReturnType<typeof useRemoveContentMutation>;
export type RemoveContentMutationResult = Apollo.MutationResult<RemoveContentMutation>;
export type RemoveContentMutationOptions = Apollo.BaseMutationOptions<RemoveContentMutation, RemoveContentMutationVariables>;
export const GetContextDocument = gql`
    query getContext($id: String!) {
  getBot(id: $id) {
    id
    title
    picture_url
    business_type
    modules
    friend_count
    friend_limit
    sku
    subscription_cost
    gender
    address
    invoice_address
    contact_number
    email
    created_by
    linenotify_token
    source
    version
  }
  getCountFriendTags {
    data {
      name
      total
    }
  }
  searchFriendGroup {
    data {
      id
      title
      favorite
      includes
      excludes
      is_some
    }
  }
  searchChannel {
    data {
      id
      title
      platform
      access_token
      channel_secret
      picture_url
      basic_id
      enabled
    }
  }
  searchTag(input: {page: {size: 1000}}) {
    data {
      id
      title
      type
    }
  }
  searchContentType {
    total
    next
    data {
      id
      title
      type
    }
  }
  searchProductCategory {
    data {
      title
      picture_url
      id
      description
    }
  }
}
    `;

/**
 * __useGetContextQuery__
 *
 * To run a query within a React component, call `useGetContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContextQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContextQuery(baseOptions: Apollo.QueryHookOptions<GetContextQuery, GetContextQueryVariables>) {
        return Apollo.useQuery<GetContextQuery, GetContextQueryVariables>(GetContextDocument, baseOptions);
      }
export function useGetContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContextQuery, GetContextQueryVariables>) {
          return Apollo.useLazyQuery<GetContextQuery, GetContextQueryVariables>(GetContextDocument, baseOptions);
        }
export type GetContextQueryHookResult = ReturnType<typeof useGetContextQuery>;
export type GetContextLazyQueryHookResult = ReturnType<typeof useGetContextLazyQuery>;
export type GetContextQueryResult = Apollo.QueryResult<GetContextQuery, GetContextQueryVariables>;
export const SearchFeedDocument = gql`
    query searchFeed($pageId: String!) {
  searchFeed(page_id: $pageId) {
    id
    message
    picture
    full_picture
  }
}
    `;

/**
 * __useSearchFeedQuery__
 *
 * To run a query within a React component, call `useSearchFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchFeedQuery({
 *   variables: {
 *      pageId: // value for 'pageId'
 *   },
 * });
 */
export function useSearchFeedQuery(baseOptions: Apollo.QueryHookOptions<SearchFeedQuery, SearchFeedQueryVariables>) {
        return Apollo.useQuery<SearchFeedQuery, SearchFeedQueryVariables>(SearchFeedDocument, baseOptions);
      }
export function useSearchFeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchFeedQuery, SearchFeedQueryVariables>) {
          return Apollo.useLazyQuery<SearchFeedQuery, SearchFeedQueryVariables>(SearchFeedDocument, baseOptions);
        }
export type SearchFeedQueryHookResult = ReturnType<typeof useSearchFeedQuery>;
export type SearchFeedLazyQueryHookResult = ReturnType<typeof useSearchFeedLazyQuery>;
export type SearchFeedQueryResult = Apollo.QueryResult<SearchFeedQuery, SearchFeedQueryVariables>;
export const SearchFbCommentDocument = gql`
    query searchFbComment($input: FbCommentSearchInput) {
  searchFbComment(input: $input) {
    total
    next
    data {
      ...fbCommentFields
    }
  }
}
    ${FbCommentFieldsFragmentDoc}`;

/**
 * __useSearchFbCommentQuery__
 *
 * To run a query within a React component, call `useSearchFbCommentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchFbCommentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchFbCommentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchFbCommentQuery(baseOptions?: Apollo.QueryHookOptions<SearchFbCommentQuery, SearchFbCommentQueryVariables>) {
        return Apollo.useQuery<SearchFbCommentQuery, SearchFbCommentQueryVariables>(SearchFbCommentDocument, baseOptions);
      }
export function useSearchFbCommentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchFbCommentQuery, SearchFbCommentQueryVariables>) {
          return Apollo.useLazyQuery<SearchFbCommentQuery, SearchFbCommentQueryVariables>(SearchFbCommentDocument, baseOptions);
        }
export type SearchFbCommentQueryHookResult = ReturnType<typeof useSearchFbCommentQuery>;
export type SearchFbCommentLazyQueryHookResult = ReturnType<typeof useSearchFbCommentLazyQuery>;
export type SearchFbCommentQueryResult = Apollo.QueryResult<SearchFbCommentQuery, SearchFbCommentQueryVariables>;
export const CreateFbCommentDocument = gql`
    mutation createFbComment($input: FbCommentInput!) {
  createFbComment(input: $input) {
    id
  }
}
    `;
export type CreateFbCommentMutationFn = Apollo.MutationFunction<CreateFbCommentMutation, CreateFbCommentMutationVariables>;

/**
 * __useCreateFbCommentMutation__
 *
 * To run a mutation, you first call `useCreateFbCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFbCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFbCommentMutation, { data, loading, error }] = useCreateFbCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFbCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateFbCommentMutation, CreateFbCommentMutationVariables>) {
        return Apollo.useMutation<CreateFbCommentMutation, CreateFbCommentMutationVariables>(CreateFbCommentDocument, baseOptions);
      }
export type CreateFbCommentMutationHookResult = ReturnType<typeof useCreateFbCommentMutation>;
export type CreateFbCommentMutationResult = Apollo.MutationResult<CreateFbCommentMutation>;
export type CreateFbCommentMutationOptions = Apollo.BaseMutationOptions<CreateFbCommentMutation, CreateFbCommentMutationVariables>;
export const GetFbCommentDocument = gql`
    query getFbComment($id: String!) {
  getFbComment(id: $id) {
    ...fbCommentFields
  }
}
    ${FbCommentFieldsFragmentDoc}`;

/**
 * __useGetFbCommentQuery__
 *
 * To run a query within a React component, call `useGetFbCommentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFbCommentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFbCommentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFbCommentQuery(baseOptions: Apollo.QueryHookOptions<GetFbCommentQuery, GetFbCommentQueryVariables>) {
        return Apollo.useQuery<GetFbCommentQuery, GetFbCommentQueryVariables>(GetFbCommentDocument, baseOptions);
      }
export function useGetFbCommentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFbCommentQuery, GetFbCommentQueryVariables>) {
          return Apollo.useLazyQuery<GetFbCommentQuery, GetFbCommentQueryVariables>(GetFbCommentDocument, baseOptions);
        }
export type GetFbCommentQueryHookResult = ReturnType<typeof useGetFbCommentQuery>;
export type GetFbCommentLazyQueryHookResult = ReturnType<typeof useGetFbCommentLazyQuery>;
export type GetFbCommentQueryResult = Apollo.QueryResult<GetFbCommentQuery, GetFbCommentQueryVariables>;
export const RemoveFbCommentDocument = gql`
    mutation removeFbComment($id: String!) {
  removeFbComment(id: $id) {
    id
  }
}
    `;
export type RemoveFbCommentMutationFn = Apollo.MutationFunction<RemoveFbCommentMutation, RemoveFbCommentMutationVariables>;

/**
 * __useRemoveFbCommentMutation__
 *
 * To run a mutation, you first call `useRemoveFbCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFbCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFbCommentMutation, { data, loading, error }] = useRemoveFbCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveFbCommentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFbCommentMutation, RemoveFbCommentMutationVariables>) {
        return Apollo.useMutation<RemoveFbCommentMutation, RemoveFbCommentMutationVariables>(RemoveFbCommentDocument, baseOptions);
      }
export type RemoveFbCommentMutationHookResult = ReturnType<typeof useRemoveFbCommentMutation>;
export type RemoveFbCommentMutationResult = Apollo.MutationResult<RemoveFbCommentMutation>;
export type RemoveFbCommentMutationOptions = Apollo.BaseMutationOptions<RemoveFbCommentMutation, RemoveFbCommentMutationVariables>;
export const OnFbCommentChangedDocument = gql`
    subscription onFbCommentChanged($filters: FbCommentSubscriptionFilter) {
  onFbCommentChanged(filters: $filters) {
    operation
    data {
      ...fbCommentFields
    }
  }
}
    ${FbCommentFieldsFragmentDoc}`;

/**
 * __useOnFbCommentChangedSubscription__
 *
 * To run a query within a React component, call `useOnFbCommentChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnFbCommentChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnFbCommentChangedSubscription({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useOnFbCommentChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnFbCommentChangedSubscription, OnFbCommentChangedSubscriptionVariables>) {
        return Apollo.useSubscription<OnFbCommentChangedSubscription, OnFbCommentChangedSubscriptionVariables>(OnFbCommentChangedDocument, baseOptions);
      }
export type OnFbCommentChangedSubscriptionHookResult = ReturnType<typeof useOnFbCommentChangedSubscription>;
export type OnFbCommentChangedSubscriptionResult = Apollo.SubscriptionResult<OnFbCommentChangedSubscription>;
export const SearchFbPostDocument = gql`
    query searchFBPost {
  searchFBPost {
    total
    next
    data {
      id
      message
      full_picture
    }
  }
}
    `;

/**
 * __useSearchFbPostQuery__
 *
 * To run a query within a React component, call `useSearchFbPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchFbPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchFbPostQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchFbPostQuery(baseOptions?: Apollo.QueryHookOptions<SearchFbPostQuery, SearchFbPostQueryVariables>) {
        return Apollo.useQuery<SearchFbPostQuery, SearchFbPostQueryVariables>(SearchFbPostDocument, baseOptions);
      }
export function useSearchFbPostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchFbPostQuery, SearchFbPostQueryVariables>) {
          return Apollo.useLazyQuery<SearchFbPostQuery, SearchFbPostQueryVariables>(SearchFbPostDocument, baseOptions);
        }
export type SearchFbPostQueryHookResult = ReturnType<typeof useSearchFbPostQuery>;
export type SearchFbPostLazyQueryHookResult = ReturnType<typeof useSearchFbPostLazyQuery>;
export type SearchFbPostQueryResult = Apollo.QueryResult<SearchFbPostQuery, SearchFbPostQueryVariables>;
export const CreateFbPostDocument = gql`
    mutation createFBPost($input: FBPostInput!) {
  createFBPost(input: $input) {
    id
    message
    full_picture
  }
}
    `;
export type CreateFbPostMutationFn = Apollo.MutationFunction<CreateFbPostMutation, CreateFbPostMutationVariables>;

/**
 * __useCreateFbPostMutation__
 *
 * To run a mutation, you first call `useCreateFbPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFbPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFbPostMutation, { data, loading, error }] = useCreateFbPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFbPostMutation(baseOptions?: Apollo.MutationHookOptions<CreateFbPostMutation, CreateFbPostMutationVariables>) {
        return Apollo.useMutation<CreateFbPostMutation, CreateFbPostMutationVariables>(CreateFbPostDocument, baseOptions);
      }
export type CreateFbPostMutationHookResult = ReturnType<typeof useCreateFbPostMutation>;
export type CreateFbPostMutationResult = Apollo.MutationResult<CreateFbPostMutation>;
export type CreateFbPostMutationOptions = Apollo.BaseMutationOptions<CreateFbPostMutation, CreateFbPostMutationVariables>;
export const GetFbPostDocument = gql`
    query getFBPost($id: String!) {
  getFBPost(id: $id) {
    id
    message
    full_picture
  }
}
    `;

/**
 * __useGetFbPostQuery__
 *
 * To run a query within a React component, call `useGetFbPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFbPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFbPostQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFbPostQuery(baseOptions: Apollo.QueryHookOptions<GetFbPostQuery, GetFbPostQueryVariables>) {
        return Apollo.useQuery<GetFbPostQuery, GetFbPostQueryVariables>(GetFbPostDocument, baseOptions);
      }
export function useGetFbPostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFbPostQuery, GetFbPostQueryVariables>) {
          return Apollo.useLazyQuery<GetFbPostQuery, GetFbPostQueryVariables>(GetFbPostDocument, baseOptions);
        }
export type GetFbPostQueryHookResult = ReturnType<typeof useGetFbPostQuery>;
export type GetFbPostLazyQueryHookResult = ReturnType<typeof useGetFbPostLazyQuery>;
export type GetFbPostQueryResult = Apollo.QueryResult<GetFbPostQuery, GetFbPostQueryVariables>;
export const UpdateFbPostDocument = gql`
    mutation updateFBPost($id: String!, $input: FBPostInput!) {
  updateFBPost(id: $id, input: $input) {
    id
  }
}
    `;
export type UpdateFbPostMutationFn = Apollo.MutationFunction<UpdateFbPostMutation, UpdateFbPostMutationVariables>;

/**
 * __useUpdateFbPostMutation__
 *
 * To run a mutation, you first call `useUpdateFbPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFbPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFbPostMutation, { data, loading, error }] = useUpdateFbPostMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFbPostMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFbPostMutation, UpdateFbPostMutationVariables>) {
        return Apollo.useMutation<UpdateFbPostMutation, UpdateFbPostMutationVariables>(UpdateFbPostDocument, baseOptions);
      }
export type UpdateFbPostMutationHookResult = ReturnType<typeof useUpdateFbPostMutation>;
export type UpdateFbPostMutationResult = Apollo.MutationResult<UpdateFbPostMutation>;
export type UpdateFbPostMutationOptions = Apollo.BaseMutationOptions<UpdateFbPostMutation, UpdateFbPostMutationVariables>;
export const RemoveFbPostDocument = gql`
    mutation removeFBPost($id: String!) {
  removeFBPost(id: $id) {
    id
  }
}
    `;
export type RemoveFbPostMutationFn = Apollo.MutationFunction<RemoveFbPostMutation, RemoveFbPostMutationVariables>;

/**
 * __useRemoveFbPostMutation__
 *
 * To run a mutation, you first call `useRemoveFbPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFbPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFbPostMutation, { data, loading, error }] = useRemoveFbPostMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveFbPostMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFbPostMutation, RemoveFbPostMutationVariables>) {
        return Apollo.useMutation<RemoveFbPostMutation, RemoveFbPostMutationVariables>(RemoveFbPostDocument, baseOptions);
      }
export type RemoveFbPostMutationHookResult = ReturnType<typeof useRemoveFbPostMutation>;
export type RemoveFbPostMutationResult = Apollo.MutationResult<RemoveFbPostMutation>;
export type RemoveFbPostMutationOptions = Apollo.BaseMutationOptions<RemoveFbPostMutation, RemoveFbPostMutationVariables>;
export const OnFbPostChangedDocument = gql`
    subscription onFBPostChanged {
  onFBPostChanged {
    operation
    data {
      id
      message
      full_picture
    }
  }
}
    `;

/**
 * __useOnFbPostChangedSubscription__
 *
 * To run a query within a React component, call `useOnFbPostChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnFbPostChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnFbPostChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnFbPostChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnFbPostChangedSubscription, OnFbPostChangedSubscriptionVariables>) {
        return Apollo.useSubscription<OnFbPostChangedSubscription, OnFbPostChangedSubscriptionVariables>(OnFbPostChangedDocument, baseOptions);
      }
export type OnFbPostChangedSubscriptionHookResult = ReturnType<typeof useOnFbPostChangedSubscription>;
export type OnFbPostChangedSubscriptionResult = Apollo.SubscriptionResult<OnFbPostChangedSubscription>;
export const SearchFriendDocument = gql`
    query searchFriend($input: FriendSearchInput!) {
  searchFriend(input: $input) {
    total
    next
    data {
      id
      channel_id
      social_id
      full_name
      picture_url
      platform
      tags
    }
  }
}
    `;

/**
 * __useSearchFriendQuery__
 *
 * To run a query within a React component, call `useSearchFriendQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchFriendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchFriendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchFriendQuery(baseOptions: Apollo.QueryHookOptions<SearchFriendQuery, SearchFriendQueryVariables>) {
        return Apollo.useQuery<SearchFriendQuery, SearchFriendQueryVariables>(SearchFriendDocument, baseOptions);
      }
export function useSearchFriendLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchFriendQuery, SearchFriendQueryVariables>) {
          return Apollo.useLazyQuery<SearchFriendQuery, SearchFriendQueryVariables>(SearchFriendDocument, baseOptions);
        }
export type SearchFriendQueryHookResult = ReturnType<typeof useSearchFriendQuery>;
export type SearchFriendLazyQueryHookResult = ReturnType<typeof useSearchFriendLazyQuery>;
export type SearchFriendQueryResult = Apollo.QueryResult<SearchFriendQuery, SearchFriendQueryVariables>;
export const GetFriendDocument = gql`
    query getFriend($friend_id: String!) {
  getFriend(friend_id: $friend_id) {
    id
    bot_id
    full_name
    status
    contact
    tags
    picture_url
    platform
    channel_id
    social_id
    rating
    about
    purchased_tags
    reached_tags
  }
}
    `;

/**
 * __useGetFriendQuery__
 *
 * To run a query within a React component, call `useGetFriendQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFriendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFriendQuery({
 *   variables: {
 *      friend_id: // value for 'friend_id'
 *   },
 * });
 */
export function useGetFriendQuery(baseOptions: Apollo.QueryHookOptions<GetFriendQuery, GetFriendQueryVariables>) {
        return Apollo.useQuery<GetFriendQuery, GetFriendQueryVariables>(GetFriendDocument, baseOptions);
      }
export function useGetFriendLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFriendQuery, GetFriendQueryVariables>) {
          return Apollo.useLazyQuery<GetFriendQuery, GetFriendQueryVariables>(GetFriendDocument, baseOptions);
        }
export type GetFriendQueryHookResult = ReturnType<typeof useGetFriendQuery>;
export type GetFriendLazyQueryHookResult = ReturnType<typeof useGetFriendLazyQuery>;
export type GetFriendQueryResult = Apollo.QueryResult<GetFriendQuery, GetFriendQueryVariables>;
export const UpdateFriendDocument = gql`
    mutation updateFriend($friend_id: String!, $input: FriendInput!) {
  updateFriend(friend_id: $friend_id, input: $input)
}
    `;
export type UpdateFriendMutationFn = Apollo.MutationFunction<UpdateFriendMutation, UpdateFriendMutationVariables>;

/**
 * __useUpdateFriendMutation__
 *
 * To run a mutation, you first call `useUpdateFriendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFriendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFriendMutation, { data, loading, error }] = useUpdateFriendMutation({
 *   variables: {
 *      friend_id: // value for 'friend_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFriendMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFriendMutation, UpdateFriendMutationVariables>) {
        return Apollo.useMutation<UpdateFriendMutation, UpdateFriendMutationVariables>(UpdateFriendDocument, baseOptions);
      }
export type UpdateFriendMutationHookResult = ReturnType<typeof useUpdateFriendMutation>;
export type UpdateFriendMutationResult = Apollo.MutationResult<UpdateFriendMutation>;
export type UpdateFriendMutationOptions = Apollo.BaseMutationOptions<UpdateFriendMutation, UpdateFriendMutationVariables>;
export const GetCountFriendTagsDocument = gql`
    query getCountFriendTags {
  getCountFriendTags {
    data {
      name
      total
    }
  }
}
    `;

/**
 * __useGetCountFriendTagsQuery__
 *
 * To run a query within a React component, call `useGetCountFriendTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountFriendTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountFriendTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountFriendTagsQuery(baseOptions?: Apollo.QueryHookOptions<GetCountFriendTagsQuery, GetCountFriendTagsQueryVariables>) {
        return Apollo.useQuery<GetCountFriendTagsQuery, GetCountFriendTagsQueryVariables>(GetCountFriendTagsDocument, baseOptions);
      }
export function useGetCountFriendTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountFriendTagsQuery, GetCountFriendTagsQueryVariables>) {
          return Apollo.useLazyQuery<GetCountFriendTagsQuery, GetCountFriendTagsQueryVariables>(GetCountFriendTagsDocument, baseOptions);
        }
export type GetCountFriendTagsQueryHookResult = ReturnType<typeof useGetCountFriendTagsQuery>;
export type GetCountFriendTagsLazyQueryHookResult = ReturnType<typeof useGetCountFriendTagsLazyQuery>;
export type GetCountFriendTagsQueryResult = Apollo.QueryResult<GetCountFriendTagsQuery, GetCountFriendTagsQueryVariables>;
export const CountFriendDocument = gql`
    query countFriend($input: FriendSearchFilter) {
  countFriend(input: $input)
}
    `;

/**
 * __useCountFriendQuery__
 *
 * To run a query within a React component, call `useCountFriendQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountFriendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountFriendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCountFriendQuery(baseOptions?: Apollo.QueryHookOptions<CountFriendQuery, CountFriendQueryVariables>) {
        return Apollo.useQuery<CountFriendQuery, CountFriendQueryVariables>(CountFriendDocument, baseOptions);
      }
export function useCountFriendLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountFriendQuery, CountFriendQueryVariables>) {
          return Apollo.useLazyQuery<CountFriendQuery, CountFriendQueryVariables>(CountFriendDocument, baseOptions);
        }
export type CountFriendQueryHookResult = ReturnType<typeof useCountFriendQuery>;
export type CountFriendLazyQueryHookResult = ReturnType<typeof useCountFriendLazyQuery>;
export type CountFriendQueryResult = Apollo.QueryResult<CountFriendQuery, CountFriendQueryVariables>;
export const OnFriendChangedDocument = gql`
    subscription onFriendChanged {
  onFriendChanged {
    data {
      id
      full_name
      status
      contact
      tags
      picture_url
      platform
      channel_id
      social_id
      rating
      about
    }
  }
}
    `;

/**
 * __useOnFriendChangedSubscription__
 *
 * To run a query within a React component, call `useOnFriendChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnFriendChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnFriendChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnFriendChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnFriendChangedSubscription, OnFriendChangedSubscriptionVariables>) {
        return Apollo.useSubscription<OnFriendChangedSubscription, OnFriendChangedSubscriptionVariables>(OnFriendChangedDocument, baseOptions);
      }
export type OnFriendChangedSubscriptionHookResult = ReturnType<typeof useOnFriendChangedSubscription>;
export type OnFriendChangedSubscriptionResult = Apollo.SubscriptionResult<OnFriendChangedSubscription>;
export const SearchIntentDocument = gql`
    query searchIntent($input: IntentSearchInput) {
  searchIntent(input: $input) {
    total
    next
    data {
      id
      lang
      type
      title
      published
      payload_type
      payload
    }
  }
}
    `;

/**
 * __useSearchIntentQuery__
 *
 * To run a query within a React component, call `useSearchIntentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchIntentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchIntentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchIntentQuery(baseOptions?: Apollo.QueryHookOptions<SearchIntentQuery, SearchIntentQueryVariables>) {
        return Apollo.useQuery<SearchIntentQuery, SearchIntentQueryVariables>(SearchIntentDocument, baseOptions);
      }
export function useSearchIntentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchIntentQuery, SearchIntentQueryVariables>) {
          return Apollo.useLazyQuery<SearchIntentQuery, SearchIntentQueryVariables>(SearchIntentDocument, baseOptions);
        }
export type SearchIntentQueryHookResult = ReturnType<typeof useSearchIntentQuery>;
export type SearchIntentLazyQueryHookResult = ReturnType<typeof useSearchIntentLazyQuery>;
export type SearchIntentQueryResult = Apollo.QueryResult<SearchIntentQuery, SearchIntentQueryVariables>;
export const CreateIntentDocument = gql`
    mutation createIntent($input: IntentInput!) {
  createIntent(input: $input) {
    id
    title
    context
  }
}
    `;
export type CreateIntentMutationFn = Apollo.MutationFunction<CreateIntentMutation, CreateIntentMutationVariables>;

/**
 * __useCreateIntentMutation__
 *
 * To run a mutation, you first call `useCreateIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIntentMutation, { data, loading, error }] = useCreateIntentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIntentMutation(baseOptions?: Apollo.MutationHookOptions<CreateIntentMutation, CreateIntentMutationVariables>) {
        return Apollo.useMutation<CreateIntentMutation, CreateIntentMutationVariables>(CreateIntentDocument, baseOptions);
      }
export type CreateIntentMutationHookResult = ReturnType<typeof useCreateIntentMutation>;
export type CreateIntentMutationResult = Apollo.MutationResult<CreateIntentMutation>;
export type CreateIntentMutationOptions = Apollo.BaseMutationOptions<CreateIntentMutation, CreateIntentMutationVariables>;
export const GetIntentDocument = gql`
    query getIntent($id: String!) {
  getIntent(id: $id) {
    id
    lang
    type
    title
    published
    is_hidden_comment
    is_delete_comment
    sentences {
      id
      text
    }
    answers {
      id
      type
      text
      payload
    }
    payload_type
    payload
    context
    answer_fbbot {
      id
      intent_id
      payload
    }
  }
}
    `;

/**
 * __useGetIntentQuery__
 *
 * To run a query within a React component, call `useGetIntentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetIntentQuery(baseOptions: Apollo.QueryHookOptions<GetIntentQuery, GetIntentQueryVariables>) {
        return Apollo.useQuery<GetIntentQuery, GetIntentQueryVariables>(GetIntentDocument, baseOptions);
      }
export function useGetIntentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIntentQuery, GetIntentQueryVariables>) {
          return Apollo.useLazyQuery<GetIntentQuery, GetIntentQueryVariables>(GetIntentDocument, baseOptions);
        }
export type GetIntentQueryHookResult = ReturnType<typeof useGetIntentQuery>;
export type GetIntentLazyQueryHookResult = ReturnType<typeof useGetIntentLazyQuery>;
export type GetIntentQueryResult = Apollo.QueryResult<GetIntentQuery, GetIntentQueryVariables>;
export const UpdateIntentDocument = gql`
    mutation updateIntent($id: String!, $input: IntentInput!, $old: IntentInput!) {
  updateIntent(id: $id, input: $input, old: $old) {
    id
  }
}
    `;
export type UpdateIntentMutationFn = Apollo.MutationFunction<UpdateIntentMutation, UpdateIntentMutationVariables>;

/**
 * __useUpdateIntentMutation__
 *
 * To run a mutation, you first call `useUpdateIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIntentMutation, { data, loading, error }] = useUpdateIntentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      old: // value for 'old'
 *   },
 * });
 */
export function useUpdateIntentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIntentMutation, UpdateIntentMutationVariables>) {
        return Apollo.useMutation<UpdateIntentMutation, UpdateIntentMutationVariables>(UpdateIntentDocument, baseOptions);
      }
export type UpdateIntentMutationHookResult = ReturnType<typeof useUpdateIntentMutation>;
export type UpdateIntentMutationResult = Apollo.MutationResult<UpdateIntentMutation>;
export type UpdateIntentMutationOptions = Apollo.BaseMutationOptions<UpdateIntentMutation, UpdateIntentMutationVariables>;
export const RemoveIntentDocument = gql`
    mutation removeIntent($id: String!) {
  removeIntent(id: $id) {
    id
  }
}
    `;
export type RemoveIntentMutationFn = Apollo.MutationFunction<RemoveIntentMutation, RemoveIntentMutationVariables>;

/**
 * __useRemoveIntentMutation__
 *
 * To run a mutation, you first call `useRemoveIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeIntentMutation, { data, loading, error }] = useRemoveIntentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveIntentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveIntentMutation, RemoveIntentMutationVariables>) {
        return Apollo.useMutation<RemoveIntentMutation, RemoveIntentMutationVariables>(RemoveIntentDocument, baseOptions);
      }
export type RemoveIntentMutationHookResult = ReturnType<typeof useRemoveIntentMutation>;
export type RemoveIntentMutationResult = Apollo.MutationResult<RemoveIntentMutation>;
export type RemoveIntentMutationOptions = Apollo.BaseMutationOptions<RemoveIntentMutation, RemoveIntentMutationVariables>;
export const OnIntentChangedDocument = gql`
    subscription onIntentChanged {
  onIntentChanged {
    operation
    data {
      id
      lang
      type
      title
      published
    }
    old {
      id
      lang
      type
      title
      published
    }
  }
}
    `;

/**
 * __useOnIntentChangedSubscription__
 *
 * To run a query within a React component, call `useOnIntentChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnIntentChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnIntentChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnIntentChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnIntentChangedSubscription, OnIntentChangedSubscriptionVariables>) {
        return Apollo.useSubscription<OnIntentChangedSubscription, OnIntentChangedSubscriptionVariables>(OnIntentChangedDocument, baseOptions);
      }
export type OnIntentChangedSubscriptionHookResult = ReturnType<typeof useOnIntentChangedSubscription>;
export type OnIntentChangedSubscriptionResult = Apollo.SubscriptionResult<OnIntentChangedSubscription>;
export const SearchItemDocument = gql`
    query searchItem($input: ItemSearchInput!) {
  searchItem(input: $input) {
    total
    next
    data {
      ...itemFields
    }
  }
}
    ${ItemFieldsFragmentDoc}`;

/**
 * __useSearchItemQuery__
 *
 * To run a query within a React component, call `useSearchItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchItemQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchItemQuery(baseOptions: Apollo.QueryHookOptions<SearchItemQuery, SearchItemQueryVariables>) {
        return Apollo.useQuery<SearchItemQuery, SearchItemQueryVariables>(SearchItemDocument, baseOptions);
      }
export function useSearchItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchItemQuery, SearchItemQueryVariables>) {
          return Apollo.useLazyQuery<SearchItemQuery, SearchItemQueryVariables>(SearchItemDocument, baseOptions);
        }
export type SearchItemQueryHookResult = ReturnType<typeof useSearchItemQuery>;
export type SearchItemLazyQueryHookResult = ReturnType<typeof useSearchItemLazyQuery>;
export type SearchItemQueryResult = Apollo.QueryResult<SearchItemQuery, SearchItemQueryVariables>;
export const OnItemChangedDocument = gql`
    subscription onItemChanged($filters: ItemSubscriptionFilter) {
  onItemChanged(filters: $filters) {
    operation
    data {
      ...itemFields
    }
  }
}
    ${ItemFieldsFragmentDoc}`;

/**
 * __useOnItemChangedSubscription__
 *
 * To run a query within a React component, call `useOnItemChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnItemChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnItemChangedSubscription({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useOnItemChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnItemChangedSubscription, OnItemChangedSubscriptionVariables>) {
        return Apollo.useSubscription<OnItemChangedSubscription, OnItemChangedSubscriptionVariables>(OnItemChangedDocument, baseOptions);
      }
export type OnItemChangedSubscriptionHookResult = ReturnType<typeof useOnItemChangedSubscription>;
export type OnItemChangedSubscriptionResult = Apollo.SubscriptionResult<OnItemChangedSubscription>;
export const FetchDigramDocument = gql`
    query fetchDigram($input: KeywordSearchInput) {
  searchKeyword(input: $input) {
    data {
      id
      title
      type
    }
  }
  searchKeywordLink {
    data {
      id
      from
      to
    }
  }
}
    `;

/**
 * __useFetchDigramQuery__
 *
 * To run a query within a React component, call `useFetchDigramQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDigramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDigramQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchDigramQuery(baseOptions?: Apollo.QueryHookOptions<FetchDigramQuery, FetchDigramQueryVariables>) {
        return Apollo.useQuery<FetchDigramQuery, FetchDigramQueryVariables>(FetchDigramDocument, baseOptions);
      }
export function useFetchDigramLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchDigramQuery, FetchDigramQueryVariables>) {
          return Apollo.useLazyQuery<FetchDigramQuery, FetchDigramQueryVariables>(FetchDigramDocument, baseOptions);
        }
export type FetchDigramQueryHookResult = ReturnType<typeof useFetchDigramQuery>;
export type FetchDigramLazyQueryHookResult = ReturnType<typeof useFetchDigramLazyQuery>;
export type FetchDigramQueryResult = Apollo.QueryResult<FetchDigramQuery, FetchDigramQueryVariables>;
export const GetKeywordDocument = gql`
    query getKeyword($keywordId: String!) {
  getKeyword(keyword_id: $keywordId) {
    id
    title
    type
    sentences {
      type
      text
    }
    action {
      id
      facebook_messages
      line_messages
    }
    published
    is_linenotify
    linenotify_payload
    is_autotag
    autotags
    is_api
    api_payload
    keywords
    plugins {
      id
      type
      body
      enabled
    }
  }
}
    `;

/**
 * __useGetKeywordQuery__
 *
 * To run a query within a React component, call `useGetKeywordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKeywordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKeywordQuery({
 *   variables: {
 *      keywordId: // value for 'keywordId'
 *   },
 * });
 */
export function useGetKeywordQuery(baseOptions: Apollo.QueryHookOptions<GetKeywordQuery, GetKeywordQueryVariables>) {
        return Apollo.useQuery<GetKeywordQuery, GetKeywordQueryVariables>(GetKeywordDocument, baseOptions);
      }
export function useGetKeywordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKeywordQuery, GetKeywordQueryVariables>) {
          return Apollo.useLazyQuery<GetKeywordQuery, GetKeywordQueryVariables>(GetKeywordDocument, baseOptions);
        }
export type GetKeywordQueryHookResult = ReturnType<typeof useGetKeywordQuery>;
export type GetKeywordLazyQueryHookResult = ReturnType<typeof useGetKeywordLazyQuery>;
export type GetKeywordQueryResult = Apollo.QueryResult<GetKeywordQuery, GetKeywordQueryVariables>;
export const CreateKeywordDocument = gql`
    mutation createKeyword($input: KeywordInput!) {
  createKeyword(input: $input) {
    id
  }
}
    `;
export type CreateKeywordMutationFn = Apollo.MutationFunction<CreateKeywordMutation, CreateKeywordMutationVariables>;

/**
 * __useCreateKeywordMutation__
 *
 * To run a mutation, you first call `useCreateKeywordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKeywordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKeywordMutation, { data, loading, error }] = useCreateKeywordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateKeywordMutation(baseOptions?: Apollo.MutationHookOptions<CreateKeywordMutation, CreateKeywordMutationVariables>) {
        return Apollo.useMutation<CreateKeywordMutation, CreateKeywordMutationVariables>(CreateKeywordDocument, baseOptions);
      }
export type CreateKeywordMutationHookResult = ReturnType<typeof useCreateKeywordMutation>;
export type CreateKeywordMutationResult = Apollo.MutationResult<CreateKeywordMutation>;
export type CreateKeywordMutationOptions = Apollo.BaseMutationOptions<CreateKeywordMutation, CreateKeywordMutationVariables>;
export const UpdateKeywordDocument = gql`
    mutation updateKeyword($keywordId: String!, $input: KeywordUpdate!) {
  updateKeyword(keyword_id: $keywordId, input: $input) {
    id
  }
}
    `;
export type UpdateKeywordMutationFn = Apollo.MutationFunction<UpdateKeywordMutation, UpdateKeywordMutationVariables>;

/**
 * __useUpdateKeywordMutation__
 *
 * To run a mutation, you first call `useUpdateKeywordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKeywordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKeywordMutation, { data, loading, error }] = useUpdateKeywordMutation({
 *   variables: {
 *      keywordId: // value for 'keywordId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateKeywordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateKeywordMutation, UpdateKeywordMutationVariables>) {
        return Apollo.useMutation<UpdateKeywordMutation, UpdateKeywordMutationVariables>(UpdateKeywordDocument, baseOptions);
      }
export type UpdateKeywordMutationHookResult = ReturnType<typeof useUpdateKeywordMutation>;
export type UpdateKeywordMutationResult = Apollo.MutationResult<UpdateKeywordMutation>;
export type UpdateKeywordMutationOptions = Apollo.BaseMutationOptions<UpdateKeywordMutation, UpdateKeywordMutationVariables>;
export const RemoveKeywordDocument = gql`
    mutation removeKeyword($id: String!) {
  removeKeyword(id: $id) {
    id
    success
    error_message
  }
}
    `;
export type RemoveKeywordMutationFn = Apollo.MutationFunction<RemoveKeywordMutation, RemoveKeywordMutationVariables>;

/**
 * __useRemoveKeywordMutation__
 *
 * To run a mutation, you first call `useRemoveKeywordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveKeywordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeKeywordMutation, { data, loading, error }] = useRemoveKeywordMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveKeywordMutation(baseOptions?: Apollo.MutationHookOptions<RemoveKeywordMutation, RemoveKeywordMutationVariables>) {
        return Apollo.useMutation<RemoveKeywordMutation, RemoveKeywordMutationVariables>(RemoveKeywordDocument, baseOptions);
      }
export type RemoveKeywordMutationHookResult = ReturnType<typeof useRemoveKeywordMutation>;
export type RemoveKeywordMutationResult = Apollo.MutationResult<RemoveKeywordMutation>;
export type RemoveKeywordMutationOptions = Apollo.BaseMutationOptions<RemoveKeywordMutation, RemoveKeywordMutationVariables>;
export const OnKeywordChangedDocument = gql`
    subscription onKeywordChanged {
  onKeywordChanged {
    operation
    data {
      id
      title
      type
    }
  }
}
    `;

/**
 * __useOnKeywordChangedSubscription__
 *
 * To run a query within a React component, call `useOnKeywordChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnKeywordChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnKeywordChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnKeywordChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnKeywordChangedSubscription, OnKeywordChangedSubscriptionVariables>) {
        return Apollo.useSubscription<OnKeywordChangedSubscription, OnKeywordChangedSubscriptionVariables>(OnKeywordChangedDocument, baseOptions);
      }
export type OnKeywordChangedSubscriptionHookResult = ReturnType<typeof useOnKeywordChangedSubscription>;
export type OnKeywordChangedSubscriptionResult = Apollo.SubscriptionResult<OnKeywordChangedSubscription>;
export const CreateKeywordLinkDocument = gql`
    mutation createKeywordLink($from_id: String!, $to_id: String!) {
  createKeywordLink(from_id: $from_id, to_id: $to_id) {
    id
    from
    to
  }
}
    `;
export type CreateKeywordLinkMutationFn = Apollo.MutationFunction<CreateKeywordLinkMutation, CreateKeywordLinkMutationVariables>;

/**
 * __useCreateKeywordLinkMutation__
 *
 * To run a mutation, you first call `useCreateKeywordLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKeywordLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKeywordLinkMutation, { data, loading, error }] = useCreateKeywordLinkMutation({
 *   variables: {
 *      from_id: // value for 'from_id'
 *      to_id: // value for 'to_id'
 *   },
 * });
 */
export function useCreateKeywordLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreateKeywordLinkMutation, CreateKeywordLinkMutationVariables>) {
        return Apollo.useMutation<CreateKeywordLinkMutation, CreateKeywordLinkMutationVariables>(CreateKeywordLinkDocument, baseOptions);
      }
export type CreateKeywordLinkMutationHookResult = ReturnType<typeof useCreateKeywordLinkMutation>;
export type CreateKeywordLinkMutationResult = Apollo.MutationResult<CreateKeywordLinkMutation>;
export type CreateKeywordLinkMutationOptions = Apollo.BaseMutationOptions<CreateKeywordLinkMutation, CreateKeywordLinkMutationVariables>;
export const RemoveKeywordLinkDocument = gql`
    mutation removeKeywordLink($id: String!) {
  removeKeywordLink(id: $id) {
    id
    success
    error_message
  }
}
    `;
export type RemoveKeywordLinkMutationFn = Apollo.MutationFunction<RemoveKeywordLinkMutation, RemoveKeywordLinkMutationVariables>;

/**
 * __useRemoveKeywordLinkMutation__
 *
 * To run a mutation, you first call `useRemoveKeywordLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveKeywordLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeKeywordLinkMutation, { data, loading, error }] = useRemoveKeywordLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveKeywordLinkMutation(baseOptions?: Apollo.MutationHookOptions<RemoveKeywordLinkMutation, RemoveKeywordLinkMutationVariables>) {
        return Apollo.useMutation<RemoveKeywordLinkMutation, RemoveKeywordLinkMutationVariables>(RemoveKeywordLinkDocument, baseOptions);
      }
export type RemoveKeywordLinkMutationHookResult = ReturnType<typeof useRemoveKeywordLinkMutation>;
export type RemoveKeywordLinkMutationResult = Apollo.MutationResult<RemoveKeywordLinkMutation>;
export type RemoveKeywordLinkMutationOptions = Apollo.BaseMutationOptions<RemoveKeywordLinkMutation, RemoveKeywordLinkMutationVariables>;
export const OnKeywordLinkChangedDocument = gql`
    subscription onKeywordLinkChanged {
  onKeywordLinkChanged {
    operation
    data {
      id
      from
      to
    }
  }
}
    `;

/**
 * __useOnKeywordLinkChangedSubscription__
 *
 * To run a query within a React component, call `useOnKeywordLinkChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnKeywordLinkChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnKeywordLinkChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnKeywordLinkChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnKeywordLinkChangedSubscription, OnKeywordLinkChangedSubscriptionVariables>) {
        return Apollo.useSubscription<OnKeywordLinkChangedSubscription, OnKeywordLinkChangedSubscriptionVariables>(OnKeywordLinkChangedDocument, baseOptions);
      }
export type OnKeywordLinkChangedSubscriptionHookResult = ReturnType<typeof useOnKeywordLinkChangedSubscription>;
export type OnKeywordLinkChangedSubscriptionResult = Apollo.SubscriptionResult<OnKeywordLinkChangedSubscription>;
export const SearchLiveDocument = gql`
    query searchLive {
  searchLive {
    total
    data {
      id
      post_id
      title
      picture_url
    }
  }
}
    `;

/**
 * __useSearchLiveQuery__
 *
 * To run a query within a React component, call `useSearchLiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchLiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchLiveQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchLiveQuery(baseOptions?: Apollo.QueryHookOptions<SearchLiveQuery, SearchLiveQueryVariables>) {
        return Apollo.useQuery<SearchLiveQuery, SearchLiveQueryVariables>(SearchLiveDocument, baseOptions);
      }
export function useSearchLiveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchLiveQuery, SearchLiveQueryVariables>) {
          return Apollo.useLazyQuery<SearchLiveQuery, SearchLiveQueryVariables>(SearchLiveDocument, baseOptions);
        }
export type SearchLiveQueryHookResult = ReturnType<typeof useSearchLiveQuery>;
export type SearchLiveLazyQueryHookResult = ReturnType<typeof useSearchLiveLazyQuery>;
export type SearchLiveQueryResult = Apollo.QueryResult<SearchLiveQuery, SearchLiveQueryVariables>;
export const CreateLiveDocument = gql`
    mutation createLive($input: LiveInput!) {
  createLive(input: $input) {
    id
    post_id
    title
    picture_url
  }
}
    `;
export type CreateLiveMutationFn = Apollo.MutationFunction<CreateLiveMutation, CreateLiveMutationVariables>;

/**
 * __useCreateLiveMutation__
 *
 * To run a mutation, you first call `useCreateLiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLiveMutation, { data, loading, error }] = useCreateLiveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLiveMutation(baseOptions?: Apollo.MutationHookOptions<CreateLiveMutation, CreateLiveMutationVariables>) {
        return Apollo.useMutation<CreateLiveMutation, CreateLiveMutationVariables>(CreateLiveDocument, baseOptions);
      }
export type CreateLiveMutationHookResult = ReturnType<typeof useCreateLiveMutation>;
export type CreateLiveMutationResult = Apollo.MutationResult<CreateLiveMutation>;
export type CreateLiveMutationOptions = Apollo.BaseMutationOptions<CreateLiveMutation, CreateLiveMutationVariables>;
export const GetLiveDocument = gql`
    query getLive($id: String!) {
  getLive(id: $id) {
    id
    post_id
    title
    picture_url
  }
}
    `;

/**
 * __useGetLiveQuery__
 *
 * To run a query within a React component, call `useGetLiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiveQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLiveQuery(baseOptions: Apollo.QueryHookOptions<GetLiveQuery, GetLiveQueryVariables>) {
        return Apollo.useQuery<GetLiveQuery, GetLiveQueryVariables>(GetLiveDocument, baseOptions);
      }
export function useGetLiveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLiveQuery, GetLiveQueryVariables>) {
          return Apollo.useLazyQuery<GetLiveQuery, GetLiveQueryVariables>(GetLiveDocument, baseOptions);
        }
export type GetLiveQueryHookResult = ReturnType<typeof useGetLiveQuery>;
export type GetLiveLazyQueryHookResult = ReturnType<typeof useGetLiveLazyQuery>;
export type GetLiveQueryResult = Apollo.QueryResult<GetLiveQuery, GetLiveQueryVariables>;
export const UpdateLiveDocument = gql`
    mutation updateLive($id: String!, $input: LiveUpdate!) {
  updateLive(id: $id, input: $input) {
    id
  }
}
    `;
export type UpdateLiveMutationFn = Apollo.MutationFunction<UpdateLiveMutation, UpdateLiveMutationVariables>;

/**
 * __useUpdateLiveMutation__
 *
 * To run a mutation, you first call `useUpdateLiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLiveMutation, { data, loading, error }] = useUpdateLiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLiveMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLiveMutation, UpdateLiveMutationVariables>) {
        return Apollo.useMutation<UpdateLiveMutation, UpdateLiveMutationVariables>(UpdateLiveDocument, baseOptions);
      }
export type UpdateLiveMutationHookResult = ReturnType<typeof useUpdateLiveMutation>;
export type UpdateLiveMutationResult = Apollo.MutationResult<UpdateLiveMutation>;
export type UpdateLiveMutationOptions = Apollo.BaseMutationOptions<UpdateLiveMutation, UpdateLiveMutationVariables>;
export const RemoveLiveDocument = gql`
    mutation removeLive($id: String!) {
  removeLive(id: $id) {
    id
  }
}
    `;
export type RemoveLiveMutationFn = Apollo.MutationFunction<RemoveLiveMutation, RemoveLiveMutationVariables>;

/**
 * __useRemoveLiveMutation__
 *
 * To run a mutation, you first call `useRemoveLiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLiveMutation, { data, loading, error }] = useRemoveLiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveLiveMutation(baseOptions?: Apollo.MutationHookOptions<RemoveLiveMutation, RemoveLiveMutationVariables>) {
        return Apollo.useMutation<RemoveLiveMutation, RemoveLiveMutationVariables>(RemoveLiveDocument, baseOptions);
      }
export type RemoveLiveMutationHookResult = ReturnType<typeof useRemoveLiveMutation>;
export type RemoveLiveMutationResult = Apollo.MutationResult<RemoveLiveMutation>;
export type RemoveLiveMutationOptions = Apollo.BaseMutationOptions<RemoveLiveMutation, RemoveLiveMutationVariables>;
export const OnLiveChangedDocument = gql`
    subscription onLiveChanged {
  onLiveChanged {
    operation
    data {
      id
      post_id
      title
    }
  }
}
    `;

/**
 * __useOnLiveChangedSubscription__
 *
 * To run a query within a React component, call `useOnLiveChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnLiveChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnLiveChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnLiveChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnLiveChangedSubscription, OnLiveChangedSubscriptionVariables>) {
        return Apollo.useSubscription<OnLiveChangedSubscription, OnLiveChangedSubscriptionVariables>(OnLiveChangedDocument, baseOptions);
      }
export type OnLiveChangedSubscriptionHookResult = ReturnType<typeof useOnLiveChangedSubscription>;
export type OnLiveChangedSubscriptionResult = Apollo.SubscriptionResult<OnLiveChangedSubscription>;
export const ImportLiveProductDocument = gql`
    mutation importLiveProduct($id: String!, $input: ImportLiveProductInput!) {
  importLiveProduct(id: $id, input: $input)
}
    `;
export type ImportLiveProductMutationFn = Apollo.MutationFunction<ImportLiveProductMutation, ImportLiveProductMutationVariables>;

/**
 * __useImportLiveProductMutation__
 *
 * To run a mutation, you first call `useImportLiveProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportLiveProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importLiveProductMutation, { data, loading, error }] = useImportLiveProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportLiveProductMutation(baseOptions?: Apollo.MutationHookOptions<ImportLiveProductMutation, ImportLiveProductMutationVariables>) {
        return Apollo.useMutation<ImportLiveProductMutation, ImportLiveProductMutationVariables>(ImportLiveProductDocument, baseOptions);
      }
export type ImportLiveProductMutationHookResult = ReturnType<typeof useImportLiveProductMutation>;
export type ImportLiveProductMutationResult = Apollo.MutationResult<ImportLiveProductMutation>;
export type ImportLiveProductMutationOptions = Apollo.BaseMutationOptions<ImportLiveProductMutation, ImportLiveProductMutationVariables>;
export const RemoveLiveProductDocument = gql`
    mutation removeLiveProduct($productId: String!) {
  removeLiveProduct(product_id: $productId) {
    id
  }
}
    `;
export type RemoveLiveProductMutationFn = Apollo.MutationFunction<RemoveLiveProductMutation, RemoveLiveProductMutationVariables>;

/**
 * __useRemoveLiveProductMutation__
 *
 * To run a mutation, you first call `useRemoveLiveProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLiveProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLiveProductMutation, { data, loading, error }] = useRemoveLiveProductMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useRemoveLiveProductMutation(baseOptions?: Apollo.MutationHookOptions<RemoveLiveProductMutation, RemoveLiveProductMutationVariables>) {
        return Apollo.useMutation<RemoveLiveProductMutation, RemoveLiveProductMutationVariables>(RemoveLiveProductDocument, baseOptions);
      }
export type RemoveLiveProductMutationHookResult = ReturnType<typeof useRemoveLiveProductMutation>;
export type RemoveLiveProductMutationResult = Apollo.MutationResult<RemoveLiveProductMutation>;
export type RemoveLiveProductMutationOptions = Apollo.BaseMutationOptions<RemoveLiveProductMutation, RemoveLiveProductMutationVariables>;
export const GetLiveStatsDocument = gql`
    query getLiveStats($liveId: String!) {
  getLiveStats(id: $liveId) {
    total_reserved_qty
    total_reserved_value
  }
}
    `;

/**
 * __useGetLiveStatsQuery__
 *
 * To run a query within a React component, call `useGetLiveStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiveStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiveStatsQuery({
 *   variables: {
 *      liveId: // value for 'liveId'
 *   },
 * });
 */
export function useGetLiveStatsQuery(baseOptions: Apollo.QueryHookOptions<GetLiveStatsQuery, GetLiveStatsQueryVariables>) {
        return Apollo.useQuery<GetLiveStatsQuery, GetLiveStatsQueryVariables>(GetLiveStatsDocument, baseOptions);
      }
export function useGetLiveStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLiveStatsQuery, GetLiveStatsQueryVariables>) {
          return Apollo.useLazyQuery<GetLiveStatsQuery, GetLiveStatsQueryVariables>(GetLiveStatsDocument, baseOptions);
        }
export type GetLiveStatsQueryHookResult = ReturnType<typeof useGetLiveStatsQuery>;
export type GetLiveStatsLazyQueryHookResult = ReturnType<typeof useGetLiveStatsLazyQuery>;
export type GetLiveStatsQueryResult = Apollo.QueryResult<GetLiveStatsQuery, GetLiveStatsQueryVariables>;
export const GetLiveContextDocument = gql`
    query getLiveContext($id: String!, $liveId: String!) {
  getLive(id: $liveId) {
    id
    post_id
    title
    picture_url
  }
  getBot(id: $id) {
    id
    title
    picture_url
    business_type
    modules
    friend_count
    friend_limit
    sku
    subscription_cost
    gender
    address
    invoice_address
    contact_number
    email
    created_by
    linenotify_token
    source
    version
  }
  searchChannel {
    data {
      id
      title
      platform
      access_token
      channel_secret
      picture_url
      basic_id
      enabled
    }
  }
  searchProduct(input: {filters: {live_id: $liveId}}) {
    next
    data {
      id
      sku
      title
      category
      full_price
      sell_description
      sell_price
      price_per_year
      price_per_month
      type
      picture_url
      picture_urls
      stock_qty
      keywords
      live_status
    }
  }
  searchBankAccount {
    total
    next
    data {
      id
      account_id
      account_type
      account_name
      bank
    }
  }
  searchShipping {
    total
    next
    data {
      id
      title
      vendor
      description
      rate
      rate_type
    }
  }
  searchItem(input: {filters: {medium_id: $liveId}}) {
    total
    next
    data {
      ...itemFields
    }
  }
  searchFbComment(input: {filters: {medium_id: $liveId}}) {
    total
    next
    data {
      ...fbCommentFields
    }
  }
}
    ${ItemFieldsFragmentDoc}
${FbCommentFieldsFragmentDoc}`;

/**
 * __useGetLiveContextQuery__
 *
 * To run a query within a React component, call `useGetLiveContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiveContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiveContextQuery({
 *   variables: {
 *      id: // value for 'id'
 *      liveId: // value for 'liveId'
 *   },
 * });
 */
export function useGetLiveContextQuery(baseOptions: Apollo.QueryHookOptions<GetLiveContextQuery, GetLiveContextQueryVariables>) {
        return Apollo.useQuery<GetLiveContextQuery, GetLiveContextQueryVariables>(GetLiveContextDocument, baseOptions);
      }
export function useGetLiveContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLiveContextQuery, GetLiveContextQueryVariables>) {
          return Apollo.useLazyQuery<GetLiveContextQuery, GetLiveContextQueryVariables>(GetLiveContextDocument, baseOptions);
        }
export type GetLiveContextQueryHookResult = ReturnType<typeof useGetLiveContextQuery>;
export type GetLiveContextLazyQueryHookResult = ReturnType<typeof useGetLiveContextLazyQuery>;
export type GetLiveContextQueryResult = Apollo.QueryResult<GetLiveContextQuery, GetLiveContextQueryVariables>;
export const RecreateLiveIntentDocument = gql`
    mutation recreateLiveIntent($liveId: String!) {
  recreateLiveIntent(live_id: $liveId)
}
    `;
export type RecreateLiveIntentMutationFn = Apollo.MutationFunction<RecreateLiveIntentMutation, RecreateLiveIntentMutationVariables>;

/**
 * __useRecreateLiveIntentMutation__
 *
 * To run a mutation, you first call `useRecreateLiveIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecreateLiveIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recreateLiveIntentMutation, { data, loading, error }] = useRecreateLiveIntentMutation({
 *   variables: {
 *      liveId: // value for 'liveId'
 *   },
 * });
 */
export function useRecreateLiveIntentMutation(baseOptions?: Apollo.MutationHookOptions<RecreateLiveIntentMutation, RecreateLiveIntentMutationVariables>) {
        return Apollo.useMutation<RecreateLiveIntentMutation, RecreateLiveIntentMutationVariables>(RecreateLiveIntentDocument, baseOptions);
      }
export type RecreateLiveIntentMutationHookResult = ReturnType<typeof useRecreateLiveIntentMutation>;
export type RecreateLiveIntentMutationResult = Apollo.MutationResult<RecreateLiveIntentMutation>;
export type RecreateLiveIntentMutationOptions = Apollo.BaseMutationOptions<RecreateLiveIntentMutation, RecreateLiveIntentMutationVariables>;
export const SearchAppointmentDocument = gql`
    query searchAppointment {
  searchSimpleBookings {
    data {
      id
      status
      title
      schedule
      user_id
      contact
      location
    }
  }
}
    `;

/**
 * __useSearchAppointmentQuery__
 *
 * To run a query within a React component, call `useSearchAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAppointmentQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchAppointmentQuery(baseOptions?: Apollo.QueryHookOptions<SearchAppointmentQuery, SearchAppointmentQueryVariables>) {
        return Apollo.useQuery<SearchAppointmentQuery, SearchAppointmentQueryVariables>(SearchAppointmentDocument, baseOptions);
      }
export function useSearchAppointmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchAppointmentQuery, SearchAppointmentQueryVariables>) {
          return Apollo.useLazyQuery<SearchAppointmentQuery, SearchAppointmentQueryVariables>(SearchAppointmentDocument, baseOptions);
        }
export type SearchAppointmentQueryHookResult = ReturnType<typeof useSearchAppointmentQuery>;
export type SearchAppointmentLazyQueryHookResult = ReturnType<typeof useSearchAppointmentLazyQuery>;
export type SearchAppointmentQueryResult = Apollo.QueryResult<SearchAppointmentQuery, SearchAppointmentQueryVariables>;
export const RemoveBookingDocument = gql`
    mutation removeBooking($bookingId: String!) {
  removeBooking(booking_id: $bookingId) {
    id
  }
}
    `;
export type RemoveBookingMutationFn = Apollo.MutationFunction<RemoveBookingMutation, RemoveBookingMutationVariables>;

/**
 * __useRemoveBookingMutation__
 *
 * To run a mutation, you first call `useRemoveBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBookingMutation, { data, loading, error }] = useRemoveBookingMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useRemoveBookingMutation(baseOptions?: Apollo.MutationHookOptions<RemoveBookingMutation, RemoveBookingMutationVariables>) {
        return Apollo.useMutation<RemoveBookingMutation, RemoveBookingMutationVariables>(RemoveBookingDocument, baseOptions);
      }
export type RemoveBookingMutationHookResult = ReturnType<typeof useRemoveBookingMutation>;
export type RemoveBookingMutationResult = Apollo.MutationResult<RemoveBookingMutation>;
export type RemoveBookingMutationOptions = Apollo.BaseMutationOptions<RemoveBookingMutation, RemoveBookingMutationVariables>;
export const ConfirmBookingDocument = gql`
    mutation confirmBooking($bookingId: String!, $newSchedule: String) {
  confirmBooking(booking_id: $bookingId, new_schedule: $newSchedule) {
    id
    title
    status
    title
    schedule
    user_id
    contact
    location
  }
}
    `;
export type ConfirmBookingMutationFn = Apollo.MutationFunction<ConfirmBookingMutation, ConfirmBookingMutationVariables>;

/**
 * __useConfirmBookingMutation__
 *
 * To run a mutation, you first call `useConfirmBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmBookingMutation, { data, loading, error }] = useConfirmBookingMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      newSchedule: // value for 'newSchedule'
 *   },
 * });
 */
export function useConfirmBookingMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmBookingMutation, ConfirmBookingMutationVariables>) {
        return Apollo.useMutation<ConfirmBookingMutation, ConfirmBookingMutationVariables>(ConfirmBookingDocument, baseOptions);
      }
export type ConfirmBookingMutationHookResult = ReturnType<typeof useConfirmBookingMutation>;
export type ConfirmBookingMutationResult = Apollo.MutationResult<ConfirmBookingMutation>;
export type ConfirmBookingMutationOptions = Apollo.BaseMutationOptions<ConfirmBookingMutation, ConfirmBookingMutationVariables>;
export const OnBookingChangedDocument = gql`
    subscription onBookingChanged {
  onBookingChanged {
    operation
    data {
      id
      title
      status
      title
      schedule
      user_id
      contact
      location
    }
  }
}
    `;

/**
 * __useOnBookingChangedSubscription__
 *
 * To run a query within a React component, call `useOnBookingChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnBookingChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnBookingChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnBookingChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnBookingChangedSubscription, OnBookingChangedSubscriptionVariables>) {
        return Apollo.useSubscription<OnBookingChangedSubscription, OnBookingChangedSubscriptionVariables>(OnBookingChangedDocument, baseOptions);
      }
export type OnBookingChangedSubscriptionHookResult = ReturnType<typeof useOnBookingChangedSubscription>;
export type OnBookingChangedSubscriptionResult = Apollo.SubscriptionResult<OnBookingChangedSubscription>;
export const AuthorizeLineBotDocument = gql`
    mutation authorizeLineBot($code: String!, $redirectUri: String!) {
  authorizeLineBot(code: $code, redirectUri: $redirectUri) {
    userId
    basicId
    displayName
    pictureUrl
    premiumId
  }
}
    `;
export type AuthorizeLineBotMutationFn = Apollo.MutationFunction<AuthorizeLineBotMutation, AuthorizeLineBotMutationVariables>;

/**
 * __useAuthorizeLineBotMutation__
 *
 * To run a mutation, you first call `useAuthorizeLineBotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorizeLineBotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorizeLineBotMutation, { data, loading, error }] = useAuthorizeLineBotMutation({
 *   variables: {
 *      code: // value for 'code'
 *      redirectUri: // value for 'redirectUri'
 *   },
 * });
 */
export function useAuthorizeLineBotMutation(baseOptions?: Apollo.MutationHookOptions<AuthorizeLineBotMutation, AuthorizeLineBotMutationVariables>) {
        return Apollo.useMutation<AuthorizeLineBotMutation, AuthorizeLineBotMutationVariables>(AuthorizeLineBotDocument, baseOptions);
      }
export type AuthorizeLineBotMutationHookResult = ReturnType<typeof useAuthorizeLineBotMutation>;
export type AuthorizeLineBotMutationResult = Apollo.MutationResult<AuthorizeLineBotMutation>;
export type AuthorizeLineBotMutationOptions = Apollo.BaseMutationOptions<AuthorizeLineBotMutation, AuthorizeLineBotMutationVariables>;
export const GetPublicBotDocument = gql`
    query getPublicBot($botId: String!) {
  getPublicBot(id: $botId) {
    id
    title
    picture_url
  }
}
    `;

/**
 * __useGetPublicBotQuery__
 *
 * To run a query within a React component, call `useGetPublicBotQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicBotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicBotQuery({
 *   variables: {
 *      botId: // value for 'botId'
 *   },
 * });
 */
export function useGetPublicBotQuery(baseOptions: Apollo.QueryHookOptions<GetPublicBotQuery, GetPublicBotQueryVariables>) {
        return Apollo.useQuery<GetPublicBotQuery, GetPublicBotQueryVariables>(GetPublicBotDocument, baseOptions);
      }
export function useGetPublicBotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublicBotQuery, GetPublicBotQueryVariables>) {
          return Apollo.useLazyQuery<GetPublicBotQuery, GetPublicBotQueryVariables>(GetPublicBotDocument, baseOptions);
        }
export type GetPublicBotQueryHookResult = ReturnType<typeof useGetPublicBotQuery>;
export type GetPublicBotLazyQueryHookResult = ReturnType<typeof useGetPublicBotLazyQuery>;
export type GetPublicBotQueryResult = Apollo.QueryResult<GetPublicBotQuery, GetPublicBotQueryVariables>;
export const GetShopInfoPublicDocument = gql`
    query getShopInfoPublic($botId: String!) {
  getShopInfoPublic(bot_id: $botId) {
    title
    picture_url
    bank_accounts {
      account_no
      account_name
      enable
      bank
    }
    shippings {
      title
      company
      cost
    }
  }
}
    `;

/**
 * __useGetShopInfoPublicQuery__
 *
 * To run a query within a React component, call `useGetShopInfoPublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopInfoPublicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopInfoPublicQuery({
 *   variables: {
 *      botId: // value for 'botId'
 *   },
 * });
 */
export function useGetShopInfoPublicQuery(baseOptions: Apollo.QueryHookOptions<GetShopInfoPublicQuery, GetShopInfoPublicQueryVariables>) {
        return Apollo.useQuery<GetShopInfoPublicQuery, GetShopInfoPublicQueryVariables>(GetShopInfoPublicDocument, baseOptions);
      }
export function useGetShopInfoPublicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShopInfoPublicQuery, GetShopInfoPublicQueryVariables>) {
          return Apollo.useLazyQuery<GetShopInfoPublicQuery, GetShopInfoPublicQueryVariables>(GetShopInfoPublicDocument, baseOptions);
        }
export type GetShopInfoPublicQueryHookResult = ReturnType<typeof useGetShopInfoPublicQuery>;
export type GetShopInfoPublicLazyQueryHookResult = ReturnType<typeof useGetShopInfoPublicLazyQuery>;
export type GetShopInfoPublicQueryResult = Apollo.QueryResult<GetShopInfoPublicQuery, GetShopInfoPublicQueryVariables>;
export const SearchBotDocument = gql`
    query searchBot {
  searchBot {
    data {
      id
      title
      picture_url
      business_type
      profile {
        title
        open_hours
        contact
        address
      }
      sku
      friend_count
      friend_limit
      subscription {
        id
        bot_id
        sku
        title
        price
        status
        billing_period
        expired_in
        subscribed_by
      }
      modules
      role
    }
    next
    total
  }
}
    `;

/**
 * __useSearchBotQuery__
 *
 * To run a query within a React component, call `useSearchBotQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBotQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchBotQuery(baseOptions?: Apollo.QueryHookOptions<SearchBotQuery, SearchBotQueryVariables>) {
        return Apollo.useQuery<SearchBotQuery, SearchBotQueryVariables>(SearchBotDocument, baseOptions);
      }
export function useSearchBotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchBotQuery, SearchBotQueryVariables>) {
          return Apollo.useLazyQuery<SearchBotQuery, SearchBotQueryVariables>(SearchBotDocument, baseOptions);
        }
export type SearchBotQueryHookResult = ReturnType<typeof useSearchBotQuery>;
export type SearchBotLazyQueryHookResult = ReturnType<typeof useSearchBotLazyQuery>;
export type SearchBotQueryResult = Apollo.QueryResult<SearchBotQuery, SearchBotQueryVariables>;
export const AccountLoginDocument = gql`
    mutation accountLogin($input: LoginInput!) {
  login2(input: $input) {
    id
    access_token
  }
}
    `;
export type AccountLoginMutationFn = Apollo.MutationFunction<AccountLoginMutation, AccountLoginMutationVariables>;

/**
 * __useAccountLoginMutation__
 *
 * To run a mutation, you first call `useAccountLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountLoginMutation, { data, loading, error }] = useAccountLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccountLoginMutation(baseOptions?: Apollo.MutationHookOptions<AccountLoginMutation, AccountLoginMutationVariables>) {
        return Apollo.useMutation<AccountLoginMutation, AccountLoginMutationVariables>(AccountLoginDocument, baseOptions);
      }
export type AccountLoginMutationHookResult = ReturnType<typeof useAccountLoginMutation>;
export type AccountLoginMutationResult = Apollo.MutationResult<AccountLoginMutation>;
export type AccountLoginMutationOptions = Apollo.BaseMutationOptions<AccountLoginMutation, AccountLoginMutationVariables>;
export const GetBotAccessTokenDocument = gql`
    query getBotAccessToken($bot_id: String!) {
  getBotAccessToken(bot_id: $bot_id) {
    id
    access_token
  }
}
    `;

/**
 * __useGetBotAccessTokenQuery__
 *
 * To run a query within a React component, call `useGetBotAccessTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBotAccessTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBotAccessTokenQuery({
 *   variables: {
 *      bot_id: // value for 'bot_id'
 *   },
 * });
 */
export function useGetBotAccessTokenQuery(baseOptions: Apollo.QueryHookOptions<GetBotAccessTokenQuery, GetBotAccessTokenQueryVariables>) {
        return Apollo.useQuery<GetBotAccessTokenQuery, GetBotAccessTokenQueryVariables>(GetBotAccessTokenDocument, baseOptions);
      }
export function useGetBotAccessTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBotAccessTokenQuery, GetBotAccessTokenQueryVariables>) {
          return Apollo.useLazyQuery<GetBotAccessTokenQuery, GetBotAccessTokenQueryVariables>(GetBotAccessTokenDocument, baseOptions);
        }
export type GetBotAccessTokenQueryHookResult = ReturnType<typeof useGetBotAccessTokenQuery>;
export type GetBotAccessTokenLazyQueryHookResult = ReturnType<typeof useGetBotAccessTokenLazyQuery>;
export type GetBotAccessTokenQueryResult = Apollo.QueryResult<GetBotAccessTokenQuery, GetBotAccessTokenQueryVariables>;
export const RegisterAccountDocument = gql`
    mutation registerAccount($input: RegisterAccountDto!) {
  registerAccount(input: $input) {
    id
    access_token
  }
}
    `;
export type RegisterAccountMutationFn = Apollo.MutationFunction<RegisterAccountMutation, RegisterAccountMutationVariables>;

/**
 * __useRegisterAccountMutation__
 *
 * To run a mutation, you first call `useRegisterAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerAccountMutation, { data, loading, error }] = useRegisterAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterAccountMutation(baseOptions?: Apollo.MutationHookOptions<RegisterAccountMutation, RegisterAccountMutationVariables>) {
        return Apollo.useMutation<RegisterAccountMutation, RegisterAccountMutationVariables>(RegisterAccountDocument, baseOptions);
      }
export type RegisterAccountMutationHookResult = ReturnType<typeof useRegisterAccountMutation>;
export type RegisterAccountMutationResult = Apollo.MutationResult<RegisterAccountMutation>;
export type RegisterAccountMutationOptions = Apollo.BaseMutationOptions<RegisterAccountMutation, RegisterAccountMutationVariables>;
export const AllBotPackagesDocument = gql`
    query allBotPackages {
  searchBotPackage(input: {filters: {category: Premium}}) {
    data {
      id
      sku
      sell_description
      price_per_month
      price_per_year
      title
    }
  }
}
    `;

/**
 * __useAllBotPackagesQuery__
 *
 * To run a query within a React component, call `useAllBotPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllBotPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBotPackagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllBotPackagesQuery(baseOptions?: Apollo.QueryHookOptions<AllBotPackagesQuery, AllBotPackagesQueryVariables>) {
        return Apollo.useQuery<AllBotPackagesQuery, AllBotPackagesQueryVariables>(AllBotPackagesDocument, baseOptions);
      }
export function useAllBotPackagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllBotPackagesQuery, AllBotPackagesQueryVariables>) {
          return Apollo.useLazyQuery<AllBotPackagesQuery, AllBotPackagesQueryVariables>(AllBotPackagesDocument, baseOptions);
        }
export type AllBotPackagesQueryHookResult = ReturnType<typeof useAllBotPackagesQuery>;
export type AllBotPackagesLazyQueryHookResult = ReturnType<typeof useAllBotPackagesLazyQuery>;
export type AllBotPackagesQueryResult = Apollo.QueryResult<AllBotPackagesQuery, AllBotPackagesQueryVariables>;
export const UpgradeBotDocument = gql`
    mutation upgradeBot($input: BotUpgradeInput!) {
  upgradeBot(input: $input) {
    id
  }
}
    `;
export type UpgradeBotMutationFn = Apollo.MutationFunction<UpgradeBotMutation, UpgradeBotMutationVariables>;

/**
 * __useUpgradeBotMutation__
 *
 * To run a mutation, you first call `useUpgradeBotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpgradeBotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upgradeBotMutation, { data, loading, error }] = useUpgradeBotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpgradeBotMutation(baseOptions?: Apollo.MutationHookOptions<UpgradeBotMutation, UpgradeBotMutationVariables>) {
        return Apollo.useMutation<UpgradeBotMutation, UpgradeBotMutationVariables>(UpgradeBotDocument, baseOptions);
      }
export type UpgradeBotMutationHookResult = ReturnType<typeof useUpgradeBotMutation>;
export type UpgradeBotMutationResult = Apollo.MutationResult<UpgradeBotMutation>;
export type UpgradeBotMutationOptions = Apollo.BaseMutationOptions<UpgradeBotMutation, UpgradeBotMutationVariables>;
export const OnBotChangedDocument = gql`
    subscription onBotChanged {
  onBotChanged {
    operation
    data {
      id
      business_type
      title
      category
      picture_url
    }
  }
}
    `;

/**
 * __useOnBotChangedSubscription__
 *
 * To run a query within a React component, call `useOnBotChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnBotChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnBotChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnBotChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnBotChangedSubscription, OnBotChangedSubscriptionVariables>) {
        return Apollo.useSubscription<OnBotChangedSubscription, OnBotChangedSubscriptionVariables>(OnBotChangedDocument, baseOptions);
      }
export type OnBotChangedSubscriptionHookResult = ReturnType<typeof useOnBotChangedSubscription>;
export type OnBotChangedSubscriptionResult = Apollo.SubscriptionResult<OnBotChangedSubscription>;
export const CreatePaymentDocument = gql`
    mutation createPayment($input: PaymentInput!) {
  createPayment(input: $input) {
    id
    payload
  }
}
    `;
export type CreatePaymentMutationFn = Apollo.MutationFunction<CreatePaymentMutation, CreatePaymentMutationVariables>;

/**
 * __useCreatePaymentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMutation, { data, loading, error }] = useCreatePaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentMutation, CreatePaymentMutationVariables>) {
        return Apollo.useMutation<CreatePaymentMutation, CreatePaymentMutationVariables>(CreatePaymentDocument, baseOptions);
      }
export type CreatePaymentMutationHookResult = ReturnType<typeof useCreatePaymentMutation>;
export type CreatePaymentMutationResult = Apollo.MutationResult<CreatePaymentMutation>;
export type CreatePaymentMutationOptions = Apollo.BaseMutationOptions<CreatePaymentMutation, CreatePaymentMutationVariables>;
export const UpdatePaymentDocument = gql`
    mutation updatePayment($id: String!, $input: PaymentUpdateInput!) {
  updatePayment(input: $input, id: $id) {
    id
    invoice_id
  }
}
    `;
export type UpdatePaymentMutationFn = Apollo.MutationFunction<UpdatePaymentMutation, UpdatePaymentMutationVariables>;

/**
 * __useUpdatePaymentMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentMutation, { data, loading, error }] = useUpdatePaymentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePaymentMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePaymentMutation, UpdatePaymentMutationVariables>) {
        return Apollo.useMutation<UpdatePaymentMutation, UpdatePaymentMutationVariables>(UpdatePaymentDocument, baseOptions);
      }
export type UpdatePaymentMutationHookResult = ReturnType<typeof useUpdatePaymentMutation>;
export type UpdatePaymentMutationResult = Apollo.MutationResult<UpdatePaymentMutation>;
export type UpdatePaymentMutationOptions = Apollo.BaseMutationOptions<UpdatePaymentMutation, UpdatePaymentMutationVariables>;
export const GetInvoiceDocument = gql`
    query getInvoice($invoice_id: String!) {
  getInvoice(invoice_id: $invoice_id) {
    id
    bot_id
    items {
      sku
      title
      amount
    }
    total
  }
}
    `;

/**
 * __useGetInvoiceQuery__
 *
 * To run a query within a React component, call `useGetInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceQuery({
 *   variables: {
 *      invoice_id: // value for 'invoice_id'
 *   },
 * });
 */
export function useGetInvoiceQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceQuery, GetInvoiceQueryVariables>) {
        return Apollo.useQuery<GetInvoiceQuery, GetInvoiceQueryVariables>(GetInvoiceDocument, baseOptions);
      }
export function useGetInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceQuery, GetInvoiceQueryVariables>) {
          return Apollo.useLazyQuery<GetInvoiceQuery, GetInvoiceQueryVariables>(GetInvoiceDocument, baseOptions);
        }
export type GetInvoiceQueryHookResult = ReturnType<typeof useGetInvoiceQuery>;
export type GetInvoiceLazyQueryHookResult = ReturnType<typeof useGetInvoiceLazyQuery>;
export type GetInvoiceQueryResult = Apollo.QueryResult<GetInvoiceQuery, GetInvoiceQueryVariables>;
export const OnInvoiceChangedDocument = gql`
    subscription onInvoiceChanged($bot_id: String!, $invoice_id: String!) {
  onInvoiceChanged(bot_id: $bot_id, invoice_id: $invoice_id) {
    operation
    data {
      id
      status
    }
  }
}
    `;

/**
 * __useOnInvoiceChangedSubscription__
 *
 * To run a query within a React component, call `useOnInvoiceChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnInvoiceChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnInvoiceChangedSubscription({
 *   variables: {
 *      bot_id: // value for 'bot_id'
 *      invoice_id: // value for 'invoice_id'
 *   },
 * });
 */
export function useOnInvoiceChangedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnInvoiceChangedSubscription, OnInvoiceChangedSubscriptionVariables>) {
        return Apollo.useSubscription<OnInvoiceChangedSubscription, OnInvoiceChangedSubscriptionVariables>(OnInvoiceChangedDocument, baseOptions);
      }
export type OnInvoiceChangedSubscriptionHookResult = ReturnType<typeof useOnInvoiceChangedSubscription>;
export type OnInvoiceChangedSubscriptionResult = Apollo.SubscriptionResult<OnInvoiceChangedSubscription>;
export const SearchPaymentDocument = gql`
    query searchPayment($input: PaymentSearchInput) {
  searchPayment(input: $input) {
    next
    total
    data {
      id
      first_name
      last_name
      method
      status
      amount
      creation
      bot_id
    }
  }
}
    `;

/**
 * __useSearchPaymentQuery__
 *
 * To run a query within a React component, call `useSearchPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPaymentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchPaymentQuery(baseOptions?: Apollo.QueryHookOptions<SearchPaymentQuery, SearchPaymentQueryVariables>) {
        return Apollo.useQuery<SearchPaymentQuery, SearchPaymentQueryVariables>(SearchPaymentDocument, baseOptions);
      }
export function useSearchPaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchPaymentQuery, SearchPaymentQueryVariables>) {
          return Apollo.useLazyQuery<SearchPaymentQuery, SearchPaymentQueryVariables>(SearchPaymentDocument, baseOptions);
        }
export type SearchPaymentQueryHookResult = ReturnType<typeof useSearchPaymentQuery>;
export type SearchPaymentLazyQueryHookResult = ReturnType<typeof useSearchPaymentLazyQuery>;
export type SearchPaymentQueryResult = Apollo.QueryResult<SearchPaymentQuery, SearchPaymentQueryVariables>;
export const SearchInvoiceDocument = gql`
    query searchInvoice($bot_id: String!) {
  searchInvoice(bot_id: $bot_id) {
    data {
      id
      total
      status
      bot_id
      billing_time
      creation
      items {
        title
        description
        sku
        qty
        price
        amount
      }
    }
  }
}
    `;

/**
 * __useSearchInvoiceQuery__
 *
 * To run a query within a React component, call `useSearchInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchInvoiceQuery({
 *   variables: {
 *      bot_id: // value for 'bot_id'
 *   },
 * });
 */
export function useSearchInvoiceQuery(baseOptions: Apollo.QueryHookOptions<SearchInvoiceQuery, SearchInvoiceQueryVariables>) {
        return Apollo.useQuery<SearchInvoiceQuery, SearchInvoiceQueryVariables>(SearchInvoiceDocument, baseOptions);
      }
export function useSearchInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchInvoiceQuery, SearchInvoiceQueryVariables>) {
          return Apollo.useLazyQuery<SearchInvoiceQuery, SearchInvoiceQueryVariables>(SearchInvoiceDocument, baseOptions);
        }
export type SearchInvoiceQueryHookResult = ReturnType<typeof useSearchInvoiceQuery>;
export type SearchInvoiceLazyQueryHookResult = ReturnType<typeof useSearchInvoiceLazyQuery>;
export type SearchInvoiceQueryResult = Apollo.QueryResult<SearchInvoiceQuery, SearchInvoiceQueryVariables>;
export const GetShopInfoDocument = gql`
    query getShopInfo {
  getShopInfo {
    title
    picture_url
    contact {
      facebook
      cover_image_url
      instagram
      phone_number
    }
    bank_accounts {
      enable
      account_no
      bank
      account_name
    }
    shippings {
      title
      company
      cost
    }
    line_notify_token
    product_google_sheet_url
    product_category_google_sheet_url
  }
}
    `;

/**
 * __useGetShopInfoQuery__
 *
 * To run a query within a React component, call `useGetShopInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetShopInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetShopInfoQuery, GetShopInfoQueryVariables>) {
        return Apollo.useQuery<GetShopInfoQuery, GetShopInfoQueryVariables>(GetShopInfoDocument, baseOptions);
      }
export function useGetShopInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShopInfoQuery, GetShopInfoQueryVariables>) {
          return Apollo.useLazyQuery<GetShopInfoQuery, GetShopInfoQueryVariables>(GetShopInfoDocument, baseOptions);
        }
export type GetShopInfoQueryHookResult = ReturnType<typeof useGetShopInfoQuery>;
export type GetShopInfoLazyQueryHookResult = ReturnType<typeof useGetShopInfoLazyQuery>;
export type GetShopInfoQueryResult = Apollo.QueryResult<GetShopInfoQuery, GetShopInfoQueryVariables>;
export const UpdateShopInfoDocument = gql`
    mutation updateShopInfo($input: ShopInput!) {
  updateShopInfo(input: $input) {
    product_google_sheet_url
  }
}
    `;
export type UpdateShopInfoMutationFn = Apollo.MutationFunction<UpdateShopInfoMutation, UpdateShopInfoMutationVariables>;

/**
 * __useUpdateShopInfoMutation__
 *
 * To run a mutation, you first call `useUpdateShopInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShopInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShopInfoMutation, { data, loading, error }] = useUpdateShopInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShopInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShopInfoMutation, UpdateShopInfoMutationVariables>) {
        return Apollo.useMutation<UpdateShopInfoMutation, UpdateShopInfoMutationVariables>(UpdateShopInfoDocument, baseOptions);
      }
export type UpdateShopInfoMutationHookResult = ReturnType<typeof useUpdateShopInfoMutation>;
export type UpdateShopInfoMutationResult = Apollo.MutationResult<UpdateShopInfoMutation>;
export type UpdateShopInfoMutationOptions = Apollo.BaseMutationOptions<UpdateShopInfoMutation, UpdateShopInfoMutationVariables>;
export const ImportProductDocument = gql`
    mutation importProduct($google_sheet_id: String!, $sheet_number: Float!) {
  importProduct(sheet_number: $sheet_number, google_sheet_id: $google_sheet_id) {
    total
  }
}
    `;
export type ImportProductMutationFn = Apollo.MutationFunction<ImportProductMutation, ImportProductMutationVariables>;

/**
 * __useImportProductMutation__
 *
 * To run a mutation, you first call `useImportProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importProductMutation, { data, loading, error }] = useImportProductMutation({
 *   variables: {
 *      google_sheet_id: // value for 'google_sheet_id'
 *      sheet_number: // value for 'sheet_number'
 *   },
 * });
 */
export function useImportProductMutation(baseOptions?: Apollo.MutationHookOptions<ImportProductMutation, ImportProductMutationVariables>) {
        return Apollo.useMutation<ImportProductMutation, ImportProductMutationVariables>(ImportProductDocument, baseOptions);
      }
export type ImportProductMutationHookResult = ReturnType<typeof useImportProductMutation>;
export type ImportProductMutationResult = Apollo.MutationResult<ImportProductMutation>;
export type ImportProductMutationOptions = Apollo.BaseMutationOptions<ImportProductMutation, ImportProductMutationVariables>;
export const SearchPermissionDocument = gql`
    query searchPermission {
  searchPermission {
    data {
      bot_id
      user_id
      role
      account {
        id
        first_name
        last_name
        picture_url
        phone
        email
      }
    }
  }
}
    `;

/**
 * __useSearchPermissionQuery__
 *
 * To run a query within a React component, call `useSearchPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPermissionQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchPermissionQuery(baseOptions?: Apollo.QueryHookOptions<SearchPermissionQuery, SearchPermissionQueryVariables>) {
        return Apollo.useQuery<SearchPermissionQuery, SearchPermissionQueryVariables>(SearchPermissionDocument, baseOptions);
      }
export function useSearchPermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchPermissionQuery, SearchPermissionQueryVariables>) {
          return Apollo.useLazyQuery<SearchPermissionQuery, SearchPermissionQueryVariables>(SearchPermissionDocument, baseOptions);
        }
export type SearchPermissionQueryHookResult = ReturnType<typeof useSearchPermissionQuery>;
export type SearchPermissionLazyQueryHookResult = ReturnType<typeof useSearchPermissionLazyQuery>;
export type SearchPermissionQueryResult = Apollo.QueryResult<SearchPermissionQuery, SearchPermissionQueryVariables>;
export const RemovePermissionDocument = gql`
    mutation removePermission($user_id: String!) {
  removePermission(user_id: $user_id) {
    id
    success
    error_message
  }
}
    `;
export type RemovePermissionMutationFn = Apollo.MutationFunction<RemovePermissionMutation, RemovePermissionMutationVariables>;

/**
 * __useRemovePermissionMutation__
 *
 * To run a mutation, you first call `useRemovePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePermissionMutation, { data, loading, error }] = useRemovePermissionMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useRemovePermissionMutation(baseOptions?: Apollo.MutationHookOptions<RemovePermissionMutation, RemovePermissionMutationVariables>) {
        return Apollo.useMutation<RemovePermissionMutation, RemovePermissionMutationVariables>(RemovePermissionDocument, baseOptions);
      }
export type RemovePermissionMutationHookResult = ReturnType<typeof useRemovePermissionMutation>;
export type RemovePermissionMutationResult = Apollo.MutationResult<RemovePermissionMutation>;
export type RemovePermissionMutationOptions = Apollo.BaseMutationOptions<RemovePermissionMutation, RemovePermissionMutationVariables>;
export const OnPermissionChangedDocument = gql`
    subscription onPermissionChanged {
  onPermissionChanged {
    operation
    data {
      bot_id
      user_id
      role
      account {
        id
        first_name
        last_name
        picture_url
        phone
        email
      }
    }
  }
}
    `;

/**
 * __useOnPermissionChangedSubscription__
 *
 * To run a query within a React component, call `useOnPermissionChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnPermissionChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnPermissionChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnPermissionChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnPermissionChangedSubscription, OnPermissionChangedSubscriptionVariables>) {
        return Apollo.useSubscription<OnPermissionChangedSubscription, OnPermissionChangedSubscriptionVariables>(OnPermissionChangedDocument, baseOptions);
      }
export type OnPermissionChangedSubscriptionHookResult = ReturnType<typeof useOnPermissionChangedSubscription>;
export type OnPermissionChangedSubscriptionResult = Apollo.SubscriptionResult<OnPermissionChangedSubscription>;
export const SearchInvitationDocument = gql`
    query searchInvitation {
  searchInvitation {
    total
    data {
      id
      module
      module_id
      permission_type
      permission_role
      creation
      expired_on
      activated
    }
  }
}
    `;

/**
 * __useSearchInvitationQuery__
 *
 * To run a query within a React component, call `useSearchInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchInvitationQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchInvitationQuery(baseOptions?: Apollo.QueryHookOptions<SearchInvitationQuery, SearchInvitationQueryVariables>) {
        return Apollo.useQuery<SearchInvitationQuery, SearchInvitationQueryVariables>(SearchInvitationDocument, baseOptions);
      }
export function useSearchInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchInvitationQuery, SearchInvitationQueryVariables>) {
          return Apollo.useLazyQuery<SearchInvitationQuery, SearchInvitationQueryVariables>(SearchInvitationDocument, baseOptions);
        }
export type SearchInvitationQueryHookResult = ReturnType<typeof useSearchInvitationQuery>;
export type SearchInvitationLazyQueryHookResult = ReturnType<typeof useSearchInvitationLazyQuery>;
export type SearchInvitationQueryResult = Apollo.QueryResult<SearchInvitationQuery, SearchInvitationQueryVariables>;
export const CreateInviteLinkDocument = gql`
    mutation createInviteLink($input: InvitationInput!) {
  createInviteLink(input: $input) {
    id
    creation
    expired_on
  }
}
    `;
export type CreateInviteLinkMutationFn = Apollo.MutationFunction<CreateInviteLinkMutation, CreateInviteLinkMutationVariables>;

/**
 * __useCreateInviteLinkMutation__
 *
 * To run a mutation, you first call `useCreateInviteLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInviteLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInviteLinkMutation, { data, loading, error }] = useCreateInviteLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInviteLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreateInviteLinkMutation, CreateInviteLinkMutationVariables>) {
        return Apollo.useMutation<CreateInviteLinkMutation, CreateInviteLinkMutationVariables>(CreateInviteLinkDocument, baseOptions);
      }
export type CreateInviteLinkMutationHookResult = ReturnType<typeof useCreateInviteLinkMutation>;
export type CreateInviteLinkMutationResult = Apollo.MutationResult<CreateInviteLinkMutation>;
export type CreateInviteLinkMutationOptions = Apollo.BaseMutationOptions<CreateInviteLinkMutation, CreateInviteLinkMutationVariables>;
export const GetInvitationDocument = gql`
    query getInvitation($id: String!) {
  getInvitation(id: $id) {
    id
    module
    module_id
    permission_type
    permission_role
    creation
    expired_on
    activated
    created_by
  }
}
    `;

/**
 * __useGetInvitationQuery__
 *
 * To run a query within a React component, call `useGetInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInvitationQuery(baseOptions: Apollo.QueryHookOptions<GetInvitationQuery, GetInvitationQueryVariables>) {
        return Apollo.useQuery<GetInvitationQuery, GetInvitationQueryVariables>(GetInvitationDocument, baseOptions);
      }
export function useGetInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvitationQuery, GetInvitationQueryVariables>) {
          return Apollo.useLazyQuery<GetInvitationQuery, GetInvitationQueryVariables>(GetInvitationDocument, baseOptions);
        }
export type GetInvitationQueryHookResult = ReturnType<typeof useGetInvitationQuery>;
export type GetInvitationLazyQueryHookResult = ReturnType<typeof useGetInvitationLazyQuery>;
export type GetInvitationQueryResult = Apollo.QueryResult<GetInvitationQuery, GetInvitationQueryVariables>;
export const ActivateInviteLinkDocument = gql`
    mutation activateInviteLink($input: InvitationActivationInput!) {
  activateInviteLink(input: $input) {
    id
    success
    error_message
  }
}
    `;
export type ActivateInviteLinkMutationFn = Apollo.MutationFunction<ActivateInviteLinkMutation, ActivateInviteLinkMutationVariables>;

/**
 * __useActivateInviteLinkMutation__
 *
 * To run a mutation, you first call `useActivateInviteLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateInviteLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateInviteLinkMutation, { data, loading, error }] = useActivateInviteLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateInviteLinkMutation(baseOptions?: Apollo.MutationHookOptions<ActivateInviteLinkMutation, ActivateInviteLinkMutationVariables>) {
        return Apollo.useMutation<ActivateInviteLinkMutation, ActivateInviteLinkMutationVariables>(ActivateInviteLinkDocument, baseOptions);
      }
export type ActivateInviteLinkMutationHookResult = ReturnType<typeof useActivateInviteLinkMutation>;
export type ActivateInviteLinkMutationResult = Apollo.MutationResult<ActivateInviteLinkMutation>;
export type ActivateInviteLinkMutationOptions = Apollo.BaseMutationOptions<ActivateInviteLinkMutation, ActivateInviteLinkMutationVariables>;
export const GetChatStatDocument = gql`
    query getChatStat($input: ChatStatInput) {
  getChatStat(input: $input) {
    total_chats
    total_intent_chats
    total_unknown_chats
  }
}
    `;

/**
 * __useGetChatStatQuery__
 *
 * To run a query within a React component, call `useGetChatStatQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatStatQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetChatStatQuery(baseOptions?: Apollo.QueryHookOptions<GetChatStatQuery, GetChatStatQueryVariables>) {
        return Apollo.useQuery<GetChatStatQuery, GetChatStatQueryVariables>(GetChatStatDocument, baseOptions);
      }
export function useGetChatStatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChatStatQuery, GetChatStatQueryVariables>) {
          return Apollo.useLazyQuery<GetChatStatQuery, GetChatStatQueryVariables>(GetChatStatDocument, baseOptions);
        }
export type GetChatStatQueryHookResult = ReturnType<typeof useGetChatStatQuery>;
export type GetChatStatLazyQueryHookResult = ReturnType<typeof useGetChatStatLazyQuery>;
export type GetChatStatQueryResult = Apollo.QueryResult<GetChatStatQuery, GetChatStatQueryVariables>;
export const GetActiveUserReportDocument = gql`
    query getActiveUserReport($input: ActiveUserReportInput) {
  getActiveUserReport(input: $input) {
    categories
    series {
      name
      data
    }
  }
}
    `;

/**
 * __useGetActiveUserReportQuery__
 *
 * To run a query within a React component, call `useGetActiveUserReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveUserReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveUserReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetActiveUserReportQuery(baseOptions?: Apollo.QueryHookOptions<GetActiveUserReportQuery, GetActiveUserReportQueryVariables>) {
        return Apollo.useQuery<GetActiveUserReportQuery, GetActiveUserReportQueryVariables>(GetActiveUserReportDocument, baseOptions);
      }
export function useGetActiveUserReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveUserReportQuery, GetActiveUserReportQueryVariables>) {
          return Apollo.useLazyQuery<GetActiveUserReportQuery, GetActiveUserReportQueryVariables>(GetActiveUserReportDocument, baseOptions);
        }
export type GetActiveUserReportQueryHookResult = ReturnType<typeof useGetActiveUserReportQuery>;
export type GetActiveUserReportLazyQueryHookResult = ReturnType<typeof useGetActiveUserReportLazyQuery>;
export type GetActiveUserReportQueryResult = Apollo.QueryResult<GetActiveUserReportQuery, GetActiveUserReportQueryVariables>;
export const GetUserHeatmapDocument = gql`
    query getUserHeatmap {
  getUserHeatmap {
    categories
    series {
      name
      data
    }
  }
}
    `;

/**
 * __useGetUserHeatmapQuery__
 *
 * To run a query within a React component, call `useGetUserHeatmapQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserHeatmapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserHeatmapQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserHeatmapQuery(baseOptions?: Apollo.QueryHookOptions<GetUserHeatmapQuery, GetUserHeatmapQueryVariables>) {
        return Apollo.useQuery<GetUserHeatmapQuery, GetUserHeatmapQueryVariables>(GetUserHeatmapDocument, baseOptions);
      }
export function useGetUserHeatmapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserHeatmapQuery, GetUserHeatmapQueryVariables>) {
          return Apollo.useLazyQuery<GetUserHeatmapQuery, GetUserHeatmapQueryVariables>(GetUserHeatmapDocument, baseOptions);
        }
export type GetUserHeatmapQueryHookResult = ReturnType<typeof useGetUserHeatmapQuery>;
export type GetUserHeatmapLazyQueryHookResult = ReturnType<typeof useGetUserHeatmapLazyQuery>;
export type GetUserHeatmapQueryResult = Apollo.QueryResult<GetUserHeatmapQuery, GetUserHeatmapQueryVariables>;
export const GetMessageByChannelDocument = gql`
    query getMessageByChannel($input: MessageByChannelInput) {
  getMessageByChannel(input: $input) {
    categories
    series {
      name
      data
    }
  }
}
    `;

/**
 * __useGetMessageByChannelQuery__
 *
 * To run a query within a React component, call `useGetMessageByChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageByChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageByChannelQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMessageByChannelQuery(baseOptions?: Apollo.QueryHookOptions<GetMessageByChannelQuery, GetMessageByChannelQueryVariables>) {
        return Apollo.useQuery<GetMessageByChannelQuery, GetMessageByChannelQueryVariables>(GetMessageByChannelDocument, baseOptions);
      }
export function useGetMessageByChannelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMessageByChannelQuery, GetMessageByChannelQueryVariables>) {
          return Apollo.useLazyQuery<GetMessageByChannelQuery, GetMessageByChannelQueryVariables>(GetMessageByChannelDocument, baseOptions);
        }
export type GetMessageByChannelQueryHookResult = ReturnType<typeof useGetMessageByChannelQuery>;
export type GetMessageByChannelLazyQueryHookResult = ReturnType<typeof useGetMessageByChannelLazyQuery>;
export type GetMessageByChannelQueryResult = Apollo.QueryResult<GetMessageByChannelQuery, GetMessageByChannelQueryVariables>;
export const SearchBroadcastDocument = gql`
    query searchBroadcast($input: BroadcastSearchInput) {
  searchBroadcast(input: $input) {
    next
    total
    data {
      id
      bot_id
      title
      schedule
      channel_id
      platform
      status
      send_type
    }
  }
}
    `;

/**
 * __useSearchBroadcastQuery__
 *
 * To run a query within a React component, call `useSearchBroadcastQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBroadcastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBroadcastQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchBroadcastQuery(baseOptions?: Apollo.QueryHookOptions<SearchBroadcastQuery, SearchBroadcastQueryVariables>) {
        return Apollo.useQuery<SearchBroadcastQuery, SearchBroadcastQueryVariables>(SearchBroadcastDocument, baseOptions);
      }
export function useSearchBroadcastLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchBroadcastQuery, SearchBroadcastQueryVariables>) {
          return Apollo.useLazyQuery<SearchBroadcastQuery, SearchBroadcastQueryVariables>(SearchBroadcastDocument, baseOptions);
        }
export type SearchBroadcastQueryHookResult = ReturnType<typeof useSearchBroadcastQuery>;
export type SearchBroadcastLazyQueryHookResult = ReturnType<typeof useSearchBroadcastLazyQuery>;
export type SearchBroadcastQueryResult = Apollo.QueryResult<SearchBroadcastQuery, SearchBroadcastQueryVariables>;
export const SearchBroadcastWithStatsDocument = gql`
    query searchBroadcastWithStats($input: BroadcastSearchInput) {
  searchBroadcast(input: $input) {
    next
    total
    data {
      id
      bot_id
      title
      schedule
      channel_id
      platform
      status
      send_type
      stats {
        total
        sent
        sending
        error
        ready
      }
    }
  }
}
    `;

/**
 * __useSearchBroadcastWithStatsQuery__
 *
 * To run a query within a React component, call `useSearchBroadcastWithStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBroadcastWithStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBroadcastWithStatsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchBroadcastWithStatsQuery(baseOptions?: Apollo.QueryHookOptions<SearchBroadcastWithStatsQuery, SearchBroadcastWithStatsQueryVariables>) {
        return Apollo.useQuery<SearchBroadcastWithStatsQuery, SearchBroadcastWithStatsQueryVariables>(SearchBroadcastWithStatsDocument, baseOptions);
      }
export function useSearchBroadcastWithStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchBroadcastWithStatsQuery, SearchBroadcastWithStatsQueryVariables>) {
          return Apollo.useLazyQuery<SearchBroadcastWithStatsQuery, SearchBroadcastWithStatsQueryVariables>(SearchBroadcastWithStatsDocument, baseOptions);
        }
export type SearchBroadcastWithStatsQueryHookResult = ReturnType<typeof useSearchBroadcastWithStatsQuery>;
export type SearchBroadcastWithStatsLazyQueryHookResult = ReturnType<typeof useSearchBroadcastWithStatsLazyQuery>;
export type SearchBroadcastWithStatsQueryResult = Apollo.QueryResult<SearchBroadcastWithStatsQuery, SearchBroadcastWithStatsQueryVariables>;
export const CreateBroadcastDocument = gql`
    mutation createBroadcast($input: BroadcastInput!) {
  createBroadcast(input: $input) {
    id
    title
  }
}
    `;
export type CreateBroadcastMutationFn = Apollo.MutationFunction<CreateBroadcastMutation, CreateBroadcastMutationVariables>;

/**
 * __useCreateBroadcastMutation__
 *
 * To run a mutation, you first call `useCreateBroadcastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBroadcastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBroadcastMutation, { data, loading, error }] = useCreateBroadcastMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBroadcastMutation(baseOptions?: Apollo.MutationHookOptions<CreateBroadcastMutation, CreateBroadcastMutationVariables>) {
        return Apollo.useMutation<CreateBroadcastMutation, CreateBroadcastMutationVariables>(CreateBroadcastDocument, baseOptions);
      }
export type CreateBroadcastMutationHookResult = ReturnType<typeof useCreateBroadcastMutation>;
export type CreateBroadcastMutationResult = Apollo.MutationResult<CreateBroadcastMutation>;
export type CreateBroadcastMutationOptions = Apollo.BaseMutationOptions<CreateBroadcastMutation, CreateBroadcastMutationVariables>;
export const GetBroadcastDocument = gql`
    query getBroadcast($id: String!) {
  getBroadcast(id: $id) {
    id
    bot_id
    title
    schedule
    channel_id
    platform
    status
    user_id
    stats {
      total
      sent
      sending
      error
      ready
    }
    send_type
    audience_type
    action {
      id
      facebook_messages
      line_messages
    }
    target
    target_url
    target_limit
    target_tags
    target_group
    is_some
  }
  getFriendStat {
    total
    targetReach
    blocked
    facebook
    line
  }
}
    `;

/**
 * __useGetBroadcastQuery__
 *
 * To run a query within a React component, call `useGetBroadcastQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBroadcastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBroadcastQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBroadcastQuery(baseOptions: Apollo.QueryHookOptions<GetBroadcastQuery, GetBroadcastQueryVariables>) {
        return Apollo.useQuery<GetBroadcastQuery, GetBroadcastQueryVariables>(GetBroadcastDocument, baseOptions);
      }
export function useGetBroadcastLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBroadcastQuery, GetBroadcastQueryVariables>) {
          return Apollo.useLazyQuery<GetBroadcastQuery, GetBroadcastQueryVariables>(GetBroadcastDocument, baseOptions);
        }
export type GetBroadcastQueryHookResult = ReturnType<typeof useGetBroadcastQuery>;
export type GetBroadcastLazyQueryHookResult = ReturnType<typeof useGetBroadcastLazyQuery>;
export type GetBroadcastQueryResult = Apollo.QueryResult<GetBroadcastQuery, GetBroadcastQueryVariables>;
export const UpdateBroadcastDocument = gql`
    mutation updateBroadcast($id: String!, $input: BroadcastInput!) {
  updateBroadcast(id: $id, input: $input) {
    id
    title
  }
}
    `;
export type UpdateBroadcastMutationFn = Apollo.MutationFunction<UpdateBroadcastMutation, UpdateBroadcastMutationVariables>;

/**
 * __useUpdateBroadcastMutation__
 *
 * To run a mutation, you first call `useUpdateBroadcastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBroadcastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBroadcastMutation, { data, loading, error }] = useUpdateBroadcastMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBroadcastMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBroadcastMutation, UpdateBroadcastMutationVariables>) {
        return Apollo.useMutation<UpdateBroadcastMutation, UpdateBroadcastMutationVariables>(UpdateBroadcastDocument, baseOptions);
      }
export type UpdateBroadcastMutationHookResult = ReturnType<typeof useUpdateBroadcastMutation>;
export type UpdateBroadcastMutationResult = Apollo.MutationResult<UpdateBroadcastMutation>;
export type UpdateBroadcastMutationOptions = Apollo.BaseMutationOptions<UpdateBroadcastMutation, UpdateBroadcastMutationVariables>;
export const RemoveBroadcastDocument = gql`
    mutation removeBroadcast($id: String!) {
  removeBroadcast(id: $id) {
    id
    success
  }
}
    `;
export type RemoveBroadcastMutationFn = Apollo.MutationFunction<RemoveBroadcastMutation, RemoveBroadcastMutationVariables>;

/**
 * __useRemoveBroadcastMutation__
 *
 * To run a mutation, you first call `useRemoveBroadcastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBroadcastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBroadcastMutation, { data, loading, error }] = useRemoveBroadcastMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveBroadcastMutation(baseOptions?: Apollo.MutationHookOptions<RemoveBroadcastMutation, RemoveBroadcastMutationVariables>) {
        return Apollo.useMutation<RemoveBroadcastMutation, RemoveBroadcastMutationVariables>(RemoveBroadcastDocument, baseOptions);
      }
export type RemoveBroadcastMutationHookResult = ReturnType<typeof useRemoveBroadcastMutation>;
export type RemoveBroadcastMutationResult = Apollo.MutationResult<RemoveBroadcastMutation>;
export type RemoveBroadcastMutationOptions = Apollo.BaseMutationOptions<RemoveBroadcastMutation, RemoveBroadcastMutationVariables>;
export const OnBroadcastChangedDocument = gql`
    subscription onBroadcastChanged {
  onBroadcastChanged {
    operation
    data {
      id
    }
  }
}
    `;

/**
 * __useOnBroadcastChangedSubscription__
 *
 * To run a query within a React component, call `useOnBroadcastChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnBroadcastChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnBroadcastChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnBroadcastChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnBroadcastChangedSubscription, OnBroadcastChangedSubscriptionVariables>) {
        return Apollo.useSubscription<OnBroadcastChangedSubscription, OnBroadcastChangedSubscriptionVariables>(OnBroadcastChangedDocument, baseOptions);
      }
export type OnBroadcastChangedSubscriptionHookResult = ReturnType<typeof useOnBroadcastChangedSubscription>;
export type OnBroadcastChangedSubscriptionResult = Apollo.SubscriptionResult<OnBroadcastChangedSubscription>;
export const UploadAudienceDocument = gql`
    mutation uploadAudience($input: BroadcastUploadAudienceInput!) {
  uploadAudience(input: $input) {
    id
  }
}
    `;
export type UploadAudienceMutationFn = Apollo.MutationFunction<UploadAudienceMutation, UploadAudienceMutationVariables>;

/**
 * __useUploadAudienceMutation__
 *
 * To run a mutation, you first call `useUploadAudienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAudienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAudienceMutation, { data, loading, error }] = useUploadAudienceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadAudienceMutation(baseOptions?: Apollo.MutationHookOptions<UploadAudienceMutation, UploadAudienceMutationVariables>) {
        return Apollo.useMutation<UploadAudienceMutation, UploadAudienceMutationVariables>(UploadAudienceDocument, baseOptions);
      }
export type UploadAudienceMutationHookResult = ReturnType<typeof useUploadAudienceMutation>;
export type UploadAudienceMutationResult = Apollo.MutationResult<UploadAudienceMutation>;
export type UploadAudienceMutationOptions = Apollo.BaseMutationOptions<UploadAudienceMutation, UploadAudienceMutationVariables>;
export const ResetAudienceDocument = gql`
    mutation resetAudience($id: String!) {
  resetAudience(id: $id) {
    success
  }
}
    `;
export type ResetAudienceMutationFn = Apollo.MutationFunction<ResetAudienceMutation, ResetAudienceMutationVariables>;

/**
 * __useResetAudienceMutation__
 *
 * To run a mutation, you first call `useResetAudienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetAudienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetAudienceMutation, { data, loading, error }] = useResetAudienceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResetAudienceMutation(baseOptions?: Apollo.MutationHookOptions<ResetAudienceMutation, ResetAudienceMutationVariables>) {
        return Apollo.useMutation<ResetAudienceMutation, ResetAudienceMutationVariables>(ResetAudienceDocument, baseOptions);
      }
export type ResetAudienceMutationHookResult = ReturnType<typeof useResetAudienceMutation>;
export type ResetAudienceMutationResult = Apollo.MutationResult<ResetAudienceMutation>;
export type ResetAudienceMutationOptions = Apollo.BaseMutationOptions<ResetAudienceMutation, ResetAudienceMutationVariables>;
export const SearchBroadcastAudienceDocument = gql`
    query searchBroadcastAudience($broadcastId: String!, $input: BroadcastAudienceSearchInput) {
  searchBroadcastAudience(broadcast_id: $broadcastId, input: $input) {
    total
    next
    data {
      id
      broadcast_id
      user_id
      data
      payload
      creation
      status
      error_message
    }
  }
}
    `;

/**
 * __useSearchBroadcastAudienceQuery__
 *
 * To run a query within a React component, call `useSearchBroadcastAudienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBroadcastAudienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBroadcastAudienceQuery({
 *   variables: {
 *      broadcastId: // value for 'broadcastId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchBroadcastAudienceQuery(baseOptions: Apollo.QueryHookOptions<SearchBroadcastAudienceQuery, SearchBroadcastAudienceQueryVariables>) {
        return Apollo.useQuery<SearchBroadcastAudienceQuery, SearchBroadcastAudienceQueryVariables>(SearchBroadcastAudienceDocument, baseOptions);
      }
export function useSearchBroadcastAudienceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchBroadcastAudienceQuery, SearchBroadcastAudienceQueryVariables>) {
          return Apollo.useLazyQuery<SearchBroadcastAudienceQuery, SearchBroadcastAudienceQueryVariables>(SearchBroadcastAudienceDocument, baseOptions);
        }
export type SearchBroadcastAudienceQueryHookResult = ReturnType<typeof useSearchBroadcastAudienceQuery>;
export type SearchBroadcastAudienceLazyQueryHookResult = ReturnType<typeof useSearchBroadcastAudienceLazyQuery>;
export type SearchBroadcastAudienceQueryResult = Apollo.QueryResult<SearchBroadcastAudienceQuery, SearchBroadcastAudienceQueryVariables>;
export const SendBroadcastDocument = gql`
    mutation sendBroadcast($broadcastId: String!, $isTest: Boolean) {
  sendBroadcast(broadcast_id: $broadcastId, is_test: $isTest) {
    success
    error_message
  }
}
    `;
export type SendBroadcastMutationFn = Apollo.MutationFunction<SendBroadcastMutation, SendBroadcastMutationVariables>;

/**
 * __useSendBroadcastMutation__
 *
 * To run a mutation, you first call `useSendBroadcastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendBroadcastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendBroadcastMutation, { data, loading, error }] = useSendBroadcastMutation({
 *   variables: {
 *      broadcastId: // value for 'broadcastId'
 *      isTest: // value for 'isTest'
 *   },
 * });
 */
export function useSendBroadcastMutation(baseOptions?: Apollo.MutationHookOptions<SendBroadcastMutation, SendBroadcastMutationVariables>) {
        return Apollo.useMutation<SendBroadcastMutation, SendBroadcastMutationVariables>(SendBroadcastDocument, baseOptions);
      }
export type SendBroadcastMutationHookResult = ReturnType<typeof useSendBroadcastMutation>;
export type SendBroadcastMutationResult = Apollo.MutationResult<SendBroadcastMutation>;
export type SendBroadcastMutationOptions = Apollo.BaseMutationOptions<SendBroadcastMutation, SendBroadcastMutationVariables>;
export const UpdateActionDocument = gql`
    mutation updateAction($id: String!, $input: ActionInput!) {
  updateAction(id: $id, input: $input) {
    id
    line_messages
    facebook_messages
  }
}
    `;
export type UpdateActionMutationFn = Apollo.MutationFunction<UpdateActionMutation, UpdateActionMutationVariables>;

/**
 * __useUpdateActionMutation__
 *
 * To run a mutation, you first call `useUpdateActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActionMutation, { data, loading, error }] = useUpdateActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateActionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActionMutation, UpdateActionMutationVariables>) {
        return Apollo.useMutation<UpdateActionMutation, UpdateActionMutationVariables>(UpdateActionDocument, baseOptions);
      }
export type UpdateActionMutationHookResult = ReturnType<typeof useUpdateActionMutation>;
export type UpdateActionMutationResult = Apollo.MutationResult<UpdateActionMutation>;
export type UpdateActionMutationOptions = Apollo.BaseMutationOptions<UpdateActionMutation, UpdateActionMutationVariables>;
export const UploadImageDocument = gql`
    mutation uploadImage($file: Upload!) {
  uploadImage(file: $file) {
    url
  }
}
    `;
export type UploadImageMutationFn = Apollo.MutationFunction<UploadImageMutation, UploadImageMutationVariables>;

/**
 * __useUploadImageMutation__
 *
 * To run a mutation, you first call `useUploadImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImageMutation, { data, loading, error }] = useUploadImageMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadImageMutation(baseOptions?: Apollo.MutationHookOptions<UploadImageMutation, UploadImageMutationVariables>) {
        return Apollo.useMutation<UploadImageMutation, UploadImageMutationVariables>(UploadImageDocument, baseOptions);
      }
export type UploadImageMutationHookResult = ReturnType<typeof useUploadImageMutation>;
export type UploadImageMutationResult = Apollo.MutationResult<UploadImageMutation>;
export type UploadImageMutationOptions = Apollo.BaseMutationOptions<UploadImageMutation, UploadImageMutationVariables>;
export const UploadFileDocument = gql`
    mutation uploadFile($file: Upload!) {
  uploadFile(file: $file) {
    url
  }
}
    `;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, baseOptions);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;
export const SearchFriendGroupDocument = gql`
    query searchFriendGroup($input: FriendGroupSearchInput) {
  searchFriendGroup(input: $input) {
    next
    data {
      id
      title
      favorite
      includes
      excludes
      is_some
    }
  }
}
    `;

/**
 * __useSearchFriendGroupQuery__
 *
 * To run a query within a React component, call `useSearchFriendGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchFriendGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchFriendGroupQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchFriendGroupQuery(baseOptions?: Apollo.QueryHookOptions<SearchFriendGroupQuery, SearchFriendGroupQueryVariables>) {
        return Apollo.useQuery<SearchFriendGroupQuery, SearchFriendGroupQueryVariables>(SearchFriendGroupDocument, baseOptions);
      }
export function useSearchFriendGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchFriendGroupQuery, SearchFriendGroupQueryVariables>) {
          return Apollo.useLazyQuery<SearchFriendGroupQuery, SearchFriendGroupQueryVariables>(SearchFriendGroupDocument, baseOptions);
        }
export type SearchFriendGroupQueryHookResult = ReturnType<typeof useSearchFriendGroupQuery>;
export type SearchFriendGroupLazyQueryHookResult = ReturnType<typeof useSearchFriendGroupLazyQuery>;
export type SearchFriendGroupQueryResult = Apollo.QueryResult<SearchFriendGroupQuery, SearchFriendGroupQueryVariables>;
export const GetFriendGroupDocument = gql`
    query getFriendGroup($id: String!) {
  getFriendGroup(id: $id) {
    id
    title
    includes
    excludes
    favorite
    is_some
  }
}
    `;

/**
 * __useGetFriendGroupQuery__
 *
 * To run a query within a React component, call `useGetFriendGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFriendGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFriendGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFriendGroupQuery(baseOptions: Apollo.QueryHookOptions<GetFriendGroupQuery, GetFriendGroupQueryVariables>) {
        return Apollo.useQuery<GetFriendGroupQuery, GetFriendGroupQueryVariables>(GetFriendGroupDocument, baseOptions);
      }
export function useGetFriendGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFriendGroupQuery, GetFriendGroupQueryVariables>) {
          return Apollo.useLazyQuery<GetFriendGroupQuery, GetFriendGroupQueryVariables>(GetFriendGroupDocument, baseOptions);
        }
export type GetFriendGroupQueryHookResult = ReturnType<typeof useGetFriendGroupQuery>;
export type GetFriendGroupLazyQueryHookResult = ReturnType<typeof useGetFriendGroupLazyQuery>;
export type GetFriendGroupQueryResult = Apollo.QueryResult<GetFriendGroupQuery, GetFriendGroupQueryVariables>;
export const GetFriendGroupWithCountDocument = gql`
    query getFriendGroupWithCount($id: String!, $platform: Platform) {
  getFriendGroup(id: $id, platform: $platform) {
    id
    title
    includes
    excludes
    favorite
    is_some
    count
  }
}
    `;

/**
 * __useGetFriendGroupWithCountQuery__
 *
 * To run a query within a React component, call `useGetFriendGroupWithCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFriendGroupWithCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFriendGroupWithCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useGetFriendGroupWithCountQuery(baseOptions: Apollo.QueryHookOptions<GetFriendGroupWithCountQuery, GetFriendGroupWithCountQueryVariables>) {
        return Apollo.useQuery<GetFriendGroupWithCountQuery, GetFriendGroupWithCountQueryVariables>(GetFriendGroupWithCountDocument, baseOptions);
      }
export function useGetFriendGroupWithCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFriendGroupWithCountQuery, GetFriendGroupWithCountQueryVariables>) {
          return Apollo.useLazyQuery<GetFriendGroupWithCountQuery, GetFriendGroupWithCountQueryVariables>(GetFriendGroupWithCountDocument, baseOptions);
        }
export type GetFriendGroupWithCountQueryHookResult = ReturnType<typeof useGetFriendGroupWithCountQuery>;
export type GetFriendGroupWithCountLazyQueryHookResult = ReturnType<typeof useGetFriendGroupWithCountLazyQuery>;
export type GetFriendGroupWithCountQueryResult = Apollo.QueryResult<GetFriendGroupWithCountQuery, GetFriendGroupWithCountQueryVariables>;
export const CreateFriendGroupDocument = gql`
    mutation createFriendGroup($input: FriendGroupInput!) {
  createFriendGroup(input: $input) {
    id
    title
    favorite
  }
}
    `;
export type CreateFriendGroupMutationFn = Apollo.MutationFunction<CreateFriendGroupMutation, CreateFriendGroupMutationVariables>;

/**
 * __useCreateFriendGroupMutation__
 *
 * To run a mutation, you first call `useCreateFriendGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFriendGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFriendGroupMutation, { data, loading, error }] = useCreateFriendGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFriendGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateFriendGroupMutation, CreateFriendGroupMutationVariables>) {
        return Apollo.useMutation<CreateFriendGroupMutation, CreateFriendGroupMutationVariables>(CreateFriendGroupDocument, baseOptions);
      }
export type CreateFriendGroupMutationHookResult = ReturnType<typeof useCreateFriendGroupMutation>;
export type CreateFriendGroupMutationResult = Apollo.MutationResult<CreateFriendGroupMutation>;
export type CreateFriendGroupMutationOptions = Apollo.BaseMutationOptions<CreateFriendGroupMutation, CreateFriendGroupMutationVariables>;
export const UpdateFriendGroupDocument = gql`
    mutation updateFriendGroup($id: String!, $input: FriendGroupInput!) {
  updateFriendGroup(id: $id, input: $input) {
    id
  }
}
    `;
export type UpdateFriendGroupMutationFn = Apollo.MutationFunction<UpdateFriendGroupMutation, UpdateFriendGroupMutationVariables>;

/**
 * __useUpdateFriendGroupMutation__
 *
 * To run a mutation, you first call `useUpdateFriendGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFriendGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFriendGroupMutation, { data, loading, error }] = useUpdateFriendGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFriendGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFriendGroupMutation, UpdateFriendGroupMutationVariables>) {
        return Apollo.useMutation<UpdateFriendGroupMutation, UpdateFriendGroupMutationVariables>(UpdateFriendGroupDocument, baseOptions);
      }
export type UpdateFriendGroupMutationHookResult = ReturnType<typeof useUpdateFriendGroupMutation>;
export type UpdateFriendGroupMutationResult = Apollo.MutationResult<UpdateFriendGroupMutation>;
export type UpdateFriendGroupMutationOptions = Apollo.BaseMutationOptions<UpdateFriendGroupMutation, UpdateFriendGroupMutationVariables>;
export const RemoveFriendGroupDocument = gql`
    mutation removeFriendGroup($id: String!) {
  removeFriendGroup(id: $id) {
    id
    success
    error_message
  }
}
    `;
export type RemoveFriendGroupMutationFn = Apollo.MutationFunction<RemoveFriendGroupMutation, RemoveFriendGroupMutationVariables>;

/**
 * __useRemoveFriendGroupMutation__
 *
 * To run a mutation, you first call `useRemoveFriendGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFriendGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFriendGroupMutation, { data, loading, error }] = useRemoveFriendGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveFriendGroupMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFriendGroupMutation, RemoveFriendGroupMutationVariables>) {
        return Apollo.useMutation<RemoveFriendGroupMutation, RemoveFriendGroupMutationVariables>(RemoveFriendGroupDocument, baseOptions);
      }
export type RemoveFriendGroupMutationHookResult = ReturnType<typeof useRemoveFriendGroupMutation>;
export type RemoveFriendGroupMutationResult = Apollo.MutationResult<RemoveFriendGroupMutation>;
export type RemoveFriendGroupMutationOptions = Apollo.BaseMutationOptions<RemoveFriendGroupMutation, RemoveFriendGroupMutationVariables>;
export const SearchSnapDocument = gql`
    query searchSnap($input: SnapSearchInput) {
  searchSnap(input: $input) {
    data {
      label
      value
      category
      action
      creation
    }
  }
}
    `;

/**
 * __useSearchSnapQuery__
 *
 * To run a query within a React component, call `useSearchSnapQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSnapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSnapQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchSnapQuery(baseOptions?: Apollo.QueryHookOptions<SearchSnapQuery, SearchSnapQueryVariables>) {
        return Apollo.useQuery<SearchSnapQuery, SearchSnapQueryVariables>(SearchSnapDocument, baseOptions);
      }
export function useSearchSnapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchSnapQuery, SearchSnapQueryVariables>) {
          return Apollo.useLazyQuery<SearchSnapQuery, SearchSnapQueryVariables>(SearchSnapDocument, baseOptions);
        }
export type SearchSnapQueryHookResult = ReturnType<typeof useSearchSnapQuery>;
export type SearchSnapLazyQueryHookResult = ReturnType<typeof useSearchSnapLazyQuery>;
export type SearchSnapQueryResult = Apollo.QueryResult<SearchSnapQuery, SearchSnapQueryVariables>;
export const GetAccountLineChartDocument = gql`
    query getAccountLineChart($input: AccountInsightInput) {
  getAccountLineChart(input: $input) {
    series {
      data
      name
    }
    categories
  }
}
    `;

/**
 * __useGetAccountLineChartQuery__
 *
 * To run a query within a React component, call `useGetAccountLineChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountLineChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountLineChartQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAccountLineChartQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountLineChartQuery, GetAccountLineChartQueryVariables>) {
        return Apollo.useQuery<GetAccountLineChartQuery, GetAccountLineChartQueryVariables>(GetAccountLineChartDocument, baseOptions);
      }
export function useGetAccountLineChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountLineChartQuery, GetAccountLineChartQueryVariables>) {
          return Apollo.useLazyQuery<GetAccountLineChartQuery, GetAccountLineChartQueryVariables>(GetAccountLineChartDocument, baseOptions);
        }
export type GetAccountLineChartQueryHookResult = ReturnType<typeof useGetAccountLineChartQuery>;
export type GetAccountLineChartLazyQueryHookResult = ReturnType<typeof useGetAccountLineChartLazyQuery>;
export type GetAccountLineChartQueryResult = Apollo.QueryResult<GetAccountLineChartQuery, GetAccountLineChartQueryVariables>;
export const GetBotLineChartDocument = gql`
    query getBotLineChart($input: BotInsightInput) {
  getBotLineChart(input: $input) {
    series {
      data
      name
    }
    categories
  }
}
    `;

/**
 * __useGetBotLineChartQuery__
 *
 * To run a query within a React component, call `useGetBotLineChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBotLineChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBotLineChartQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBotLineChartQuery(baseOptions?: Apollo.QueryHookOptions<GetBotLineChartQuery, GetBotLineChartQueryVariables>) {
        return Apollo.useQuery<GetBotLineChartQuery, GetBotLineChartQueryVariables>(GetBotLineChartDocument, baseOptions);
      }
export function useGetBotLineChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBotLineChartQuery, GetBotLineChartQueryVariables>) {
          return Apollo.useLazyQuery<GetBotLineChartQuery, GetBotLineChartQueryVariables>(GetBotLineChartDocument, baseOptions);
        }
export type GetBotLineChartQueryHookResult = ReturnType<typeof useGetBotLineChartQuery>;
export type GetBotLineChartLazyQueryHookResult = ReturnType<typeof useGetBotLineChartLazyQuery>;
export type GetBotLineChartQueryResult = Apollo.QueryResult<GetBotLineChartQuery, GetBotLineChartQueryVariables>;
export const AllShopsDocument = gql`
    query allShops($input: BotInsightInput) {
  allShops(input: $input) {
    data {
      id
      title
      picture_url
      business_type
      profile {
        title
        open_hours
        contact
        address
      }
      friend_count
      friend_limit
      subscription {
        id
        title
        sku
        price
        end_date
        expired_in
      }
      owner {
        id
        first_name
        last_name
        email
        phone
      }
      created_by
      source
      creation
    }
    total
  }
}
    `;

/**
 * __useAllShopsQuery__
 *
 * To run a query within a React component, call `useAllShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllShopsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAllShopsQuery(baseOptions?: Apollo.QueryHookOptions<AllShopsQuery, AllShopsQueryVariables>) {
        return Apollo.useQuery<AllShopsQuery, AllShopsQueryVariables>(AllShopsDocument, baseOptions);
      }
export function useAllShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllShopsQuery, AllShopsQueryVariables>) {
          return Apollo.useLazyQuery<AllShopsQuery, AllShopsQueryVariables>(AllShopsDocument, baseOptions);
        }
export type AllShopsQueryHookResult = ReturnType<typeof useAllShopsQuery>;
export type AllShopsLazyQueryHookResult = ReturnType<typeof useAllShopsLazyQuery>;
export type AllShopsQueryResult = Apollo.QueryResult<AllShopsQuery, AllShopsQueryVariables>;
export const GetOrderPublicDocument = gql`
    query getOrderPublic($order_id: String!) {
  getOrderPublic(order_id: $order_id) {
    id
    title
    status
    full_name
    comment
    items {
      title
      sku
      amount
      qty
    }
    total
    vat_amount
    grand_total
    customer_info {
      full_name
      tel
      email
      address
    }
    payment_info {
      status
      reason
      method
      account_no
      account_name
      transfer_date
      transfer_time
      transaction_image_url
      bank
    }
    delivery_info {
      delivery_company
      tracking_no
      delivery_time
      cost
    }
    creation
    expiration_date
    total_item
  }
}
    `;

/**
 * __useGetOrderPublicQuery__
 *
 * To run a query within a React component, call `useGetOrderPublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderPublicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderPublicQuery({
 *   variables: {
 *      order_id: // value for 'order_id'
 *   },
 * });
 */
export function useGetOrderPublicQuery(baseOptions: Apollo.QueryHookOptions<GetOrderPublicQuery, GetOrderPublicQueryVariables>) {
        return Apollo.useQuery<GetOrderPublicQuery, GetOrderPublicQueryVariables>(GetOrderPublicDocument, baseOptions);
      }
export function useGetOrderPublicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderPublicQuery, GetOrderPublicQueryVariables>) {
          return Apollo.useLazyQuery<GetOrderPublicQuery, GetOrderPublicQueryVariables>(GetOrderPublicDocument, baseOptions);
        }
export type GetOrderPublicQueryHookResult = ReturnType<typeof useGetOrderPublicQuery>;
export type GetOrderPublicLazyQueryHookResult = ReturnType<typeof useGetOrderPublicLazyQuery>;
export type GetOrderPublicQueryResult = Apollo.QueryResult<GetOrderPublicQuery, GetOrderPublicQueryVariables>;
export const UpdateOrderPaymentPublicDocument = gql`
    mutation updateOrderPaymentPublic($input: OrderPaymentInput!, $order_id: String!) {
  updateOrderPaymentPublic(input: $input, order_id: $order_id) {
    id
    title
    status
    full_name
    comment
    items {
      title
      sku
      amount
      qty
    }
    total
    vat_amount
    grand_total
    customer_info {
      full_name
      tel
      email
      address
    }
    payment_info {
      status
      reason
      method
      account_no
      account_name
      transfer_date
      transfer_time
      transaction_image_url
      bank
    }
    delivery_info {
      delivery_company
      tracking_no
      delivery_time
    }
    creation
    expiration_date
    total_item
  }
}
    `;
export type UpdateOrderPaymentPublicMutationFn = Apollo.MutationFunction<UpdateOrderPaymentPublicMutation, UpdateOrderPaymentPublicMutationVariables>;

/**
 * __useUpdateOrderPaymentPublicMutation__
 *
 * To run a mutation, you first call `useUpdateOrderPaymentPublicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderPaymentPublicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderPaymentPublicMutation, { data, loading, error }] = useUpdateOrderPaymentPublicMutation({
 *   variables: {
 *      input: // value for 'input'
 *      order_id: // value for 'order_id'
 *   },
 * });
 */
export function useUpdateOrderPaymentPublicMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderPaymentPublicMutation, UpdateOrderPaymentPublicMutationVariables>) {
        return Apollo.useMutation<UpdateOrderPaymentPublicMutation, UpdateOrderPaymentPublicMutationVariables>(UpdateOrderPaymentPublicDocument, baseOptions);
      }
export type UpdateOrderPaymentPublicMutationHookResult = ReturnType<typeof useUpdateOrderPaymentPublicMutation>;
export type UpdateOrderPaymentPublicMutationResult = Apollo.MutationResult<UpdateOrderPaymentPublicMutation>;
export type UpdateOrderPaymentPublicMutationOptions = Apollo.BaseMutationOptions<UpdateOrderPaymentPublicMutation, UpdateOrderPaymentPublicMutationVariables>;
export const UploadOrderPaymentImageDocument = gql`
    mutation uploadOrderPaymentImage($file: Upload!, $order_id: String!) {
  uploadOrderPaymentImage(file: $file, order_id: $order_id) {
    id
    filename
    url
    mimetype
    encoding
  }
}
    `;
export type UploadOrderPaymentImageMutationFn = Apollo.MutationFunction<UploadOrderPaymentImageMutation, UploadOrderPaymentImageMutationVariables>;

/**
 * __useUploadOrderPaymentImageMutation__
 *
 * To run a mutation, you first call `useUploadOrderPaymentImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadOrderPaymentImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadOrderPaymentImageMutation, { data, loading, error }] = useUploadOrderPaymentImageMutation({
 *   variables: {
 *      file: // value for 'file'
 *      order_id: // value for 'order_id'
 *   },
 * });
 */
export function useUploadOrderPaymentImageMutation(baseOptions?: Apollo.MutationHookOptions<UploadOrderPaymentImageMutation, UploadOrderPaymentImageMutationVariables>) {
        return Apollo.useMutation<UploadOrderPaymentImageMutation, UploadOrderPaymentImageMutationVariables>(UploadOrderPaymentImageDocument, baseOptions);
      }
export type UploadOrderPaymentImageMutationHookResult = ReturnType<typeof useUploadOrderPaymentImageMutation>;
export type UploadOrderPaymentImageMutationResult = Apollo.MutationResult<UploadOrderPaymentImageMutation>;
export type UploadOrderPaymentImageMutationOptions = Apollo.BaseMutationOptions<UploadOrderPaymentImageMutation, UploadOrderPaymentImageMutationVariables>;
export const GetFbLongLivedUserAccessTokenDocument = gql`
    mutation getFbLongLivedUserAccessToken($appId: String!, $token: String!) {
  getFbLongLivedUserAccessToken(app_id: $appId, token: $token) {
    access_token
    expired_on
  }
}
    `;
export type GetFbLongLivedUserAccessTokenMutationFn = Apollo.MutationFunction<GetFbLongLivedUserAccessTokenMutation, GetFbLongLivedUserAccessTokenMutationVariables>;

/**
 * __useGetFbLongLivedUserAccessTokenMutation__
 *
 * To run a mutation, you first call `useGetFbLongLivedUserAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetFbLongLivedUserAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getFbLongLivedUserAccessTokenMutation, { data, loading, error }] = useGetFbLongLivedUserAccessTokenMutation({
 *   variables: {
 *      appId: // value for 'appId'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetFbLongLivedUserAccessTokenMutation(baseOptions?: Apollo.MutationHookOptions<GetFbLongLivedUserAccessTokenMutation, GetFbLongLivedUserAccessTokenMutationVariables>) {
        return Apollo.useMutation<GetFbLongLivedUserAccessTokenMutation, GetFbLongLivedUserAccessTokenMutationVariables>(GetFbLongLivedUserAccessTokenDocument, baseOptions);
      }
export type GetFbLongLivedUserAccessTokenMutationHookResult = ReturnType<typeof useGetFbLongLivedUserAccessTokenMutation>;
export type GetFbLongLivedUserAccessTokenMutationResult = Apollo.MutationResult<GetFbLongLivedUserAccessTokenMutation>;
export type GetFbLongLivedUserAccessTokenMutationOptions = Apollo.BaseMutationOptions<GetFbLongLivedUserAccessTokenMutation, GetFbLongLivedUserAccessTokenMutationVariables>;
export const UpdatePluginDocument = gql`
    mutation updatePlugin($id: String!, $input: PluginInput!) {
  updatePlugin(id: $id, input: $input) {
    id
  }
}
    `;
export type UpdatePluginMutationFn = Apollo.MutationFunction<UpdatePluginMutation, UpdatePluginMutationVariables>;

/**
 * __useUpdatePluginMutation__
 *
 * To run a mutation, you first call `useUpdatePluginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePluginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePluginMutation, { data, loading, error }] = useUpdatePluginMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePluginMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePluginMutation, UpdatePluginMutationVariables>) {
        return Apollo.useMutation<UpdatePluginMutation, UpdatePluginMutationVariables>(UpdatePluginDocument, baseOptions);
      }
export type UpdatePluginMutationHookResult = ReturnType<typeof useUpdatePluginMutation>;
export type UpdatePluginMutationResult = Apollo.MutationResult<UpdatePluginMutation>;
export type UpdatePluginMutationOptions = Apollo.BaseMutationOptions<UpdatePluginMutation, UpdatePluginMutationVariables>;
export const SearchChitchatDocument = gql`
    query searchChitchat {
  searchChitchat {
    next
    data {
      question
      answer
    }
  }
}
    `;

/**
 * __useSearchChitchatQuery__
 *
 * To run a query within a React component, call `useSearchChitchatQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchChitchatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchChitchatQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchChitchatQuery(baseOptions?: Apollo.QueryHookOptions<SearchChitchatQuery, SearchChitchatQueryVariables>) {
        return Apollo.useQuery<SearchChitchatQuery, SearchChitchatQueryVariables>(SearchChitchatDocument, baseOptions);
      }
export function useSearchChitchatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchChitchatQuery, SearchChitchatQueryVariables>) {
          return Apollo.useLazyQuery<SearchChitchatQuery, SearchChitchatQueryVariables>(SearchChitchatDocument, baseOptions);
        }
export type SearchChitchatQueryHookResult = ReturnType<typeof useSearchChitchatQuery>;
export type SearchChitchatLazyQueryHookResult = ReturnType<typeof useSearchChitchatLazyQuery>;
export type SearchChitchatQueryResult = Apollo.QueryResult<SearchChitchatQuery, SearchChitchatQueryVariables>;
export const GetFriendStatDocument = gql`
    query getFriendStat {
  getFriendStat {
    total
    targetReach
    blocked
    facebook
    line
  }
}
    `;

/**
 * __useGetFriendStatQuery__
 *
 * To run a query within a React component, call `useGetFriendStatQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFriendStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFriendStatQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFriendStatQuery(baseOptions?: Apollo.QueryHookOptions<GetFriendStatQuery, GetFriendStatQueryVariables>) {
        return Apollo.useQuery<GetFriendStatQuery, GetFriendStatQueryVariables>(GetFriendStatDocument, baseOptions);
      }
export function useGetFriendStatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFriendStatQuery, GetFriendStatQueryVariables>) {
          return Apollo.useLazyQuery<GetFriendStatQuery, GetFriendStatQueryVariables>(GetFriendStatDocument, baseOptions);
        }
export type GetFriendStatQueryHookResult = ReturnType<typeof useGetFriendStatQuery>;
export type GetFriendStatLazyQueryHookResult = ReturnType<typeof useGetFriendStatLazyQuery>;
export type GetFriendStatQueryResult = Apollo.QueryResult<GetFriendStatQuery, GetFriendStatQueryVariables>;
export const GetChatIntentHitsDocument = gql`
    query getChatIntentHits($input: ChatInsightInput) {
  getChatIntentHits(input: $input) {
    data {
      title
      count
    }
  }
}
    `;

/**
 * __useGetChatIntentHitsQuery__
 *
 * To run a query within a React component, call `useGetChatIntentHitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatIntentHitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatIntentHitsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetChatIntentHitsQuery(baseOptions?: Apollo.QueryHookOptions<GetChatIntentHitsQuery, GetChatIntentHitsQueryVariables>) {
        return Apollo.useQuery<GetChatIntentHitsQuery, GetChatIntentHitsQueryVariables>(GetChatIntentHitsDocument, baseOptions);
      }
export function useGetChatIntentHitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChatIntentHitsQuery, GetChatIntentHitsQueryVariables>) {
          return Apollo.useLazyQuery<GetChatIntentHitsQuery, GetChatIntentHitsQueryVariables>(GetChatIntentHitsDocument, baseOptions);
        }
export type GetChatIntentHitsQueryHookResult = ReturnType<typeof useGetChatIntentHitsQuery>;
export type GetChatIntentHitsLazyQueryHookResult = ReturnType<typeof useGetChatIntentHitsLazyQuery>;
export type GetChatIntentHitsQueryResult = Apollo.QueryResult<GetChatIntentHitsQuery, GetChatIntentHitsQueryVariables>;
export const ExportChatDocument = gql`
    query exportChat($input: ChatExportInput!) {
  exportChat(input: $input) {
    total
    data {
      message
      intent {
        title
      }
      timestamp
      full_name
    }
  }
}
    `;

/**
 * __useExportChatQuery__
 *
 * To run a query within a React component, call `useExportChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportChatQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportChatQuery(baseOptions: Apollo.QueryHookOptions<ExportChatQuery, ExportChatQueryVariables>) {
        return Apollo.useQuery<ExportChatQuery, ExportChatQueryVariables>(ExportChatDocument, baseOptions);
      }
export function useExportChatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportChatQuery, ExportChatQueryVariables>) {
          return Apollo.useLazyQuery<ExportChatQuery, ExportChatQueryVariables>(ExportChatDocument, baseOptions);
        }
export type ExportChatQueryHookResult = ReturnType<typeof useExportChatQuery>;
export type ExportChatLazyQueryHookResult = ReturnType<typeof useExportChatLazyQuery>;
export type ExportChatQueryResult = Apollo.QueryResult<ExportChatQuery, ExportChatQueryVariables>;
export const GetLearnStatsDocument = gql`
    query getLearnStats {
  getTrainingReport {
    data {
      keyword_id
      title
      sentence {
        text
      }
      creation
    }
  }
  getLearnedKeywordReport {
    total_keywords
    total_sentences
    total_today_keywords
    total_today_learns
  }
}
    `;

/**
 * __useGetLearnStatsQuery__
 *
 * To run a query within a React component, call `useGetLearnStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLearnStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLearnStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLearnStatsQuery(baseOptions?: Apollo.QueryHookOptions<GetLearnStatsQuery, GetLearnStatsQueryVariables>) {
        return Apollo.useQuery<GetLearnStatsQuery, GetLearnStatsQueryVariables>(GetLearnStatsDocument, baseOptions);
      }
export function useGetLearnStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLearnStatsQuery, GetLearnStatsQueryVariables>) {
          return Apollo.useLazyQuery<GetLearnStatsQuery, GetLearnStatsQueryVariables>(GetLearnStatsDocument, baseOptions);
        }
export type GetLearnStatsQueryHookResult = ReturnType<typeof useGetLearnStatsQuery>;
export type GetLearnStatsLazyQueryHookResult = ReturnType<typeof useGetLearnStatsLazyQuery>;
export type GetLearnStatsQueryResult = Apollo.QueryResult<GetLearnStatsQuery, GetLearnStatsQueryVariables>;
export const SearchUserInboxDocument = gql`
    query searchUserInbox($input: UserInboxSearchInput) {
  searchUserInbox(input: $input) {
    next
    data {
      id
      read
      message
      type_id
      type
      creation
    }
  }
}
    `;

/**
 * __useSearchUserInboxQuery__
 *
 * To run a query within a React component, call `useSearchUserInboxQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUserInboxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUserInboxQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchUserInboxQuery(baseOptions?: Apollo.QueryHookOptions<SearchUserInboxQuery, SearchUserInboxQueryVariables>) {
        return Apollo.useQuery<SearchUserInboxQuery, SearchUserInboxQueryVariables>(SearchUserInboxDocument, baseOptions);
      }
export function useSearchUserInboxLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchUserInboxQuery, SearchUserInboxQueryVariables>) {
          return Apollo.useLazyQuery<SearchUserInboxQuery, SearchUserInboxQueryVariables>(SearchUserInboxDocument, baseOptions);
        }
export type SearchUserInboxQueryHookResult = ReturnType<typeof useSearchUserInboxQuery>;
export type SearchUserInboxLazyQueryHookResult = ReturnType<typeof useSearchUserInboxLazyQuery>;
export type SearchUserInboxQueryResult = Apollo.QueryResult<SearchUserInboxQuery, SearchUserInboxQueryVariables>;
export const OnUserInboxChangedDocument = gql`
    subscription onUserInboxChanged {
  onUserInboxChanged {
    operation
    data {
      id
      read
      message
      type_id
      type
    }
  }
}
    `;

/**
 * __useOnUserInboxChangedSubscription__
 *
 * To run a query within a React component, call `useOnUserInboxChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUserInboxChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUserInboxChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnUserInboxChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnUserInboxChangedSubscription, OnUserInboxChangedSubscriptionVariables>) {
        return Apollo.useSubscription<OnUserInboxChangedSubscription, OnUserInboxChangedSubscriptionVariables>(OnUserInboxChangedDocument, baseOptions);
      }
export type OnUserInboxChangedSubscriptionHookResult = ReturnType<typeof useOnUserInboxChangedSubscription>;
export type OnUserInboxChangedSubscriptionResult = Apollo.SubscriptionResult<OnUserInboxChangedSubscription>;
export const ReadUserInboxDocument = gql`
    mutation readUserInbox($id: String!) {
  readUserInbox(id: $id) {
    id
  }
}
    `;
export type ReadUserInboxMutationFn = Apollo.MutationFunction<ReadUserInboxMutation, ReadUserInboxMutationVariables>;

/**
 * __useReadUserInboxMutation__
 *
 * To run a mutation, you first call `useReadUserInboxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadUserInboxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readUserInboxMutation, { data, loading, error }] = useReadUserInboxMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadUserInboxMutation(baseOptions?: Apollo.MutationHookOptions<ReadUserInboxMutation, ReadUserInboxMutationVariables>) {
        return Apollo.useMutation<ReadUserInboxMutation, ReadUserInboxMutationVariables>(ReadUserInboxDocument, baseOptions);
      }
export type ReadUserInboxMutationHookResult = ReturnType<typeof useReadUserInboxMutation>;
export type ReadUserInboxMutationResult = Apollo.MutationResult<ReadUserInboxMutation>;
export type ReadUserInboxMutationOptions = Apollo.BaseMutationOptions<ReadUserInboxMutation, ReadUserInboxMutationVariables>;
export const CountUserInboxDocument = gql`
    query countUserInbox($read: Boolean!) {
  countUserInbox(read: $read)
}
    `;

/**
 * __useCountUserInboxQuery__
 *
 * To run a query within a React component, call `useCountUserInboxQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountUserInboxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountUserInboxQuery({
 *   variables: {
 *      read: // value for 'read'
 *   },
 * });
 */
export function useCountUserInboxQuery(baseOptions: Apollo.QueryHookOptions<CountUserInboxQuery, CountUserInboxQueryVariables>) {
        return Apollo.useQuery<CountUserInboxQuery, CountUserInboxQueryVariables>(CountUserInboxDocument, baseOptions);
      }
export function useCountUserInboxLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountUserInboxQuery, CountUserInboxQueryVariables>) {
          return Apollo.useLazyQuery<CountUserInboxQuery, CountUserInboxQueryVariables>(CountUserInboxDocument, baseOptions);
        }
export type CountUserInboxQueryHookResult = ReturnType<typeof useCountUserInboxQuery>;
export type CountUserInboxLazyQueryHookResult = ReturnType<typeof useCountUserInboxLazyQuery>;
export type CountUserInboxQueryResult = Apollo.QueryResult<CountUserInboxQuery, CountUserInboxQueryVariables>;
export const SearchInboxDocument = gql`
    query searchInbox($filters: InboxSearchFilter, $page: InboxSearchPagination) {
  searchInbox(input: {filters: $filters, page: $page}) {
    total
    next
    data {
      id
      chat_id
      title
      picture_url
      read
      tags
      platform
      status
      event
      bot_id
      creation
      bot_disabled
    }
  }
}
    `;

/**
 * __useSearchInboxQuery__
 *
 * To run a query within a React component, call `useSearchInboxQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchInboxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchInboxQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useSearchInboxQuery(baseOptions?: Apollo.QueryHookOptions<SearchInboxQuery, SearchInboxQueryVariables>) {
        return Apollo.useQuery<SearchInboxQuery, SearchInboxQueryVariables>(SearchInboxDocument, baseOptions);
      }
export function useSearchInboxLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchInboxQuery, SearchInboxQueryVariables>) {
          return Apollo.useLazyQuery<SearchInboxQuery, SearchInboxQueryVariables>(SearchInboxDocument, baseOptions);
        }
export type SearchInboxQueryHookResult = ReturnType<typeof useSearchInboxQuery>;
export type SearchInboxLazyQueryHookResult = ReturnType<typeof useSearchInboxLazyQuery>;
export type SearchInboxQueryResult = Apollo.QueryResult<SearchInboxQuery, SearchInboxQueryVariables>;
export const GetInboxDocument = gql`
    query getInbox($inboxId: String!) {
  getInbox(inbox_id: $inboxId) {
    chat_id
    bot_id
    id
    title
    picture_url
    platform
    tags
    bot_disabled
    status
    creation
  }
}
    `;

/**
 * __useGetInboxQuery__
 *
 * To run a query within a React component, call `useGetInboxQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInboxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInboxQuery({
 *   variables: {
 *      inboxId: // value for 'inboxId'
 *   },
 * });
 */
export function useGetInboxQuery(baseOptions: Apollo.QueryHookOptions<GetInboxQuery, GetInboxQueryVariables>) {
        return Apollo.useQuery<GetInboxQuery, GetInboxQueryVariables>(GetInboxDocument, baseOptions);
      }
export function useGetInboxLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInboxQuery, GetInboxQueryVariables>) {
          return Apollo.useLazyQuery<GetInboxQuery, GetInboxQueryVariables>(GetInboxDocument, baseOptions);
        }
export type GetInboxQueryHookResult = ReturnType<typeof useGetInboxQuery>;
export type GetInboxLazyQueryHookResult = ReturnType<typeof useGetInboxLazyQuery>;
export type GetInboxQueryResult = Apollo.QueryResult<GetInboxQuery, GetInboxQueryVariables>;
export const SearchChatDocument = gql`
    query searchChat($inboxId: String!, $page: ChatSearchPagination, $filters: ChatSearchFilter) {
  searchChat(input: {inbox_id: $inboxId, page: $page, filters: $filters}) {
    total
    next
    data {
      id
      bot_id
      channel_id
      chat_id
      message
      postback {
        title
        data
      }
      source {
        type
        name
        userId
        id
      }
      timestamp
      type
    }
  }
}
    `;

/**
 * __useSearchChatQuery__
 *
 * To run a query within a React component, call `useSearchChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchChatQuery({
 *   variables: {
 *      inboxId: // value for 'inboxId'
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSearchChatQuery(baseOptions: Apollo.QueryHookOptions<SearchChatQuery, SearchChatQueryVariables>) {
        return Apollo.useQuery<SearchChatQuery, SearchChatQueryVariables>(SearchChatDocument, baseOptions);
      }
export function useSearchChatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchChatQuery, SearchChatQueryVariables>) {
          return Apollo.useLazyQuery<SearchChatQuery, SearchChatQueryVariables>(SearchChatDocument, baseOptions);
        }
export type SearchChatQueryHookResult = ReturnType<typeof useSearchChatQuery>;
export type SearchChatLazyQueryHookResult = ReturnType<typeof useSearchChatLazyQuery>;
export type SearchChatQueryResult = Apollo.QueryResult<SearchChatQuery, SearchChatQueryVariables>;
export const IssueTicketDocument = gql`
    mutation issueTicket($input: IssueTicketInput!) {
  issueTicket(input: $input) {
    id
    code
  }
}
    `;
export type IssueTicketMutationFn = Apollo.MutationFunction<IssueTicketMutation, IssueTicketMutationVariables>;

/**
 * __useIssueTicketMutation__
 *
 * To run a mutation, you first call `useIssueTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueTicketMutation, { data, loading, error }] = useIssueTicketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIssueTicketMutation(baseOptions?: Apollo.MutationHookOptions<IssueTicketMutation, IssueTicketMutationVariables>) {
        return Apollo.useMutation<IssueTicketMutation, IssueTicketMutationVariables>(IssueTicketDocument, baseOptions);
      }
export type IssueTicketMutationHookResult = ReturnType<typeof useIssueTicketMutation>;
export type IssueTicketMutationResult = Apollo.MutationResult<IssueTicketMutation>;
export type IssueTicketMutationOptions = Apollo.BaseMutationOptions<IssueTicketMutation, IssueTicketMutationVariables>;
export const SearchActivityDocument = gql`
    query searchActivity($input: ActivitySearchInput!) {
  searchActivity(input: $input) {
    total
    next
    data {
      id
      service
      name
      user_id
      message
      body
      created_by
      creation
      friend {
        id
        full_name
        picture_url
      }
    }
  }
}
    `;

/**
 * __useSearchActivityQuery__
 *
 * To run a query within a React component, call `useSearchActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchActivityQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchActivityQuery(baseOptions: Apollo.QueryHookOptions<SearchActivityQuery, SearchActivityQueryVariables>) {
        return Apollo.useQuery<SearchActivityQuery, SearchActivityQueryVariables>(SearchActivityDocument, baseOptions);
      }
export function useSearchActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchActivityQuery, SearchActivityQueryVariables>) {
          return Apollo.useLazyQuery<SearchActivityQuery, SearchActivityQueryVariables>(SearchActivityDocument, baseOptions);
        }
export type SearchActivityQueryHookResult = ReturnType<typeof useSearchActivityQuery>;
export type SearchActivityLazyQueryHookResult = ReturnType<typeof useSearchActivityLazyQuery>;
export type SearchActivityQueryResult = Apollo.QueryResult<SearchActivityQuery, SearchActivityQueryVariables>;
export const SearchOrderDocument = gql`
    query searchOrder($input: OrderSearchInput) {
  searchOrder(input: $input) {
    next
    data {
      status
      id
      items {
        title
        amount
        sku
        qty
      }
      comment
      full_name
      total
      vat_amount
      grand_total
      creation
      user_id
      customer_info {
        full_name
        tel
        address
      }
      delivery_info {
        cost
        delivery_company
        tracking_no
        delivery_time
      }
      cancel_reason
      payment_info {
        status
        transfer_date
        transfer_time
        transaction_image_url
      }
    }
  }
}
    `;

/**
 * __useSearchOrderQuery__
 *
 * To run a query within a React component, call `useSearchOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchOrderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchOrderQuery(baseOptions?: Apollo.QueryHookOptions<SearchOrderQuery, SearchOrderQueryVariables>) {
        return Apollo.useQuery<SearchOrderQuery, SearchOrderQueryVariables>(SearchOrderDocument, baseOptions);
      }
export function useSearchOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchOrderQuery, SearchOrderQueryVariables>) {
          return Apollo.useLazyQuery<SearchOrderQuery, SearchOrderQueryVariables>(SearchOrderDocument, baseOptions);
        }
export type SearchOrderQueryHookResult = ReturnType<typeof useSearchOrderQuery>;
export type SearchOrderLazyQueryHookResult = ReturnType<typeof useSearchOrderLazyQuery>;
export type SearchOrderQueryResult = Apollo.QueryResult<SearchOrderQuery, SearchOrderQueryVariables>;
export const CreateOrderDocument = gql`
    mutation createOrder($input: OrderInput!) {
  createOrder(input: $input) {
    status
    id
    items {
      title
      amount
      sku
      qty
    }
    comment
    chat_id
    user_id
    full_name
    total
    vat_amount
    grand_total
    creation
  }
}
    `;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, baseOptions);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const GetOrderDocument = gql`
    query getOrder($order_id: String!) {
  getOrder(order_id: $order_id) {
    status
    id
    items {
      title
      amount
      sku
      qty
    }
    comment
    chat_id
    user_id
    full_name
    total
    vat_amount
    grand_total
    creation
    customer_info {
      full_name
      tel
      address
    }
    delivery_info {
      cost
      delivery_company
      tracking_no
      delivery_time
    }
    cancel_reason
    payment_info {
      status
      transfer_date
      transfer_time
      transaction_image_url
    }
  }
}
    `;

/**
 * __useGetOrderQuery__
 *
 * To run a query within a React component, call `useGetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderQuery({
 *   variables: {
 *      order_id: // value for 'order_id'
 *   },
 * });
 */
export function useGetOrderQuery(baseOptions: Apollo.QueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
        return Apollo.useQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, baseOptions);
      }
export function useGetOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
          return Apollo.useLazyQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, baseOptions);
        }
export type GetOrderQueryHookResult = ReturnType<typeof useGetOrderQuery>;
export type GetOrderLazyQueryHookResult = ReturnType<typeof useGetOrderLazyQuery>;
export type GetOrderQueryResult = Apollo.QueryResult<GetOrderQuery, GetOrderQueryVariables>;
export const UpdateOrderDocument = gql`
    mutation updateOrder($order_id: String!, $input: OrderUpdate!) {
  updateOrder(order_id: $order_id, input: $input) {
    id
  }
}
    `;
export type UpdateOrderMutationFn = Apollo.MutationFunction<UpdateOrderMutation, UpdateOrderMutationVariables>;

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      order_id: // value for 'order_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderMutation, UpdateOrderMutationVariables>) {
        return Apollo.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(UpdateOrderDocument, baseOptions);
      }
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>;
export type UpdateOrderMutationResult = Apollo.MutationResult<UpdateOrderMutation>;
export type UpdateOrderMutationOptions = Apollo.BaseMutationOptions<UpdateOrderMutation, UpdateOrderMutationVariables>;
export const RemoveOrderDocument = gql`
    mutation removeOrder($id: String!) {
  removeOrder(id: $id) {
    id
  }
}
    `;
export type RemoveOrderMutationFn = Apollo.MutationFunction<RemoveOrderMutation, RemoveOrderMutationVariables>;

/**
 * __useRemoveOrderMutation__
 *
 * To run a mutation, you first call `useRemoveOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrderMutation, { data, loading, error }] = useRemoveOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveOrderMutation(baseOptions?: Apollo.MutationHookOptions<RemoveOrderMutation, RemoveOrderMutationVariables>) {
        return Apollo.useMutation<RemoveOrderMutation, RemoveOrderMutationVariables>(RemoveOrderDocument, baseOptions);
      }
export type RemoveOrderMutationHookResult = ReturnType<typeof useRemoveOrderMutation>;
export type RemoveOrderMutationResult = Apollo.MutationResult<RemoveOrderMutation>;
export type RemoveOrderMutationOptions = Apollo.BaseMutationOptions<RemoveOrderMutation, RemoveOrderMutationVariables>;
export const CancelOrderPaymentDocument = gql`
    mutation cancelOrderPayment($order_id: String!, $cancel_reason: String!) {
  cancelOrderPayment(order_id: $order_id, cancel_reason: $cancel_reason) {
    id
  }
}
    `;
export type CancelOrderPaymentMutationFn = Apollo.MutationFunction<CancelOrderPaymentMutation, CancelOrderPaymentMutationVariables>;

/**
 * __useCancelOrderPaymentMutation__
 *
 * To run a mutation, you first call `useCancelOrderPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOrderPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOrderPaymentMutation, { data, loading, error }] = useCancelOrderPaymentMutation({
 *   variables: {
 *      order_id: // value for 'order_id'
 *      cancel_reason: // value for 'cancel_reason'
 *   },
 * });
 */
export function useCancelOrderPaymentMutation(baseOptions?: Apollo.MutationHookOptions<CancelOrderPaymentMutation, CancelOrderPaymentMutationVariables>) {
        return Apollo.useMutation<CancelOrderPaymentMutation, CancelOrderPaymentMutationVariables>(CancelOrderPaymentDocument, baseOptions);
      }
export type CancelOrderPaymentMutationHookResult = ReturnType<typeof useCancelOrderPaymentMutation>;
export type CancelOrderPaymentMutationResult = Apollo.MutationResult<CancelOrderPaymentMutation>;
export type CancelOrderPaymentMutationOptions = Apollo.BaseMutationOptions<CancelOrderPaymentMutation, CancelOrderPaymentMutationVariables>;
export const ConfirmOrderPaymentDocument = gql`
    mutation confirmOrderPayment($order_id: String!) {
  confirmOrderPayment(order_id: $order_id) {
    id
  }
}
    `;
export type ConfirmOrderPaymentMutationFn = Apollo.MutationFunction<ConfirmOrderPaymentMutation, ConfirmOrderPaymentMutationVariables>;

/**
 * __useConfirmOrderPaymentMutation__
 *
 * To run a mutation, you first call `useConfirmOrderPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmOrderPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmOrderPaymentMutation, { data, loading, error }] = useConfirmOrderPaymentMutation({
 *   variables: {
 *      order_id: // value for 'order_id'
 *   },
 * });
 */
export function useConfirmOrderPaymentMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmOrderPaymentMutation, ConfirmOrderPaymentMutationVariables>) {
        return Apollo.useMutation<ConfirmOrderPaymentMutation, ConfirmOrderPaymentMutationVariables>(ConfirmOrderPaymentDocument, baseOptions);
      }
export type ConfirmOrderPaymentMutationHookResult = ReturnType<typeof useConfirmOrderPaymentMutation>;
export type ConfirmOrderPaymentMutationResult = Apollo.MutationResult<ConfirmOrderPaymentMutation>;
export type ConfirmOrderPaymentMutationOptions = Apollo.BaseMutationOptions<ConfirmOrderPaymentMutation, ConfirmOrderPaymentMutationVariables>;
export const IssueOrderPaymentDocument = gql`
    mutation issueOrderPayment($input: OrderIssuePaymentInput!, $order_id: String!) {
  issueOrderPayment(input: $input, order_id: $order_id) {
    id
  }
}
    `;
export type IssueOrderPaymentMutationFn = Apollo.MutationFunction<IssueOrderPaymentMutation, IssueOrderPaymentMutationVariables>;

/**
 * __useIssueOrderPaymentMutation__
 *
 * To run a mutation, you first call `useIssueOrderPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueOrderPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueOrderPaymentMutation, { data, loading, error }] = useIssueOrderPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      order_id: // value for 'order_id'
 *   },
 * });
 */
export function useIssueOrderPaymentMutation(baseOptions?: Apollo.MutationHookOptions<IssueOrderPaymentMutation, IssueOrderPaymentMutationVariables>) {
        return Apollo.useMutation<IssueOrderPaymentMutation, IssueOrderPaymentMutationVariables>(IssueOrderPaymentDocument, baseOptions);
      }
export type IssueOrderPaymentMutationHookResult = ReturnType<typeof useIssueOrderPaymentMutation>;
export type IssueOrderPaymentMutationResult = Apollo.MutationResult<IssueOrderPaymentMutation>;
export type IssueOrderPaymentMutationOptions = Apollo.BaseMutationOptions<IssueOrderPaymentMutation, IssueOrderPaymentMutationVariables>;
export const UpdateOrderDeliveryDocument = gql`
    mutation updateOrderDelivery($input: OrderDeliveryInfoInput!, $order_id: String!) {
  updateOrderDelivery(input: $input, order_id: $order_id) {
    id
  }
}
    `;
export type UpdateOrderDeliveryMutationFn = Apollo.MutationFunction<UpdateOrderDeliveryMutation, UpdateOrderDeliveryMutationVariables>;

/**
 * __useUpdateOrderDeliveryMutation__
 *
 * To run a mutation, you first call `useUpdateOrderDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderDeliveryMutation, { data, loading, error }] = useUpdateOrderDeliveryMutation({
 *   variables: {
 *      input: // value for 'input'
 *      order_id: // value for 'order_id'
 *   },
 * });
 */
export function useUpdateOrderDeliveryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderDeliveryMutation, UpdateOrderDeliveryMutationVariables>) {
        return Apollo.useMutation<UpdateOrderDeliveryMutation, UpdateOrderDeliveryMutationVariables>(UpdateOrderDeliveryDocument, baseOptions);
      }
export type UpdateOrderDeliveryMutationHookResult = ReturnType<typeof useUpdateOrderDeliveryMutation>;
export type UpdateOrderDeliveryMutationResult = Apollo.MutationResult<UpdateOrderDeliveryMutation>;
export type UpdateOrderDeliveryMutationOptions = Apollo.BaseMutationOptions<UpdateOrderDeliveryMutation, UpdateOrderDeliveryMutationVariables>;
export const ConfirmLinepayPaymentDocument = gql`
    mutation confirmLinepayPayment($order_id: String!, $transaction_id: String!) {
  confirmLinepayPayment(order_id: $order_id, transaction_id: $transaction_id) {
    id
  }
}
    `;
export type ConfirmLinepayPaymentMutationFn = Apollo.MutationFunction<ConfirmLinepayPaymentMutation, ConfirmLinepayPaymentMutationVariables>;

/**
 * __useConfirmLinepayPaymentMutation__
 *
 * To run a mutation, you first call `useConfirmLinepayPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmLinepayPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmLinepayPaymentMutation, { data, loading, error }] = useConfirmLinepayPaymentMutation({
 *   variables: {
 *      order_id: // value for 'order_id'
 *      transaction_id: // value for 'transaction_id'
 *   },
 * });
 */
export function useConfirmLinepayPaymentMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmLinepayPaymentMutation, ConfirmLinepayPaymentMutationVariables>) {
        return Apollo.useMutation<ConfirmLinepayPaymentMutation, ConfirmLinepayPaymentMutationVariables>(ConfirmLinepayPaymentDocument, baseOptions);
      }
export type ConfirmLinepayPaymentMutationHookResult = ReturnType<typeof useConfirmLinepayPaymentMutation>;
export type ConfirmLinepayPaymentMutationResult = Apollo.MutationResult<ConfirmLinepayPaymentMutation>;
export type ConfirmLinepayPaymentMutationOptions = Apollo.BaseMutationOptions<ConfirmLinepayPaymentMutation, ConfirmLinepayPaymentMutationVariables>;
export const OnOrderChangedDocument = gql`
    subscription onOrderChanged($orderId: String) {
  onOrderChanged(order_id: $orderId) {
    operation
    data {
      status
      id
      items {
        title
        amount
        sku
        qty
      }
      comment
      full_name
      total
      vat_amount
      grand_total
      creation
      customer_info {
        full_name
        tel
        address
      }
      delivery_info {
        cost
        delivery_company
        tracking_no
        delivery_time
      }
      cancel_reason
      payment_info {
        status
        transfer_date
        transfer_time
        transaction_image_url
      }
    }
  }
}
    `;

/**
 * __useOnOrderChangedSubscription__
 *
 * To run a query within a React component, call `useOnOrderChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnOrderChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnOrderChangedSubscription({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOnOrderChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnOrderChangedSubscription, OnOrderChangedSubscriptionVariables>) {
        return Apollo.useSubscription<OnOrderChangedSubscription, OnOrderChangedSubscriptionVariables>(OnOrderChangedDocument, baseOptions);
      }
export type OnOrderChangedSubscriptionHookResult = ReturnType<typeof useOnOrderChangedSubscription>;
export type OnOrderChangedSubscriptionResult = Apollo.SubscriptionResult<OnOrderChangedSubscription>;
export const SearchProductCategoryDocument = gql`
    query searchProductCategory {
  searchProductCategory {
    data {
      title
      picture_url
      id
      description
    }
  }
}
    `;

/**
 * __useSearchProductCategoryQuery__
 *
 * To run a query within a React component, call `useSearchProductCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProductCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProductCategoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchProductCategoryQuery(baseOptions?: Apollo.QueryHookOptions<SearchProductCategoryQuery, SearchProductCategoryQueryVariables>) {
        return Apollo.useQuery<SearchProductCategoryQuery, SearchProductCategoryQueryVariables>(SearchProductCategoryDocument, baseOptions);
      }
export function useSearchProductCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchProductCategoryQuery, SearchProductCategoryQueryVariables>) {
          return Apollo.useLazyQuery<SearchProductCategoryQuery, SearchProductCategoryQueryVariables>(SearchProductCategoryDocument, baseOptions);
        }
export type SearchProductCategoryQueryHookResult = ReturnType<typeof useSearchProductCategoryQuery>;
export type SearchProductCategoryLazyQueryHookResult = ReturnType<typeof useSearchProductCategoryLazyQuery>;
export type SearchProductCategoryQueryResult = Apollo.QueryResult<SearchProductCategoryQuery, SearchProductCategoryQueryVariables>;
export const SearchProductCategoryPublicDocument = gql`
    query searchProductCategoryPublic($botId: String!) {
  searchProductCategoryPublic(bot_id: $botId) {
    next
    total
    data {
      id
      title
      picture_url
    }
  }
}
    `;

/**
 * __useSearchProductCategoryPublicQuery__
 *
 * To run a query within a React component, call `useSearchProductCategoryPublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProductCategoryPublicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProductCategoryPublicQuery({
 *   variables: {
 *      botId: // value for 'botId'
 *   },
 * });
 */
export function useSearchProductCategoryPublicQuery(baseOptions: Apollo.QueryHookOptions<SearchProductCategoryPublicQuery, SearchProductCategoryPublicQueryVariables>) {
        return Apollo.useQuery<SearchProductCategoryPublicQuery, SearchProductCategoryPublicQueryVariables>(SearchProductCategoryPublicDocument, baseOptions);
      }
export function useSearchProductCategoryPublicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchProductCategoryPublicQuery, SearchProductCategoryPublicQueryVariables>) {
          return Apollo.useLazyQuery<SearchProductCategoryPublicQuery, SearchProductCategoryPublicQueryVariables>(SearchProductCategoryPublicDocument, baseOptions);
        }
export type SearchProductCategoryPublicQueryHookResult = ReturnType<typeof useSearchProductCategoryPublicQuery>;
export type SearchProductCategoryPublicLazyQueryHookResult = ReturnType<typeof useSearchProductCategoryPublicLazyQuery>;
export type SearchProductCategoryPublicQueryResult = Apollo.QueryResult<SearchProductCategoryPublicQuery, SearchProductCategoryPublicQueryVariables>;
export const CreateProductCategoryDocument = gql`
    mutation createProductCategory($input: ProductCategoryInput!) {
  createProductCategory(input: $input) {
    id
    description
    picture_url
  }
}
    `;
export type CreateProductCategoryMutationFn = Apollo.MutationFunction<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>;

/**
 * __useCreateProductCategoryMutation__
 *
 * To run a mutation, you first call `useCreateProductCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductCategoryMutation, { data, loading, error }] = useCreateProductCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>) {
        return Apollo.useMutation<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>(CreateProductCategoryDocument, baseOptions);
      }
export type CreateProductCategoryMutationHookResult = ReturnType<typeof useCreateProductCategoryMutation>;
export type CreateProductCategoryMutationResult = Apollo.MutationResult<CreateProductCategoryMutation>;
export type CreateProductCategoryMutationOptions = Apollo.BaseMutationOptions<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>;
export const GetProductCategoryDocument = gql`
    query getProductCategory($id: String!) {
  getProductCategory(id: $id) {
    id
    title
    picture_url
  }
}
    `;

/**
 * __useGetProductCategoryQuery__
 *
 * To run a query within a React component, call `useGetProductCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductCategoryQuery(baseOptions: Apollo.QueryHookOptions<GetProductCategoryQuery, GetProductCategoryQueryVariables>) {
        return Apollo.useQuery<GetProductCategoryQuery, GetProductCategoryQueryVariables>(GetProductCategoryDocument, baseOptions);
      }
export function useGetProductCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductCategoryQuery, GetProductCategoryQueryVariables>) {
          return Apollo.useLazyQuery<GetProductCategoryQuery, GetProductCategoryQueryVariables>(GetProductCategoryDocument, baseOptions);
        }
export type GetProductCategoryQueryHookResult = ReturnType<typeof useGetProductCategoryQuery>;
export type GetProductCategoryLazyQueryHookResult = ReturnType<typeof useGetProductCategoryLazyQuery>;
export type GetProductCategoryQueryResult = Apollo.QueryResult<GetProductCategoryQuery, GetProductCategoryQueryVariables>;
export const UpdateProductCategoryDocument = gql`
    mutation updateProductCategory($id: String!, $input: ProductCategoryUpdate!) {
  updateProductCategory(id: $id, input: $input) {
    id
    picture_url
  }
}
    `;
export type UpdateProductCategoryMutationFn = Apollo.MutationFunction<UpdateProductCategoryMutation, UpdateProductCategoryMutationVariables>;

/**
 * __useUpdateProductCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateProductCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductCategoryMutation, { data, loading, error }] = useUpdateProductCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductCategoryMutation, UpdateProductCategoryMutationVariables>) {
        return Apollo.useMutation<UpdateProductCategoryMutation, UpdateProductCategoryMutationVariables>(UpdateProductCategoryDocument, baseOptions);
      }
export type UpdateProductCategoryMutationHookResult = ReturnType<typeof useUpdateProductCategoryMutation>;
export type UpdateProductCategoryMutationResult = Apollo.MutationResult<UpdateProductCategoryMutation>;
export type UpdateProductCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateProductCategoryMutation, UpdateProductCategoryMutationVariables>;
export const RemoveProductCategoryDocument = gql`
    mutation removeProductCategory($id: String!) {
  removeProductCategory(id: $id) {
    id
  }
}
    `;
export type RemoveProductCategoryMutationFn = Apollo.MutationFunction<RemoveProductCategoryMutation, RemoveProductCategoryMutationVariables>;

/**
 * __useRemoveProductCategoryMutation__
 *
 * To run a mutation, you first call `useRemoveProductCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProductCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProductCategoryMutation, { data, loading, error }] = useRemoveProductCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveProductCategoryMutation(baseOptions?: Apollo.MutationHookOptions<RemoveProductCategoryMutation, RemoveProductCategoryMutationVariables>) {
        return Apollo.useMutation<RemoveProductCategoryMutation, RemoveProductCategoryMutationVariables>(RemoveProductCategoryDocument, baseOptions);
      }
export type RemoveProductCategoryMutationHookResult = ReturnType<typeof useRemoveProductCategoryMutation>;
export type RemoveProductCategoryMutationResult = Apollo.MutationResult<RemoveProductCategoryMutation>;
export type RemoveProductCategoryMutationOptions = Apollo.BaseMutationOptions<RemoveProductCategoryMutation, RemoveProductCategoryMutationVariables>;
export const ImportProductCategoryDocument = gql`
    mutation importProductCategory($google_sheet_id: String!, $sheet_number: Float!) {
  importProductCategory(sheet_number: $sheet_number, google_sheet_id: $google_sheet_id) {
    total
  }
}
    `;
export type ImportProductCategoryMutationFn = Apollo.MutationFunction<ImportProductCategoryMutation, ImportProductCategoryMutationVariables>;

/**
 * __useImportProductCategoryMutation__
 *
 * To run a mutation, you first call `useImportProductCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportProductCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importProductCategoryMutation, { data, loading, error }] = useImportProductCategoryMutation({
 *   variables: {
 *      google_sheet_id: // value for 'google_sheet_id'
 *      sheet_number: // value for 'sheet_number'
 *   },
 * });
 */
export function useImportProductCategoryMutation(baseOptions?: Apollo.MutationHookOptions<ImportProductCategoryMutation, ImportProductCategoryMutationVariables>) {
        return Apollo.useMutation<ImportProductCategoryMutation, ImportProductCategoryMutationVariables>(ImportProductCategoryDocument, baseOptions);
      }
export type ImportProductCategoryMutationHookResult = ReturnType<typeof useImportProductCategoryMutation>;
export type ImportProductCategoryMutationResult = Apollo.MutationResult<ImportProductCategoryMutation>;
export type ImportProductCategoryMutationOptions = Apollo.BaseMutationOptions<ImportProductCategoryMutation, ImportProductCategoryMutationVariables>;
export const OnProductCategoryChangedDocument = gql`
    subscription onProductCategoryChanged {
  onProductCategoryChanged {
    operation
    data {
      id
      title
      picture_url
      description
    }
  }
}
    `;

/**
 * __useOnProductCategoryChangedSubscription__
 *
 * To run a query within a React component, call `useOnProductCategoryChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnProductCategoryChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnProductCategoryChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnProductCategoryChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnProductCategoryChangedSubscription, OnProductCategoryChangedSubscriptionVariables>) {
        return Apollo.useSubscription<OnProductCategoryChangedSubscription, OnProductCategoryChangedSubscriptionVariables>(OnProductCategoryChangedDocument, baseOptions);
      }
export type OnProductCategoryChangedSubscriptionHookResult = ReturnType<typeof useOnProductCategoryChangedSubscription>;
export type OnProductCategoryChangedSubscriptionResult = Apollo.SubscriptionResult<OnProductCategoryChangedSubscription>;
export const SearchProductDocument = gql`
    query searchProduct($input: ProductSearchInput) {
  searchProduct(input: $input) {
    next
    data {
      id
      sku
      title
      category
      full_price
      sell_description
      sell_price
      price_per_year
      price_per_month
      type
      picture_url
      picture_urls
      stock_qty
      keywords
      live_status
    }
  }
}
    `;

/**
 * __useSearchProductQuery__
 *
 * To run a query within a React component, call `useSearchProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProductQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchProductQuery(baseOptions?: Apollo.QueryHookOptions<SearchProductQuery, SearchProductQueryVariables>) {
        return Apollo.useQuery<SearchProductQuery, SearchProductQueryVariables>(SearchProductDocument, baseOptions);
      }
export function useSearchProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchProductQuery, SearchProductQueryVariables>) {
          return Apollo.useLazyQuery<SearchProductQuery, SearchProductQueryVariables>(SearchProductDocument, baseOptions);
        }
export type SearchProductQueryHookResult = ReturnType<typeof useSearchProductQuery>;
export type SearchProductLazyQueryHookResult = ReturnType<typeof useSearchProductLazyQuery>;
export type SearchProductQueryResult = Apollo.QueryResult<SearchProductQuery, SearchProductQueryVariables>;
export const GetProductDocument = gql`
    query getProduct($id: String!) {
  getProduct(id: $id) {
    id
    sku
    title
    full_price
    description
    sell_price
    sell_description
    price_per_month
    price_per_year
    category
    picture_url
    picture_urls
    bot {
      id
    }
    stock_qty
    stock_total_in
    stock_total_out
    live {
      id
      title
    }
    keywords
    live_id
    live_status
  }
}
    `;

/**
 * __useGetProductQuery__
 *
 * To run a query within a React component, call `useGetProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductQuery(baseOptions: Apollo.QueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
        return Apollo.useQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, baseOptions);
      }
export function useGetProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
          return Apollo.useLazyQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, baseOptions);
        }
export type GetProductQueryHookResult = ReturnType<typeof useGetProductQuery>;
export type GetProductLazyQueryHookResult = ReturnType<typeof useGetProductLazyQuery>;
export type GetProductQueryResult = Apollo.QueryResult<GetProductQuery, GetProductQueryVariables>;
export const GetProductBySkuDocument = gql`
    query getProductBySku($sku: String!) {
  getProductBySku(sku: $sku) {
    id
    sku
    title
    description
    full_price
    sell_description
    sell_price
    price_per_month
    price_per_year
    category
    picture_url
    picture_urls
    bot {
      id
    }
    stock_qty
  }
}
    `;

/**
 * __useGetProductBySkuQuery__
 *
 * To run a query within a React component, call `useGetProductBySkuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductBySkuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductBySkuQuery({
 *   variables: {
 *      sku: // value for 'sku'
 *   },
 * });
 */
export function useGetProductBySkuQuery(baseOptions: Apollo.QueryHookOptions<GetProductBySkuQuery, GetProductBySkuQueryVariables>) {
        return Apollo.useQuery<GetProductBySkuQuery, GetProductBySkuQueryVariables>(GetProductBySkuDocument, baseOptions);
      }
export function useGetProductBySkuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductBySkuQuery, GetProductBySkuQueryVariables>) {
          return Apollo.useLazyQuery<GetProductBySkuQuery, GetProductBySkuQueryVariables>(GetProductBySkuDocument, baseOptions);
        }
export type GetProductBySkuQueryHookResult = ReturnType<typeof useGetProductBySkuQuery>;
export type GetProductBySkuLazyQueryHookResult = ReturnType<typeof useGetProductBySkuLazyQuery>;
export type GetProductBySkuQueryResult = Apollo.QueryResult<GetProductBySkuQuery, GetProductBySkuQueryVariables>;
export const AllProductsDocument = gql`
    query allProducts($input: ProductSearchInput) {
  allProducts(input: $input) {
    total
    data {
      sku
      title
      sell_description
      sell_price
      category
    }
  }
}
    `;

/**
 * __useAllProductsQuery__
 *
 * To run a query within a React component, call `useAllProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProductsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAllProductsQuery(baseOptions?: Apollo.QueryHookOptions<AllProductsQuery, AllProductsQueryVariables>) {
        return Apollo.useQuery<AllProductsQuery, AllProductsQueryVariables>(AllProductsDocument, baseOptions);
      }
export function useAllProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllProductsQuery, AllProductsQueryVariables>) {
          return Apollo.useLazyQuery<AllProductsQuery, AllProductsQueryVariables>(AllProductsDocument, baseOptions);
        }
export type AllProductsQueryHookResult = ReturnType<typeof useAllProductsQuery>;
export type AllProductsLazyQueryHookResult = ReturnType<typeof useAllProductsLazyQuery>;
export type AllProductsQueryResult = Apollo.QueryResult<AllProductsQuery, AllProductsQueryVariables>;
export const AddStockToProductDocument = gql`
    mutation addStockToProduct($qty: Float!, $sku: String!) {
  addStockToProduct(qty: $qty, sku: $sku) {
    id
  }
}
    `;
export type AddStockToProductMutationFn = Apollo.MutationFunction<AddStockToProductMutation, AddStockToProductMutationVariables>;

/**
 * __useAddStockToProductMutation__
 *
 * To run a mutation, you first call `useAddStockToProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStockToProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStockToProductMutation, { data, loading, error }] = useAddStockToProductMutation({
 *   variables: {
 *      qty: // value for 'qty'
 *      sku: // value for 'sku'
 *   },
 * });
 */
export function useAddStockToProductMutation(baseOptions?: Apollo.MutationHookOptions<AddStockToProductMutation, AddStockToProductMutationVariables>) {
        return Apollo.useMutation<AddStockToProductMutation, AddStockToProductMutationVariables>(AddStockToProductDocument, baseOptions);
      }
export type AddStockToProductMutationHookResult = ReturnType<typeof useAddStockToProductMutation>;
export type AddStockToProductMutationResult = Apollo.MutationResult<AddStockToProductMutation>;
export type AddStockToProductMutationOptions = Apollo.BaseMutationOptions<AddStockToProductMutation, AddStockToProductMutationVariables>;
export const UpdateProductDocument = gql`
    mutation updateProduct($id: String!, $input: ProductInput!) {
  updateProduct(id: $id, input: $input) {
    id
    sku
    title
    description
    sell_price
    sell_description
    price_per_month
    price_per_year
    category
    picture_url
    picture_urls
    stock_qty
  }
}
    `;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, baseOptions);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const RemoveProductDocument = gql`
    mutation removeProduct($id: String!) {
  removeProduct(id: $id) {
    id
  }
}
    `;
export type RemoveProductMutationFn = Apollo.MutationFunction<RemoveProductMutation, RemoveProductMutationVariables>;

/**
 * __useRemoveProductMutation__
 *
 * To run a mutation, you first call `useRemoveProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProductMutation, { data, loading, error }] = useRemoveProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveProductMutation(baseOptions?: Apollo.MutationHookOptions<RemoveProductMutation, RemoveProductMutationVariables>) {
        return Apollo.useMutation<RemoveProductMutation, RemoveProductMutationVariables>(RemoveProductDocument, baseOptions);
      }
export type RemoveProductMutationHookResult = ReturnType<typeof useRemoveProductMutation>;
export type RemoveProductMutationResult = Apollo.MutationResult<RemoveProductMutation>;
export type RemoveProductMutationOptions = Apollo.BaseMutationOptions<RemoveProductMutation, RemoveProductMutationVariables>;
export const OnProductChangedDocument = gql`
    subscription onProductChanged($filters: ProductSubscriptionFilter) {
  onProductChanged(filters: $filters) {
    operation
    data {
      id
      sku
      title
      category
      sell_price
      sell_description
      picture_url
      picture_urls
      stock_qty
      description
      live_id
      keywords
      live_status
    }
  }
}
    `;

/**
 * __useOnProductChangedSubscription__
 *
 * To run a query within a React component, call `useOnProductChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnProductChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnProductChangedSubscription({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useOnProductChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnProductChangedSubscription, OnProductChangedSubscriptionVariables>) {
        return Apollo.useSubscription<OnProductChangedSubscription, OnProductChangedSubscriptionVariables>(OnProductChangedDocument, baseOptions);
      }
export type OnProductChangedSubscriptionHookResult = ReturnType<typeof useOnProductChangedSubscription>;
export type OnProductChangedSubscriptionResult = Apollo.SubscriptionResult<OnProductChangedSubscription>;
export const SearchSalesOrderDocument = gql`
    query searchSalesOrder($input: SalesOrderSearchInput!) {
  searchSalesOrder(input: $input) {
    total
    next
    data {
      id
      doc_no
      code
      status
      user_id
      medium_id
      display_name
      profile_pic
      grand_total
      total_qty
      creation
      tags
      delivery_note {
        id
        rate
      }
    }
  }
}
    `;

/**
 * __useSearchSalesOrderQuery__
 *
 * To run a query within a React component, call `useSearchSalesOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSalesOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSalesOrderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchSalesOrderQuery(baseOptions: Apollo.QueryHookOptions<SearchSalesOrderQuery, SearchSalesOrderQueryVariables>) {
        return Apollo.useQuery<SearchSalesOrderQuery, SearchSalesOrderQueryVariables>(SearchSalesOrderDocument, baseOptions);
      }
export function useSearchSalesOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchSalesOrderQuery, SearchSalesOrderQueryVariables>) {
          return Apollo.useLazyQuery<SearchSalesOrderQuery, SearchSalesOrderQueryVariables>(SearchSalesOrderDocument, baseOptions);
        }
export type SearchSalesOrderQueryHookResult = ReturnType<typeof useSearchSalesOrderQuery>;
export type SearchSalesOrderLazyQueryHookResult = ReturnType<typeof useSearchSalesOrderLazyQuery>;
export type SearchSalesOrderQueryResult = Apollo.QueryResult<SearchSalesOrderQuery, SearchSalesOrderQueryVariables>;
export const CreateSalesOrderDocument = gql`
    mutation createSalesOrder($input: SalesOrderInput!) {
  createSalesOrder(input: $input) {
    id
    doc_no
    medium_id
    contact {
      ...contactFields
    }
    items {
      ...itemFields
    }
  }
}
    ${ContactFieldsFragmentDoc}
${ItemFieldsFragmentDoc}`;
export type CreateSalesOrderMutationFn = Apollo.MutationFunction<CreateSalesOrderMutation, CreateSalesOrderMutationVariables>;

/**
 * __useCreateSalesOrderMutation__
 *
 * To run a mutation, you first call `useCreateSalesOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSalesOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSalesOrderMutation, { data, loading, error }] = useCreateSalesOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSalesOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateSalesOrderMutation, CreateSalesOrderMutationVariables>) {
        return Apollo.useMutation<CreateSalesOrderMutation, CreateSalesOrderMutationVariables>(CreateSalesOrderDocument, baseOptions);
      }
export type CreateSalesOrderMutationHookResult = ReturnType<typeof useCreateSalesOrderMutation>;
export type CreateSalesOrderMutationResult = Apollo.MutationResult<CreateSalesOrderMutation>;
export type CreateSalesOrderMutationOptions = Apollo.BaseMutationOptions<CreateSalesOrderMutation, CreateSalesOrderMutationVariables>;
export const GetSalesOrderDocument = gql`
    query getSalesOrder($id: String!) {
  getSalesOrder(id: $id) {
    id
    doc_no
    code
    status
    user_id
    display_name
    profile_pic
    grand_total
    total_qty
    tags
    medium_id
    contact {
      ...contactFields
    }
    items {
      ...itemFields
    }
    payment {
      id
      slip_url
      amount
    }
    delivery_note {
      id
      shipping {
        vendor
      }
      receiver
      address
      zipcode
      phone
      rate
    }
    creation
  }
}
    ${ContactFieldsFragmentDoc}
${ItemFieldsFragmentDoc}`;

/**
 * __useGetSalesOrderQuery__
 *
 * To run a query within a React component, call `useGetSalesOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSalesOrderQuery(baseOptions: Apollo.QueryHookOptions<GetSalesOrderQuery, GetSalesOrderQueryVariables>) {
        return Apollo.useQuery<GetSalesOrderQuery, GetSalesOrderQueryVariables>(GetSalesOrderDocument, baseOptions);
      }
export function useGetSalesOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalesOrderQuery, GetSalesOrderQueryVariables>) {
          return Apollo.useLazyQuery<GetSalesOrderQuery, GetSalesOrderQueryVariables>(GetSalesOrderDocument, baseOptions);
        }
export type GetSalesOrderQueryHookResult = ReturnType<typeof useGetSalesOrderQuery>;
export type GetSalesOrderLazyQueryHookResult = ReturnType<typeof useGetSalesOrderLazyQuery>;
export type GetSalesOrderQueryResult = Apollo.QueryResult<GetSalesOrderQuery, GetSalesOrderQueryVariables>;
export const UpdateSalesOrderDocument = gql`
    mutation updateSalesOrder($id: String!, $input: SalesOrderInput!) {
  updateSalesOrder(id: $id, input: $input) {
    id
  }
}
    `;
export type UpdateSalesOrderMutationFn = Apollo.MutationFunction<UpdateSalesOrderMutation, UpdateSalesOrderMutationVariables>;

/**
 * __useUpdateSalesOrderMutation__
 *
 * To run a mutation, you first call `useUpdateSalesOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSalesOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSalesOrderMutation, { data, loading, error }] = useUpdateSalesOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSalesOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSalesOrderMutation, UpdateSalesOrderMutationVariables>) {
        return Apollo.useMutation<UpdateSalesOrderMutation, UpdateSalesOrderMutationVariables>(UpdateSalesOrderDocument, baseOptions);
      }
export type UpdateSalesOrderMutationHookResult = ReturnType<typeof useUpdateSalesOrderMutation>;
export type UpdateSalesOrderMutationResult = Apollo.MutationResult<UpdateSalesOrderMutation>;
export type UpdateSalesOrderMutationOptions = Apollo.BaseMutationOptions<UpdateSalesOrderMutation, UpdateSalesOrderMutationVariables>;
export const RemoveSalesOrderDocument = gql`
    mutation removeSalesOrder($id: String!) {
  removeSalesOrder(id: $id) {
    id
  }
}
    `;
export type RemoveSalesOrderMutationFn = Apollo.MutationFunction<RemoveSalesOrderMutation, RemoveSalesOrderMutationVariables>;

/**
 * __useRemoveSalesOrderMutation__
 *
 * To run a mutation, you first call `useRemoveSalesOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSalesOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSalesOrderMutation, { data, loading, error }] = useRemoveSalesOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveSalesOrderMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSalesOrderMutation, RemoveSalesOrderMutationVariables>) {
        return Apollo.useMutation<RemoveSalesOrderMutation, RemoveSalesOrderMutationVariables>(RemoveSalesOrderDocument, baseOptions);
      }
export type RemoveSalesOrderMutationHookResult = ReturnType<typeof useRemoveSalesOrderMutation>;
export type RemoveSalesOrderMutationResult = Apollo.MutationResult<RemoveSalesOrderMutation>;
export type RemoveSalesOrderMutationOptions = Apollo.BaseMutationOptions<RemoveSalesOrderMutation, RemoveSalesOrderMutationVariables>;
export const OnSalesOrderChangedDocument = gql`
    subscription onSalesOrderChanged($filters: SalesOrderSubscriptionFilter) {
  onSalesOrderChanged(filters: $filters) {
    operation
    data {
      id
      doc_no
      code
      status
      user_id
      display_name
      profile_pic
      grand_total
      total_qty
      medium_id
      tags
      items {
        ...itemFields
      }
      delivery_note {
        id
        shipping {
          vendor
        }
        receiver
        address
        zipcode
        phone
        rate
      }
      creation
    }
  }
}
    ${ItemFieldsFragmentDoc}`;

/**
 * __useOnSalesOrderChangedSubscription__
 *
 * To run a query within a React component, call `useOnSalesOrderChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnSalesOrderChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnSalesOrderChangedSubscription({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useOnSalesOrderChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnSalesOrderChangedSubscription, OnSalesOrderChangedSubscriptionVariables>) {
        return Apollo.useSubscription<OnSalesOrderChangedSubscription, OnSalesOrderChangedSubscriptionVariables>(OnSalesOrderChangedDocument, baseOptions);
      }
export type OnSalesOrderChangedSubscriptionHookResult = ReturnType<typeof useOnSalesOrderChangedSubscription>;
export type OnSalesOrderChangedSubscriptionResult = Apollo.SubscriptionResult<OnSalesOrderChangedSubscription>;
export const SearchSentenceDocument = gql`
    query searchSentence($input: SentenceSearchInput) {
  searchSentence(input: $input) {
    total
    next
    data {
      id
      lang
      type
      text
      intent_id
      intent_title
    }
  }
}
    `;

/**
 * __useSearchSentenceQuery__
 *
 * To run a query within a React component, call `useSearchSentenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSentenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSentenceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchSentenceQuery(baseOptions?: Apollo.QueryHookOptions<SearchSentenceQuery, SearchSentenceQueryVariables>) {
        return Apollo.useQuery<SearchSentenceQuery, SearchSentenceQueryVariables>(SearchSentenceDocument, baseOptions);
      }
export function useSearchSentenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchSentenceQuery, SearchSentenceQueryVariables>) {
          return Apollo.useLazyQuery<SearchSentenceQuery, SearchSentenceQueryVariables>(SearchSentenceDocument, baseOptions);
        }
export type SearchSentenceQueryHookResult = ReturnType<typeof useSearchSentenceQuery>;
export type SearchSentenceLazyQueryHookResult = ReturnType<typeof useSearchSentenceLazyQuery>;
export type SearchSentenceQueryResult = Apollo.QueryResult<SearchSentenceQuery, SearchSentenceQueryVariables>;
export const CreateSentenceDocument = gql`
    mutation createSentence($input: SentenceInput!) {
  createSentence(input: $input) {
    id
    lang
    type
    text
    intent_id
    intent_title
  }
}
    `;
export type CreateSentenceMutationFn = Apollo.MutationFunction<CreateSentenceMutation, CreateSentenceMutationVariables>;

/**
 * __useCreateSentenceMutation__
 *
 * To run a mutation, you first call `useCreateSentenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSentenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSentenceMutation, { data, loading, error }] = useCreateSentenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSentenceMutation(baseOptions?: Apollo.MutationHookOptions<CreateSentenceMutation, CreateSentenceMutationVariables>) {
        return Apollo.useMutation<CreateSentenceMutation, CreateSentenceMutationVariables>(CreateSentenceDocument, baseOptions);
      }
export type CreateSentenceMutationHookResult = ReturnType<typeof useCreateSentenceMutation>;
export type CreateSentenceMutationResult = Apollo.MutationResult<CreateSentenceMutation>;
export type CreateSentenceMutationOptions = Apollo.BaseMutationOptions<CreateSentenceMutation, CreateSentenceMutationVariables>;
export const GetSentenceDocument = gql`
    query getSentence($id: String!) {
  getSentence(id: $id) {
    id
    lang
    type
    text
    intent_id
    intent_title
  }
}
    `;

/**
 * __useGetSentenceQuery__
 *
 * To run a query within a React component, call `useGetSentenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSentenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSentenceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSentenceQuery(baseOptions: Apollo.QueryHookOptions<GetSentenceQuery, GetSentenceQueryVariables>) {
        return Apollo.useQuery<GetSentenceQuery, GetSentenceQueryVariables>(GetSentenceDocument, baseOptions);
      }
export function useGetSentenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSentenceQuery, GetSentenceQueryVariables>) {
          return Apollo.useLazyQuery<GetSentenceQuery, GetSentenceQueryVariables>(GetSentenceDocument, baseOptions);
        }
export type GetSentenceQueryHookResult = ReturnType<typeof useGetSentenceQuery>;
export type GetSentenceLazyQueryHookResult = ReturnType<typeof useGetSentenceLazyQuery>;
export type GetSentenceQueryResult = Apollo.QueryResult<GetSentenceQuery, GetSentenceQueryVariables>;
export const UpdateSentenceDocument = gql`
    mutation updateSentence($id: String!, $input: SentenceInput!) {
  updateSentence(id: $id, input: $input) {
    id
  }
}
    `;
export type UpdateSentenceMutationFn = Apollo.MutationFunction<UpdateSentenceMutation, UpdateSentenceMutationVariables>;

/**
 * __useUpdateSentenceMutation__
 *
 * To run a mutation, you first call `useUpdateSentenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSentenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSentenceMutation, { data, loading, error }] = useUpdateSentenceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSentenceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSentenceMutation, UpdateSentenceMutationVariables>) {
        return Apollo.useMutation<UpdateSentenceMutation, UpdateSentenceMutationVariables>(UpdateSentenceDocument, baseOptions);
      }
export type UpdateSentenceMutationHookResult = ReturnType<typeof useUpdateSentenceMutation>;
export type UpdateSentenceMutationResult = Apollo.MutationResult<UpdateSentenceMutation>;
export type UpdateSentenceMutationOptions = Apollo.BaseMutationOptions<UpdateSentenceMutation, UpdateSentenceMutationVariables>;
export const RemoveSentenceDocument = gql`
    mutation removeSentence($id: String!) {
  removeSentence(id: $id) {
    id
  }
}
    `;
export type RemoveSentenceMutationFn = Apollo.MutationFunction<RemoveSentenceMutation, RemoveSentenceMutationVariables>;

/**
 * __useRemoveSentenceMutation__
 *
 * To run a mutation, you first call `useRemoveSentenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSentenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSentenceMutation, { data, loading, error }] = useRemoveSentenceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveSentenceMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSentenceMutation, RemoveSentenceMutationVariables>) {
        return Apollo.useMutation<RemoveSentenceMutation, RemoveSentenceMutationVariables>(RemoveSentenceDocument, baseOptions);
      }
export type RemoveSentenceMutationHookResult = ReturnType<typeof useRemoveSentenceMutation>;
export type RemoveSentenceMutationResult = Apollo.MutationResult<RemoveSentenceMutation>;
export type RemoveSentenceMutationOptions = Apollo.BaseMutationOptions<RemoveSentenceMutation, RemoveSentenceMutationVariables>;
export const OnSentenceChangedDocument = gql`
    subscription onSentenceChanged {
  onSentenceChanged {
    operation
    data {
      id
      lang
      type
      text
      intent_id
      intent_title
    }
  }
}
    `;

/**
 * __useOnSentenceChangedSubscription__
 *
 * To run a query within a React component, call `useOnSentenceChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnSentenceChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnSentenceChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnSentenceChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnSentenceChangedSubscription, OnSentenceChangedSubscriptionVariables>) {
        return Apollo.useSubscription<OnSentenceChangedSubscription, OnSentenceChangedSubscriptionVariables>(OnSentenceChangedDocument, baseOptions);
      }
export type OnSentenceChangedSubscriptionHookResult = ReturnType<typeof useOnSentenceChangedSubscription>;
export type OnSentenceChangedSubscriptionResult = Apollo.SubscriptionResult<OnSentenceChangedSubscription>;
export const SearchShippingDocument = gql`
    query searchShipping {
  searchShipping {
    total
    next
    data {
      id
      title
      vendor
      description
      rate
      rate_type
    }
  }
}
    `;

/**
 * __useSearchShippingQuery__
 *
 * To run a query within a React component, call `useSearchShippingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchShippingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchShippingQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchShippingQuery(baseOptions?: Apollo.QueryHookOptions<SearchShippingQuery, SearchShippingQueryVariables>) {
        return Apollo.useQuery<SearchShippingQuery, SearchShippingQueryVariables>(SearchShippingDocument, baseOptions);
      }
export function useSearchShippingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchShippingQuery, SearchShippingQueryVariables>) {
          return Apollo.useLazyQuery<SearchShippingQuery, SearchShippingQueryVariables>(SearchShippingDocument, baseOptions);
        }
export type SearchShippingQueryHookResult = ReturnType<typeof useSearchShippingQuery>;
export type SearchShippingLazyQueryHookResult = ReturnType<typeof useSearchShippingLazyQuery>;
export type SearchShippingQueryResult = Apollo.QueryResult<SearchShippingQuery, SearchShippingQueryVariables>;
export const CreateShippingDocument = gql`
    mutation createShipping($input: ShippingInput!) {
  createShipping(input: $input) {
    id
    title
    vendor
    description
    rate
    rate_type
  }
}
    `;
export type CreateShippingMutationFn = Apollo.MutationFunction<CreateShippingMutation, CreateShippingMutationVariables>;

/**
 * __useCreateShippingMutation__
 *
 * To run a mutation, you first call `useCreateShippingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShippingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShippingMutation, { data, loading, error }] = useCreateShippingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateShippingMutation(baseOptions?: Apollo.MutationHookOptions<CreateShippingMutation, CreateShippingMutationVariables>) {
        return Apollo.useMutation<CreateShippingMutation, CreateShippingMutationVariables>(CreateShippingDocument, baseOptions);
      }
export type CreateShippingMutationHookResult = ReturnType<typeof useCreateShippingMutation>;
export type CreateShippingMutationResult = Apollo.MutationResult<CreateShippingMutation>;
export type CreateShippingMutationOptions = Apollo.BaseMutationOptions<CreateShippingMutation, CreateShippingMutationVariables>;
export const GetShippingDocument = gql`
    query getShipping($id: String!) {
  getShipping(id: $id) {
    id
    title
    vendor
    description
    rate
    rate_type
    min_weight
    max_weight
  }
}
    `;

/**
 * __useGetShippingQuery__
 *
 * To run a query within a React component, call `useGetShippingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShippingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShippingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetShippingQuery(baseOptions: Apollo.QueryHookOptions<GetShippingQuery, GetShippingQueryVariables>) {
        return Apollo.useQuery<GetShippingQuery, GetShippingQueryVariables>(GetShippingDocument, baseOptions);
      }
export function useGetShippingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShippingQuery, GetShippingQueryVariables>) {
          return Apollo.useLazyQuery<GetShippingQuery, GetShippingQueryVariables>(GetShippingDocument, baseOptions);
        }
export type GetShippingQueryHookResult = ReturnType<typeof useGetShippingQuery>;
export type GetShippingLazyQueryHookResult = ReturnType<typeof useGetShippingLazyQuery>;
export type GetShippingQueryResult = Apollo.QueryResult<GetShippingQuery, GetShippingQueryVariables>;
export const UpdateShippingDocument = gql`
    mutation updateShipping($id: String!, $input: ShippingInput!) {
  updateShipping(id: $id, input: $input) {
    id
  }
}
    `;
export type UpdateShippingMutationFn = Apollo.MutationFunction<UpdateShippingMutation, UpdateShippingMutationVariables>;

/**
 * __useUpdateShippingMutation__
 *
 * To run a mutation, you first call `useUpdateShippingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShippingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShippingMutation, { data, loading, error }] = useUpdateShippingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShippingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShippingMutation, UpdateShippingMutationVariables>) {
        return Apollo.useMutation<UpdateShippingMutation, UpdateShippingMutationVariables>(UpdateShippingDocument, baseOptions);
      }
export type UpdateShippingMutationHookResult = ReturnType<typeof useUpdateShippingMutation>;
export type UpdateShippingMutationResult = Apollo.MutationResult<UpdateShippingMutation>;
export type UpdateShippingMutationOptions = Apollo.BaseMutationOptions<UpdateShippingMutation, UpdateShippingMutationVariables>;
export const RemoveShippingDocument = gql`
    mutation removeShipping($id: String!) {
  removeShipping(id: $id) {
    id
  }
}
    `;
export type RemoveShippingMutationFn = Apollo.MutationFunction<RemoveShippingMutation, RemoveShippingMutationVariables>;

/**
 * __useRemoveShippingMutation__
 *
 * To run a mutation, you first call `useRemoveShippingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveShippingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeShippingMutation, { data, loading, error }] = useRemoveShippingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveShippingMutation(baseOptions?: Apollo.MutationHookOptions<RemoveShippingMutation, RemoveShippingMutationVariables>) {
        return Apollo.useMutation<RemoveShippingMutation, RemoveShippingMutationVariables>(RemoveShippingDocument, baseOptions);
      }
export type RemoveShippingMutationHookResult = ReturnType<typeof useRemoveShippingMutation>;
export type RemoveShippingMutationResult = Apollo.MutationResult<RemoveShippingMutation>;
export type RemoveShippingMutationOptions = Apollo.BaseMutationOptions<RemoveShippingMutation, RemoveShippingMutationVariables>;
export const OnShippingChangedDocument = gql`
    subscription onShippingChanged {
  onShippingChanged {
    operation
    data {
      id
      title
      vendor
      description
      rate
      rate_type
    }
  }
}
    `;

/**
 * __useOnShippingChangedSubscription__
 *
 * To run a query within a React component, call `useOnShippingChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnShippingChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnShippingChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnShippingChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnShippingChangedSubscription, OnShippingChangedSubscriptionVariables>) {
        return Apollo.useSubscription<OnShippingChangedSubscription, OnShippingChangedSubscriptionVariables>(OnShippingChangedDocument, baseOptions);
      }
export type OnShippingChangedSubscriptionHookResult = ReturnType<typeof useOnShippingChangedSubscription>;
export type OnShippingChangedSubscriptionResult = Apollo.SubscriptionResult<OnShippingChangedSubscription>;
export const GetShopInfoWithCategoryPublicDocument = gql`
    query getShopInfoWithCategoryPublic($botId: String!) {
  getShopInfoPublic(bot_id: $botId) {
    title
    picture_url
    shippings {
      title
      company
      cost
    }
  }
  searchProductCategoryPublic(bot_id: $botId) {
    next
    total
    data {
      id
      title
      picture_url
    }
  }
}
    `;

/**
 * __useGetShopInfoWithCategoryPublicQuery__
 *
 * To run a query within a React component, call `useGetShopInfoWithCategoryPublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopInfoWithCategoryPublicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopInfoWithCategoryPublicQuery({
 *   variables: {
 *      botId: // value for 'botId'
 *   },
 * });
 */
export function useGetShopInfoWithCategoryPublicQuery(baseOptions: Apollo.QueryHookOptions<GetShopInfoWithCategoryPublicQuery, GetShopInfoWithCategoryPublicQueryVariables>) {
        return Apollo.useQuery<GetShopInfoWithCategoryPublicQuery, GetShopInfoWithCategoryPublicQueryVariables>(GetShopInfoWithCategoryPublicDocument, baseOptions);
      }
export function useGetShopInfoWithCategoryPublicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShopInfoWithCategoryPublicQuery, GetShopInfoWithCategoryPublicQueryVariables>) {
          return Apollo.useLazyQuery<GetShopInfoWithCategoryPublicQuery, GetShopInfoWithCategoryPublicQueryVariables>(GetShopInfoWithCategoryPublicDocument, baseOptions);
        }
export type GetShopInfoWithCategoryPublicQueryHookResult = ReturnType<typeof useGetShopInfoWithCategoryPublicQuery>;
export type GetShopInfoWithCategoryPublicLazyQueryHookResult = ReturnType<typeof useGetShopInfoWithCategoryPublicLazyQuery>;
export type GetShopInfoWithCategoryPublicQueryResult = Apollo.QueryResult<GetShopInfoWithCategoryPublicQuery, GetShopInfoWithCategoryPublicQueryVariables>;
export const SearchProductPublicDocument = gql`
    query searchProductPublic($botId: String!, $category: String!) {
  searchProductPublic(bot_id: $botId, input: {filters: {category: $category}}) {
    data {
      id
      sku
      title
      category
      sell_price
      sell_description
      picture_url
      picture_urls
      stock_qty
    }
  }
}
    `;

/**
 * __useSearchProductPublicQuery__
 *
 * To run a query within a React component, call `useSearchProductPublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProductPublicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProductPublicQuery({
 *   variables: {
 *      botId: // value for 'botId'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useSearchProductPublicQuery(baseOptions: Apollo.QueryHookOptions<SearchProductPublicQuery, SearchProductPublicQueryVariables>) {
        return Apollo.useQuery<SearchProductPublicQuery, SearchProductPublicQueryVariables>(SearchProductPublicDocument, baseOptions);
      }
export function useSearchProductPublicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchProductPublicQuery, SearchProductPublicQueryVariables>) {
          return Apollo.useLazyQuery<SearchProductPublicQuery, SearchProductPublicQueryVariables>(SearchProductPublicDocument, baseOptions);
        }
export type SearchProductPublicQueryHookResult = ReturnType<typeof useSearchProductPublicQuery>;
export type SearchProductPublicLazyQueryHookResult = ReturnType<typeof useSearchProductPublicLazyQuery>;
export type SearchProductPublicQueryResult = Apollo.QueryResult<SearchProductPublicQuery, SearchProductPublicQueryVariables>;
export const GetProductPublicDocument = gql`
    query getProductPublic($botId: String!, $sku: String!) {
  getProductPublic(bot_id: $botId, sku: $sku) {
    id
    title
    sku
    category
    sell_price
    sell_description
    picture_url
    stock_qty
  }
}
    `;

/**
 * __useGetProductPublicQuery__
 *
 * To run a query within a React component, call `useGetProductPublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductPublicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductPublicQuery({
 *   variables: {
 *      botId: // value for 'botId'
 *      sku: // value for 'sku'
 *   },
 * });
 */
export function useGetProductPublicQuery(baseOptions: Apollo.QueryHookOptions<GetProductPublicQuery, GetProductPublicQueryVariables>) {
        return Apollo.useQuery<GetProductPublicQuery, GetProductPublicQueryVariables>(GetProductPublicDocument, baseOptions);
      }
export function useGetProductPublicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductPublicQuery, GetProductPublicQueryVariables>) {
          return Apollo.useLazyQuery<GetProductPublicQuery, GetProductPublicQueryVariables>(GetProductPublicDocument, baseOptions);
        }
export type GetProductPublicQueryHookResult = ReturnType<typeof useGetProductPublicQuery>;
export type GetProductPublicLazyQueryHookResult = ReturnType<typeof useGetProductPublicLazyQuery>;
export type GetProductPublicQueryResult = Apollo.QueryResult<GetProductPublicQuery, GetProductPublicQueryVariables>;
export const CreateProductDocument = gql`
    mutation createProduct($input: ProductInput!) {
  createProduct(input: $input) {
    id
    sku
  }
}
    `;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, baseOptions);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<CreateProductMutation, CreateProductMutationVariables>;
export const SearchSimpleBookingDocument = gql`
    query searchSimpleBooking {
  searchSimpleBooking {
    total
    next
    data {
      id
      subject
      contact_name
      contact_phone
      start_time
      end_time
      branch
      sku
      status
      duration
      friend {
        id
        picture_url
      }
    }
  }
}
    `;

/**
 * __useSearchSimpleBookingQuery__
 *
 * To run a query within a React component, call `useSearchSimpleBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSimpleBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSimpleBookingQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchSimpleBookingQuery(baseOptions?: Apollo.QueryHookOptions<SearchSimpleBookingQuery, SearchSimpleBookingQueryVariables>) {
        return Apollo.useQuery<SearchSimpleBookingQuery, SearchSimpleBookingQueryVariables>(SearchSimpleBookingDocument, baseOptions);
      }
export function useSearchSimpleBookingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchSimpleBookingQuery, SearchSimpleBookingQueryVariables>) {
          return Apollo.useLazyQuery<SearchSimpleBookingQuery, SearchSimpleBookingQueryVariables>(SearchSimpleBookingDocument, baseOptions);
        }
export type SearchSimpleBookingQueryHookResult = ReturnType<typeof useSearchSimpleBookingQuery>;
export type SearchSimpleBookingLazyQueryHookResult = ReturnType<typeof useSearchSimpleBookingLazyQuery>;
export type SearchSimpleBookingQueryResult = Apollo.QueryResult<SearchSimpleBookingQuery, SearchSimpleBookingQueryVariables>;
export const CreateSimpleBookingDocument = gql`
    mutation createSimpleBooking($input: SimpleBookingInput!) {
  createSimpleBooking(input: $input) {
    id
    subject
  }
}
    `;
export type CreateSimpleBookingMutationFn = Apollo.MutationFunction<CreateSimpleBookingMutation, CreateSimpleBookingMutationVariables>;

/**
 * __useCreateSimpleBookingMutation__
 *
 * To run a mutation, you first call `useCreateSimpleBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSimpleBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSimpleBookingMutation, { data, loading, error }] = useCreateSimpleBookingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSimpleBookingMutation(baseOptions?: Apollo.MutationHookOptions<CreateSimpleBookingMutation, CreateSimpleBookingMutationVariables>) {
        return Apollo.useMutation<CreateSimpleBookingMutation, CreateSimpleBookingMutationVariables>(CreateSimpleBookingDocument, baseOptions);
      }
export type CreateSimpleBookingMutationHookResult = ReturnType<typeof useCreateSimpleBookingMutation>;
export type CreateSimpleBookingMutationResult = Apollo.MutationResult<CreateSimpleBookingMutation>;
export type CreateSimpleBookingMutationOptions = Apollo.BaseMutationOptions<CreateSimpleBookingMutation, CreateSimpleBookingMutationVariables>;
export const GetSimpleBookingDocument = gql`
    query getSimpleBooking($id: String!) {
  getSimpleBooking(id: $id) {
    bot_id
    chat_id
    id
    subject
    contact_name
    contact_phone
    description
    start_time
    end_time
    duration
    branch
    sku
    status
  }
}
    `;

/**
 * __useGetSimpleBookingQuery__
 *
 * To run a query within a React component, call `useGetSimpleBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSimpleBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimpleBookingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSimpleBookingQuery(baseOptions: Apollo.QueryHookOptions<GetSimpleBookingQuery, GetSimpleBookingQueryVariables>) {
        return Apollo.useQuery<GetSimpleBookingQuery, GetSimpleBookingQueryVariables>(GetSimpleBookingDocument, baseOptions);
      }
export function useGetSimpleBookingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSimpleBookingQuery, GetSimpleBookingQueryVariables>) {
          return Apollo.useLazyQuery<GetSimpleBookingQuery, GetSimpleBookingQueryVariables>(GetSimpleBookingDocument, baseOptions);
        }
export type GetSimpleBookingQueryHookResult = ReturnType<typeof useGetSimpleBookingQuery>;
export type GetSimpleBookingLazyQueryHookResult = ReturnType<typeof useGetSimpleBookingLazyQuery>;
export type GetSimpleBookingQueryResult = Apollo.QueryResult<GetSimpleBookingQuery, GetSimpleBookingQueryVariables>;
export const UpdateSimpleBookingDocument = gql`
    mutation updateSimpleBooking($id: String!, $input: SimpleBookingInput!) {
  updateSimpleBooking(id: $id, input: $input) {
    id
  }
}
    `;
export type UpdateSimpleBookingMutationFn = Apollo.MutationFunction<UpdateSimpleBookingMutation, UpdateSimpleBookingMutationVariables>;

/**
 * __useUpdateSimpleBookingMutation__
 *
 * To run a mutation, you first call `useUpdateSimpleBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSimpleBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSimpleBookingMutation, { data, loading, error }] = useUpdateSimpleBookingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSimpleBookingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSimpleBookingMutation, UpdateSimpleBookingMutationVariables>) {
        return Apollo.useMutation<UpdateSimpleBookingMutation, UpdateSimpleBookingMutationVariables>(UpdateSimpleBookingDocument, baseOptions);
      }
export type UpdateSimpleBookingMutationHookResult = ReturnType<typeof useUpdateSimpleBookingMutation>;
export type UpdateSimpleBookingMutationResult = Apollo.MutationResult<UpdateSimpleBookingMutation>;
export type UpdateSimpleBookingMutationOptions = Apollo.BaseMutationOptions<UpdateSimpleBookingMutation, UpdateSimpleBookingMutationVariables>;
export const RemoveSimpleBookingDocument = gql`
    mutation removeSimpleBooking($id: String!) {
  removeSimpleBooking(id: $id) {
    id
  }
}
    `;
export type RemoveSimpleBookingMutationFn = Apollo.MutationFunction<RemoveSimpleBookingMutation, RemoveSimpleBookingMutationVariables>;

/**
 * __useRemoveSimpleBookingMutation__
 *
 * To run a mutation, you first call `useRemoveSimpleBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSimpleBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSimpleBookingMutation, { data, loading, error }] = useRemoveSimpleBookingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveSimpleBookingMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSimpleBookingMutation, RemoveSimpleBookingMutationVariables>) {
        return Apollo.useMutation<RemoveSimpleBookingMutation, RemoveSimpleBookingMutationVariables>(RemoveSimpleBookingDocument, baseOptions);
      }
export type RemoveSimpleBookingMutationHookResult = ReturnType<typeof useRemoveSimpleBookingMutation>;
export type RemoveSimpleBookingMutationResult = Apollo.MutationResult<RemoveSimpleBookingMutation>;
export type RemoveSimpleBookingMutationOptions = Apollo.BaseMutationOptions<RemoveSimpleBookingMutation, RemoveSimpleBookingMutationVariables>;
export const OnSimpleBookingChangedDocument = gql`
    subscription onSimpleBookingChanged {
  onSimpleBookingChanged {
    operation
    data {
      id
      subject
      contact_name
      contact_phone
      description
      start_time
      branch
      sku
      status
    }
  }
}
    `;

/**
 * __useOnSimpleBookingChangedSubscription__
 *
 * To run a query within a React component, call `useOnSimpleBookingChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnSimpleBookingChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnSimpleBookingChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnSimpleBookingChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnSimpleBookingChangedSubscription, OnSimpleBookingChangedSubscriptionVariables>) {
        return Apollo.useSubscription<OnSimpleBookingChangedSubscription, OnSimpleBookingChangedSubscriptionVariables>(OnSimpleBookingChangedDocument, baseOptions);
      }
export type OnSimpleBookingChangedSubscriptionHookResult = ReturnType<typeof useOnSimpleBookingChangedSubscription>;
export type OnSimpleBookingChangedSubscriptionResult = Apollo.SubscriptionResult<OnSimpleBookingChangedSubscription>;
export const CreateStockDocument = gql`
    mutation createStock($input: StockInput!) {
  createStock(input: $input) {
    id
  }
}
    `;
export type CreateStockMutationFn = Apollo.MutationFunction<CreateStockMutation, CreateStockMutationVariables>;

/**
 * __useCreateStockMutation__
 *
 * To run a mutation, you first call `useCreateStockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockMutation, { data, loading, error }] = useCreateStockMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStockMutation(baseOptions?: Apollo.MutationHookOptions<CreateStockMutation, CreateStockMutationVariables>) {
        return Apollo.useMutation<CreateStockMutation, CreateStockMutationVariables>(CreateStockDocument, baseOptions);
      }
export type CreateStockMutationHookResult = ReturnType<typeof useCreateStockMutation>;
export type CreateStockMutationResult = Apollo.MutationResult<CreateStockMutation>;
export type CreateStockMutationOptions = Apollo.BaseMutationOptions<CreateStockMutation, CreateStockMutationVariables>;
export const OnStockChangedDocument = gql`
    subscription onStockChanged($filters: StockSubscriptionFilter) {
  onStockChanged(filters: $filters) {
    operation
    data {
      type
      product_id
      qty
      status
    }
  }
}
    `;

/**
 * __useOnStockChangedSubscription__
 *
 * To run a query within a React component, call `useOnStockChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnStockChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnStockChangedSubscription({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useOnStockChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnStockChangedSubscription, OnStockChangedSubscriptionVariables>) {
        return Apollo.useSubscription<OnStockChangedSubscription, OnStockChangedSubscriptionVariables>(OnStockChangedDocument, baseOptions);
      }
export type OnStockChangedSubscriptionHookResult = ReturnType<typeof useOnStockChangedSubscription>;
export type OnStockChangedSubscriptionResult = Apollo.SubscriptionResult<OnStockChangedSubscription>;
export const SearchTagDocument = gql`
    query searchTag($input: TagSearchInput) {
  searchTag(input: $input) {
    data {
      id
      title
      type
    }
  }
}
    `;

/**
 * __useSearchTagQuery__
 *
 * To run a query within a React component, call `useSearchTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTagQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchTagQuery(baseOptions?: Apollo.QueryHookOptions<SearchTagQuery, SearchTagQueryVariables>) {
        return Apollo.useQuery<SearchTagQuery, SearchTagQueryVariables>(SearchTagDocument, baseOptions);
      }
export function useSearchTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchTagQuery, SearchTagQueryVariables>) {
          return Apollo.useLazyQuery<SearchTagQuery, SearchTagQueryVariables>(SearchTagDocument, baseOptions);
        }
export type SearchTagQueryHookResult = ReturnType<typeof useSearchTagQuery>;
export type SearchTagLazyQueryHookResult = ReturnType<typeof useSearchTagLazyQuery>;
export type SearchTagQueryResult = Apollo.QueryResult<SearchTagQuery, SearchTagQueryVariables>;
export const SearchTagWithCountDocument = gql`
    query searchTagWithCount($input: TagSearchInput) {
  searchTag(input: $input) {
    data {
      id
      title
      type
    }
  }
}
    `;

/**
 * __useSearchTagWithCountQuery__
 *
 * To run a query within a React component, call `useSearchTagWithCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTagWithCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTagWithCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchTagWithCountQuery(baseOptions?: Apollo.QueryHookOptions<SearchTagWithCountQuery, SearchTagWithCountQueryVariables>) {
        return Apollo.useQuery<SearchTagWithCountQuery, SearchTagWithCountQueryVariables>(SearchTagWithCountDocument, baseOptions);
      }
export function useSearchTagWithCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchTagWithCountQuery, SearchTagWithCountQueryVariables>) {
          return Apollo.useLazyQuery<SearchTagWithCountQuery, SearchTagWithCountQueryVariables>(SearchTagWithCountDocument, baseOptions);
        }
export type SearchTagWithCountQueryHookResult = ReturnType<typeof useSearchTagWithCountQuery>;
export type SearchTagWithCountLazyQueryHookResult = ReturnType<typeof useSearchTagWithCountLazyQuery>;
export type SearchTagWithCountQueryResult = Apollo.QueryResult<SearchTagWithCountQuery, SearchTagWithCountQueryVariables>;
export const OnTagChangedDocument = gql`
    subscription onTagChanged {
  onTagChanged {
    operation
    data {
      id
    }
  }
}
    `;

/**
 * __useOnTagChangedSubscription__
 *
 * To run a query within a React component, call `useOnTagChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnTagChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnTagChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnTagChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnTagChangedSubscription, OnTagChangedSubscriptionVariables>) {
        return Apollo.useSubscription<OnTagChangedSubscription, OnTagChangedSubscriptionVariables>(OnTagChangedDocument, baseOptions);
      }
export type OnTagChangedSubscriptionHookResult = ReturnType<typeof useOnTagChangedSubscription>;
export type OnTagChangedSubscriptionResult = Apollo.SubscriptionResult<OnTagChangedSubscription>;